import { AlignJustify, AlignLeft, AlignRight } from './align';
import { BaseIconProps } from './types/iconography.types';

interface Props extends BaseIconProps {
  align: 'left' | 'justify' | 'right';
}

/**
 ```tsx
  <AlignIcon 
    align="justify" 
    color="gray700" 
  />
 ```
 */
export const AlignIcon = (props: Props) => {
  const { className, align, color } = props;

  switch (align) {
    case 'left':
      return <AlignLeft className={className} color={color} />;
    case 'justify':
      return <AlignJustify className={className} color={color} />;
    case 'right':
      return <AlignRight className={className} color={color} />;
  }
};
