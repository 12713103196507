import { createContext, useContext } from 'react';
import { DropdownMenuSize } from '../DropdownMenu.types';

export interface DropdownMenuContextType {
  size: DropdownMenuSize;
  isToggled: boolean;
  onToggle: (nextIsToggled: boolean) => void;
}

export const DropdownMenuContext = createContext<DropdownMenuContextType | null>(null);

export const DropdownMenuProvider = DropdownMenuContext.Provider;

export const useDropdownMenuContext = () => {
  const context = useContext(DropdownMenuContext);

  if (!context) {
    throw new Error('useDropdownMenuContext should be used within Root');
  }

  return context;
};
