import { useDialog } from '@29cm/admin-design-system';
import { useLocalStorage } from '@29cm/admin-utils';
import { HTTP_STATUS } from '@inhouse/core';
import { useRouter } from 'next/router';
import { FormEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { JoinFailMessageErrorCode, Result } from '~apps/@shared/apis/join/JoinService.types';
import { loginApi } from '~apps/@shared/apis/login/LoginService';
import { otpApi } from '~apps/@shared/apis/otp/OtpService';
import { AUTH_FORM_ERROR_MESSAGE, AUTH_FORM_REGEXP } from '~apps/@shared/constants/authForm';
import { IS_VISITED_LOCAL_STORAGE_KEY } from '~apps/@shared/constants/firstVisit';
import { useErrorHandler, useQueryParams, useRedirectUrl } from '~apps/@shared/hooks';
import { UnionRegistration } from '~apps/login/components/dialogs/union-registration/UnionRegistration';
import { UNION_REGISTRATION_DIALOG_ID } from '~apps/login/constants/unionRegistrationDialogId';

interface FormElements extends HTMLFormElement {
  loginId: HTMLInputElement;
  password: HTMLInputElement;
}

interface FormTarget extends FormEvent<HTMLFormElement> {
  target: FormElements;
}

interface LoginFormElements {
  loginId: string;
  password: string;
}

export const useLoginFormWithId = () => {
  const {
    register,
    formState: { errors },
  } = useForm<LoginFormElements>({ mode: 'onChange' });

  const loginIdFieldProps = {
    ...register('loginId', {
      pattern: {
        value: AUTH_FORM_REGEXP.ID,
        message: AUTH_FORM_ERROR_MESSAGE.ID,
      },
    }),
  };

  const passwordFieldProps = {
    ...register('password', {
      pattern: {
        value: AUTH_FORM_REGEXP.PASSWORD,
        message: AUTH_FORM_ERROR_MESSAGE.PASSWORD,
      },
    }),
  };

  return {
    errors,
    loginIdFieldProps,
    passwordFieldProps,
  };
};

const verifyOtpCode = async (key: string) => {
  const { data, status } = await otpApi.verifyKey(key);

  if (status !== HTTP_STATUS.OK || data.result !== 'SUCCESS') {
    throw new Error(data.message || 'user key가 정확하지 않습니다.');
  }

  return data.data.otpQRUri;
};

export const useLoginAction = () => {
  const [submitFlag, setSubmitFlag] = useState(false);
  const router = useRouter();
  const errorHandler = useErrorHandler();
  const { redirect_uri } = useQueryParams<{ redirect_uri: string }>();
  const redirectUrl = useRedirectUrl(redirect_uri);

  const handleLoginSubmit = async (event: FormTarget) => {
    event.preventDefault();

    if (submitFlag) {
      return;
    }

    setSubmitFlag(true);

    const userInfo = {
      loginId: event.target.loginId.value,
      password: event.target.password.value,
    };

    try {
      const { data: loginApiData, status } = await loginApi.login(userInfo);

      if (
        JoinFailMessageErrorCode.REQUIRED_UPDATE_PASSWORD &&
        loginApiData.data &&
        'verificationCode' in loginApiData.data
      ) {
        setSubmitFlag(false);

        router.push({
          pathname: '/join/find-password/reset-password',
          query: {
            ...router.query,
            code: loginApiData.data.verificationCode,
          },
        });
        return;
      }

      if (status !== HTTP_STATUS.OK || loginApiData.result !== Result.SUCCESS || loginApiData.data == null) {
        throw new Error(loginApiData.message || '로그인 정보를 확인해 주세요.');
      }

      if (!('key' in loginApiData.data)) {
        return;
      }

      const key = loginApiData.data.key;

      const otpQRUri = await verifyOtpCode(key);

      if (!otpQRUri) {
        router.push(
          {
            pathname: '/otp',
            query: {
              userKey: key,
              redirect_uri: redirectUrl,
            },
          },
          '/otp',
        );
        return;
      }

      router.push(
        {
          pathname: '/otp/register',
          query: {
            redirect_uri: redirectUrl,
            userKey: key,
            otpQRUri,
          },
        },
        '/otp/register',
      );
    } catch (err) {
      errorHandler(err, setSubmitFlag);
    }
  };

  return { handleLoginSubmit, isLoading: submitFlag };
};

export const useUnionRegistration = () => {
  const { push } = useRouter();

  const handleUnionRegistrationClick = () => {
    push('/join');
  };

  return {
    handleUnionRegistrationClick,
  };
};

export const useUnionRegistrationDialog = () => {
  const [isVisited, setIsVisited] = useLocalStorage<boolean>(IS_VISITED_LOCAL_STORAGE_KEY, false);

  const dialog = useDialog();

  useEffect(() => {
    if (!isVisited) {
      dialog.show({
        id: UNION_REGISTRATION_DIALOG_ID,
        content: <UnionRegistration />,
        hideButton: true,
        size: 'lg',
      });
    }
  }, [dialog, isVisited]);

  useEffect(() => {
    if (!isVisited) {
      setIsVisited(true);
    }
  }, [isVisited, setIsVisited]);
};

export const useFirstVisitUnionRegistrationGuideTooltip = () => {
  const [isVisited] = useLocalStorage<boolean>(IS_VISITED_LOCAL_STORAGE_KEY, false);
  const [showUnionRegistrationGuideTooltip, setShowUnionRegistrationGuideTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (!isVisited) {
      setShowUnionRegistrationGuideTooltip(true);
    }
  }, [isVisited]);

  return {
    showUnionRegistrationGuideTooltip,
  };
};
