import styled from '@emotion/styled';
import { size, SizeOptions } from '@29cm/admin-emotion-utils';
import { EMAIL_SENT_IMAGE_URL } from '~apps/@shared/constants/imageUrl';

interface Props extends SizeOptions {
  className?: string;
}

export const EmailSentImage = ({ className, width, height }: Props) => {
  return <Image src={EMAIL_SENT_IMAGE_URL} alt="" className={className} width={width} height={height} />;
};

const Image = styled.img<SizeOptions>`
  ${({ width, height }) =>
    size({
      width,
      height,
    })}
`;
