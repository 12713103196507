import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

export const ChevronLeftUp = ({ className, color = 'gray500' }: BaseIconProps) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path d="M3 3.5V9H11" stroke={stroke} />
      <path d="M1.56752 3.5L3 1.78102L4.43248 3.5H1.56752Z" stroke={stroke} />
    </svg>
  );
};
