export enum Result {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export enum VerificationFailMessageErrorCode {
  NOT_EXISTS_EMAIL = 'NOT_EXISTS_EMAIL',
  DUPLICATED_ID = 'DUPLICATED_ID',
  NOT_ALLOWED_INTERNAL_REGISTRATION = 'NOT_ALLOWED_INTERNAL_REGISTRATION',
  ADMIN_APPROVAL_REQUIRED = 'ADMIN_APPROVAL_REQUIRED',
}

export enum JoinFailMessageErrorCode {
  REQUIRED_UPDATE_PASSWORD = 'REQUIRED_UPDATE_PASSWORD',
}

export interface JoinCommonResponse {
  result: Result;
  data: null;
  message: string;
  errorCode: string;
}

export interface SignUpParams {
  loginId: string;
  password: string;
  verificationCode: string;
}

export interface PostVerificationParams {
  email: string;
  loginId: string;
}

export interface GetVerificationResponse extends Omit<JoinCommonResponse, 'data'> {
  data: GetVerificationData;
}

export interface GetVerificationData {
  loginId: string;
  verificationCode: string;
}
