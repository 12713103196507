import Head from 'next/head';

const DEFAULT_TITLE = '29Connect [Inhouse]';

interface Props {
  title?: string;
}

export const CommonHeadlessLayout = ({ title }: Props) => {
  const combinedTitle = title ? `${title} - ${DEFAULT_TITLE}` : DEFAULT_TITLE;

  return (
    <Head>
      <title>{combinedTitle}</title>
      <meta
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no, viewport-fit=cover"
        name="viewport"
      />
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
    </Head>
  );
};
