import { getColor } from '../../../core/colors';
import { BaseIconProps } from '../types/iconography.types';

export const AlignRight = ({ className, color = 'gray700' }: BaseIconProps) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.9967 12H5.66667" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 9.33334H3" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.9967 6.66666H5.66667" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 4H3" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
