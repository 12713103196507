import { useRef, useState } from 'react';

export const useDialogHandler = (onSuccess?: () => Promise<void> | void | null) => {
  const [isLoading, setIsLoading] = useState(false);
  const isCallingRef = useRef(false);

  const handleSuccess = async () => {
    if (!onSuccess) {
      return;
    }

    if (isCallingRef.current) {
      return;
    }

    isCallingRef.current = true;
    setIsLoading(true);
    try {
      await onSuccess();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      isCallingRef.current = false;
    }
  };

  return { isLoading, handleSuccess };
};
