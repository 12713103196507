export enum InputCommonErrorCode {
  EMPTY = 'EMPTY',
  REGEX = 'REGEX',
}

export interface CommonFieldType {
  value: string;
  error?: InputCommonErrorCode;
}

export interface ExistingInformationInitialState {
  id: CommonFieldType;
  email: CommonFieldType;
}

export interface ExistingInformationState extends ExistingInformationInitialState {
  dispatchId: <T extends keyof ExistingInformationInitialState['id']>(
    key: T,
    value: ExistingInformationInitialState['id'][T],
  ) => void;
  dispatchEmail: <T extends keyof ExistingInformationInitialState['email']>(
    key: T,
    value: ExistingInformationInitialState['email'][T],
  ) => void;
  dispatchInitialize: VoidFunction;
}
