import env from '@29cm/admin-env';

export const getEnvName = () => {
  if (env.development) {
    return 'dev';
  }
  if (env.qa) {
    return 'qa';
  }
};
