import NextLink, { LinkProps } from 'next/link';
import { MouseEventHandler, PropsWithChildren } from 'react';

interface Props {
  className?: string;
  href: LinkProps['href'];
  native?: boolean;
  replace?: boolean;
  target?: string;
  scroll?: boolean;
  shallow?: boolean;
  prefetch?: boolean;
  locale?: string | false;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

/**
 * https://nextjs.org/docs/api-reference/next/link
 */
export const Link = ({
  className,
  href,
  native: isNative,
  target,
  onClick,
  children,
  ...restProps
}: PropsWithChildren<Props>) => {
  const stringifiedHref = typeof href === 'string' ? href : href.pathname;
  const isAppScheme = stringifiedHref?.startsWith('app29cm');

  /**
   * app29cm 같은 커스텀 protocol을 href로 넘기면 nextjs link에서 invalid url 에러를 뱉습니다
   */
  if ((isAppScheme || isNative) && stringifiedHref) {
    return (
      <a href={stringifiedHref} className={className} onClick={onClick} target={target}>
        {children}
      </a>
    );
  }

  return (
    <NextLink href={href} passHref {...restProps} className={className} onClick={onClick} target={target}>
      {children}
    </NextLink>
  );
};
