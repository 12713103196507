import {
  autoUpdate,
  safePolygon,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useMemo, useState } from 'react';
import { TooltipOptions } from '../Tooltip.types';

export const useTooltip = ({
  initialOpen = false,
  placement = 'top',
  applySafePolygon = false,
}: TooltipOptions = {}) => {
  const [open, setOpen] = useState(initialOpen);

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    handleClose: applySafePolygon ? safePolygon() : undefined,
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data],
  );
};
