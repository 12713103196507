import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

export const SuccessIcon = ({ className, color = 'blue500' }: BaseIconProps) => {
  const fill = getColor(color);

  return (
    <svg
      width="22"
      height="22"
      className={className}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill={fill} />
      <path d="M4 10.5L9.01754 16L17 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
