import { Id, toast, ToastPosition, TypeOptions, ToastOptions as BaseToastOption } from 'react-toastify';
import { ErrorIcon } from '../../../foundations/iconography/ErrorIcon';
import { SuccessIcon } from '../../../foundations/iconography/SuccessIcon';
import { Toast } from '../components/Toast';

const toastPopups: Id[] = [];

export interface ToastOptions {
  type?: Extract<TypeOptions, 'success' | 'error'>;
  position?: ToastPosition;
  duration?: BaseToastOption['autoClose'];
}

export interface ShowToastParams {
  message: string;
  subMessage?: string;
  options?: ToastOptions;
}

export const showToast = ({ message, subMessage, options }: ShowToastParams) => {
  if (toastPopups.length > 1) {
    const toastId = toastPopups.shift();
    toast.dismiss(toastId);
  }

  const type = options?.type ? options.type : 'success';
  const position = options?.position ? options.position : toast.POSITION.TOP_CENTER;
  const autoClose = options?.duration;

  const messageComponent = <Toast message={message} subMessage={subMessage} />;

  switch (type) {
    case 'success':
      toastPopups.push(
        toast.success(messageComponent, {
          icon: <SuccessIcon />,
          position,
          autoClose,
        }),
      );
      break;
    case 'error':
      toastPopups.push(
        toast.error(messageComponent, {
          icon: <ErrorIcon />,
          position,
          autoClose,
        }),
      );
      break;
    default:
      toastPopups.push(
        toast(message, {
          position,
          autoClose,
        }),
      );
  }
};
