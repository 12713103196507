import { Column, DefaultRecordType } from './Table.types';
import { useTableContext } from './providers';

export interface Props<DataRecord extends DefaultRecordType> {
  colWidths: readonly (number | string | undefined)[];
  flattenColumns: readonly Column<DataRecord>[];
}

export const TableColgroup = <DataRecord extends DefaultRecordType>({
  colWidths,
  flattenColumns,
}: Props<DataRecord>) => {
  const { selection } = useTableContext();
  const cols: React.ReactElement[] = [];
  const len = flattenColumns.length;

  let mustInsert = false;
  for (let i = len - 1; i >= 0; i -= 1) {
    const width = colWidths ? colWidths[i] : '';
    const column = flattenColumns && flattenColumns[i];

    if (width || column || mustInsert) {
      cols.unshift(<col key={i} style={{ width }} />);
      mustInsert = true;
    }
  }
  if (selection) {
    cols.unshift(<col key="checkbox" style={{ width: '50px' }} />);
  }

  return <colgroup>{cols}</colgroup>;
};
