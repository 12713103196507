import { useMergeRefs, FloatingPortal } from '@floating-ui/react';

import { forwardRef } from 'react';
import { ZIndex } from '../../../../constants';
import { useTooltipContext } from '../providers/TooltipProvider';

export const TooltipContent = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>((props, propRef) => {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  return (
    <FloatingPortal>
      {context.open ? (
        <div
          ref={ref}
          style={{
            position: context.strategy,
            top: context.y ?? 0,
            left: context.x ?? 0,
            visibility: context.x == null ? 'hidden' : 'visible',
            zIndex: ZIndex.Tooltip,
            ...props.style,
          }}
          {...context.getFloatingProps(props)}
        />
      ) : null}
    </FloatingPortal>
  );
});
