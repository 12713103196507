import styled from '@emotion/styled';
import format from 'date-fns/format';
import { Typography } from '../../../foundations/typography';
import { ChevronIcon } from '../../../foundations/iconography';

interface Props {
  date: Date;
  onPrevClick: () => void;
  onNextClick: () => void;
}

const DATE_FORMAT = 'yyyy.MM';

export const CalendarManagementToolBar = ({ date, onPrevClick, onNextClick }: Props) => {
  return (
    <Container>
      <StyledButton onClick={onPrevClick}>
        <ChevronIcon direction="left" color="gray600" />
      </StyledButton>
      <Typography variant="body/14-semiBold" color="gray700">
        {format(date, DATE_FORMAT)}
      </Typography>
      <StyledButton onClick={onNextClick}>
        <ChevronIcon direction="right" color="gray600" />
      </StyledButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
`;
