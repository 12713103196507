import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { PropsWithChildren, useId } from 'react';
import { colors, getColor } from '../../core';

type Theme = keyof typeof colors;

interface Props<T extends string = string> {
  className?: string;
  inputCss?: SerializedStyles;
  labelCss?: SerializedStyles;
  name?: string;
  value: T;
  theme?: Theme;
  checked: boolean;
  disabled?: boolean;
  onClick?: (value: T) => void;
}

export const RadioButton = <T extends string>({
  className,
  inputCss,
  labelCss,
  name,
  value,
  theme = 'blue',
  children,
  disabled,
  onClick,
  ...restProps
}: PropsWithChildren<Props<T>>) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }
    onClick?.(value);
  };

  const radioId = useId();

  return (
    <Container className={className}>
      <Input
        id={radioId}
        css={inputCss}
        type="radio"
        name={name}
        value={value}
        disabled={disabled}
        onChange={handleClick}
        themes={theme}
        {...restProps}
      />
      <Label css={labelCss} htmlFor={radioId} title={name} onClick={handleClick}>
        {children}
      </Label>
    </Container>
  );
};

const Container = styled.span`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  min-height: 20px;
  line-height: 20px;
`;

const Label = styled.label`
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-height: 20px;
  padding-left: 27px;
  line-height: 22px;
  color: ${colors.primary};

  &::before {
    content: '';
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 1px solid ${colors.line_gray01};
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 1px;
    transform: translateY(-50%);
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    background: ${colors.white};
    border: 4px solid ${colors.white};
    border-radius: 50%;
  }
`;

const Input = styled.input<{ themes: Theme }>`
  position: absolute;
  z-index: -10;
  top: -100%;
  left: -100%;
  width: 13px;
  height: 13px;
  background: transparent;

  &:checked + ${Label}::after {
    background: ${({ themes }) => getColor(themes) ?? colors.blue};
  }

  &:disabled + ${Label} {
    cursor: not-allowed;
    color: ${colors.text_gray01};

    &::before {
      background: ${colors.bg_gray01};
      border: 1px solid ${colors.bg_gray01};
    }

    &::after {
      display: none;
    }
  }
`;
