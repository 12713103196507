import { ClearIndicatorProps, components, DropdownIndicatorProps, IndicatorSeparatorProps } from 'react-select';
import { ChevronIcon, CloseIcon } from '../../foundations/iconography';
import { SearchSelectOptionType } from './SearchSelect';

type SelectIndicatorParams = {
  isClearable: boolean;
  hint?: string;
  hasError: boolean;
};

export const SelectIndicator = ({ isClearable }: SelectIndicatorParams) => {
  const selectComponent = {
    ClearIndicator: (props: ClearIndicatorProps<SearchSelectOptionType>) => {
      return (
        <components.ClearIndicator {...props}>
          <CloseIcon size="medium" color="gray500" />
        </components.ClearIndicator>
      );
    },
    IndicatorSeparator: (props: IndicatorSeparatorProps<SearchSelectOptionType>) => {
      return isClearable ? <components.IndicatorSeparator {...props} /> : null;
    },
    DropdownIndicator: ({ ...props }: DropdownIndicatorProps<SearchSelectOptionType>) => {
      const { menuIsOpen } = props.selectProps;
      return (
        <components.DropdownIndicator {...props}>
          <ChevronIcon direction={menuIsOpen ? 'up' : 'down'} />
        </components.DropdownIndicator>
      );
    },
  };
  return selectComponent;
};
