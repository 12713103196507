import styled from '@emotion/styled';
import { MouseEvent, ReactNode, useRef } from 'react';
import { colors } from '../../core';
import { useOutsideClick } from '../../hooks';
import { useTooltip } from './Tooltip.hooks';

interface TooltipProps {
  className?: string;
  content?: ReactNode;
  tooltipWidth: string;
  onClick?: () => void;
}

interface TextTooltipProps {
  type: 'text';
  text: string;
}

interface IconTooltipProps {
  type: 'icon';
  icon: ReactNode;
  text?: string;
}

type Props = TooltipProps & (TextTooltipProps | IconTooltipProps);

export const Tooltip = (props: Props) => {
  const { className, content, tooltipWidth, onClick } = props;
  const { isShow, handleTooltipEnter, handleTooltipLeave, handleTooltipClose } = useTooltip();

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, handleTooltipClose);

  if (!content) {
    return null;
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onClick?.();
  };

  return (
    <Container ref={ref} onMouseEnter={handleTooltipEnter} onMouseLeave={handleTooltipLeave}>
      <TooltipButton className={className} type="button" onClick={handleClick}>
        {props.type === 'text' ? (
          <ButtonHelpTitle>{props.text}</ButtonHelpTitle>
        ) : (
          <>
            {props.icon}
            <HiddenText>{props.text ?? '툴팁'}</HiddenText>
          </>
        )}
      </TooltipButton>

      <TooltipLayer show={isShow} tooltipWidth={tooltipWidth}>
        <TooltipDescription>{content}</TooltipDescription>
      </TooltipLayer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: inline-flex;
  align-items: baseline;
`;

const TooltipButton = styled.button`
  line-height: 20px;
`;

const ButtonHelpTitle = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.primary};
  text-decoration: underline;
  white-space: nowrap;
`;

const TooltipLayer = styled.div<{ show: boolean; tooltipWidth: string }>`
  position: absolute;
  z-index: 20;
  bottom: 0;
  left: 0;
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: ${({ tooltipWidth }) => (tooltipWidth ? tooltipWidth : '100%')};
  margin-bottom: 26px;
`;

const TooltipDescription = styled.div`
  box-sizing: border-box;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  word-break: break-all;
  white-space: pre-line;
  background: ${colors.white};
  border: 1px solid ${colors.line_gray01};
  border-radius: 4px;
`;

const HiddenText = styled.span`
  position: absolute;
  display: block;
  text-indent: -9999px;
`;
