import { size as _size, padding } from '@29cm/admin-emotion-utils';
import styled from '@emotion/styled';
import FadeLoader from 'react-spinners/FadeLoader';
import {
  DEFAULT_INDICATOR_SIZE,
  LOADING_INDICATOR_DEFAULT_HEIGHT,
  LOADING_INDICATOR_DEFAULT_MARGIN,
  LOADING_INDICATOR_DEFAULT_RADIUS,
  LOADING_INDICATOR_DEFAULT_WIDTH,
} from '.';
import { KeyOfColor, getColor } from '../../core/colors';

interface Props {
  className?: string;
  loading?: boolean;
  size?: number;
  color?: KeyOfColor;
}

export const LoadingIndicator = ({
  className,
  size = DEFAULT_INDICATOR_SIZE,
  loading = true,
  color = 'gray500',
}: Props) => {
  if (!loading) {
    return null;
  }

  const weight = size / DEFAULT_INDICATOR_SIZE;
  const inverseWeight = DEFAULT_INDICATOR_SIZE / size;

  return (
    <Container className={className} size={size}>
      <FadeLoader
        width={LOADING_INDICATOR_DEFAULT_WIDTH * weight}
        height={LOADING_INDICATOR_DEFAULT_HEIGHT * weight}
        margin={LOADING_INDICATOR_DEFAULT_MARGIN * inverseWeight}
        radius={LOADING_INDICATOR_DEFAULT_RADIUS * weight}
        color={getColor(color)}
      />
    </Container>
  );
};

const Container = styled.div<{ size: number }>`
  ${({ size }) =>
    _size({
      width: size,
      height: size,
    })};

  ${padding({
    top: 8,
    left: 11,
  })}
`;
