import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { Button } from '../../button';

interface Props {
  className?: string;
  onClick: () => void;
}

export const SecondaryButton = ({ className, children, onClick }: PropsWithChildren<Props>) => {
  return (
    <StyledButton className={className} size="md" color="secondaryGray" onClick={onClick}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  flex: 1;
  justify-content: center;
`;
