import { ValueOfColor } from '../..';
import { theme } from '../../core/theme';
import { ButtonColor } from './Button.types';

export const IconColors: Record<ButtonColor, ValueOfColor> = {
  primaryRed: theme.colors.white,
  primaryBlue: theme.colors.white,
  secondaryRed: theme.colors.red500,
  secondaryBlue: theme.colors.blue500,
  secondaryGray: theme.colors.gray700,
};
