import env from '@29cm/admin-env';
import axios from 'axios';
import type { NextPageContext } from 'next';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { AudienceType } from '..';

import { getEnvName } from './getEnvName';
import { isExpiredToken } from './isExpiredToken';

interface SignInResponse {
  result: string;
  data: SignInResponseData;
  message: string;
  errorCode: string;
}

interface SignInResponseData {
  token: string;
  accessToken: string;
  refreshToken: string;
}

interface NewTokenParams {
  accessToken: string;
  refreshToken: string;
}

const EXPIRED_TOKEN_ERROR_CODE = 'EXPIRED_TOKEN';

const createTokenCookieHelpers = (ctx?: Pick<NextPageContext, 'req' | 'res'>) => {
  const envName = getEnvName();
  const cookieSuffix = envName ? `_${envName}` : '';

  const domain = '.29cm.co.kr';
  const accessTokenCookieName = `_fatn${cookieSuffix}`;
  const refreshTokenCookieName = `_frtn${cookieSuffix}`;
  const cookieOptions = {
    domain,
    path: '/',
  };

  const setTokenCookies = (newTokens: NewTokenParams) => {
    const { accessToken, refreshToken } = newTokens;

    setCookie(ctx, accessTokenCookieName, accessToken, cookieOptions);
    setCookie(ctx, refreshTokenCookieName, refreshToken, cookieOptions);
  };

  const deleteTokenCookies = () => {
    destroyCookie(ctx, accessTokenCookieName, cookieOptions);
    destroyCookie(ctx, refreshTokenCookieName, cookieOptions);

    throw new Error(EXPIRED_TOKEN_ERROR_CODE);
  };

  return {
    setTokenCookies,
    deleteTokenCookies,
  };
};

const getRefreshToken = async (refreshToken: string, ctx?: Pick<NextPageContext, 'req' | 'res'>) => {
  const envName = getEnvName();
  const cookies = parseCookies(ctx);
  const { setTokenCookies, deleteTokenCookies } = createTokenCookieHelpers(ctx);

  const cookieSuffix = envName ? `_${envName}` : '';
  const audience = cookies[`_fws${cookieSuffix}`];

  if (isExpiredToken(refreshToken)) {
    deleteTokenCookies();
  }

  const body = { refreshToken };
  const refreshTokenFirstEndPoint = audience === AudienceType.PARTNER ? 'partner-admin' : 'inhouse-admin';

  const { data: newTokenData } = await axios
    .post<SignInResponse>(`${env.api.auth}/${refreshTokenFirstEndPoint}/v4/token/refresh`, body)
    .then((res) => {
      if (res.data.errorCode === EXPIRED_TOKEN_ERROR_CODE) {
        deleteTokenCookies();
      }

      return res;
    })
    .catch((error) => {
      throw error;
    });

  const newTokens = {
    accessToken: newTokenData.data.accessToken,
    refreshToken: newTokenData.data.refreshToken,
  };

  setTokenCookies(newTokens);

  return newTokens;
};

export const refetchToken = async (ctx?: Pick<NextPageContext, 'req' | 'res'>) => {
  const envName = getEnvName();
  const cookies = parseCookies(ctx);
  const { deleteTokenCookies } = createTokenCookieHelpers(ctx);

  const cookieSuffix = envName ? `_${envName}` : '';
  const accessToken = cookies[`_fatn${cookieSuffix}`];
  const refreshToken = cookies[`_frtn${cookieSuffix}`];
  const hasAccessToken = Boolean(accessToken);
  const hasRefreshToken = Boolean(refreshToken);

  if (hasAccessToken && !hasRefreshToken) {
    deleteTokenCookies();
  }

  if (isExpiredToken(accessToken) && hasRefreshToken) {
    const newTokens = await getRefreshToken(refreshToken, ctx);

    return newTokens;
  }

  return { accessToken, refreshToken };
};
