import type { JwtPayload } from 'jsonwebtoken';
import jwt from 'jsonwebtoken';

const isJwtPayload = (decodedToken: unknown): decodedToken is JwtPayload => {
  return (decodedToken as JwtPayload).exp !== undefined;
};

export const getExpiryTimeFromJwt = (jsonwebtoken?: string) => {
  if (!jsonwebtoken) {
    return;
  }

  const decodedToken = jwt.decode(jsonwebtoken);

  if (!isJwtPayload(decodedToken)) {
    return;
  }

  if (!decodedToken.exp) {
    return;
  }

  return new Date(decodedToken.exp * 1000);
};
