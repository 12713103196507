import { useCallback, useRef } from 'react';

export const useResizeObserver = (cb: ResizeObserverCallback) => {
  const observer = useRef<ResizeObserver | null>(null);

  return useCallback(
    (node: HTMLElement | null) => {
      if (node) {
        observer.current = new ResizeObserver(cb);
        observer.current.observe(node);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};
