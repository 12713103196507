import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

type Size = 'md' | 'sm';
interface Props extends BaseIconProps {
  size?: Size;
}

export const RemoveIcon = ({ className, color = 'gray500', size = 'md' }: Props) => {
  const stroke = getColor(color);

  const MediumIcon = () => {
    return (
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.22553 17.6839C4.92482 17.9846 4.92482 18.4721 5.22553 18.7728C5.52623 19.0735 6.01377 19.0735 6.31447 18.7728L11.9992 13.0881L17.6839 18.7728C17.9846 19.0735 18.4721 19.0735 18.7728 18.7728C19.0735 18.4721 19.0735 17.9846 18.7728 17.6839L13.0881 11.9992L18.7728 6.31447C19.0735 6.01377 19.0735 5.52623 18.7728 5.22553C18.4721 4.92482 17.9846 4.92482 17.6839 5.22553L11.9992 10.9102L6.31447 5.22553C6.01377 4.92483 5.52623 4.92483 5.22553 5.22553C4.92482 5.52624 4.92482 6.01377 5.22553 6.31448L10.9102 11.9992L5.22553 17.6839Z"
          fill={stroke}
        />
      </svg>
    );
  };

  const SmallIcon = () => {
    return (
      <svg className={className} width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.853553 0.146447C0.658291 -0.0488155 0.341709 -0.0488155 0.146447 0.146447C-0.0488155 0.341709 -0.0488155 0.658291 0.146447 0.853553L2.29242 2.99952L0.146447 5.14549C-0.0488155 5.34076 -0.0488155 5.65734 0.146447 5.8526C0.341709 6.04786 0.658291 6.04786 0.853553 5.8526L2.99952 3.70663L5.14548 5.85259C5.34074 6.04785 5.65733 6.04785 5.85259 5.85259C6.04785 5.65733 6.04785 5.34074 5.85259 5.14548L3.70663 2.99952L5.85259 0.853565C6.04785 0.658303 6.04785 0.341721 5.85259 0.146459C5.65733 -0.0488033 5.34074 -0.0488033 5.14548 0.146459L2.99952 2.29242L0.853553 0.146447Z"
          fill={stroke}
        />
      </svg>
    );
  };

  return <>{size === 'md' ? <MediumIcon /> : <SmallIcon />}</>;
};
