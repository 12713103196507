import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Badge, Tooltip } from '..';
import { QuestionIcon, Typography } from '../../foundations';
import { CardTabType } from './CardTab.types';

export interface CardTabProps {
  cardTab: CardTabType;
  onClick?: (value: CardTabProps['cardTab']['value']) => void;
}

export const CardTab = ({
  cardTab: { value, title, content, badge, tooltip, isActive = false },
  onClick,
}: CardTabProps) => {
  const handleClick = () => {
    onClick?.(value);
  };

  return (
    <Root isActive={isActive} onClick={handleClick}>
      <TopContainer>
        <Title variant="body/14-medium" isActive={isActive}>
          {title}
        </Title>
        {badge ? (
          <BadgeContainer>
            <Badge color={badge.color} size={badge.size}>
              {badge.content}
            </Badge>
          </BadgeContainer>
        ) : null}
        {tooltip ? (
          <TooltipContainer>
            <Tooltip tooltipText={tooltip.content}>
              <IconWrapper>
                <QuestionIcon color="gray400" />
              </IconWrapper>
            </Tooltip>
          </TooltipContainer>
        ) : null}
      </TopContainer>
      <Content variant="heading/24-medium" isActive={isActive}>
        {content}
      </Content>
    </Root>
  );
};

const Root = styled.button<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  min-width: 190px;
  height: 110px;
  padding-left: 20px;

  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;

  ${({ theme, isActive }) => css`
    border-color: ${isActive ? theme.colors.blue500 : theme.colors.gray400};
  `};
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 8px;
`;
const Title = styled(Typography)<{ isActive?: boolean }>`
  ${({ theme, isActive }) => css`
    color: ${isActive ? theme.colors.blue500 : theme.colors.gray600};
  `};
`;
const Content = styled(Typography)<{ isActive?: boolean }>`
  ${({ theme, isActive }) => css`
    color: ${isActive ? theme.colors.blue500 : theme.colors.gray700};
  `};
`;

const BadgeContainer = styled.div`
  margin-left: 4px;
`;
const TooltipContainer = styled.div`
  margin-left: 4px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
