import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

/**
 ```tsx
 <TrashIcon
 color="gray600"
 />
 ```
 */

export const TrashIcon = ({ className, color = 'gray500' }: BaseIconProps) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 4H3.33333H14" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.33333 3.99999V2.66666C5.33333 2.31304 5.47381 1.9739 5.72386 1.72385C5.97391 1.4738 6.31304 1.33333 6.66667 1.33333H9.33333C9.68696 1.33333 10.0261 1.4738 10.2761 1.72385C10.5262 1.9739 10.6667 2.31304 10.6667 2.66666C10.6667 3.02028 10.6667 3.4793 10.6667 3.99999M12.6667 3.99999V13.3333C12.6667 13.6869 12.5262 14.0261 12.2761 14.2761C12.0261 14.5262 11.687 14.6667 11.3333 14.6667H4.66667C4.31304 14.6667 3.9739 14.5262 3.72386 14.2761C3.47381 14.0261 3.33333 13.6869 3.33333 13.3333V3.99999H12.6667Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.51111 7.33333V11.7778" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.48889 7.33333V11.7778" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
