import { Spacing } from '@29cm/admin-emotion-utils';
import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { ZIndex } from '../../../constants';
import { useDateInputBoxContext } from '../providers';
import { CalendarContent } from './CalendarContent';
import { CalendarFooter } from './CalendarFooter';
import { CalendarManagementToolBar } from './CalendarManagementToolBar';

interface Props {
  className?: string;
}

export const CalendarPopup = forwardRef<HTMLDivElement, Props>(({ className }: Props, ref) => {
  const { currentDate, isNeedPrevDatePrevents, prevMonth, nextMonth, preventBeforeDate, preventAfterDate } =
    useDateInputBoxContext();

  return (
    <Container className={className} ref={ref}>
      <CalendarManagementToolBar date={currentDate} onPrevClick={prevMonth} onNextClick={nextMonth} />
      <Spacing size={23} />
      <CalendarContent
        date={currentDate}
        isNeedPrevDatePrevents={isNeedPrevDatePrevents}
        preventBeforeDate={preventBeforeDate}
        preventAfterDate={preventAfterDate}
      />
      <Spacing size={18} />
      <CalendarFooter />
    </Container>
  );
});

const Container = styled.div`
  z-index: ${ZIndex.Float};
  width: 306px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 2px 16px 19px rgb(0 0 0 / 9%);
`;
