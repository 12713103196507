import { getColor } from '../../../core/colors';
import { BaseIconProps } from '../types/iconography.types';

export const ChevronLeft = ({ className, color = 'gray500' }: BaseIconProps) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 1L3 6L8 11" stroke={stroke} />
    </svg>
  );
};
