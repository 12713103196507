import { tag } from '@29cm/admin-env';
import styled from '@emotion/styled';
import { useState } from 'react';
import { useEnvironments } from '../../hooks';

export const DebugReleaseInfo = () => {
  const env = useEnvironments();
  const [hide, setHide] = useState(false);

  const handleBoxClick = () => {
    setHide(true);
  };

  const shouldRenderReleaseBox = env.development || env.qa;

  if (!tag || hide) {
    return null;
  }

  if (!shouldRenderReleaseBox) {
    return null;
  }

  return <Box onClick={handleBoxClick}>{tag}</Box>;
};

const Box = styled.button`
  position: fixed;
  left: 0;
  top: 0;
  padding: 4px 10px;
  z-index: 15;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.legacy.white};
  background-color: ${({ theme }) => theme.colors.legacy.red};
`;
