import { css } from '@emotion/react';
/**
 * @deprecated
 * Design Platform에서 제공하는 디자인 시스템을 사용하기 위한 Custom theme을 사용하기 위해 해당 설정은 더 이상 사용되지 않습니다.
 * 추가 문의 사항은 #design_platform 채널로 문의 바랍니다.
 * @see AntdThemeConfig
 * libs/admin-design-system/src/theme/AntdThemeConfig.ts
 */
export const antdStyle = css`
  .ant-input,
  .ant-select,
  .ant-select-item,
  .ant-picker-input > input {
    font-size: 14px;
  }

  .ant-table {
    font-size: 13px;
  }

  .ant-table-thead .ant-table-cell {
    padding: 8px 12px;

    &::before {
      display: none;
    }
  }

  .ant-table-tbody .ant-table-cell {
    padding: 8px 12px;
  }

  .ant-pagination-item-active {
    border-color: #d1d6db;
  }

  .ant-table-tbody tr.ant-table-row-selected > td {
    border-color: #d1d6db;
  }
`;
