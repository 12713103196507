import { createContext, PropsWithChildren, useContext } from 'react';
import { useTooltip } from '../hooks';
import { TooltipOptions } from '../Tooltip.types';

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = createContext<ContextType>(null);

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <TooltipContainer />');
  }

  return context;
};

export const TooltipProvider = ({ children, ...options }: PropsWithChildren<TooltipOptions>) => {
  const tooltip = useTooltip(options);
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
};
