/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { coerceCssPixelValue } from '../utils/coerceCssPixelValue';
import { BoxType } from './boxSpacing.types';

export const boxSerializer = (target: 'padding' | 'margin', box: BoxType) => {
  return Object.entries(box)
    .filter(([, value]) => value != null)
    .map(([dir, value]) => `${target}-${dir}: ${coerceCssPixelValue(value)};`)
    .join('');
};
