const enum ZIndex {
  Hide = -1,
  Auto = 'auto',
  Base = 0,
  Float = 1,
  Dialog = 1000,
  Calendar = 1100,
  Select = 1100,
  Tooltip = 1200,

  /**
   * 🚨 **Recommend not to use this value.**
   * If used unnecessarily, uses only this value for components that must be on **top of everything.**
   */
  Important = 2000,
}

export { ZIndex };
