import styled from '@emotion/styled';
import { forwardRef, useEffect, useState } from 'react';
import ReactSelect, { ActionMeta, CSSObjectWithLabel, GroupBase, MultiValue, SingleValue } from 'react-select';
import { ZIndex } from '../../constants';
import { theme } from '../../core';
import { colors } from '../../core/colors';
import { isClient } from '../../utils';
import { BaseSelect } from './base-select';
import { customClassName } from './Select.constants';
import { useMenuOpenHandler } from './Select.hooks';
import { dropdownStyle } from './select.style';
import { SelectIndicator } from './SelectIndicator';

export type OptionType<Label = string, Value = string> = {
  label: Label;
  value: Value;
};
export type SingleOptionType = OptionType | null;
export type MultiOptionType = readonly OptionType[];
export type SelectOptionType = MultiValue<OptionType> | SingleValue<OptionType>;
export type Size = 'md' | 'sm';
export type MenuPosition = 'absolute' | 'fixed';
export type MenuAlign = 'left' | 'right';

export interface DropdownProps {
  id?: string;
  hasError?: boolean;
  hint?: string;
  placeholder?: string;
  className?: string;
  options: OptionType[];
  value?: SelectOptionType;
  isDisabled?: boolean;
  isSearchable?: boolean;
  size?: Size;
  title?: string;
  menuPortalZIndex?: number;
  menuPosition?: MenuPosition;
  menuWidth?: CSSObjectWithLabel['width'];
  menuAlign?: MenuAlign;
  subTitle?: string;
  onChange?: (value: SelectOptionType, actionMeta: ActionMeta<OptionType>) => void;
  isClearable?: boolean;
  borderColor?: keyof typeof colors;
}

export const Select = forwardRef<
  BaseSelect<OptionType<string, string>, boolean, GroupBase<OptionType<string, string>>>,
  DropdownProps
>(
  (
    {
      id = 'react-select-id',
      hasError = false,
      hint,
      placeholder = '선택',
      className,
      options,
      value,
      isDisabled = false,
      isSearchable = false,
      onChange,
      size = 'md',
      title,
      menuPortalZIndex = ZIndex.Select,
      menuPosition = 'absolute',
      subTitle,
      isClearable = false,
      menuWidth = '100%',
      menuAlign = 'left',
      borderColor,
    },
    ref,
  ) => {
    const [isMounted, setIsMounted] = useState(false);
    const sizeMenuHeight = size === 'md' ? 380 : 320;

    const onMenuOpenHandler = useMenuOpenHandler();

    useEffect(() => setIsMounted(true), []);

    if (!isMounted) {
      return null;
    }

    return (
      <Container className={className}>
        <ReactSelect
          ref={ref}
          options={options}
          placeholder={placeholder}
          styles={dropdownStyle(menuAlign, hasError, size, menuPortalZIndex, menuWidth, borderColor)}
          components={SelectIndicator({ hint, hasError, title, subTitle, isClearable })}
          isClearable={isClearable}
          value={value}
          isDisabled={isDisabled}
          isSearchable={isSearchable}
          onChange={onChange}
          maxMenuHeight={sizeMenuHeight}
          theme={(selectTheme) => ({
            ...selectTheme,
            colors: {
              ...selectTheme.colors,
              neutral50: theme.colors.gray500,
            },
          })}
          menuPosition={menuPosition}
          menuPlacement="auto"
          id={id}
          inputId={`input-${id}`}
          instanceId={`instance-${id}`}
          hideSelectedOptions={false}
          menuPortalTarget={isClient() ? document.querySelector('body') : null}
          onMenuOpen={onMenuOpenHandler}
          className={customClassName}
          classNamePrefix={customClassName}
        />
      </Container>
    );
  },
);

const Container = styled.div``;
