import { flex, size } from '@29cm/admin-emotion-utils';
import styled from '@emotion/styled';
import { FormEventHandler, FormHTMLAttributes } from 'react';

interface Props extends FormHTMLAttributes<HTMLFormElement> {
  className?: string;
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

export const Form = ({ className, onSubmit, children }: Props) => {
  return (
    <Container className={className} onSubmit={onSubmit}>
      {children}
    </Container>
  );
};

const Container = styled.form`
  ${size.width100};
  ${flex.center('column')};
`;
