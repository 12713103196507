import type { Environment } from '@29cm/admin-env';
import jwt from 'jsonwebtoken';

import type { CookieService } from '../../cookies';
import type { JWTPayload, User } from '../models';

export class UserService {
  private cookieService: CookieService;

  private user?: User;

  private environment: string;

  constructor(cookieService: CookieService, environment: Environment) {
    this.environment = environment.name;
    this.cookieService = cookieService;
    this.user = this.getCurrentUser();
  }

  public getCurrentUser(): User {
    if (this.user?.loginId) {
      return this.user;
    }

    return this.refetch();
  }

  public refetch(): User {
    this.user = this.createCurrentUserFromCookies(this.cookieService);
    return this.user;
  }

  public isLoggedIn(): boolean {
    return this.user?.logged ?? false;
  }

  public clear() {
    const env = this.getUserInfoCookieSuffix();
    const options = { domain: '.29cm.co.kr', path: '/' };

    this.cookieService.remove(`_fatn${env}`, options);
    this.cookieService.remove(`_frtn${env}`, options);

    return this.refetch();
  }

  private createCurrentUserFromCookies(cookie: CookieService): User {
    const env = this.getUserInfoCookieSuffix();
    const hasAccessToken = Boolean(cookie.get(`_fatn${env}`));

    if (hasAccessToken) {
      const accessToken = cookie.get(`_fatn${env}`);
      const refreshToken = cookie.get(`_frtn${env}`);

      const { userInfo } = jwt.decode(accessToken) as JWTPayload;

      return {
        loginId: userInfo.loginId,
        adminId: userInfo.adminId,
        partnerId: userInfo.partnerId,
        email: userInfo.email,
        expiredAt: userInfo.expiredAt,
        accessToken,
        refreshToken,
        logged: true,
      };
    }

    return {
      loginId: '',
      adminId: 0,
      partnerId: null,
      email: '',
      accessToken: '',
      refreshToken: '',
      logged: false,
      expiredAt: null,
    };
  }

  private getUserInfoCookieSuffix() {
    if (this.environment === 'development') {
      return `_dev`;
    }
    if (this.environment === 'qa') {
      return `_qa`;
    }
    return '';
  }
}
