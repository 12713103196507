export const env = {
  'development': false,
  'qa': true,
  'production': false,
  'name': 'qa',
  'image': {
    host: 'https://img.29cm.co.kr',
  },
  'asset': {
    host: 'https://asset.29cm.co.kr',
  },
  '29cm': {
    product: 'https://qa-product.29cm.co.kr',
    shop: 'https://qa-shop.29cm.co.kr',
  },
  'workspace': {
    inhouse: {
      index: 'https://qa-inhouse-connect.29cm.co.kr',
      auth: 'https://qa-inhouse-auth.29cm.co.kr',
      brand: 'https://qa-inhouse-brand.29cm.co.kr',
      booking: 'https://qa-inhouse-booking.29cm.co.kr',
      content: 'https://qa-inhouse-content.29cm.co.kr',
      review: 'https://qa-inhouse-review.29cm.co.kr',
      search: 'https://qa-inhouse-search.29cm.co.kr',
      item: 'https://qa-inhouse-item.29cm.co.kr',
      order: 'https://qa-inhouse-order.29cm.co.kr',
      settlement: 'https://qa-inhouse-settlement.29cm.co.kr',
      regulation: 'https://qa-inhouse-regulation.29cm.co.kr',
      analytics: 'https://qa-inhouse-analytics.29cm.co.kr',
      display: 'https://qa-inhouse-display.29cm.co.kr',
    },
    partner: {
      index: 'https://qa-partner-connect.29cm.co.kr',
      auth: 'https://qa-partner-auth.29cm.co.kr',
      brand: 'https://qa-partner-brand.29cm.co.kr',
      booking: 'https://qa-partner-booking.29cm.co.kr',
      content: 'https://qa-partner-content.29cm.co.kr',
      review: 'https://qa-partner-review.29cm.co.kr',
      search: 'https://qa-partner-search.29cm.co.kr',
      item: 'https://qa-partner-item.29cm.co.kr',
      order: 'https://qa-partner-order.29cm.co.kr',
      settlement: 'https://qa-partner-settlement.29cm.co.kr',
      regulation: 'https://qa-partner-regulation.29cm.co.kr',
      analytics: 'https://qa-partner-analytics.29cm.co.kr',
    },
  },
  'api': {
    apihub: 'https://apihub.29cm.co.kr/qa',
    item: 'https://qa-item-api.29cm.co.kr',
    brand: 'https://qa-brand-api.29cm.co.kr',
    booking: 'https://qa-booking-item-api.29cm.co.kr',
    auth: 'https://qa-authentication-api.29cm.co.kr',
    content: 'https://qa-content-api.29cm.co.kr',
    review: 'https://qa-review-api.29cm.co.kr',
    settlement: 'https://qa-settlement-api.29cm.co.kr',
    logistics: 'https://qa-logistics-admin-api.29cm.co.kr',
    claim: 'https://qa-claim-admin-api.29cm.co.kr',
    itemBatch: 'https://qa-item-batch-api.29cm.co.kr',
    promotion: 'https://qa-promotion-admin-api.29cm.co.kr',
    activation: 'https://qa-activation-api.29cm.co.kr',
    mileage: 'https://qa-mileage-admin-api.29cm.co.kr',
    partner: 'https://qa-partner-api.29cm.co.kr',
    commerce: 'https://qa-commerce-admin-api.29cm.co.kr',
    payment: 'https://qa-payment-api.29cm.co.kr',
    searchAdmin: 'https://qa-search-admin-api.29cm.co.kr/',
    search: 'https://qa-search-api.29cm.co.kr',
    tagAdmin: 'https://qa-tag-admin-api.29cm.co.kr',
    user: 'https://qa-user-api.29cm.co.kr',
  },
  'elasticsearch': {
    endpoint: 'https://dev-web-log.29cm.co.kr',
  },
  'sdk': {
    unleash: {
      proxy: 'https://dev-flag.29cm.co.kr/proxy',
      clientSecret: '0f5e8ef7-f1ea-4bec-af65-abb5e1925bea',
    },
    kakao: {
      clientId: 'jrfG6IJNBEXLPQJdrUqD39kfe7k6vInZ', //29CM partner center developers
      apiKey: '97953ac3bf12765ea54b6fc39a05a8a9', //29CM partner center developers
      channelPublicId: '_zWbTG', //29CM partner center developers
    },
  },
  'admin': {
    mother: 'https://mother-qa.29cm.co.kr',
    partner: 'https://partner-qa.29cm.co.kr',
  },
};
