import { parseErrorMessage, parseStatusCode } from '@29cm/admin-core/function';
import { showToast } from '@29cm/admin-design-system';
import { Dispatch, SetStateAction } from 'react';

export const useErrorHandler = () => {
  return (err: unknown, flagState?: Dispatch<SetStateAction<boolean>>) => {
    const errStatus = parseStatusCode(err);
    const errMessage = parseErrorMessage(err);
    showToast({
      message: '오류가 발생했습니다.',
      subMessage: `${errMessage || '알 수 없는 에러가 발생했습니다.'}${
        !errStatus ? '' : `\n(status code: ${errStatus})`
      }`,
      options: {
        type: 'error',
      },
    });

    flagState?.(false);
  };
};
