import styled from '@emotion/styled';
import { ShowToastParams } from '..';
import { Typography } from '../../../foundations/typography';

interface Props extends Pick<ShowToastParams, 'message' | 'subMessage'> {
  className?: string;
}

export const Toast = ({ className, message, subMessage }: Props) => {
  return (
    <Container className={className}>
      <StyledTypography variant="body/16-semiBold" color="gray700">
        {message}
      </StyledTypography>
      {subMessage && (
        <StyledTypography variant="body/13-regular" color="gray600">
          {subMessage}
        </StyledTypography>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const StyledTypography = styled(Typography)`
  white-space: pre-line;
`;
