import create from 'zustand';

export interface SideMenuInitialState {
  collapsed: boolean;
  openKeys: string[];
  selectedKeys: string[];
}

export interface SideMenuState extends SideMenuInitialState {
  dispatchCollapse: (collapsed: boolean) => void;
}

const initialState: SideMenuInitialState = {
  collapsed: false,
  openKeys: [],
  selectedKeys: ['/'],
};

export const useSideMenuStore = create<SideMenuState>((set) => ({
  ...initialState,
  dispatchCollapse: (collapsed: boolean) => set({ collapsed }),
}));
