import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { Button } from '../../button';

interface Props {
  className?: string;
  onClick?: () => void;
}

export const PrimaryButton = ({ className, children, onClick }: PropsWithChildren<Props>) => {
  const handleClick = () => {
    onClick?.();
  };

  return (
    <StyledButton className={className} size="md" color="primaryBlue" onClick={handleClick}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  flex: 2.5;
  justify-content: center;
`;
