import { useMemo } from 'react';

const REDIRECT_URL_REGEX = /^\/|https?:\/\/(?:.+\.)?29cm\.co\.kr/;

export const useRedirectUrl = (path?: string): string | undefined => {
  return useMemo(() => {
    if (!path) {
      return undefined;
    }

    const redirectUrl = decodeURIComponent(path);

    if (!REDIRECT_URL_REGEX.test(redirectUrl)) {
      return undefined;
    }

    return redirectUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
};
