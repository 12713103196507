import { AUTH_FORM_ERROR_MESSAGE } from '~apps/auth/@shared/constants/authForm';
import { InputCommonErrorCode } from '~apps/auth/login/stores/existing-information/existingInformation.types';

export const VERIFICATION_FORM_ERROR_MESSAGE = {
  [InputCommonErrorCode.EMPTY]: {
    id: AUTH_FORM_ERROR_MESSAGE.EMPTY_ID,
    email: AUTH_FORM_ERROR_MESSAGE.EMPTY_EMAIL,
  },
  [InputCommonErrorCode.REGEX]: {
    id: AUTH_FORM_ERROR_MESSAGE.ID,
    email: AUTH_FORM_ERROR_MESSAGE.EMAIL,
  },
};
