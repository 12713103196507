import { flex } from '@29cm/admin-emotion-utils';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { getTypographyStyle } from '../../foundations/typography/styles/variants';
import { badgeBackgroundColor, badgeColor } from './Badge.constants';
import { BadgeColorType, BadgeSizeType } from './Badge.types';

export interface BadgeProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  className?: string;
  as?: React.ElementType;
  color?: BadgeColorType;
  size?: BadgeSizeType;
  labelCSS?: SerializedStyles;
  endIcon?: React.ReactElement;
  onIconClick?: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
}

/**
 ```tsx
 <Badge
    color="blue"
    size="m"
 />
 ```
 */
export const Badge = ({
  className,
  as,
  color = 'blue',
  size = 'm',
  labelCSS,
  endIcon,
  onIconClick,
  children,
  ...restProps
}: React.PropsWithChildren<BadgeProps>) => {
  return (
    <Root as={as} className={className} color={color} size={size} hasEndIcon={Boolean(endIcon)} {...restProps}>
      <Label css={labelCSS}>{children}</Label>
      {endIcon ? (
        <EndIconButton type="button" onClick={onIconClick}>
          {endIcon}
        </EndIconButton>
      ) : null}
    </Root>
  );
};

const Root = styled.div<{ color: BadgeColorType; size: BadgeSizeType; hasEndIcon: boolean }>`
  display: inline-block;
  border-radius: 4px;

  ${({ hasEndIcon }) =>
    hasEndIcon &&
    css`
      display: flex;
      gap: 6px;
      align-items: center;
      width: fit-content;
    `}

  ${({ color, theme }) => {
    return css`
      color: ${theme.colors[badgeColor[color]]};
      background: ${theme.colors[badgeBackgroundColor[color]]};
    `;
  }}

  ${({ size }) => {
    switch (size) {
      case 's': {
        return css`
          ${getTypographyStyle('body/12-medium')}
          min-height: 18px;
          padding: 2px 6px;
        `;
      }
      case 'm': {
        return css`
          ${getTypographyStyle('body/14-semiBold')}
          min-height: 22px;
          padding: 3px 8px;
        `;
      }
    }
  }}
`;

const Label = styled.span();

const EndIconButton = styled.button`
  ${flex.center()};
`;
