import { SHOW_BACK_BUTTON_STEP_LIST } from '~apps/auth/login/components/dialogs/union-registration/bottom-container/BottomContainer.constants';
import { useExistingInformationStore } from '~apps/auth/login/stores/existing-information/existingInformation.store';
import { useUnionRegistrationStore } from '~apps/auth/login/stores/union-registration/unionRegistration.store';

export const useShowBackButton = () => {
  const currentStep = useUnionRegistrationStore((state) => state.currentStep);

  return {
    showBackButton: SHOW_BACK_BUTTON_STEP_LIST.includes(currentStep),
  };
};

export const useCurrentStep = () => {
  const currentStep = useUnionRegistrationStore((state) => state.currentStep);

  return {
    currentStep,
  };
};

export const useClickButton = () => {
  const resetExistingInformation = useExistingInformationStore((state) => state.dispatchInitialize);

  const { currentStep, dispatchStep } = useUnionRegistrationStore((state) => ({
    currentStep: state.currentStep,
    dispatchStep: state.dispatchStep,
  }));

  const handlePrevButtonClick = () => {
    dispatchStep(currentStep - 1);
    resetExistingInformation();
  };

  return {
    handlePrevButtonClick,
  };
};
