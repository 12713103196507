import { getColor } from '../../core/colors';
import { CellElement, DefaultRecordType, FlattenColumns, TableSize } from './Table.types';
import { TableBodyEmpty } from './TableBodyEmpty';
import { TableBodyRow } from './TableBodyRow';
import { TableCell } from './TableCell';
import { useTableContext } from './providers';

const COLOR_WHITE = getColor('white');

interface Props<DataRecord extends DefaultRecordType> {
  flattenColumns: FlattenColumns<DataRecord>;
  data: readonly DataRecord[];
  size: TableSize;
}

export const TableBody = <DataRecord extends DefaultRecordType>({ flattenColumns, data, size }: Props<DataRecord>) => {
  const { selection } = useTableContext();
  const columnCount = flattenColumns.length;
  const colSpan = selection ? columnCount + 1 : columnCount;

  return (
    <tbody>
      {data.length ? (
        data.map((row, index) => {
          const key = row.key ? row.key : index;
          const backgroundColor = row.backgroundColor ? row.backgroundColor : COLOR_WHITE;

          return (
            <TableBodyRow
              key={key}
              rowKey={key}
              backgroundColor={backgroundColor}
              data={row}
              size={size}
              flattenColumns={flattenColumns}
              renderCell={(column) => {
                const { dataIndex } = column;
                const cell = dataIndex ? row[dataIndex] : undefined;
                const isFirstRow = index === 0;

                return (
                  <TableCell
                    key={column.key}
                    column={column}
                    data={cell}
                    size={size}
                    component={CellElement.TD}
                    isFirstRow={isFirstRow}
                  />
                );
              }}
            />
          );
        })
      ) : (
        <TableBodyEmpty colSpan={colSpan} />
      )}
    </tbody>
  );
};
