import baseEnvironment from '@29cm/admin-env';

export const env = {
  ...baseEnvironment,
  cs: {
    default: 'https://dev-customer-service-admin.29cm.co.kr',
  },
  api: {
    ...baseEnvironment.api,
    cs: 'https://dev-cs-support-api.29cm.co.kr',
  },
};
