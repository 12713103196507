import styled from '@emotion/styled';
import React from 'react';
import { colors } from '../../core';
import { getColor } from '../../core/colors';

export const ButtonType = {
  FILL: 'fill',
  LINE: 'line',
  TEXT: 'text',
} as const;

export const ButtonSize = {
  REGULAR: 'regular',
} as const;

type Type = typeof ButtonType[keyof typeof ButtonType];
type Size = typeof ButtonSize[keyof typeof ButtonSize];
type Theme = keyof typeof colors;

interface Props {
  className?: string;
  buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  type: Type;
  size?: Size;
  theme?: Theme;
  disabled?: React.ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  onClick?: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  children: React.ReactNode;
}

export const Button = React.forwardRef(
  (
    { className, buttonType = 'button', type, size = 'regular', theme, disabled, onClick, children }: Props,
    forwardRef: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    if (type === ButtonType.FILL) {
      return (
        <FillButton
          ref={forwardRef}
          className={className}
          type={buttonType}
          buttonSize={size}
          buttonTheme={theme || 'blue'}
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </FillButton>
      );
    }

    if (type === ButtonType.LINE) {
      return (
        <LineButton
          ref={forwardRef}
          className={className}
          type={buttonType}
          buttonSize={size}
          buttonTheme={theme || 'black'}
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </LineButton>
      );
    }

    return (
      <BaseButton
        ref={forwardRef}
        className={className}
        type={buttonType}
        buttonSize={size}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </BaseButton>
    );
  },
);

const BaseButton = styled.button<{ buttonSize: Size }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &:disabled {
    color: ${colors.line_gray02};
    cursor: not-allowed;
  }
`;

const FillButton = styled(BaseButton)<{ buttonTheme: Theme }>`
  padding: 7px 18px;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  min-height: 34px;
  color: ${colors.white};
  background: ${({ buttonTheme }) => getColor(buttonTheme)};

  &:disabled {
    background-color: ${colors.line_gray02};
    color: ${colors.white};
  }
`;

const LineButton = styled(BaseButton)<{ buttonTheme: Theme }>`
  padding: 5px 18px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  min-height: 34px;
  color: ${({ buttonTheme }) => getColor(buttonTheme)};
  border: 1px solid ${({ buttonTheme }) => getColor(buttonTheme)};
  background: ${colors.white};

  &:disabled {
    border-color: ${colors.line_gray01};
    color: ${colors.line_gray02};
  }
`;
