import { useEffect } from 'react';

type Message<T> = {
  type: string;
  eventName?: string;
  payload: T;
};

export const useReceiveMessage = <T>(callback: (e: MessageEvent<Message<T>>) => void) => {
  useEffect(() => {
    window.addEventListener('message', callback);

    return () => {
      window.removeEventListener('message', callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
