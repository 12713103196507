import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

interface Props {
  className?: string;
}

export const Content = ({ className, children }: PropsWithChildren<Props>) => {
  return <Container className={className}>{children}</Container>;
};

const Container = styled.div`
  width: 100%;
`;
