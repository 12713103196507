import { useCallback, useEffect } from 'react';
import { Dialog } from '../Dialog';
import { CreateDialogParams } from '../Dialog.types';
import { useDialogEventEmitter } from '../hooks';
import { Events, alert, dialogDispatcher, emitter } from '../utils';

export const DialogContainer = () => {
  const { dialogs, dispatch } = useDialogEventEmitter();

  useEffect(() => {
    dialogDispatcher({ dispatch });
    return () => {
      emitter.off();
    };
  }, [dispatch]);

  const handleSuccess = useCallback(
    async (id: string, alwaysCloseOnSuccess: boolean, cb?: () => Promise<void> | void | null) => {
      await cb?.();
      if (alwaysCloseOnSuccess) {
        emitter.emit(Events.HIDE, id);
      }
    },
    [],
  );

  const handleSubSuccess = useCallback(
    async (id: string, alwaysCloseOnSubSuccess: boolean, cb?: () => Promise<void> | void | null) => {
      await cb?.();

      if (alwaysCloseOnSubSuccess) {
        emitter.emit(Events.HIDE, id);
      }
    },
    [],
  );

  const handleCancel = useCallback(async (id: string, cb?: () => Promise<void> | void | null) => {
    await cb?.();
    emitter.emit(Events.HIDE, id);
  }, []);

  return (
    <>
      {dialogs.map(
        ({
          id,
          open,
          content,
          onSuccess,
          onSubSuccess,
          alwaysCloseOnSuccess = true,
          alwaysCloseOnSubSuccess = false,
          onCancel,
          cautionText,
          ...restProps
        }) => {
          const handleDialogSuccess = () => handleSuccess(id, alwaysCloseOnSuccess, onSuccess);
          const handleDialogSubSuccess = () => handleSubSuccess(id, alwaysCloseOnSubSuccess, onSubSuccess);
          const handleDialogCancel = () => handleCancel(id, onCancel);

          return (
            <Dialog
              key={id}
              open={open}
              onSuccess={onSuccess && handleDialogSuccess}
              onCancel={handleDialogCancel}
              cautionText={cautionText}
              onSubSuccess={onSubSuccess && handleDialogSubSuccess}
              {...restProps}
            >
              {content}
            </Dialog>
          );
        },
      )}
    </>
  );
};

export const dialog = {
  caution(id: string, content: string) {
    emitter.emit(Events.CAUTION, { id, content });
  },
  show(params: CreateDialogParams) {
    emitter.emit(Events.SHOW, alert(params));
  },
  hide(id: string) {
    emitter.emit(Events.HIDE, id);
  },
};
