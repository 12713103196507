import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

export const TypeIcon = ({ className, color = 'gray700' }: BaseIconProps) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 3.5H13" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 12.5H9" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13 5V3.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 5V3.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 4L8 12" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
