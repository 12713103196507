import { useState } from 'react';
import { useResizeObserver } from './useResizeObserver';

export const useIsOverflow = () => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  const setResizeRef = useResizeObserver((entries) => {
    const target = entries[0].target;
    const checkIsOverflow = target.scrollHeight > target.clientHeight;
    setIsOverflow(checkIsOverflow);
  });
  return {
    isOverflow,
    setResizeRef,
  };
};
