import { getColor } from '../../core/colors/colors';
import { BaseIconProps } from './types/iconography.types';

export const CautionFilledIcon = ({ className, color = 'red500' }: BaseIconProps) => {
  const fill = getColor(color);

  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33501 1.68666C9.09458 0.303832 11.0813 0.303829 11.8409 1.68665L19.7114 16.0151C20.4435 17.348 19.4792 18.978 17.9584 18.978H2.2175C0.696779 18.978 -0.26759 17.348 0.464549 16.0151L8.33501 1.68666ZM9.99999 6.63356C10.497 6.63356 10.9 7.03651 10.9 7.53356L10.9 11.2083C10.9 11.7054 10.497 12.1083 9.99999 12.1083C9.50293 12.1083 9.09999 11.7054 9.09999 11.2083V7.53356C9.09999 7.03651 9.50293 6.63356 9.99999 6.63356ZM10.9 14.2002C10.9 13.7032 10.497 13.3002 9.99999 13.3002C9.50293 13.3002 9.09999 13.7032 9.09999 14.2002V14.8807C9.09999 15.3778 9.50293 15.7807 9.99999 15.7807C10.497 15.7807 10.9 15.3778 10.9 14.8807V14.2002Z"
        fill={fill}
      />
    </svg>
  );
};
