import { flex } from '@29cm/admin-emotion-utils';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface Props {
  className?: string;
  size?: 'sm' | 'lg';
  active: boolean;
  disabled?: boolean;
  onToggle?: (active: boolean) => void;
}

const ANIMATION_MS = 260;

/**
  ```tsx
  <Toggle
    size="lg"
    active
  />
  ```
 */
export const Toggle = ({
  className,
  size = 'lg',
  active,
  disabled,
  onToggle,
  children,
}: React.PropsWithChildren<Props>) => {
  const handleToggle: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (disabled) {
      return;
    }

    const nextValue = !active;
    onToggle?.(nextValue);
  };

  return (
    <ToggleButton className={className} size={size} type="button" onClick={handleToggle} disabled={disabled}>
      <CircleWrapper active={active} disabled={disabled}>
        <Circle size={size} active={active} disabled={disabled} />
      </CircleWrapper>

      {children ? <ChildrenWrapper>{children}</ChildrenWrapper> : null}
    </ToggleButton>
  );
};

const ToggleButton = styled.button<{ size: 'sm' | 'lg' }>`
  margin: 0;
  padding: 0;
  position: relative;
  cursor: pointer;
  outline: none;
  border: 0;
  background-color: transparent;
  font-family: Pretendard;
  ${flex.center()};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  ${({ size }) => {
    if (size === 'lg') {
      return css`
        ${CircleWrapper} {
          width: 46px;
          height: 26px;
        }

        ${Circle} {
          left: 2px;
          width: 20px;
          height: 20px;
        }

        ${ChildrenWrapper} {
          line-height: 23px;
        }
      `;
    }
  }}
`;

const CircleWrapper = styled.div<{ active: boolean; disabled?: boolean }>`
  position: relative;
  ${flex.center()};
  width: 36px;
  height: 20px;
  border: 1px solid ${({ theme, active }) => (active ? theme.colors.blue500 : theme.colors.gray400)};
  border-radius: 16px;
  background-color: ${({ theme, active }) => (active ? theme.colors.blue500 : theme.colors.gray400)};
  transition: all ${ANIMATION_MS}ms ease-in-out;
  box-sizing: border-box;

  ${({ theme, disabled }) =>
    disabled &&
    css`
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.gray400};
    `}
`;

const ChildrenWrapper = styled.div`
  margin-left: 8px;
  position: relative;
  ${flex({ align: 'center' })};
  font-family: Pretendard;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.gray700};
`;

const Circle = styled.div<{ active: boolean; disabled?: boolean; size: 'sm' | 'lg' }>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  position: absolute;
  left: 1px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all ${ANIMATION_MS}ms ease-in-out;

  ${({ theme, disabled }) =>
    disabled &&
    css`
      background-color: ${theme.colors.gray400};
    `}

  ${({ active, size }) => {
    if (!active) {
      return;
    }

    if (size === 'lg') {
      return css`
        transform: translate(20px, 0);
      `;
    }

    return css`
      transform: translate(16px, 0);
    `;
  }}
`;
