import { colors } from '../../../core';

interface Props {
  className?: string;
  stroke?: string;
}

export const PrevIcon = ({ className, stroke = colors.primary }: Props) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 12L7 9L11 6" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
