import { base64 } from './base64';

interface JWTPayload {
  jti: string; // jwt id
  iss: string; // issuer
  sub: string; // subject
  aud: string; // audience
  exp: number; // expiration time
  nbf: number; // not before
  iat: number; // issued at
  userInfo: UserInfo;
}

interface UserInfo {
  userAltKey: string;
  name: string;
  email: string;
  emailVerified: boolean;
  roles: string[];
}

export const getPayloadFromToken = (accessToken: string) => {
  if (!accessToken) {
    return null;
  }

  const getPayload = accessToken.split('.')[1];
  const decoded = base64.decode(getPayload);

  return JSON.parse(decoded) as JWTPayload;
};
