/* eslint-disable @typescript-eslint/no-explicit-any */
// ref: https://github.com/microsoft/TypeScript/issues/42825

import { DropdownMenuItemType, OnClose, OnReset, OnSubmit } from './DropdownMenu.types';
import { useState } from 'react';
import cloneDeep from 'lodash-es/cloneDeep';

export type UseDropdownMenuParamType<T = Record<PropertyKey, any>> = {
  menuItems: DropdownMenuItemType<T>[];
  onSubmit?: OnSubmit<T>;
  onReset?: OnReset<T>;
  onClose?: OnClose<T>;
};

export const useDropdownMenu = <T = Record<PropertyKey, any>>({
  menuItems,
  onSubmit,
  onReset,
  onClose,
}: UseDropdownMenuParamType<T>) => {
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [draftItems, setDraftItems] = useState<DropdownMenuItemType<T>[]>(cloneDeep(menuItems));

  const handleToggle = (nextIsToggled: boolean) => {
    setIsToggled(nextIsToggled);
  };

  const handleSubmit = () => {
    onSubmit?.(draftItems);
    handleToggle(false);
  };

  const handleReset = (nextItems: DropdownMenuItemType<T>[]) => {
    onReset?.(draftItems);
    setDraftItems(cloneDeep(nextItems));
  };

  const handleClose = () => {
    onClose?.(draftItems);
    setDraftItems(cloneDeep(menuItems));
  };

  const handleItemChange = <K extends keyof T>(key: DropdownMenuItemType<T>['key'], dataKey: K, dataValue: T[K]) => {
    const index = draftItems.findIndex((findingItem) => findingItem.key === key);

    if (index < 0) {
      return;
    }

    const nextItems = [...draftItems];
    const nextItem = cloneDeep(nextItems[index]);

    if (!nextItem.data) {
      return;
    }

    nextItem.data = {
      ...nextItem.data,
      [dataKey]: dataValue,
    };

    nextItems[index] = nextItem;

    setDraftItems(nextItems);
  };

  const handleItemsChange = (nextItems: DropdownMenuItemType<T>[]) => {
    setDraftItems(nextItems);
  };

  return {
    isToggled,
    items: draftItems,
    handleToggle,
    handleSubmit,
    handleReset,
    handleClose,
    handleItemChange,
    handleItemsChange,
  };
};
