import { css, SerializedStyles } from '@emotion/react';
import { KeyOfTypographyVariantType } from '../../foundations/typography/types/variant.types';
import { RadioButtonSize } from './RadioButton.types';

export const BUTTON_SIZE_BY_SIZE_VALUE: Record<RadioButtonSize, SerializedStyles> = {
  [RadioButtonSize.SMALL]: css`
    min-height: 18px;
    padding-left: 26px;

    &::before {
      width: 18px;
      height: 18px;
    }

    &::after {
      width: 16px;
      height: 16px;
      border-width: 4px;
    }
  `,
  [RadioButtonSize.MEDIUM]: css`
    min-height: 22px;
    padding-left: 30px;

    &::before {
      width: 22px;
      height: 22px;
    }

    &::after {
      width: 20px;
      height: 20px;
    }
  `,
} as const;

export const TYPOGRAPHY_VARIANT_BY_SIZE_VALUE: Record<RadioButtonSize, KeyOfTypographyVariantType> = {
  [RadioButtonSize.SMALL]: 'body/13-regular',
  [RadioButtonSize.MEDIUM]: 'body/15-regular',
} as const;
