import { colors } from '@29cm/admin-design-system';
import { colors as legacyColors } from './colors';

export const theme = {
  colors: {
    ...colors,
    legacy: legacyColors,
    custom: {
      bg_table: '#f4f5f7',
    },
  },
} as const;

export type Theme = typeof theme;

export default theme;
