import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  alignType?: 'space-between' | 'flex-start' | 'flex-end';
}

export const ButtonGroup = ({ className, alignType = 'flex-end', children }: Props) => {
  return (
    <Container className={className} alignType={alignType}>
      {children}
    </Container>
  );
};

const Container = styled.div<{ alignType: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ alignType }) => alignType};
  margin-top: 30px;
`;
