import { ThemeConfig } from 'antd';
/**
 * @description
 * This is the theme configuration for Ant Design.
 * 주석처리 된 경우 Antd 와 관련 Type Error Issue 존재, 해당 컴포넌트가 정상적인 UI가 반영되지 않을 수 있습니다.
 * UI에 대한 이질적인 부분이 발생할 경우, @dahyeshin_29cm 혹은 #design-platform 채널에 문의해주세요.
 */

export const antdTheme = {
  token: {
    'blue-1': '#ebf1ff',
    'blue-2': '#b8cdff',
    'blue-3': '#86a5ff',
    'blue-4': '#5b7fff',
    'blue-5': '#375fff',
    'blue-6': '#203ff6',
    'blue-7': '#1025e5',
    'blue-8': '#0611bd',
    'blue-9': '#010474',
    'blue-10': '#000033',
    'cyan-1': '#e6fffb',
    'cyan-2': '#b5f5ec',
    'cyan-3': '#87e8de',
    'cyan-4': '#5cdbd3',
    'cyan-5': '#36cfc9',
    'cyan-6': '#13c2c2',
    'cyan-7': '#08979c',
    'cyan-8': '#006d75',
    'cyan-9': '#00474f',
    'cyan-10': '#002329',
    'geekblue-1': '#f0f5ff',
    'geekblue-2': '#d6e4ff',
    'geekblue-3': '#adc6ff',
    'geekblue-4': '#85a5ff',
    'geekblue-5': '#597ef7',
    'geekblue-6': '#2f54eb',
    'geekblue-7': '#1d39c4',
    'geekblue-8': '#10239e',
    'geekblue-9': '#061178',
    'geekblue-10': '#030852',
    'gold-1': '#faedb5',
    'gold-2': '#f8df8b',
    'gold-3': '#ffe58f',
    'gold-4': '#e8b339',
    'gold-5': '#d89614',
    'gold-6': '#aa7714',
    'gold-7': '#7c5914',
    'gold-8': '#594214',
    'gold-9': '#594214',
    'gold-10': '#2b2111',
    'green-1': '#eefee6',
    'green-2': '#c2eeb2',
    'green-3': '#81dc71',
    'green-4': '#3fcb3c',
    'green-5': '#12ba23',
    'green-6': '#139e14',
    'green-7': '#1e8215',
    'green-8': '#246616',
    'green-9': '#234a15',
    'green-10': '#1b2e11',
    'lime-1': '#fcffe6',
    'lime-2': '#f4ffb8',
    'lime-3': '#eaff8f',
    'lime-4': '#d3f261',
    'lime-5': '#bae637',
    'lime-6': '#a0d911',
    'lime-7': '#7cb305',
    'lime-8': '#5b8c00',
    'lime-9': '#3f6600',
    'lime-10': '#254000',
    'magenta-1': '#fff0f6',
    'magenta-2': '#ffd6e7',
    'magenta-3': '#ffadd2',
    'magenta-4': '#ff85c0',
    'magenta-5': '#f759ab',
    'magenta-6': '#eb2f96',
    'magenta-7': '#c41d7f',
    'magenta-8': '#9e1068',
    'magenta-9': '#780650',
    'magenta-10': '#520339',
    'orange-1': '#fff7e6',
    'orange-2': '#ffe7ba',
    'orange-3': '#ffd591',
    'orange-4': '#ffc069',
    'colorText': '#19191a',
    'colorTextSecondary': '#474747',
    'orange-5': '#ffa940',
    'colorTextTertiary': '#a0a0a0',
    'colorTextQuaternary': '#c4c4c4',
    'orange-6': '#fa8c16',
    'orange-7': '#d46b08',
    'colorTextLightSolid': '#ffffff',
    'colorTextHeading': '#19191a',
    'colorTextLabel': '#474747',
    'orange-8': '#ad4e00',
    'colorTextDescription': '#a0a0a0',
    'orange-9': '#873800',
    'colorTextDisabled': '#c4c4c4',
    'orange-10': '#612500',
    'purple-1': '#f9f0ff',
    'purple-2': '#efdbff',
    'purple-3': '#d3adf7',
    'colorTextPlaceholder': '#c4c4c4',
    'colorIcon': '#a0a0a0',
    'purple-4': '#b37feb',
    'colorIconHover': '#19191a',
    'colorBgContainer': '#ffffff',
    'purple-5': '#9254de',
    'colorBgElevated': '#ffffff',
    'purple-6': '#722ed1',
    'colorBgLayout': '#f4f4f4',
    'purple-7': '#531dab',
    'colorBgMask': 'rgba(0, 0, 0, 0.45)',
    'purple-8': '#391085',
    'colorBgSpotlight': 'rgba(0, 0, 0, 0.85)',
    'purple-9': '#22075e',
    'colorBorder': '#c4c4c4',
    'purple-10': '#120338',
    'colorBorderSecondary': '#e4e4e4',
    'red-1': '#ffefeb',
    'colorFill': '#f4f4f4',
    'red-2': '#ffbfaa',
    'colorFillSecondary': 'rgba(0, 0, 0, 0.06)',
    'colorFillTertiary': 'rgba(0, 0, 0, 0.04)',
    'colorFillQuaternary': 'rgba(0, 0, 0, 0.02)',
    'red-3': '#ff916c',
    'red-4': '#ff6933',
    'colorWhite': '#ffffff',
    'red-5': '#ff4800',
    'colorBgBase': '#ffffff',
    'red-6': '#d53f00',
    'colorTextBase': '#000000',
    'red-7': '#ab3803',
    'red-8': '#823107',
    'colorBgContainerDisabled': 'rgba(0, 0, 0, 0.04)',
    'red-9': '#582609',
    'colorBgTextActive': '#f4f4f4',
    'red-10': '#2e1608',
    'colorBgTextHover': 'rgba(0, 0, 0, 0.06)',
    'volcano-1': '#fff2e8',
    'colorBorderBg': '#ffffff',
    'volcano-2': '#ffd8bf',
    'colorFillContent': 'rgba(0, 0, 0, 0.06)',
    'volcano-3': '#ffbb96',
    'colorFillContentHover': '#f4f4f4',
    'volcano-4': '#ff9c6e',
    'colorFillAlter': 'rgba(0, 0, 0, 0.02)',
    'volcano-5': '#ff7a45',
    'volcano-6': '#fa541c',
    'volcano-7': '#d4380d',
    'colorSplit': 'rgba(0, 0, 0, 0.06)',
    'yellow-1': '#fff7f0',
    'volcano-8': '#ad2102',
    'yellow-2': '#ffdfb9',
    'yellow-3': '#ffc97f',
    'volcano-9': '#871400',
    'yellow-4': '#ffb741',
    'volcano-10': '#610b00',
    'yellow-5': '#ffb741',
    'yellow-6': '#d18d16',
    'yellow-7': '#a37021',
    'yellow-8': '#755322',
    'yellow-9': '#47341b',
    'yellow-10': '#19130c',
    'colorPrimary': '#375fff',
    'colorSuccess': '#12ba23',
    'colorWarning': '#ffa800',
    'colorInfo': '#375fff',
    'colorError': '#ff4800',
    'colorLink': '#375fff',
    'colorErrorBg': '#ffefeb',
    'colorErrorBgHover': '#ffbfaa',
    'colorErrorBorder': '#ff916c',
    'colorErrorBorderHover': '#ff6933',
    'colorErrorHover': '#d53f00',
    'colorErrorActive': '#d53f00',
    'colorErrorTextHover': '#d53f00',
    'colorErrorText': '#ff4800',
    'colorErrorTextActive': '#ab3803',
    'colorLinkHover': '#203ff6',
    'colorInfoBg': '#ebf1ff',
    'colorInfoBgHover': '#b8cdff',
    'colorInfoBorder': '#b8cdff',
    'colorInfoBorderHover': '#86a5ff',
    'colorInfoHover': '#203ff6',
    'colorInfoActive': '#203ff6',
    'colorInfoTextHover': '#203ff6',
    'colorInfoText': '#203ff6',
    'colorInfoTextActive': '#1025e5',
    'colorLinkActive': '#1025e5',
    'colorPrimaryBg': '#ebf1ff',
    'colorPrimaryBgHover': '#b8cdff',
    'colorPrimaryBorder': '#b8cdff',
    'colorPrimaryBorderHover': '#86a5ff',
    'colorPrimaryHover': '#203ff6',
    'colorPrimaryActive': '#203ff6',
    'colorPrimaryTextHover': '#203ff6',
    'colorPrimaryText': '#203ff6',
    'colorPrimaryTextActive': '#1025e5',
    'colorSuccessBg': '#eefee6',
    'colorSuccessBgHover': '#c2eeb2',
    'colorSuccessBorder': '#81dc71',
    'colorSuccessBorderHover': '#3fcb3c',
    'colorSuccessHover': '#139e14',
    'colorSuccessActive': '#139e14',
    'colorSuccessTextHover': '#139e14',
    'colorSuccessText': '#12ba23',
    'colorSuccessTextActive': '#1e8215',
    'colorWarningBg': '#faedb5',
    'colorWarningBgHover': '#ffdfb9',
    'colorWarningBorder': '#ffc97f',
    'colorWarningBorderHover': '#ffb741',
    'colorWarningHover': '#d18d16',
    'colorWarningActive': '#d18d16',
    'colorWarningTextHover': '#ffa800',
    'colorWarningText': '#ffb741',
    'colorWarningTextActive': '#d18d16',
    'colorErrorOutline': 'rgba(255, 38, 6, 0.06)',
    'colorWarningOutline': 'rgba(255, 215, 5, 0.1)',
    'controlItemBgActive': '#ebf1ff',
    'controlItemBgActiveDisabled': '#f4f4f4',
    'controlItemBgActiveHover': '#b8cdff',
    'controlItemBgHover': 'rgba(0, 0, 0, 0.04)',
    'controlOutline': 'rgba(55, 95, 255, 0.1)',
    'controlTmpOutline': 'rgba(0, 0, 0, 0.02)',
    'borderRadius': 4,
    'borderRadiusLG': 4,
    'borderRadiusSM': 2,
    'borderRadiusXS': 1,
    'sizeStep': 4,
    'sizeUnit': 2,
    'controlInteractiveSize': 16,
    'size': 16,
    'sizeLG': 24,
    'sizeMD': 20,
    'sizeMS': 16,
    'sizeSM': 12,
    'sizeXL': 32,
    'sizeXS': 8,
    'sizeXXL': 48,
    'controlHeight': 36,
    'sizeXXS': 4,
    'controlHeightLG': 46,
    'controlHeightSM': 24,
    'controlHeightXS': 16,
    'lineWidth': 1,
    'lineWidthBold': 2,
    'lineWidthFocus': 4,
    'controlOutlineWidth': 2,
    'screenLG': 992,
    'screenLGMax': 1199,
    'screenLGMin': 992,
    'screenMD': 768,
    'screenMDMax': 991,
    'screenMDMin': 768,
    'screenSM': 576,
    'screenSMMax': 767,
    'screenSMMin': 576,
    'screenXL': 1200,
    'screenXLMax': 1599,
    'screenXLMin': 1200,
    'screenXS': 480,
    'screenXSMax': 575,
    'screenXSMin': 480,
    'screenXXL': 1600,
    'screenXXLMin': 1600,
    'sizePopupArrow': 16,
    'margin': 16,
    'marginLG': 24,
    'marginMD': 20,
    'marginSM': 12,
    'marginXL': 32,
    'marginXS': 8,
    'marginXXL': 48,
    'marginXXS': 4,
    'padding': 16,
    'paddingLG': 24,
    'paddingMD': 20,
    'paddingSM': 12,
    'paddingXL': 32,
    'paddingXS': 8,
    'paddingXXS': 4,
    'paddingContentHorizontal': 16,
    'paddingContentHorizontalLG': 24,
    'paddingContentHorizontalSM': 16,
    'paddingContentVertical': 12,
    'paddingContentVerticalLG': 16,
    'paddingContentVerticalSM': 8,
    'controlPaddingHorizontal': 12,
    'controlPaddingHorizontalSM': 8,
    'fontFamily': 'Pretendard',
    'fontFamilyCode': 'Courier Prime',
    'fontSize': 14,
    'fontSizeLG': 16,
    'fontSizeSM': 12,
    'fontSizeXL': 20,
    'fontSizeHeading1': 38,
    'fontSizeHeading2': 30,
    'fontSizeHeading3': 24,
    'fontSizeHeading4': 20,
    'fontSizeHeading5': 18,
    'lineHeight': 1.5714285714285714,
    'lineHeightHeading1': 1.2105263157894737,
    'lineHeightHeading2': 1.2666666666666666,
    'lineHeightHeading3': 1.3333333333333333,
    'lineHeightHeading4': 1.4,
    'lineHeightHeading5': 1.3333333333333333,
    'lineHeightLG': 1.5,
    'lineHeightSM': 1.6666666666666667,
    'fontSizeIcon': 12,
    'fontWeightStrong': 600,
  },
  components: {
    Input: {
      paddingInlineSM: 7,
      paddingInlineLG: 11,
      paddingInline: 11,
      paddingBlockSM: 0,
      paddingBlockLG: 7,
      paddingBlock: 4,
      paddingXXS: 4,
      paddingXS: 8,
      paddingSM: 12,
      paddingLG: 24,
      lineWidth: 1,
      lineHeightLG: 1.5,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSizeIcon: 12,
      fontSize: 14,
      controlPaddingHorizontalSM: 8,
      controlPaddingHorizontal: 12,
      controlOutlineWidth: 2,
      controlOutline: 'rgba(55, 95, 255, 0.1)',
      controlHeightSM: 24,
      controlHeightLG: 46,
      controlHeight: 36,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      borderRadius: 4,
      colorWarningOutline: 'rgba(255, 215, 5, 0.1)',
      colorWarningBorderHover: '#ffb741',
      colorWarning: '#ffa800',
      colorTextTertiary: '#a0a0a0',
      colorTextQuaternary: '#c4c4c4',
      colorTextPlaceholder: '#c4c4c4',
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorPrimary: '#375fff',
      colorIconHover: '#19191a',
      colorIcon: '#a0a0a0',
      colorFillAlter: 'rgba(0, 0, 0, 0.02)',
      colorErrorOutline: 'rgba(255, 38, 6, 0.06)',
      colorErrorBorderHover: '#ff6933',
      colorError: '#ff4800',
      colorBorder: '#c4c4c4',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      hoverBorderColor: '#203ff6',
      addonBg: 'rgba(0, 0, 0, 0.02)',
      activeBorderColor: '#375fff',
      colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
      colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
      colorErrorBgHover: '#ffbfaa',
      colorErrorBg: '#ffefeb',
      colorWarningBg: '#faedb5',
      colorWarningBgHover: '#ffdfb9',
      colorWarningText: '#ffb741',
      colorErrorText: '#ff4800',
      activeBg: '#ffffff',
      hoverBg: '#ffffff',
      inputFontSize: 14,
      inputFontSizeLG: 16,
      inputFontSizeSM: 12,
      fontFamily: 'Pretendard',
    },
    Transfer: {
      listWidthLG: 250,
      listWidth: 180,
      listHeight: 200,
      itemPaddingBlock: 5,
      paddingXS: 8,
      paddingSM: 12,
      marginXXS: 4,
      marginXS: 8,
      margin: 16,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeIcon: 12,
      fontSize: 14,
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlItemBgActiveHover: '#b8cdff',
      controlItemBgActive: '#ebf1ff',
      controlHeightLG: 46,
      controlHeight: 36,
      borderRadiusLG: 4,
      colorWarning: '#ffa800',
      colorTextDisabled: '#c4c4c4',
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorLinkHover: '#203ff6',
      colorError: '#ff4800',
      colorBorder: '#c4c4c4',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      itemHeight: 36,
      headerHeight: 46,
      fontFamily: 'Pretendard',
    },
    Segmented: {
      paddingXXS: 4,
      marginSM: 12,
      lineWidthBold: 2,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSize: 14,
      controlPaddingHorizontalSM: 8,
      controlPaddingHorizontal: 12,
      controlHeightSM: 24,
      controlHeightLG: 46,
      controlHeight: 36,
      borderRadiusXS: 1,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      borderRadius: 4,
      colorTextLabel: '#474747',
      colorTextDisabled: '#c4c4c4',
      colorText: '#19191a',
      colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
      colorFill: '#f4f4f4',
      colorBgLayout: '#f4f4f4',
      colorBgElevated: '#ffffff',
      itemSelectedColor: '#19191a',
      itemSelectedBg: '#ffffff',
      itemHoverColor: '#19191a',
      itemHoverBg: 'rgba(0, 0, 0, 0.06)',
      itemColor: '#474747',
      itemActiveBg: '#f4f4f4',
      trackPadding: 2,
      trackBg: '#f4f4f4',
      fontFamily: 'Pretendard',
    },
    Switch: {
      trackPadding: 2,
      trackMinWidthSM: 28,
      trackMinWidth: 44,
      trackHeightSM: 16,
      trackHeight: 22,
      handleSizeSM: 12,
      handleSize: 18,
      marginXXS: 4,
      lineWidthFocus: 4,
      lineHeight: 1.5714285714285714,
      fontSizeSM: 12,
      fontSizeIcon: 12,
      fontSize: 14,
      controlHeight: 36,
      colorWhite: '#ffffff',
      colorTextTertiary: '#a0a0a0',
      colorTextQuaternary: '#c4c4c4',
      colorTextLightSolid: '#ffffff',
      colorText: '#19191a',
      colorPrimaryHover: '#203ff6',
      colorPrimaryBorder: '#b8cdff',
      colorPrimary: '#375fff',
      handleBg: '#ffffff',
      fontFamily: 'Pretendard',
    },
    // TimePicker: {
    //   timeColumnWidth: 56,
    //   timeColumnHeight: 224,
    //   timeCellHeight: 28,
    //   borderRadiusSM: 2,
    //   borderRadiusLG: 4,
    //   sizePopupArrow: 16,
    //   paddingXXS: 4,
    //   paddingXS: 8,
    //   paddingSM: 12,
    //   padding: 16,
    //   marginXXS: 4,
    //   marginXS: 8,
    //   lineWidthBold: 2,
    //   lineWidth: 1,
    //   lineHeightLG: 1.5,
    //   lineHeight: 1.5714285714285714,
    //   fontWeightStrong: 600,
    //   fontSizeLG: 16,
    //   fontSize: 14,
    //   controlPaddingHorizontalSM: 8,
    //   controlPaddingHorizontal: 12,
    //   controlOutlineWidth: 2,
    //   controlOutline: 'rgba(55, 95, 255, 0.1)',
    //   controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
    //   controlItemBgActive: '#ebf1ff',
    //   controlHeightSM: 24,
    //   controlHeightLG: 46,
    //   controlHeight: 36,
    //   borderRadiusXS: 1,
    //   borderRadius: 4,
    //   colorWarningOutline: 'rgba(255, 215, 5, 0.1)',
    //   colorWarning: '#ffa800',
    //   colorTextQuaternary: '#c4c4c4',
    //   colorTextPlaceholder: '#c4c4c4',
    //   colorTextLightSolid: '#ffffff',
    //   colorTextHeading: '#19191a',
    //   colorTextDisabled: '#c4c4c4',
    //   colorTextDescription: '#a0a0a0',
    //   colorText: '#19191a',
    //   colorSplit: 'rgba(0, 0, 0, 0.06)',
    //   colorPrimaryHover: '#203ff6',
    //   colorPrimaryBorder: '#b8cdff',
    //   colorPrimary: '#375fff',
    //   colorLinkHover: '#203ff6',
    //   colorLinkActive: '#1025e5',
    //   colorLink: '#375fff',
    //   colorIconHover: '#19191a',
    //   colorIcon: '#a0a0a0',
    //   colorFillAlter: 'rgba(0, 0, 0, 0.02)',
    //   colorErrorOutline: 'rgba(255, 38, 6, 0.06)',
    //   colorError: '#ff4800',
    //   colorBorder: '#c4c4c4',
    //   colorBgElevated: '#ffffff',
    //   colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
    //   colorBgContainer: '#ffffff',
    //   hoverBorderColor: '#203ff6',
    //   cellHoverBg: 'rgba(0, 0, 0, 0.04)',
    //   cellHeight: 24,
    //   activeBorderColor: '#375fff',
    //   paddingInline: 11,
    //   paddingInlineLG: 11,
    //   paddingInlineSM: 7,
    //   activeBg: '#ffffff',
    //   hoverBg: '#ffffff',
    //   cellBgDisabled: 'rgba(0, 0, 0, 0.04)',
    //   cellActiveWithRangeBg: '#ebf1ff',
    //   cellHoverWithRangeBg: '#c8dfff',
    //   cellRangeBorderColor: '#7cb3ff',
    //   multipleItemBg: 'rgba(0, 0, 0, 0.06)',
    //   multipleItemBorderColor: 'rgba(0, 0, 0, 0)',
    //   multipleItemBorderColorDisabled: 'rgba(0, 0, 0, 0)',
    //   multipleItemColorDisabled: '#c4c4c4',
    //   multipleSelectorBgDisabled: 'rgba(0, 0, 0, 0.04)',
    //   cellWidth: 36,
    //   multipleItemHeight: 24,
    //   multipleItemHeightLG: 32,
    //   multipleItemHeightSM: 16,
    //   paddingBlock: 4,
    //   paddingBlockLG: 7,
    //   paddingBlockSM: 0,
    //   presetsMaxWidth: 200,
    //   presetsWidth: 120,
    //   textHeight: 46,
    //   withoutTimeCellHeight: 66,
    //   inputFontSize: 14,
    //   inputFontSizeLG: 16,
    //   inputFontSizeSM: 12,
    //   fontFamily: 'Pretendard',
    // },
    Timeline: {
      itemPaddingBottom: 20,
      paddingXXS: 4,
      padding: 16,
      marginXXS: 4,
      marginXS: 8,
      marginSM: 12,
      margin: 16,
      lineWidthBold: 2,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeSM: 12,
      fontSize: 14,
      controlHeightLG: 46,
      colorTextDisabled: '#c4c4c4',
      colorText: '#19191a',
      colorSuccess: '#12ba23',
      colorPrimary: '#375fff',
      colorError: '#ff4800',
      colorBgContainer: '#ffffff',
      tailColor: 'rgba(0, 0, 0, 0.06)',
      dotBg: '#ffffff',
      fontFamily: 'Pretendard',
    },
    Tabs: {
      horizontalItemGutter: 32,
      cardGutter: 2,
      paddingXXS: 4,
      paddingXS: 8,
      paddingSM: 12,
      paddingLG: 24,
      padding: 16,
      marginXXS: 4,
      marginXS: 8,
      marginSM: 12,
      margin: 16,
      lineWidthFocus: 4,
      lineWidthBold: 2,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeSM: 12,
      fontSizeLG: 16,
      fontSize: 14,
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlHeightLG: 46,
      controlHeight: 36,
      borderRadiusLG: 4,
      borderRadius: 4,
      colorTextHeading: '#19191a',
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorPrimaryHover: '#203ff6',
      colorPrimaryBorder: '#b8cdff',
      colorPrimaryActive: '#203ff6',
      colorPrimary: '#375fff',
      colorFillAlter: 'rgba(0, 0, 0, 0.02)',
      colorBorderSecondary: '#e4e4e4',
      colorBorder: '#c4c4c4',
      colorBgContainer: '#ffffff',
      itemSelectedColor: '#375fff',
      itemHoverColor: '#203ff6',
      itemColor: '#19191a',
      itemActiveColor: '#203ff6',
      inkBarColor: '#375fff',
      cardHeight: 46,
      cardBg: 'rgba(0, 0, 0, 0.02)',
      titleFontSize: 14,
      titleFontSizeLG: 16,
      titleFontSizeSM: 14,
      fontFamily: 'Pretendard',
    },
    Table: {
      stickyScrollBarBorderRadius: 100,
      headerSplitColor: '#e4e4e4',
      headerBg: 'rgba(0, 0, 0, 0.02)',
      footerBg: 'rgba(0, 0, 0, 0.02)',
      paddingXXS: 4,
      paddingXS: 8,
      paddingSM: 12,
      padding: 16,
      marginXXS: 4,
      margin: 16,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontWeightStrong: 600,
      fontSizeSM: 12,
      fontSizeIcon: 12,
      fontSize: 14,
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlItemBgActiveHover: '#b8cdff',
      controlItemBgActive: '#ebf1ff',
      controlInteractiveSize: 16,
      controlHeight: 36,
      borderRadiusLG: 4,
      borderRadius: 4,
      colorTextPlaceholder: '#c4c4c4',
      colorTextHeading: '#19191a',
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorPrimary: '#375fff',
      colorLinkHover: '#203ff6',
      colorLinkActive: '#1025e5',
      colorLink: '#375fff',
      colorIconHover: '#19191a',
      colorIcon: '#a0a0a0',
      colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
      colorFillContent: 'rgba(0, 0, 0, 0.06)',
      colorFillAlter: 'rgba(0, 0, 0, 0.02)',
      colorBorderSecondary: '#e4e4e4',
      colorBgContainer: '#ffffff',
      rowSelectedHoverBg: '#b8cdff',
      headerFilterHoverBg: 'rgba(0, 0, 0, 0.06)',
      headerColor: '#19191a',
      headerBorderRadius: 4,
      footerColor: '#19191a',
      filterDropdownMenuBg: '#ffffff',
      filterDropdownBg: '#ffffff',
      cellPaddingInlineSM: 8,
      cellPaddingInlineMD: 8,
      cellPaddingInline: 16,
      cellPaddingBlockSM: 8,
      cellPaddingBlockMD: 12,
      cellPaddingBlock: 16,
      borderColor: '#e4e4e4',
      rowHoverBg: '#fafafa',
      headerSortActiveBg: 'rgba(0, 0, 0, 0.06)',
      bodySortBg: '#fafafa',
      headerSortHoverBg: 'rgba(0, 0, 0, 0.06)',
      cellFontSize: 14,
      cellFontSizeMD: 14,
      cellFontSizeSM: 14,
      fontFamily: 'Pretendard',
    },
    Steps: {
      dotSize: 8,
      dotCurrentSize: 10,
      descriptionMaxWidth: 140,
      paddingXXS: 4,
      paddingXS: 8,
      paddingSM: 12,
      paddingLG: 24,
      padding: 16,
      marginXXS: 4,
      marginXS: 8,
      marginSM: 12,
      marginLG: 24,
      margin: 16,
      lineWidthFocus: 4,
      lineWidthBold: 2,
      lineWidth: 1,
      lineHeightSM: 1.6666666666666667,
      lineHeight: 1.5714285714285714,
      fontWeightStrong: 600,
      fontSizeSM: 12,
      fontSizeLG: 16,
      fontSizeIcon: 12,
      fontSizeHeading3: 24,
      fontSize: 14,
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlItemBgActive: '#ebf1ff',
      controlHeightLG: 46,
      borderRadiusSM: 2,
      colorTextQuaternary: '#c4c4c4',
      colorTextLightSolid: '#ffffff',
      colorTextLabel: '#474747',
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorPrimaryBorder: '#b8cdff',
      colorPrimary: '#375fff',
      colorFillContent: 'rgba(0, 0, 0, 0.06)',
      colorError: '#ff4800',
      colorBorderSecondary: '#e4e4e4',
      colorBorderBg: '#ffffff',
      colorBgContainer: '#ffffff',
      titleLineHeight: 36,
      iconSizeSM: 24,
      iconSize: 36,
      customIconFontSize: 24,
      iconFontSize: 14,
      fontFamily: 'Pretendard',
    },
    Spin: {
      dotSizeSM: 14,
      dotSize: 20,
      contentHeight: 400,
      marginXXS: 4,
      lineHeight: 1.5714285714285714,
      fontSize: 14,
      controlHeightLG: 46,
      controlHeight: 36,
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorPrimary: '#375fff',
      colorBgContainer: '#ffffff',
      dotSizeLG: 36,
      fontFamily: 'Pretendard',
    },
    Slider: {
      railSize: 4,
      handleSizeHover: 12,
      dotSize: 8,
      controlSize: 10,
      handleSize: 10,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSize: 14,
      controlHeightSM: 24,
      controlHeightLG: 46,
      controlHeight: 36,
      borderRadiusXS: 1,
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorPrimaryBorderHover: '#86a5ff',
      colorPrimaryBorder: '#b8cdff',
      colorPrimary: '#375fff',
      colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
      colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
      colorFillContentHover: '#f4f4f4',
      colorBorderSecondary: '#e4e4e4',
      colorBgElevated: '#ffffff',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      trackHoverBg: '#86a5ff',
      trackBgDisabled: 'rgba(0, 0, 0, 0.04)',
      trackBg: '#b8cdff',
      railHoverBg: 'rgba(0, 0, 0, 0.06)',
      railBg: 'rgba(0, 0, 0, 0.04)',
      handleColor: '#b8cdff',
      handleActiveColor: '#375fff',
      dotBorderColor: '#e4e4e4',
      dotActiveBorderColor: '#b8cdff',
      handleColorDisabled: '#bfbfbf',
      fontFamily: 'Pretendard',
    },
    Rate: {
      marginXS: 8,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSize: 14,
      controlHeightLG: 46,
      colorText: '#19191a',
      colorFillContent: 'rgba(0, 0, 0, 0.06)',
      fontFamily: 'Pretendard',
    },
    Radio: {
      algorithm: true,
      radioSize: 16,
      dotSize: 8,
      buttonPaddingInline: 15,
      paddingXS: 8,
      padding: 16,
      marginXS: 8,
      lineWidthFocus: 4,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSize: 14,
      controlOutlineWidth: 2,
      controlOutline: 'rgba(55, 95, 255, 0.1)',
      controlItemBgActiveDisabled: '#f4f4f4',
      controlHeightSM: 24,
      controlHeightLG: 46,
      controlHeight: 36,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      borderRadius: 4,
      colorWhite: '#ffffff',
      colorTextLightSolid: '#ffffff',
      colorTextDisabled: '#c4c4c4',
      colorText: '#19191a',
      colorPrimaryHover: '#203ff6',
      colorPrimaryBorder: '#b8cdff',
      colorPrimaryActive: '#203ff6',
      colorPrimary: '#375fff',
      colorBorder: '#c4c4c4',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      wrapperMarginInlineEnd: 8,
      dotColorDisabled: '#c4c4c4',
      buttonSolidCheckedHoverBg: '#203ff6',
      buttonSolidCheckedColor: '#ffffff',
      buttonSolidCheckedBg: '#375fff',
      buttonSolidCheckedActiveBg: '#203ff6',
      buttonColor: '#19191a',
      buttonCheckedColorDisabled: '#c4c4c4',
      buttonCheckedBgDisabled: '#f4f4f4',
      buttonCheckedBg: '#ffffff',
      buttonBg: '#ffffff',
      fontFamily: 'Pretendard',
    },
    Popover: {
      titleMinWidth: 177,
      sizePopupArrow: 16,
      paddingSM: 12,
      padding: 16,
      marginXS: 8,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontWeightStrong: 600,
      fontSize: 14,
      controlHeight: 36,
      borderRadiusXS: 1,
      borderRadiusLG: 4,
      colorTextHeading: '#19191a',
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorBgElevated: '#ffffff',
      fontFamily: 'Pretendard',
    },
    Notification: {
      width: 384,
      paddingMD: 20,
      paddingLG: 24,
      paddingContentHorizontalLG: 24,
      marginXS: 8,
      marginSM: 12,
      marginLG: 24,
      margin: 16,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSize: 14,
      controlHeightLG: 46,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      colorWarning: '#ffa800',
      colorTextHeading: '#19191a',
      colorText: '#19191a',
      colorSuccess: '#12ba23',
      colorInfo: '#375fff',
      colorIconHover: '#19191a',
      colorIcon: '#a0a0a0',
      colorError: '#ff4800',
      colorBgElevated: '#ffffff',
      lineHeightLG: 1.5,
      lineWidthFocus: 4,
      fontFamily: 'Pretendard',
    },
    Tooltip: {
      paddingSM: 12,
      sizePopupArrow: 16,
      paddingXS: 8,
      lineHeight: 1.5714285714285714,
      fontSize: 14,
      controlHeight: 36,
      borderRadiusXS: 1,
      borderRadius: 4,
      colorTextLightSolid: '#ffffff',
      colorText: '#19191a',
      colorBgSpotlight: 'rgba(0, 0, 0, 0.85)',
      fontFamily: 'Pretendard',
    },
    Menu: {
      subMenuItemBg: 'rgba(0, 0, 0, 0.02)',
      darkSubMenuItemBg: '#000c17',
      darkItemDisabledColor: 'rgba(255, 255, 255, 0.25)',
      darkItemColor: 'rgba(255, 255, 255, 0.65)',
      darkGroupTitleColor: 'rgba(255, 255, 255, 0.65)',
      darkItemBg: '#001529',
      paddingXS: 8,
      paddingXL: 32,
      padding: 16,
      marginXXS: 4,
      marginXS: 8,
      margin: 16,
      lineWidthFocus: 4,
      lineWidthBold: 2,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSize: 14,
      controlItemBgActive: '#ebf1ff',
      controlHeightSM: 24,
      controlHeightLG: 46,
      borderRadius: 4,
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorPrimaryBorder: '#b8cdff',
      colorPrimary: '#375fff',
      colorFillContent: 'rgba(0, 0, 0, 0.06)',
      colorErrorHover: '#d53f00',
      colorErrorBg: '#ffefeb',
      colorError: '#ff4800',
      colorBgElevated: '#ffffff',
      colorBgContainer: '#ffffff',
      subMenuItemBorderRadius: 2,
      popupBg: '#ffffff',
      itemSelectedColor: '#375fff',
      itemSelectedBg: '#ebf1ff',
      itemHoverColor: '#19191a',
      itemHoverBg: 'rgba(0, 0, 0, 0.06)',
      itemHeight: 46,
      itemDisabledColor: '#c4c4c4',
      itemColor: '#19191a',
      itemBorderRadius: 4,
      itemBg: '#ffffff',
      iconSize: 14,
      horizontalItemSelectedColor: '#375fff',
      horizontalItemSelectedBg: 'rgba(0, 0, 0, 0)',
      horizontalItemHoverColor: '#375fff',
      horizontalItemHoverBg: 'rgba(0, 0, 0, 0)',
      groupTitleColor: '#a0a0a0',
      darkItemSelectedColor: '#ffffff',
      darkItemSelectedBg: '#375fff',
      darkItemHoverColor: '#ffffff',
      darkItemHoverBg: 'rgba(0, 0, 0, 0)',
      collapsedIconSize: 16,
      darkPopupBg: '#001529',
      activeBarBorderWidth: 1,
      collapsedWidth: 80,
      dropdownWidth: 160,
      itemMarginBlock: 4,
      itemMarginInline: 4,
      iconMarginInlineEnd: 10,
      groupTitleFontSize: 14,
      groupTitleLineHeight: 1.5714285714285714,
      fontFamily: 'Pretendard',
    },
    InputNumber: {
      paddingInlineSM: 7,
      paddingInlineLG: 11,
      paddingInline: 11,
      paddingBlockSM: 0,
      paddingBlockLG: 7,
      paddingBlock: 4,
      handleWidth: 22,
      controlWidth: 90,
      paddingXXS: 4,
      paddingXS: 8,
      paddingSM: 12,
      lineWidth: 1,
      lineHeightLG: 1.5,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSize: 14,
      controlPaddingHorizontalSM: 8,
      controlPaddingHorizontal: 12,
      controlHeightSM: 24,
      controlHeightLG: 46,
      controlHeight: 36,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      borderRadius: 4,
      colorWarningBorderHover: '#ffb741',
      colorWarning: '#ffa800',
      colorTextPlaceholder: '#c4c4c4',
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorPrimaryHover: '#203ff6',
      colorPrimary: '#375fff',
      colorFillAlter: 'rgba(0, 0, 0, 0.02)',
      colorErrorBorderHover: '#ff6933',
      colorError: '#ff4800',
      colorBorder: '#c4c4c4',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      hoverBorderColor: '#203ff6',
      handleHoverColor: '#375fff',
      handleBorderColor: '#c4c4c4',
      handleBg: '#ffffff',
      handleActiveBg: 'rgba(0, 0, 0, 0.02)',
      addonBg: 'rgba(0, 0, 0, 0.02)',
      activeBorderColor: '#375fff',
      activeBg: '#ffffff',
      hoverBg: '#ffffff',
      inputFontSize: 14,
      inputFontSizeLG: 16,
      inputFontSizeSM: 12,
      fontFamily: 'Pretendard',
    },
    Image: {
      previewOperationSize: 18,
      previewOperationHoverColor: 'rgba(255, 255, 255, 0.85)',
      previewOperationColorDisabled: 'rgba(255, 255, 255, 0.25)',
      previewOperationColor: 'rgba(255, 255, 255, 0.65)',
      paddingXXS: 4,
      paddingSM: 12,
      paddingLG: 24,
      marginXXS: 4,
      marginXL: 32,
      marginSM: 12,
      margin: 16,
      fontSizeIcon: 12,
      controlHeightLG: 46,
      colorTextLightSolid: '#ffffff',
      colorBgMask: 'rgba(0, 0, 0, 0.45)',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
    },
    Card: {
      headerHeightSM: 38,
      headerHeight: 56,
      paddingXS: 8,
      paddingSM: 12,
      paddingLG: 24,
      padding: 16,
      marginXXS: 4,
      marginXS: 8,
      lineWidth: 1,
      lineHeightLG: 1.5,
      lineHeight: 1.5714285714285714,
      fontWeightStrong: 600,
      fontSize: 14,
      borderRadiusLG: 4,
      colorTextHeading: '#19191a',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorPrimary: '#375fff',
      colorFillAlter: 'rgba(0, 0, 0, 0.02)',
      colorBorderSecondary: '#e4e4e4',
      colorBgContainer: '#ffffff',
      headerBg: 'rgba(0, 0, 0, 0)',
      headerFontSize: 16,
      headerFontSizeSM: 14,
      fontSizeLG: 16,
      fontFamily: 'Pretendard',
    },
    Carousel: {
      dotWidth: 16,
      dotHeight: 3,
      dotActiveWidth: 24,
      marginXXS: 4,
      lineHeight: 1.5714285714285714,
      fontSize: 14,
      controlHeightSM: 24,
      controlHeightLG: 46,
      colorText: '#19191a',
      colorBgContainer: '#ffffff',
      fontFamily: 'Pretendard',
    },
    Cascader: {
      dropdownHeight: 180,
      controlWidth: 184,
      controlItemWidth: 111,
      paddingXXS: 4,
      paddingXS: 8,
      paddingSM: 12,
      marginXS: 8,
      lineWidthFocus: 4,
      lineWidthBold: 2,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontWeightStrong: 600,
      fontSizeLG: 16,
      fontSizeIcon: 12,
      fontSize: 14,
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlItemBgActive: '#ebf1ff',
      controlInteractiveSize: 16,
      controlHeight: 36,
      borderRadiusSM: 2,
      colorWhite: '#ffffff',
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorPrimaryHover: '#203ff6',
      colorPrimaryBorder: '#b8cdff',
      colorPrimary: '#375fff',
      colorBorder: '#c4c4c4',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      optionSelectedBg: '#ebf1ff',
      menuPadding: 4,
      optionSelectedFontWeight: 600,
      fontFamily: 'Pretendard',
    },
    Calendar: {
      yearControlWidth: 80,
      monthControlWidth: 70,
      controlHeight: 36,
      screenXS: 480,
      paddingXXS: 4,
      paddingXS: 8,
      paddingSM: 12,
      padding: 16,
      marginXXS: 4,
      marginXS: 8,
      lineWidthBold: 2,
      lineWidth: 1,
      lineHeightSM: 1.6666666666666667,
      lineHeight: 1.5714285714285714,
      fontWeightStrong: 600,
      fontSizeSM: 12,
      fontSize: 14,
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlItemBgActive: '#ebf1ff',
      controlHeightSM: 24,
      controlHeightLG: 46,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      colorTextLightSolid: '#ffffff',
      colorTextHeading: '#19191a',
      colorTextDisabled: '#c4c4c4',
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorPrimary: '#375fff',
      colorLinkHover: '#203ff6',
      colorLinkActive: '#1025e5',
      colorLink: '#375fff',
      colorIconHover: '#19191a',
      colorIcon: '#a0a0a0',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      itemActiveBg: '#ebf1ff',
      fullPanelBg: '#ffffff',
      fullBg: '#ffffff',
      fontFamily: 'Pretendard',
    },
    Button: {
      paddingInlineSM: 8,
      paddingInlineLG: 16,
      paddingInline: 16,
      onlyIconSizeSM: 14,
      onlyIconSizeLG: 18,
      paddingXS: 6,
      paddingContentHorizontal: 16,
      marginXS: 6,
      lineWidthFocus: 4,
      lineWidth: 1,
      contentLineHeight: 1.5714285714285714,
      contentFontSizeLG: 16,
      contentFontSize: 14,
      controlOutlineWidth: 2,
      controlOutline: 'rgba(55, 95, 255, 0.1)',
      controlHeightSM: 24,
      controlHeightLG: 46,
      controlHeight: 36,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      borderRadius: 4,
      colorTextLightSolid: '#ffffff',
      colorTextDisabled: '#c4c4c4',
      colorText: '#19191a',
      colorPrimaryHover: '#203ff6',
      colorPrimaryBorder: '#b8cdff',
      colorPrimaryActive: '#203ff6',
      colorPrimary: '#375fff',
      colorLinkHover: '#203ff6',
      colorLinkActive: '#1025e5',
      colorLink: '#375fff',
      colorErrorOutline: 'rgba(255, 38, 6, 0.06)',
      colorErrorHover: '#d53f00',
      colorErrorBorderHover: '#ff6933',
      colorErrorBg: '#ffefeb',
      colorErrorActive: '#d53f00',
      colorError: '#ff4800',
      colorBorder: '#c4c4c4',
      colorBgTextActive: '#f4f4f4',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      textHoverBg: 'rgba(0, 0, 0, 0.06)',
      primaryColor: '#ffffff',
      onlyIconSize: 16,
      linkHoverBg: 'rgba(0, 0, 0, 0)',
      groupBorderColor: '#203ff6',
      ghostBg: 'rgba(0, 0, 0, 0)',
      defaultGhostColor: '#ffffff',
      defaultGhostBorderColor: '#ffffff',
      defaultColor: '#19191a',
      defaultBorderColor: '#c4c4c4',
      defaultBg: '#ffffff',
      dangerColor: '#ffffff',
      borderColorDisabled: '#c4c4c4',
      defaultHoverBg: '#ffffff',
      defaultHoverColor: '#203ff6',
      defaultHoverBorderColor: '#203ff6',
      defaultActiveBg: '#ffffff',
      defaultActiveColor: '#203ff6',
      defaultActiveBorderColor: '#203ff6',
      fontWeight: 500,
      contentFontSizeSM: 12,
      contentLineHeightLG: 24,
      contentLineHeightSM: 22,
    },
    Badge: {
      statusSize: 6,
      indicatorHeight: 20,
      dotSize: 6,
      paddingXS: 8,
      marginXS: 8,
      lineWidth: 1,
      borderRadiusSM: 2,
      fontSizeSM: 12,
      fontSize: 14,
      colorWarning: '#ffa800',
      colorTextPlaceholder: '#c4c4c4',
      colorTextLightSolid: '#ffffff',
      colorText: '#19191a',
      colorSuccess: '#12ba23',
      colorPrimary: '#375fff',
      colorErrorHover: '#d53f00',
      colorError: '#ff4800',
      colorBorderBg: '#ffffff',
      colorBgContainer: '#ffffff',
      indicatorHeightSM: 14,
      textFontSize: 12,
      textFontSizeSM: 12,
      lineHeight: 1.5714285714285714,
      fontFamily: 'Pretendard',
    },
    Form: {
      screenXSMax: 575,
      screenSMMax: 767,
      screenMDMax: 991,
      screenLGMax: 1199,
      paddingXS: 8,
      paddingSM: 12,
      marginXXS: 4,
      marginXS: 8,
      marginLG: 24,
      margin: 16,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSize: 14,
      controlOutlineWidth: 2,
      controlOutline: 'rgba(55, 95, 255, 0.1)',
      controlHeightSM: 24,
      controlHeightLG: 46,
      controlHeight: 36,
      colorWarning: '#ffa800',
      colorTextHeading: '#19191a',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorSuccess: '#12ba23',
      colorPrimary: '#375fff',
      colorError: '#ff4800',
      colorBorder: '#c4c4c4',
      labelRequiredMarkColor: '#ff4800',
      labelColor: '#19191a',
      itemMarginBottom: 24,
      labelColonMarginInlineEnd: 8,
      labelColonMarginInlineStart: 2,
      labelHeight: 36,
      labelFontSize: 14,
      fontFamily: 'Pretendard',
    },
    Avatar: {
      marginXXS: 4,
      marginXS: 8,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeXL: 20,
      fontSizeLG: 16,
      fontSizeHeading3: 24,
      fontSize: 14,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      borderRadius: 4,
      colorTextPlaceholder: '#c4c4c4',
      colorTextLightSolid: '#ffffff',
      colorText: '#19191a',
      colorBorderBg: '#ffffff',
      containerSizeSM: 24,
      containerSizeLG: 46,
      containerSize: 36,
      textFontSize: 18,
      textFontSizeLG: 24,
      textFontSizeSM: 14,
      fontFamily: 'Pretendard',
    },
    Tour: {
      sizePopupArrow: 16,
      paddingXS: 8,
      padding: 16,
      marginXS: 8,
      lineHeight: 1.5714285714285714,
      fontWeightStrong: 600,
      fontSize: 14,
      borderRadiusXS: 1,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      borderRadius: 4,
      colorWhite: '#ffffff',
      colorTextLightSolid: '#ffffff',
      colorText: '#19191a',
      colorPrimary: '#375fff',
      colorIconHover: '#19191a',
      colorIcon: '#a0a0a0',
      colorFill: '#f4f4f4',
      colorBgTextHover: 'rgba(0, 0, 0, 0.06)',
      colorBgElevated: '#ffffff',
      closeBtnSize: 22,
      primaryNextBtnHoverBg: '#f0f0f0',
      primaryPrevBtnBg: 'rgba(255, 255, 255, 0.15)',
      fontFamily: 'Pretendard',
      lineWidthFocus: 4,
    },
    QRCode: {
      paddingSM: 12,
      marginXS: 8,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSize: 14,
      controlHeight: 36,
      borderRadiusLG: 4,
      colorWhite: '#ffffff',
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      fontFamily: 'Pretendard',
    },
    Upload: {
      paddingXS: 8,
      paddingSM: 12,
      padding: 16,
      marginXXS: 4,
      marginXS: 8,
      marginXL: 32,
      margin: 16,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSizeHeading3: 24,
      fontSizeHeading2: 30,
      fontSize: 14,
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlHeightLG: 46,
      borderRadiusLG: 4,
      colorTextLightSolid: '#ffffff',
      colorTextHeading: '#19191a',
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorPrimaryHover: '#203ff6',
      colorPrimary: '#375fff',
      colorFillAlter: 'rgba(0, 0, 0, 0.02)',
      colorErrorBg: '#ffefeb',
      colorError: '#ff4800',
      colorBorder: '#c4c4c4',
      colorBgMask: 'rgba(0, 0, 0, 0.45)',
      lineWidthFocus: 4,
      fontFamily: 'Pretendard',
    },
    Typography: {
      paddingSM: 12,
      marginXXS: 4,
      marginXS: 8,
      lineWidth: 1,
      lineHeightHeading5: 1.3333333333333333,
      lineHeightHeading4: 1.4,
      lineHeightHeading3: 1.3333333333333333,
      lineHeightHeading2: 1.2666666666666666,
      lineHeightHeading1: 1.2105263157894737,
      lineHeight: 1.5714285714285714,
      fontWeightStrong: 600,
      fontSizeHeading5: 18,
      fontSizeHeading4: 20,
      fontSizeHeading3: 24,
      fontSizeHeading2: 30,
      fontSizeHeading1: 38,
      fontSize: 14,
      colorWarning: '#ffa800',
      colorTextHeading: '#19191a',
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorSuccess: '#12ba23',
      colorLinkHover: '#203ff6',
      colorLinkActive: '#1025e5',
      colorLink: '#375fff',
      colorErrorHover: '#d53f00',
      colorErrorActive: '#d53f00',
      colorError: '#ff4800',
      fontFamilyCode: 'Pretendard',
    },
    TreeSelect: {
      paddingXS: 8,
      marginXXS: 4,
      marginXS: 8,
      lineWidthFocus: 4,
      lineWidthBold: 2,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSize: 14,
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlItemBgActive: '#ebf1ff',
      controlInteractiveSize: 16,
      controlHeightSM: 24,
      borderRadiusSM: 2,
      borderRadius: 4,
      colorWhite: '#ffffff',
      colorTextDisabled: '#c4c4c4',
      colorText: '#19191a',
      colorPrimaryHover: '#203ff6',
      colorPrimaryBorder: '#b8cdff',
      colorPrimary: '#375fff',
      colorBorder: '#c4c4c4',
      colorBgElevated: '#ffffff',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      titleHeight: 24,
      nodeSelectedBg: '#ebf1ff',
      nodeHoverBg: 'rgba(0, 0, 0, 0.04)',
      fontFamily: 'Pretendard',
    },
    Tree: {
      paddingSM: 12,
      borderRadiusLG: 4,
      paddingXS: 8,
      marginXXS: 4,
      marginXS: 8,
      lineWidthFocus: 4,
      lineWidthBold: 2,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSize: 14,
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlItemBgActive: '#ebf1ff',
      controlInteractiveSize: 16,
      controlHeightSM: 24,
      borderRadiusSM: 2,
      borderRadius: 4,
      colorWhite: '#ffffff',
      colorTextLightSolid: '#ffffff',
      colorTextDisabled: '#c4c4c4',
      colorText: '#19191a',
      colorPrimaryHover: '#203ff6',
      colorPrimaryBorder: '#b8cdff',
      colorPrimary: '#375fff',
      colorBorder: '#c4c4c4',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      titleHeight: 24,
      nodeSelectedBg: '#ebf1ff',
      nodeHoverBg: 'rgba(0, 0, 0, 0.04)',
      directoryNodeSelectedColor: '#ffffff',
      directoryNodeSelectedBg: '#375fff',
      fontFamily: 'Pretendard',
    },
    Tag: {
      paddingXXS: 4,
      marginXS: 8,
      lineWidth: 1,
      lineHeightSM: 1.6666666666666667,
      lineHeight: 1.5714285714285714,
      fontSizeSM: 12,
      fontSizeIcon: 12,
      fontSize: 14,
      borderRadiusSM: 2,
      colorTextLightSolid: '#ffffff',
      colorTextHeading: '#19191a',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorPrimaryHover: '#203ff6',
      colorPrimaryActive: '#203ff6',
      colorPrimary: '#375fff',
      colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
      colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
      colorFillQuaternary: 'rgba(0, 0, 0, 0.02)',
      colorBorder: '#c4c4c4',
      colorWarningBorder: '#ffc97f',
      colorWarningBg: '#faedb5',
      colorSuccessBorder: '#81dc71',
      colorSuccessBg: '#eefee6',
      colorInfoBorder: '#b8cdff',
      colorInfoBg: '#ebf1ff',
      colorErrorBorder: '#ff916c',
      colorErrorBg: '#ffefeb',
      defaultColor: '#19191a',
      defaultBg: 'rgba(0, 0, 0, 0.02)',
      fontFamily: 'Pretendard',
    },
    Statistic: {
      padding: 16,
      marginXXS: 4,
      lineHeight: 1.5714285714285714,
      fontSizeHeading3: 24,
      fontSize: 14,
      colorTextHeading: '#19191a',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      contentFontSize: 24,
      titleFontSize: 14,
      fontFamily: 'Pretendard',
    },
    Skeleton: {
      padding: 16,
      marginXXS: 4,
      marginSM: 12,
      marginLG: 24,
      controlHeightXS: 16,
      controlHeightSM: 24,
      controlHeightLG: 46,
      controlHeight: 36,
      borderRadiusSM: 2,
      colorFillContent: 'rgba(0, 0, 0, 0.06)',
      colorFill: '#f4f4f4',
    },
    Select: {
      paddingXXS: 4,
      paddingXS: 8,
      paddingSM: 12,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontWeightStrong: 600,
      fontSizeSM: 12,
      fontSizeLG: 16,
      fontSizeIcon: 12,
      fontSize: 14,
      controlPaddingHorizontalSM: 8,
      controlPaddingHorizontal: 12,
      controlOutlineWidth: 2,
      controlOutline: 'rgba(55, 95, 255, 0.1)',
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlItemBgActive: '#ebf1ff',
      controlHeightXS: 16,
      controlHeightSM: 24,
      controlHeightLG: 46,
      controlHeight: 36,
      borderRadiusXS: 1,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      borderRadius: 4,
      colorWarningOutline: 'rgba(255, 215, 5, 0.1)',
      colorWarningHover: '#d18d16',
      colorTextTertiary: '#a0a0a0',
      colorTextQuaternary: '#c4c4c4',
      colorTextPlaceholder: '#c4c4c4',
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorPrimaryHover: '#203ff6',
      colorPrimary: '#375fff',
      colorIconHover: '#19191a',
      colorIcon: '#a0a0a0',
      colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
      colorErrorOutline: 'rgba(255, 38, 6, 0.06)',
      colorErrorHover: '#d53f00',
      colorBorder: '#c4c4c4',
      colorBgElevated: '#ffffff',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      singleItemHeightLG: 46,
      selectorBg: '#ffffff',
      optionSelectedColor: '#19191a',
      optionSelectedBg: '#ebf1ff',
      optionHeight: 36,
      optionActiveBg: 'rgba(0, 0, 0, 0.04)',
      multipleSelectorBgDisabled: 'rgba(0, 0, 0, 0.04)',
      multipleItemHeightLG: 36,
      multipleItemHeight: 24,
      multipleItemColorDisabled: '#c4c4c4',
      multipleItemBorderColorDisabled: 'rgba(0, 0, 0, 0)',
      multipleItemBorderColor: 'rgba(0, 0, 0, 0)',
      multipleItemBg: 'rgba(0, 0, 0, 0.06)',
      clearBg: '#ffffff',
      optionSelectedFontWeight: 600,
      fontFamily: 'Pretendard',
    },
    Result: {
      paddingXS: 8,
      paddingXL: 32,
      paddingLG: 24,
      padding: 16,
      marginXS: 8,
      lineHeightHeading3: 1.3333333333333333,
      lineHeight: 1.5714285714285714,
      fontSizeHeading3: 24,
      fontSize: 14,
      colorWarning: '#ffa800',
      colorTextHeading: '#19191a',
      colorTextDescription: '#a0a0a0',
      colorSuccess: '#12ba23',
      colorInfo: '#375fff',
      colorFillAlter: 'rgba(0, 0, 0, 0.02)',
      colorError: '#ff4800',
      iconFontSize: 72,
      subtitleFontSize: 14,
      titleFontSize: 24,
    },
    Progress: {
      paddingXS: 8,
      marginXXS: 4,
      marginXS: 8,
      lineHeight: 1.5714285714285714,
      fontSizeSM: 12,
      fontSize: 14,
      colorText: '#19191a',
      colorSuccess: '#12ba23',
      colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
      colorError: '#ff4800',
      colorBgContainer: '#ffffff',
      remainingColor: 'rgba(0, 0, 0, 0.06)',
      defaultColor: '#375fff',
      circleTextColor: '#19191a',
      fontFamily: 'Pretendard',
    },
    Popconfirm: {
      marginXXS: 4,
      marginXS: 8,
      fontWeightStrong: 600,
      fontSize: 14,
      colorWarning: '#ffa800',
      colorTextHeading: '#19191a',
      colorText: '#19191a',
    },
    Pagination: {
      screenSM: 576,
      screenLG: 992,
      paddingXXS: 4,
      paddingSM: 12,
      marginXXS: 4,
      marginXS: 8,
      marginSM: 12,
      margin: 16,
      lineWidthFocus: 4,
      lineWidth: 1,
      lineHeightLG: 1.5,
      lineHeight: 1.5714285714285714,
      fontWeightStrong: 600,
      fontSizeSM: 12,
      fontSizeLG: 16,
      fontSize: 14,
      controlPaddingHorizontalSM: 8,
      controlPaddingHorizontal: 12,
      controlOutlineWidth: 2,
      controlOutline: 'rgba(55, 95, 255, 0.1)',
      controlItemBgActiveDisabled: '#f4f4f4',
      controlHeightSM: 24,
      controlHeightLG: 46,
      controlHeight: 36,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      borderRadius: 4,
      colorWarningOutline: 'rgba(255, 215, 5, 0.1)',
      colorTextPlaceholder: '#c4c4c4',
      colorTextDisabled: '#c4c4c4',
      colorText: '#19191a',
      colorPrimaryHover: '#203ff6',
      colorPrimaryBorder: '#b8cdff',
      colorPrimary: '#375fff',
      colorFillAlter: 'rgba(0, 0, 0, 0.02)',
      colorErrorOutline: 'rgba(255, 38, 6, 0.06)',
      colorBorder: '#c4c4c4',
      colorBgTextHover: 'rgba(0, 0, 0, 0.06)',
      colorBgTextActive: '#f4f4f4',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      itemSizeSM: 24,
      itemSize: 36,
      itemLinkBg: '#ffffff',
      itemInputBg: '#ffffff',
      itemBg: '#ffffff',
      itemActiveColorDisabled: '#c4c4c4',
      itemActiveBgDisabled: '#f4f4f4',
      itemActiveBg: '#ffffff',
      fontFamily: 'Pretendard',
    },
    Modal: {
      screenSMMax: 767,
      paddingXS: 8,
      paddingMD: 20,
      paddingLG: 24,
      paddingContentHorizontalLG: 24,
      padding: 16,
      marginXS: 8,
      marginSM: 12,
      marginLG: 24,
      margin: 16,
      lineWidthFocus: 4,
      lineWidth: 1,
      lineHeightHeading5: 1.3333333333333333,
      lineHeight: 1.5714285714285714,
      fontWeightStrong: 600,
      fontSizeLG: 16,
      fontSizeHeading5: 18,
      fontSize: 14,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      colorWarning: '#ffa800',
      colorTextHeading: '#19191a',
      colorText: '#19191a',
      colorSuccess: '#12ba23',
      colorPrimaryBorder: '#b8cdff',
      colorIconHover: '#19191a',
      colorIcon: '#a0a0a0',
      colorBgMask: 'rgba(0, 0, 0, 0.45)',
      titleColor: '#19191a',
      headerBg: '#ffffff',
      footerBg: 'rgba(0, 0, 0, 0)',
      contentBg: '#ffffff',
      titleFontSize: 16,
      titleLineHeight: 1.375,
      fontFamily: 'Pretendard',
    },
    Message: {
      paddingXS: 8,
      paddingSM: 12,
      marginXS: 8,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSize: 14,
      controlHeightLG: 46,
      borderRadiusLG: 4,
      colorWarning: '#ffa800',
      colorText: '#19191a',
      colorSuccess: '#12ba23',
      colorInfo: '#375fff',
      colorError: '#ff4800',
      contentBg: '#ffffff',
      fontFamily: 'Pretendard',
    },
    List: {
      screenSM: 576,
      screenMD: 768,
      paddingXS: 8,
      paddingSM: 12,
      paddingLG: 24,
      paddingContentVerticalSM: 8,
      paddingContentVerticalLG: 16,
      paddingContentVertical: 12,
      paddingContentHorizontalLG: 24,
      paddingContentHorizontal: 16,
      padding: 16,
      marginXXS: 4,
      marginXXL: 48,
      marginSM: 12,
      marginLG: 24,
      margin: 16,
      lineWidth: 1,
      lineHeightLG: 1.5,
      lineHeight: 1.5714285714285714,
      fontSizeSM: 12,
      fontSizeLG: 16,
      fontSize: 14,
      controlHeightLG: 46,
      controlHeight: 36,
      borderRadiusLG: 4,
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorPrimary: '#375fff',
      colorBorder: '#c4c4c4',
      headerBg: 'rgba(0, 0, 0, 0)',
      footerBg: 'rgba(0, 0, 0, 0)',
      avatarMarginRight: 16,
      descriptionFontSize: 14,
      fontFamily: 'Pretendard',
    },
    FloatButton: {
      paddingXXS: 4,
      marginXXL: 48,
      marginLG: 24,
      margin: 16,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeSM: 12,
      fontSizeLG: 16,
      fontSizeIcon: 12,
      fontSize: 14,
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlHeightLG: 46,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      colorTextLightSolid: '#ffffff',
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorPrimaryHover: '#203ff6',
      colorPrimary: '#375fff',
      colorFillContent: 'rgba(0, 0, 0, 0.06)',
      colorBgElevated: '#ffffff',
      fontFamily: 'Pretendard',
    },
    Empty: {
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      fontSize: 14,
      lineHeight: 1.5714285714285714,
    },
    Dropdown: {
      sizePopupArrow: 16,
      paddingXXS: 4,
      paddingXS: 8,
      marginXXS: 4,
      marginXS: 8,
      lineWidthFocus: 4,
      lineHeight: 1.5714285714285714,
      fontSizeSM: 12,
      fontSizeIcon: 12,
      fontSize: 14,
      controlPaddingHorizontal: 16,
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlItemBgActiveHover: '#b8cdff',
      controlItemBgActive: '#ebf1ff',
      controlHeight: 36,
      borderRadiusXS: 1,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      colorTextLightSolid: '#ffffff',
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorPrimaryBorder: '#b8cdff',
      colorPrimary: '#375fff',
      colorError: '#ff4800',
      colorBgElevated: '#ffffff',
      fontFamily: 'Pretendard',
    },
    Drawer: {
      paddingXS: 8,
      paddingLG: 24,
      padding: 16,
      marginSM: 12,
      lineWidth: 1,
      lineHeightLG: 1.5,
      fontWeightStrong: 600,
      fontSizeLG: 16,
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorIconHover: '#19191a',
      colorIcon: '#a0a0a0',
      colorBgMask: 'rgba(0, 0, 0, 0.45)',
      colorBgElevated: '#ffffff',
      lineWidthFocus: 4,
    },
    Divider: {
      marginXS: 8,
      marginLG: 24,
      margin: 16,
      lineWidth: 1,
      fontSizeLG: 16,
      fontSize: 14,
      colorTextHeading: '#19191a',
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      lineHeight: 1.5714285714285714,
      fontFamily: 'Pretendard',
    },
    Descriptions: {
      paddingXS: 8,
      paddingSM: 12,
      paddingLG: 24,
      padding: 16,
      marginXXS: 4,
      marginXS: 8,
      lineWidth: 1,
      lineHeightSM: 1.6666666666666667,
      lineHeightLG: 1.5,
      lineHeight: 1.5714285714285714,
      fontWeightStrong: 600,
      fontSizeSM: 12,
      fontSizeLG: 16,
      fontSize: 14,
      borderRadiusLG: 4,
      colorTextTertiary: '#a0a0a0',
      colorTextSecondary: '#474747',
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorFillAlter: 'rgba(0, 0, 0, 0.02)',
      titleColor: '#19191a',
      labelBg: 'rgba(0, 0, 0, 0.02)',
      contentColor: '#19191a',
      fontFamily: 'Pretendard',
    },
    DatePicker: {
      sizePopupArrow: 16,
      paddingXXS: 4,
      paddingXS: 8,
      paddingSM: 12,
      padding: 16,
      marginXXS: 4,
      marginXS: 8,
      lineWidthBold: 2,
      lineWidth: 1,
      lineHeightLG: 1.5,
      lineHeight: 1.5714285714285714,
      fontWeightStrong: 600,
      fontSizeLG: 16,
      fontSize: 14,
      controlPaddingHorizontalSM: 8,
      controlPaddingHorizontal: 12,
      controlOutlineWidth: 2,
      controlOutline: 'rgba(55, 95, 255, 0.1)',
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlItemBgActive: '#ebf1ff',
      controlHeightSM: 24,
      controlHeightLG: 46,
      controlHeight: 36,
      borderRadiusXS: 1,
      borderRadiusSM: 2,
      borderRadiusLG: 4,
      borderRadius: 4,
      colorWarningOutline: 'rgba(255, 215, 5, 0.1)',
      colorWarningHover: '#d18d16',
      colorWarning: '#ffa800',
      colorTextQuaternary: '#c4c4c4',
      colorTextPlaceholder: '#c4c4c4',
      colorTextLightSolid: '#ffffff',
      colorTextHeading: '#19191a',
      colorTextDisabled: '#c4c4c4',
      colorTextDescription: '#a0a0a0',
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorPrimaryHover: '#203ff6',
      colorPrimaryBorder: '#b8cdff',
      colorPrimary: '#375fff',
      colorLinkHover: '#203ff6',
      colorLinkActive: '#1025e5',
      colorLink: '#375fff',
      colorIconHover: '#19191a',
      colorIcon: '#a0a0a0',
      colorFillAlter: 'rgba(0, 0, 0, 0.02)',
      colorErrorOutline: 'rgba(255, 38, 6, 0.06)',
      colorErrorHover: '#d53f00',
      colorError: '#ff4800',
      colorBorder: '#c4c4c4',
      colorBgElevated: '#ffffff',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      hoverBorderColor: '#203ff6',
      cellHoverBg: 'rgba(0, 0, 0, 0.04)',
      cellHeight: 24,
      activeBorderColor: '#375fff',
      paddingInline: 11,
      paddingInlineSM: 7,
      colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
      colorErrorBg: '#ffefeb',
      colorWarningBg: '#faedb5',
      colorWarningText: '#ffb741',
      colorErrorText: '#ff4800',
      colorWarningBgHover: '#ffdfb9',
      colorErrorBgHover: '#ffbfaa',
      activeBg: '#ffffff',
      hoverBg: '#ffffff',
      cellBgDisabled: 'rgba(0, 0, 0, 0.04)',
      cellActiveWithRangeBg: '#ebf1ff',
      cellHoverWithRangeBg: '#c8dfff',
      cellRangeBorderColor: '#7cb3ff',
      multipleItemBg: 'rgba(0, 0, 0, 0.06)',
      multipleItemBorderColor: 'rgba(0, 0, 0, 0)',
      multipleItemBorderColorDisabled: 'rgba(0, 0, 0, 0)',
      multipleItemColorDisabled: '#c4c4c4',
      multipleSelectorBgDisabled: 'rgba(0, 0, 0, 0.04)',
      cellWidth: 36,
      multipleItemHeight: 24,
      multipleItemHeightLG: 32,
      multipleItemHeightSM: 16,
      paddingBlock: 4,
      paddingBlockLG: 7,
      paddingBlockSM: 0,
      paddingInlineLG: 15,
      presetsMaxWidth: 200,
      presetsWidth: 120,
      textHeight: 46,
      timeCellHeight: 28,
      timeColumnHeight: 224,
      timeColumnWidth: 56,
      withoutTimeCellHeight: 66,
      inputFontSize: 14,
      inputFontSizeLG: 16,
      inputFontSizeSM: 12,
      // fontHeightLG: 24,
      fontFamily: 'Pretendard',
    },
    Collapse: {
      paddingXXS: 4,
      paddingXS: 8,
      paddingSM: 12,
      paddingLG: 24,
      padding: 16,
      marginSM: 12,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSizeIcon: 12,
      fontSize: 14,
      borderRadiusLG: 4,
      colorTextHeading: '#19191a',
      colorTextDisabled: '#c4c4c4',
      colorText: '#19191a',
      colorFillAlter: 'rgba(0, 0, 0, 0.02)',
      colorBorder: '#c4c4c4',
      colorBgContainer: '#ffffff',
      headerBg: 'rgba(0, 0, 0, 0.02)',
      contentBg: '#ffffff',
      // fontHeightLG: 24,
      lineHeightLG: 1.5,
      fontFamily: 'Pretendard',
    },
    Checkbox: {
      paddingXS: 8,
      marginXS: 8,
      lineWidthFocus: 4,
      lineWidthBold: 2,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSize: 14,
      controlInteractiveSize: 16,
      borderRadiusSM: 2,
      colorWhite: '#ffffff',
      colorTextDisabled: '#c4c4c4',
      colorText: '#19191a',
      colorPrimaryHover: '#203ff6',
      colorPrimaryBorder: '#b8cdff',
      colorPrimary: '#375fff',
      colorBorder: '#c4c4c4',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
      fontFamily: 'Pretendard',
    },
    Breadcrumb: {
      paddingXXS: 4,
      marginXXS: 4,
      marginXS: 8,
      lineWidthFocus: 4,
      lineHeight: 1.5714285714285714,
      fontSizeIcon: 12,
      fontSize: 14,
      borderRadiusSM: 2,
      colorPrimaryBorder: '#b8cdff',
      colorBgTextHover: 'rgba(0, 0, 0, 0.06)',
      separatorColor: '#a0a0a0',
      linkHoverColor: '#19191a',
      linkColor: '#a0a0a0',
      lastItemColor: '#19191a',
      itemColor: '#a0a0a0',
      separatorMargin: 8,
      iconFontSize: 14,
      fontFamily: 'Pretendard',
    },
    Anchor: {
      paddingXXS: 4,
      lineWidthBold: 2,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSize: 14,
      colorText: '#19191a',
      colorSplit: 'rgba(0, 0, 0, 0.06)',
      colorPrimary: '#375fff',
      linkPaddingInlineStart: 16,
      linkPaddingBlock: 4,
      fontFamily: 'Pretendard',
    },
    Alert: {
      paddingMD: 20,
      paddingContentVerticalSM: 8,
      paddingContentHorizontalLG: 24,
      marginXS: 8,
      marginSM: 12,
      lineWidth: 1,
      lineHeight: 1.5714285714285714,
      fontSizeLG: 16,
      fontSizeIcon: 12,
      fontSizeHeading3: 24,
      fontSize: 14,
      borderRadiusLG: 4,
      colorWarningBorder: '#ffc97f',
      colorWarningBg: '#faedb5',
      colorWarning: '#ffa800',
      colorTextHeading: '#19191a',
      colorText: '#19191a',
      colorSuccessBorder: '#81dc71',
      colorSuccessBg: '#eefee6',
      colorSuccess: '#12ba23',
      colorInfoBorder: '#b8cdff',
      colorInfoBg: '#ebf1ff',
      colorInfo: '#375fff',
      colorIconHover: '#19191a',
      colorIcon: '#a0a0a0',
      colorErrorBorder: '#ff916c',
      colorErrorBg: '#ffefeb',
      colorError: '#ff4800',
      withDescriptionIconSize: 24,
      fontFamily: 'Pretendard',
    },
    Space: {
      paddingXS: 8,
      paddingLG: 24,
      padding: 16,
    },
    // AutoComplete: {
    //   paddingXXS: 4,
    //   paddingXS: 8,
    //   paddingSM: 12,
    //   lineWidth: 1,
    //   lineHeight: 1.5714285714285714,
    //   controlPaddingHorizontalSM: 8,
    //   controlPaddingHorizontal: 12,
    //   controlOutlineWidth: 2,
    //   controlOutline: 'rgba(55, 95, 255, 0.1)',
    //   controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
    //   controlItemBgActive: '#ebf1ff',
    //   controlHeightXS: 16,
    //   controlHeightSM: 24,
    //   controlHeightLG: 46,
    //   controlHeight: 36,
    //   borderRadiusXS: 1,
    //   borderRadiusSM: 2,
    //   borderRadiusLG: 4,
    //   borderRadius: 4,
    //   colorWarningOutline: 'rgba(255, 215, 5, 0.1)',
    //   colorWarningHover: '#d18d16',
    //   colorWarning: '#ffa800',
    //   colorTextTertiary: '#a0a0a0',
    //   colorTextQuaternary: '#c4c4c4',
    //   colorTextPlaceholder: '#c4c4c4',
    //   colorTextDisabled: '#c4c4c4',
    //   colorTextDescription: '#a0a0a0',
    //   colorText: '#19191a',
    //   colorPrimaryHover: '#203ff6',
    //   colorPrimary: '#375fff',
    //   colorIconHover: '#19191a',
    //   colorIcon: '#a0a0a0',
    //   colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
    //   colorErrorOutline: 'rgba(255, 38, 6, 0.06)',
    //   colorErrorHover: '#d53f00',
    //   colorError: '#ff4800',
    //   colorBorder: '#c4c4c4',
    //   colorBgElevated: '#ffffff',
    //   colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
    //   colorBgContainer: '#ffffff',
    //   optionActiveBg: 'rgba(0, 0, 0, 0.04)',
    //   optionHeight: 32,
    //   optionFontSize: 14,
    //   fontFamily: 'Pretendard',
    //   fontSize: 14,
    //   fontSizeIcon: 12,
    //   fontSizeLG: 16,
    //   fontSizeSM: 12,
    // },
    Layout: {
      bodyBg: '#f4f4f4',
      footerBg: '#f4f4f4',
      headerBg: '#001529',
      headerColor: '#19191a',
      lightSiderBg: '#ffffff',
      lightTriggerBg: '#ffffff',
      lightTriggerColor: '#19191a',
      siderBg: '#001529',
      triggerBg: '#002140',
      triggerColor: '#ffffff',
      colorText: '#19191a',
      headerHeight: 64,
      triggerHeight: 48,
      zeroTriggerHeight: 46,
      zeroTriggerWidth: 46,
      borderRadius: 4,
      fontSize: 14,
      fontSizeXL: 20,
    },
    Mentions: {
      fontFamily: 'Pretendard',
      fontSize: 14,
      lineHeight: 1.5714285714285714,
    },
  },
} satisfies ThemeConfig;
