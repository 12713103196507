import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useId } from 'react';
import { colors, getColor } from '../../core';
import { Typography } from '../../foundations/typography';
import { BUTTON_SIZE_BY_SIZE_VALUE, TYPOGRAPHY_VARIANT_BY_SIZE_VALUE } from './RadioButton.constants';
import { RadioButtonSizeValue } from './RadioButton.types';

type Colors = keyof typeof colors;

interface Props<T extends string = string> {
  className?: string;
  inputCss?: SerializedStyles;
  labelCss?: SerializedStyles;
  size?: RadioButtonSizeValue;
  name?: string;
  value: T;
  color?: Colors;
  selected: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  onClick?: (value: T) => void;
}

export const RadioButton = <T extends string>({
  className,
  inputCss,
  labelCss,
  name,
  size = 'md',
  value,
  color = 'blue500',
  children,
  disabled,
  selected,
  onChange,
  onClick,
  ...restProps
}: React.PropsWithChildren<Props<T>>) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    onChange?.(e.target.checked);
  };

  const handleClick = () => {
    if (disabled) {
      return;
    }
    onClick?.(value);
  };

  const radioId = useId();

  return (
    <Container className={className}>
      <Input
        id={radioId}
        css={inputCss}
        type="radio"
        name={name}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        color={color}
        checked={selected}
        {...restProps}
      />
      <Label as="label" css={labelCss} htmlFor={radioId} title={name} color={color} size={size} onClick={handleClick}>
        <Text variant={TYPOGRAPHY_VARIANT_BY_SIZE_VALUE[size]} color={disabled ? 'gray500' : 'gray700'}>
          {children}
        </Text>
      </Label>
    </Container>
  );
};

const Container = styled.span`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  min-height: 20px;
  line-height: 20px;
`;

const Text = styled(Typography)`
  line-height: 1;
`;

const Label = styled.label<{ color: Colors; size: RadioButtonSizeValue }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  z-index: 1;
  cursor: pointer;

  &:hover::before {
    border-color: ${({ theme, color }) => getColor(color) ?? theme.colors.blue500};
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border: 1px solid ${({ theme }) => theme.colors.gray400};
    border-radius: 50%;
    transition: 0.3s all ease;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 1px;
    transform: translateY(-50%);
    box-sizing: border-box;
    background: ${({ theme }) => theme.colors.white};
    border: 5px solid ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    transition: 0.3s all ease;
  }

  ${({ size }) => BUTTON_SIZE_BY_SIZE_VALUE[size]}
`;

const Input = styled.input<{ color: Colors }>`
  position: absolute;
  z-index: -10;
  top: -100%;
  left: -100%;
  width: 13px;
  height: 13px;
  background: transparent;

  &:checked + ${Label}::before {
    background: ${({ theme, color }) => getColor(color) ?? theme.colors.blue500};
    border-color: ${({ theme, color }) => getColor(color) ?? theme.colors.blue500};
  }

  &:checked + ${Label}::after {
    background: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme, color }) => getColor(color) ?? theme.colors.blue500};
  }

  &:disabled + ${Label} {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray500};

    &::before {
      background: ${({ theme }) => theme.colors.gray100};
      border: 1px solid ${({ theme }) => theme.colors.gray400};
    }

    &::after {
      display: none;
    }
  }

  &:checked:disabled + ${Label} {
    &::before {
      background: ${({ theme }) => theme.colors.blue500};
      border-color: ${({ theme }) => theme.colors.blue500};
    }

    &::after {
      display: block;
      background: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.blue500};
    }
  }
`;
