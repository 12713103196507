import styled from '@emotion/styled';
import React from 'react';
import { colors } from '../../core';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const InputBox = React.forwardRef<HTMLInputElement, Props>(({ className, placeholder, ...restProps }, ref) => {
  return <Input ref={ref} className={className} placeholder={placeholder} autoCapitalize="none" {...restProps} />;
});

const Input = styled.input`
  border: 1px solid ${colors.line_gray01};
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 34px;
  padding: 0 18px;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.primary};
  outline: none;

  &::placeholder {
    color: ${colors.text_gray01};
  }

  &:disabled {
    background-color: ${colors.bg_gray01};
    cursor: not-allowed;
  }
`;
