import styled from '@emotion/styled';
import { TableSize } from './Table.types';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  size?: TableSize;
}

export const TableCellImage = ({ size = 'md', ...others }: Props) => {
  return <Root size={size} loading="lazy" {...others} />;
};

const Root = styled.img<{ size?: TableSize }>`
  cursor: pointer;
  width: ${({ size = 'md' }) => (size === 'sm' ? '30px' : '80px')};
  height: ${({ size = 'md' }) => (size === 'sm' ? '30px' : '80px')};
  object-fit: cover;
  background-color: ${({ theme }) => theme.colors.gray400};
  border-radius: 4px;
`;
