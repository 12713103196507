import React, { useCallback, useEffect } from 'react';
import { Popup } from '../components';
import { CreatePopupParams } from '../components/Popup/Popup.types';
import { usePopupEventEmitter } from '../hooks';
import { alert, dialogDispatcher, emitter, Events } from '../utils/popup';

export const PopupContainer = () => {
  const { popups, dispatch } = usePopupEventEmitter();

  useEffect(() => {
    dialogDispatcher({ dispatch });
    return () => {
      emitter.off();
    };
  }, [dispatch]);

  const handleSuccess = useCallback(async (id: string, cb?: () => Promise<void> | void | null) => {
    await cb?.();
    emitter.emit(Events.HIDE, id);
  }, []);

  return (
    <>
      {popups.map(({ id, open, content, onSuccess, ...restProps }) => {
        const handleDialogSuccess = () => handleSuccess(id, onSuccess);

        return (
          <Popup key={id} open={open} onSuccess={handleDialogSuccess} {...restProps}>
            {content}
          </Popup>
        );
      })}
    </>
  );
};

export const popup = {
  show(params: CreatePopupParams) {
    emitter.emit(Events.SHOW, alert(params));
  },
  hide(id: string) {
    emitter.emit(Events.HIDE, id);
  },
};
