export enum TypographyVariantType {
  'heading/26-bold' = 0,
  'heading/22-semiBold',
  'heading/24-medium',
  'body/18-semiBold',
  'body/16-semiBold',
  'body/15-semiBold',
  'body/15-regular',
  'body/14-semiBold',
  'body/14-bold',
  'body/14-medium',
  'body/14-regular',
  'body/14-regular-table',
  'body/13-semiBold',
  'body/13-regular',
  'body/12-medium',
  'body/12-regular',
}

export type KeyOfTypographyVariantType = keyof typeof TypographyVariantType;
