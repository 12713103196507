import { TableProvider } from './providers';
import { AlignType, Columns, DefaultRecordType, TableRowSelection, TableSize } from './Table.types';
import { TableContents } from './TableContents';

interface Props<DataRecord extends DefaultRecordType> {
  columns: Columns<DataRecord>;
  data: readonly DataRecord[];
  /**
   * 기본 너비
   * @default 1000
   * */
  defaultWidth?: number;
  /**
   * 정렬
   * @default left
   * */
  align?: AlignType;
  /**
   * 체크박스
   * @default {onChange:(selectedKeys, selectedRows) => void}
   * */
  rowSelection?: TableRowSelection;
  emptyText?: string;
  verticalScroll?: boolean;
  size?: TableSize;
  /**
   * 초기 가로스크롤 위치
   * @default 0
   */
  initScrollLeftPosition?: number;
  className?: string;
}

/**
 ```tsx
 <Table
 columns={columns}
 data={data}
 />
 ```
 */
export const Table = <DataRecord extends DefaultRecordType>({
  columns,
  data,
  defaultWidth,
  align = 'left',
  size = 'md',
  rowSelection,
  emptyText,
  verticalScroll = true,
  initScrollLeftPosition = 0,
  className,
}: Props<DataRecord>) => {
  return (
    <TableProvider rowSelection={rowSelection} emptyText={emptyText}>
      <TableContents
        className={className}
        columns={columns}
        data={data}
        defaultWidth={defaultWidth}
        align={align}
        size={size}
        verticalScroll={verticalScroll}
        initScrollLeftPosition={initScrollLeftPosition}
      />
    </TableProvider>
  );
};
