import * as cookie from 'cookie';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

export class CookieService {
  protected cookie = '';

  constructor(serverCookie = '') {
    this.cookie = serverCookie;
  }

  get(key: string) {
    const isServer = typeof window === 'undefined';
    const cookies = isServer ? cookie.parse(this.cookie) : parseCookies();

    return cookies[key];
  }

  set(key: string, value: string, options?: cookie.CookieSerializeOptions) {
    return setCookie(null, key, value, options);
  }

  remove(key: string, options?: cookie.CookieSerializeOptions) {
    return destroyCookie(null, key, options);
  }

  getAccessToken(environment?: 'dev' | 'qa') {
    const cookieSuffix = environment ? `_${environment}` : '';
    const isServer = typeof window === 'undefined';
    const cookies = isServer ? cookie.parse(this.cookie) : parseCookies();

    return cookies[`_fatn${cookieSuffix}`];
  }

  getAudience(environment?: 'dev' | 'qa') {
    const cookieSuffix = environment ? `_${environment}` : '';
    const isServer = typeof window === 'undefined';
    const cookies = isServer ? cookie.parse(this.cookie) : parseCookies();

    return cookies[`_fws${cookieSuffix}`];
  }
}
