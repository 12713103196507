import { BaseIconProps } from './types/iconography.types';
import { Search, SearchBold } from './search';

interface Props extends BaseIconProps {
  bold?: boolean;
}

/**
 ```tsx
  <SearchIcon 
    color="gray700" 
  />
 ```
 */
export const SearchIcon = ({ className, color, bold }: Props) => {
  if (bold) {
    return <SearchBold className={className} color={color} />;
  }

  return <Search className={className} color={color} />;
};
