export interface ErrorModel<T> {
  code?: string;
  status: number;
  message: string;
  data: T;
}

export default class APIError<T = unknown> extends Error {
  code?: string;

  status: number;

  data: T;

  constructor(error: ErrorModel<T>) {
    super(error.message);
    this.name = 'APIError';
    this.code = error.code;
    this.status = error.status;
    this.data = error.data;
  }
}
