import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

interface Props {
  className?: string;
}

export const Box = ({ className, children }: PropsWithChildren<Props>) => {
  return <Container className={className}>{children}</Container>;
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;
