import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { ChangeEventHandler, FormEventHandler, PropsWithChildren, useId } from 'react';
import { CheckIcon } from './CheckIcon';

export type CheckBoxSize = 'md' | 'sm';

export interface CheckBoxProps {
  className?: string;
  inputCss?: SerializedStyles;
  labelCss?: SerializedStyles;
  size?: CheckBoxSize;
  name?: string;
  selected: boolean;
  disabled?: boolean;
  hasError?: boolean;
  onChange?: (checked: boolean) => void;
  onClick?: () => void;
}

/**
  ```tsx
  <CheckBox
    onChange={(checked: boolean) => {}}
    checked
  />
  ```
 */
export const CheckBox = ({
  className,
  inputCss,
  labelCss,
  name,
  size = 'md',
  selected: checked,
  disabled,
  hasError,
  children,
  onChange,
  onClick,
}: PropsWithChildren<CheckBoxProps>) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> & FormEventHandler<HTMLLabelElement> = (e) => {
    if (disabled) {
      return;
    }

    onChange?.((e.target as HTMLInputElement).checked);
  };

  const handleClick = () => {
    onClick?.();
  };

  const radioId = useId();

  return (
    <Container className={className} size={size} hasError={hasError} onClick={handleClick}>
      <Input
        type="checkbox"
        id={radioId}
        css={inputCss}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <Label css={labelCss} htmlFor={radioId} title={name}>
        <Box>{checked ? <StyledCheckIcon color={disabled ? 'gray400' : 'white'} /> : null}</Box>
        {children}
      </Label>
    </Container>
  );
};

const Box = styled.div`
  content: '';
  position: relative;
  box-sizing: border-box;
  text-align: center;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray400};
  border-radius: 2px;
  transition: all 0.2s;
`;

const Label = styled.label`
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  min-height: 22px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray700};
`;

const Input = styled.input`
  position: absolute;
  z-index: -1;
  top: 2px;
  left: 2px;
  overflow: hidden;
  width: 1px;
  height: 1px;
  appearance: none;
  visibility: hidden;
  background: transparent;
  border: 0;

  &:hover + ${Label} {
    ${Box} {
      border-color: ${({ theme }) => theme.colors.blue500};
    }
  }

  &:checked + ${Label} {
    ${Box} {
      background: ${({ theme }) => theme.colors.blue500};
      border-color: ${({ theme }) => theme.colors.blue500};
    }
  }

  &:disabled + ${Label} {
    pointer-events: none;
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray700};
    border-color: ${({ theme }) => theme.colors.gray700};

    ${Box} {
      background: ${({ theme }) => theme.colors.gray100};
      border-color: ${({ theme }) => theme.colors.gray400};
    }
  }
`;

const Container = styled.span<{ size?: CheckBoxSize; hasError?: boolean }>`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  box-sizing: border-box;
  vertical-align: top;

  ${({ size }) => {
    switch (size) {
      case 'md': {
        return css`
          min-width: 22px;
          min-height: 22px;
          line-height: 22px;

          ${Box} {
            width: 22px;
            height: 22px;
            margin-right: 12px;
          }

          ${Label} {
            min-height: 22px;
            font-size: 15px;
          }

          ${StyledCheckIcon} {
            width: 18px;
            height: 14px;
          }
        `;
      }
      case 'sm': {
        return css`
          min-width: 18px;
          min-height: 18px;
          line-height: 18px;

          ${Box} {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }

          ${Label} {
            min-height: 18px;
            font-size: 14px;
          }

          ${StyledCheckIcon} {
            width: 16.36px;
            height: 12.73px;
          }
        `;
      }
    }
  }}

  ${({ theme, hasError }) =>
    hasError &&
    css`
      ${Box} {
        border-color: ${theme.colors.red500};
      }
    `}
`;

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
