import styled from '@emotion/styled';
import React from 'react';
import { Typography, ChevronIcon } from '@29cm/admin-design-system';

interface Props {
  className?: string;
  breadcrumbs?: string[];
}

export const Breadcrumb = ({ className, breadcrumbs = [] }: Props) => {
  return (
    <Root className={className}>
      {breadcrumbs.map((breadcrumb, idx) => {
        const isLastBreadcrumb = breadcrumbs.length === idx + 1;

        return (
          <React.Fragment key={[breadcrumb, idx].join('-')}>
            <Wrapper>
              <Typography variant="body/14-regular" color="gray700">
                {breadcrumb}
              </Typography>
              {isLastBreadcrumb ? null : <StyledNextIcon direction="right" />}
            </Wrapper>
          </React.Fragment>
        );
      })}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledNextIcon = styled(ChevronIcon)`
  margin: 0 6px;
`;
