import type { Environment } from '@29cm/admin-env';

import type { CookieService } from '../../cookies';
import type { User } from '../models';
import { UserService } from './UserService';

export class AuthService {
  private userService: UserService;

  constructor(cookieService: CookieService, environment: Environment) {
    this.userService = new UserService(cookieService, environment);
  }

  public getCurrentUser(): User {
    return this.userService.getCurrentUser();
  }

  public async refetch(): Promise<User> {
    return Promise.resolve(this.userService.refetch());
  }

  // TODO
  public async loginWithId() {
    return null;
  }

  // TODO
  public async loginWithCode() {
    return null;
  }

  public logout() {
    return this.userService.clear();
  }
}
