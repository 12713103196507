import React from 'react';
import { InputBox } from '../input-box';
import { DateInputBoxController } from './DateInputBoxController';
import { DateInputBoxProvider } from './providers';

interface Props extends Omit<React.ComponentProps<typeof InputBox>, 'onChange' | 'onKeyDown'> {
  className?: string;
  hasError?: boolean;
  value: string;
  onChange?: (value: string) => void;
  onClose?: (beginDate: Date | null, endDate: Date | null) => void;
  initialOptions?: {
    isNeedEndDate?: boolean;
    isNeedPrevDatePrevents?: boolean;
    hideCalendarFooterToggle?: boolean;
    preventBeforeDate?: Date;
    preventAfterDate?: Date;
  };
}

/**
 ```tsx
 <DateInputBox
   value={value}
   placeholder="YYYY.MM.DD - YYYY.MM.DD"
   onChange={(value: string) => {
    setValue(value);
   }}
 />
 ```
 */
export const DateInputBox = ({ className, value, initialOptions = {}, onChange, onClose, ...restProps }: Props) => {
  const { isNeedEndDate, isNeedPrevDatePrevents, hideCalendarFooterToggle, preventBeforeDate, preventAfterDate } =
    initialOptions;

  return (
    <DateInputBoxProvider
      value={value}
      isNeedEndDate={isNeedEndDate}
      isNeedPrevDatePrevents={isNeedPrevDatePrevents}
      hideCalendarFooterToggle={hideCalendarFooterToggle}
      preventBeforeDate={preventBeforeDate}
      preventAfterDate={preventAfterDate}
      onChange={onChange}
      onClose={onClose}
    >
      <DateInputBoxController className={className} {...restProps} />
    </DateInputBoxProvider>
  );
};
