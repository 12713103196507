import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { ChevronIcon } from '../../foundations';

interface Props {
  className?: string;
  /**
   * 현재 페이지
   * @default 1
   */
  currentPage?: number;
  /**
   * 페이지 당 글 개수
   * @default 50
   */
  perPage?: number;
  /**
   * 총 개수
   * @default 0
   */
  totalCount?: number;
  /**
   * 한 번에 표시될 최대 페이지 수
   * @default 10
   */
  displayCount?: number;
  onPageChange: (page: number) => void;
}

/**
  ```tsx
  <Pagination
    currentPage={1}
    displayCount={6}
    onPageChange={() => {}}
    perPage={10}
    totalCount={160}
  />
  ```
 */
export const Pagination = ({
  className,
  currentPage = 1,
  perPage = 50,
  displayCount = 10,
  totalCount = 0,
  onPageChange,
}: Props) => {
  const totalPage = Math.ceil(totalCount / perPage);

  const page = Math.min(currentPage, totalPage);

  const pages = useMemo(() => {
    const firstPage = Math.max(page - Math.floor(displayCount / 2), 1);
    const lastPage = Math.min(displayCount, totalPage);
    const lastIndex = lastPage - 1;

    const needCollapse = displayCount >= 5; // ... 표시 유무

    return Array.from({ length: lastPage }).map((_, idx) => {
      const pageNo = firstPage + idx;

      // 첫번째 페이지
      if (idx === 0) {
        return {
          pageNo: needCollapse ? 1 : pageNo,
          shouldRenderCollapse: false,
        };
      }

      // 마지막 페이지
      if (idx === lastIndex) {
        return {
          pageNo: needCollapse ? Math.max(pageNo, totalPage) : pageNo,
          shouldRenderCollapse: false,
        };
      }

      // ... 표시
      if (idx === 1) {
        const collapsable = firstPage !== 1 && pageNo !== firstPage;

        return {
          pageNo,
          shouldRenderCollapse: needCollapse && collapsable,
        };
      }

      // ... 표시
      if (idx === lastIndex - 1) {
        const collapsable = lastPage !== totalPage && pageNo !== totalPage;

        return {
          pageNo,
          shouldRenderCollapse: needCollapse && collapsable,
        };
      }

      return {
        pageNo,
        shouldRenderCollapse: false,
      };
    });
  }, [page, displayCount, totalPage]);

  if (totalCount === 0) {
    return null;
  }

  const isFirstPage = page === 1;
  const isLastPage = page === totalPage;

  const handlePrevClick = () => onPageChange(page - 1);
  const handleNextClick = () => onPageChange(page + 1);
  const handlePageClick = (pageIndex: number) => onPageChange(pageIndex);

  return (
    <Container className={className}>
      <ArrowButton type="button" onClick={handlePrevClick} disabled={isFirstPage} aria-disabled={isFirstPage}>
        {isFirstPage ? (
          <StyledPrevIcon color="gray400" direction="left" />
        ) : (
          <StyledPrevIcon color="blue500" direction="left" />
        )}
        <HiddenText>이전 페이지</HiddenText>
      </ArrowButton>

      <PageList>
        {pages.map(({ pageNo: currentNo, shouldRenderCollapse }, idx) => {
          if (currentNo > totalPage) {
            return null;
          }

          if (shouldRenderCollapse) {
            // eslint-disable-next-line react/no-array-index-key
            return (
              <PageItem key={`${currentNo}-${idx}`}>
                <PageEllipsis>...</PageEllipsis>
              </PageItem>
            );
          }

          const isCurrent = currentNo === page;

          return (
            <PageItem key={currentNo} aria-current={isCurrent}>
              <StyledButton type="button" active={isCurrent} onClick={() => handlePageClick(currentNo)}>
                {currentNo}
              </StyledButton>
            </PageItem>
          );
        })}
      </PageList>
      <ArrowButton type="button" onClick={handleNextClick} disabled={isLastPage} aria-disabled={isLastPage}>
        {isLastPage ? (
          <StyledNextIcon color="gray400" direction="right" />
        ) : (
          <StyledNextIcon color="blue500" direction="right" />
        )}

        <HiddenText>다음 페이지</HiddenText>
      </ArrowButton>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 50px;
  position: relative;
  display: flex;
  justify-content: center;
`;

const PageList = styled.ul`
  display: flex;
  align-items: center;
  line-height: 1;
`;

const PageItem = styled.li`
  padding: 0 2px;
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.blue500};
`;

const PageEllipsis = styled.span`
  display: inline-block;
  width: 26px;
  height: 26px;
  margin: 0 2px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.gray400};
  border-radius: 1px;
  transition: background 150ms ease-out;

  &:hover {
    border: 0.5px solid ${({ theme }) => theme.colors.gray400};
    background: ${({ theme }) => theme.colors.blue300};
  }
`;

const ArrowButton = styled.button<{ disabled?: boolean }>`
  width: 26px;
  height: 26px;
  margin: 0 2px;
  border: 1px solid ${({ theme }) => theme.colors.gray400};
  border-radius: 1px;
  line-height: 0;
  transition: background 150ms ease-out;

  &:hover {
    border: 0.5px solid ${({ theme }) => theme.colors.gray400};
    background: ${({ theme }) => theme.colors.blue300};
  }

  ${({ theme, disabled }) =>
    disabled &&
    css`
      cursor: default;
      border: 0.5px solid ${theme.colors.gray400};

      &:hover {
        background: ${theme.colors.white};
      }
    `}
`;

const StyledButton = styled.button<{ active?: boolean }>`
  min-width: 26px;
  height: 26px;
  padding: 0 4px;
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.blue500};
  border: 1px solid ${({ theme }) => theme.colors.gray400};
  border-radius: 1px;
  transition: background 150ms ease-out;

  &:hover {
    border: 0.5px solid ${({ theme }) => theme.colors.gray400};
    background: ${({ theme }) => theme.colors.blue300};
  }

  ${({ theme, active }) =>
    active &&
    css`
      color: ${theme.colors.white};
      border: 0.5px solid ${theme.colors.gray400};
      background: ${theme.colors.blue500};

      &:hover {
        background: ${theme.colors.blue500};
      }
    `}
`;

const StyledPrevIcon = styled(ChevronIcon)``;

const StyledNextIcon = styled(ChevronIcon)``;

const HiddenText = styled.span`
  position: absolute;
  display: block;
  text-indent: -9999px;
`;
