import { BadgeBackgroundColorValueType, BadgeColorType, BadgeColorValueType } from './Badge.types';

export const badgeColor: Record<BadgeColorType, BadgeColorValueType> = {
  red: 'red500',
  strongRed: 'white',
  blue: 'blue500',
  green: 'green500',
  gray: 'gray700',
  yellow: 'yellow500',
};

export const badgeBackgroundColor: Record<BadgeColorType, BadgeBackgroundColorValueType> = {
  red: 'red100',
  strongRed: 'red500',
  blue: 'blue100',
  green: 'green100',
  gray: 'gray300',
  yellow: 'yellow100',
};
