import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { colors, getColor } from '../../core';

type Size = 'md' | 'sm';
type InputBoxType = 'text' | 'number';
type Colors = keyof typeof colors;
interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  id?: string;
  className?: string;
  size?: Size;
  inputType?: InputBoxType;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  label?: React.ReactNode;
  hint?: string;
  hasError?: boolean;
  paddingRightAndLeft?: number;
  decimalDigits?: number;
  borderColor?: Colors;
}

/**
  ```tsx
  <InputBox
    title="타이틀"
    hint="힌트"
    value=""
    placeholder="text"
  />
  ```
 */
export const InputBox = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      className,
      label,
      hint,
      placeholder,
      leftComponent,
      borderColor,
      rightComponent,
      hasError,
      disabled,
      readOnly,
      size = 'md',
      inputType = 'text',
      onClick,
      onChange,
      maxLength,
      decimalDigits = 3,
      value,
      paddingRightAndLeft,
      ...restProps
    },
    ref,
  ) => {
    const [overvalued, setOvervalued] = useState(false);
    const [valueCount, setValueCount] = useState('');
    const pattern = inputType === 'number' ? '[0-9.]' : undefined;

    const formatNumber = useCallback((inputValue: string) => {
      const regex = /\B(?=(\d{3})+(?!\d))/g;

      return inputValue.replace(regex, ',');
    }, []);

    const formatDecimal = useCallback(
      (inputValue: string | number | readonly string[] | undefined) => {
        if (!inputValue) {
          return '';
        }

        const decimalIndex = String(inputValue).indexOf('.');

        if (decimalIndex !== -1) {
          const decimalPart = String(inputValue).slice(decimalIndex, decimalIndex + decimalDigits + 1);
          return String(inputValue).slice(0, decimalIndex) + decimalPart;
        }

        return String(inputValue);
      },
      [decimalDigits],
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (inputType === 'number') {
        let replaceValue = e.target.value.replace(/[^0-9.]/g, '');
        const decimalIndex = replaceValue.indexOf('.');

        if (decimalIndex === -1) {
          const newChangeEvent = {
            ...e,
            target: { ...e.target, dataset: { ...e.target.dataset }, value: replaceValue },
          };
          onChange?.(newChangeEvent);
          return;
        }

        const integerPart = replaceValue.slice(0, decimalIndex);

        const decimalPart = replaceValue.slice(decimalIndex, decimalIndex + decimalDigits + 1);

        replaceValue = integerPart + '.' + decimalPart.replace(/\./g, '');

        const newChangeEvent = { ...e, target: { ...e.target, dataset: { ...e.target.dataset }, value: replaceValue } };
        onChange?.(newChangeEvent);
        return;
      }
      onChange?.(e);
    };

    const formattedValue = useMemo(() => {
      return inputType === 'number' ? formatNumber(formatDecimal(value)) : value;
    }, [inputType, value, formatDecimal, formatNumber]);

    useEffect(() => {
      if (maxLength && typeof value === 'string') {
        setOvervalued(maxLength < value.length);
        setValueCount(`${value.length}/${maxLength}`);
      }
    }, [hasError, maxLength, value]);

    return (
      <Container className={className} hasError={hasError || overvalued}>
        {createLabelElement(label)}

        <InputWrapper
          className="date-input-wrapper"
          size={size}
          disabled={disabled}
          paddingRightAndLeft={paddingRightAndLeft}
          onClick={onClick}
          borderColor={borderColor}
        >
          {createSideElement(leftComponent)}
          <Input
            id={id ? id : undefined}
            ref={ref}
            placeholder={placeholder}
            autoCapitalize="none"
            {...restProps}
            disabled={disabled}
            pattern={pattern}
            value={formattedValue}
            readOnly={readOnly}
            maxLength={maxLength}
            onChange={handleChange}
          />
          {createSideElement(rightComponent ? rightComponent : valueCount)}
        </InputWrapper>

        {hint ? <Hint>{hint}</Hint> : null}
        {overvalued && !hint ? <Hint>최대 {maxLength}자까지 입력할 수 있습니다.</Hint> : null}
      </Container>
    );
  },
);

const createSideElement = (node: React.ReactNode) => {
  if (!node) {
    return null;
  }

  if (typeof node === 'string') {
    return <Side>{node}</Side>;
  }

  return node;
};

const createLabelElement = (node: React.ReactNode) => {
  if (!node) {
    return null;
  }

  if (typeof node === 'string') {
    return <Label>{node}</Label>;
  }

  return node;
};

const Container = styled.div<{ hasError?: boolean }>`
  font-family: Pretendard;

  ${({ theme, hasError }) =>
    hasError &&
    css`
      ${InputWrapper} {
        border-color: ${theme.colors.red500};
      }

      ${Hint} {
        color: ${theme.colors.red500};
      }
    `}
`;

const Label = styled.h3`
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray700};
`;

const Hint = styled.p`
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.gray500};
`;

const Input = styled.input`
  padding: 0;
  width: 100%;
  height: 44px;
  font-family: Pretendard;
  color: ${({ theme }) => theme.colors.gray700};
  border: 0;
  outline: none;
  box-sizing: border-box;

  &::placeholder {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray500};
  }

  &:read-only {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.colors.gray100};
  }
`;

const InputWrapper = styled.div<{
  size?: Size;
  paddingRightAndLeft?: number;
  disabled?: boolean;
  borderColor?: Colors;
}>`
  padding: ${({ paddingRightAndLeft }) => (paddingRightAndLeft ? `0 ${paddingRightAndLeft}px` : '0 14px')};
  border: 1px solid ${({ theme, borderColor }) => (borderColor ? getColor(borderColor) : theme.colors.gray400)};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  box-sizing: border-box;

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.blue500};
  }

  ${({ theme, disabled }) =>
    disabled &&
    css`
      background-color: ${theme.colors.gray100};

      ${Input} {
        cursor: not-allowed;
      }

      ${Hint} {
        color: ${theme.colors.red500};
      }
    `}

  ${({ size }) => {
    switch (size) {
      case 'sm': {
        return css`
          ${Input} {
            height: 34px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }
        `;
      }
      case 'md': {
        return css`
          ${Input} {
            height: 44px;
            font-weight: 400;
            font-size: 15px;
            line-height: 23px;
          }
        `;
      }
    }
  }}
`;

const Side = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.gray500};
`;
