import { startTransition, useState } from 'react';
import { SearchSelectOptionType } from './SearchSelect';

export const useSearchSelect = (optionList: SearchSelectOptionType[], onInputChange?: (value: string) => void) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<SearchSelectOptionType[]>([]);

  const debouncedFilter = (newValue: string) => {
    startTransition(() => {
      if (newValue.trim()) {
        setOptions(optionList.filter((option) => option.label.toLowerCase().includes(newValue.toLowerCase())));
      } else {
        setOptions([]);
      }
    });
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
    debouncedFilter(newValue);
    onInputChange?.(newValue);
  };

  return {
    options,
    inputValue,
    handleInputChange,
  };
};
