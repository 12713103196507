import { useFeatureFlagContext } from '@29cm/feature-flag';
import { useEffect } from 'react';

/**
 * 사용자 아이디(이메일)를 unleash에 설정합니다.
 */
export const useSetUserIdToFeatureFlag = () => {
  const updateContext = useFeatureFlagContext();

  const loggedUserNo = 'todo';

  useEffect(() => {
    // TODO
    updateContext({ userId: loggedUserNo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserNo]);
};
