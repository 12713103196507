import { useState } from 'react';
import { CardTabType } from './CardTab.types';

export const useSelectedCardTab = (initialState?: CardTabType['value']) => {
  const [activeTab, setActiveTab] = useState<CardTabType['value'] | null>(initialState ?? null);

  const handleTabChange = (nextTabValue: CardTabType['value'] | null) => {
    setActiveTab(nextTabValue);
  };

  const isActiveTab = (tabValue: CardTabType['value']): boolean => tabValue === activeTab;

  return { activeTab, handleTabChange, isActiveTab };
};
