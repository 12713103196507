import styled from '@emotion/styled';
import { CSSProperties } from 'react';
import { CardTabType } from './CardTab.types';

interface CardTabsProps {
  className?: string;
  values: CardTabType[];
  gap: NonNullable<CSSProperties['gap']>;
  renderItem: (item: CardTabType) => JSX.Element;
}

export const CardTabs = ({ className, values, gap, renderItem }: CardTabsProps) => {
  return (
    <Container className={className} style={{ gap }}>
      {values.map(renderItem)}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: fit-content;
`;
