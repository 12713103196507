import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { DropdownMenuSize } from '../../DropdownMenu.types';
import { useDropdownMenuContext } from '../../providers/DropdownMenuProvider';
import { css } from '@emotion/react';

interface Props {
  className?: string;
  hover?: boolean;
  onClick?: () => void;
}

export const Item = ({ className, children, hover = true, onClick }: PropsWithChildren<Props>) => {
  const { size } = useDropdownMenuContext();

  return (
    <Container className={className} size={size} hover={hover} onClick={onClick}>
      {children}
    </Container>
  );
};

const Container = styled.div<{ size: DropdownMenuSize; hover: boolean }>`
  width: 100%;
  height: ${({ size }) => (size === 'md' ? '46px' : '36px')};

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;

  cursor: pointer;

  &:hover {
    ${({ hover, theme }) =>
      hover &&
      css`
        background-color: ${theme.colors.gray100};
      `}
  }
`;
