import { css } from '@emotion/react';

export const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const multiLineEllipsis = (line: number, lineHeight = 1.6) => css`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: ${lineHeight * line}em;
  text-overflow: ellipsis;

  -webkit-line-clamp: ${line};
`;
