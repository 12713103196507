import styled from '@emotion/styled';
import { FloatingPortal } from '@floating-ui/react';
import format from 'date-fns/format';
import React from 'react';
import { ZIndex } from '../../constants';
import { CalendarIcon } from '../../foundations/iconography/CalendarIcon';
import { InputBox } from '../input-box';
import { CalendarPopup } from './calendar-popup';
import { DATE_FORMAT, HasDateError, useDateInputBoxHandler } from './DateInputBoxController.hooks';
import { useDateInputBoxContext } from './providers';

interface Props extends Omit<React.ComponentProps<typeof InputBox>, 'value' | 'onKeyDown'> {
  className?: string;
  hasError?: boolean;
}

const DEFAULT_PREV_DATE_PREVENT_ERROR_TEXT = '오늘 이전으로 설정할 수 없습니다.';

export const DateInputBoxController = ({
  className,
  width,
  hasError: initialError,
  label,
  hint,
  disabled,
  ...restProps
}: Props) => {
  const { popupState, preventBeforeDate, preventAfterDate, updateShowCalendarPopup } = useDateInputBoxContext();
  const { hasError, value, handleKeyDown, handleChange } = useDateInputBoxHandler({
    hasError: initialError,
  });

  const showCalendarPopup = () => updateShowCalendarPopup(true);

  const handleClick = () => {
    if (disabled) {
      return;
    }

    showCalendarPopup();
  };

  const toggleCalendarPopup = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }

    updateShowCalendarPopup(!popupState?.isShowCalendarPopup);
  };

  const getHintText = () => {
    if (hasError === HasDateError.PREVENT_BEFORE_DATE && preventBeforeDate) {
      return `${format(preventBeforeDate, DATE_FORMAT)} 이전으로 설정할 수 없습니다.`;
    }

    if (hasError === HasDateError.PREVENT_AFTER_DATE && preventAfterDate) {
      return `${format(preventAfterDate, DATE_FORMAT)} 이후로 설정할 수 없습니다.`;
    }

    if (hasError === HasDateError.PREV_DATE) {
      return DEFAULT_PREV_DATE_PREVENT_ERROR_TEXT;
    }

    return hint;
  };

  return (
    <Container>
      <InputBox
        ref={popupState?.context.refs.setReference}
        className={className}
        hasError={hasError !== HasDateError.NONE}
        hint={getHintText()}
        label={label}
        value={value}
        disabled={disabled}
        rightComponent={
          <Button type="button" disabled={disabled} onClick={toggleCalendarPopup}>
            <CalendarIcon />
          </Button>
        }
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onClick={handleClick}
        {...restProps}
      />
      <FloatingPortal>
        {popupState?.isShowCalendarPopup ? (
          <section
            ref={popupState?.context.refs.setFloating}
            style={{
              position: popupState?.context.strategy,
              zIndex: ZIndex.Calendar,
              top: popupState?.context.y ?? 0,
              left: popupState?.context.x ?? 0,
              visibility: popupState?.context.x == null ? 'hidden' : 'visible',
            }}
          >
            <CalendarPopup />
          </section>
        ) : null}
      </FloatingPortal>
    </Container>
  );
};

const Container = styled.div``;

const Button = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
  line-height: 0;

  &:disabled {
    cursor: not-allowed;
  }
`;
