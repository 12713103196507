import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { MouseEvent, PropsWithChildren, ReactElement, cloneElement } from 'react';
import { colors } from '../../core';

export interface SelectButtonItem {
  label: string;
  value: string;
  disabled?: boolean;
}

interface Props<T extends SelectButtonItem = SelectButtonItem> {
  className?: string;
  buttonCss?: SerializedStyles;
  gap?: number;
  items: T[] | readonly T[];
  selectedItems?: T[];
  disabled?: boolean;
  onChange?: (item: T) => void;
  renderItem?: (item: T) => ReactElement;
}

export const SelectButton = <T extends SelectButtonItem>({
  className,
  buttonCss,
  gap,
  items,
  selectedItems,
  disabled,
  onChange,
  renderItem,
  children,
}: PropsWithChildren<Props<T>>) => {
  return (
    <Container className={className}>
      {items.map((item, idx) => {
        const active = selectedItems?.some((selected) => selected?.value === item.value);

        const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();

          if (disabled || item.disabled) {
            return;
          }

          onChange?.(item);
        };

        if (renderItem) {
          return cloneElement(renderItem(item), {
            idx,
            key: item.value,
            role: 'button',
            gap,
            active,
            position: idx + 1,
            disabled: disabled || item.disabled,
            onClick: handleClick,
          });
        }

        return (
          <Button
            type="button"
            key={item.value}
            css={buttonCss}
            gap={gap}
            onClick={handleClick}
            active={active}
            disabled={disabled || item.disabled}
          >
            {item.label}
          </Button>
        );
      })}

      {children}
    </Container>
  );
};

const Container = styled.span`
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -6px;
`;

const Button = styled.button<{ active?: boolean; gap?: number }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ gap }) => gap ?? 6}px;
  margin-bottom: 6px;
  padding: 7px 18px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${colors.primary};
  background: ${colors.white};
  border: 1px solid ${({ active }) => (active ? colors.primary : colors.line_gray01)};
  border-radius: 4px;

  &:last-of-type {
    margin-right: 0;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${colors.line_gray02};
    border-color: ${colors.line_gray01};
  }
`;
