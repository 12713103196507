import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CSSProperties } from 'react';
import { CheckBox } from '../check-box';
import { DefaultRecordType, FlattenColumn, FlattenColumns, TableSize } from './Table.types';
import { useTableBodyRow } from './TableBodyRow.hooks';

interface Props<DataRecord extends DefaultRecordType> {
  rowKey: React.Key;
  data: DataRecord;
  flattenColumns: FlattenColumns<DataRecord>;
  size: TableSize;
  backgroundColor?: CSSProperties['backgroundColor'];
  renderCell: (column: FlattenColumn<DataRecord>) => void;
}

export const TableBodyRow = <DataRecord extends DefaultRecordType = DefaultRecordType>({
  rowKey,
  data,
  flattenColumns,
  backgroundColor,
  renderCell,
}: Props<DataRecord>) => {
  const { checked, disabled, selection, handleChange } = useTableBodyRow(rowKey, data);

  return (
    <StyledRow key={rowKey} backgroundColor={backgroundColor}>
      {selection ? (
        <CheckBoxCell>
          <CheckBox selected={checked} disabled={disabled} onChange={handleChange} />
        </CheckBoxCell>
      ) : null}
      <>{flattenColumns.map(renderCell)}</>
    </StyledRow>
  );
};

const StyledRow = styled.tr<{ backgroundColor?: CSSProperties['backgroundColor'] }>`
  & > td {
    ${({ backgroundColor }) => {
      return (
        backgroundColor &&
        css`
          background-color: ${backgroundColor};
        `
      );
    }}
  }
`;

const CheckBoxCell = styled.td<{ isEvenRow?: boolean }>`
  position: sticky;
  z-index: 2;
  left: 0;
  padding: 10px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.gray700};
  border-right: ${({ theme }) => `1px solid ${theme.colors.gray300}`};
`;
