import add from 'date-fns/add';
import format from 'date-fns/format';
import isMatch from 'date-fns/isMatch';
import parse from 'date-fns/parse';
import sub from 'date-fns/sub';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { noop } from '../../../utils';
import { useCalendarPopupState } from '../hooks';

export interface CalendarProviderProps {
  currentDate: Date;
  beginDate: Date | null;
  endDate: Date | null;
  value: string;
  isNeedEndDate: boolean;
  preventBeforeDate?: Date;
  preventAfterDate?: Date;
  isNeedPrevDatePrevents: boolean;
  hideCalendarFooterToggle: boolean;
  handleChange?: (value: string) => void;
  prevMonth: () => void;
  nextMonth: () => void;
  syncInputBoxFromCalendar: (targetBeginDate: Date | null, targetEndDate: Date | null) => void;
  syncCalendarFromInputBox: (value: string) => void;
  updateTextValue: React.Dispatch<React.SetStateAction<string>>;
  updateBeginDate: React.Dispatch<React.SetStateAction<Date | null>>;
  updateEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  updateNeedEndDate: React.Dispatch<React.SetStateAction<boolean>>;
  updateShowCalendarPopup: (value: boolean) => void;
  popupState: ReturnType<typeof useCalendarPopupState> | null;
}

const DateInputBoxContext = React.createContext<CalendarProviderProps>({
  currentDate: new Date(),
  beginDate: null,
  endDate: null,
  value: '',
  isNeedEndDate: false,
  isNeedPrevDatePrevents: false,
  hideCalendarFooterToggle: false,
  preventBeforeDate: undefined,
  preventAfterDate: undefined,
  prevMonth: noop,
  nextMonth: noop,
  syncInputBoxFromCalendar: noop,
  syncCalendarFromInputBox: noop,
  updateTextValue: noop,
  updateBeginDate: noop,
  updateEndDate: noop,
  updateNeedEndDate: noop,
  updateShowCalendarPopup: noop,
  popupState: null,
});

const DateInputBoxContextProvider = DateInputBoxContext.Provider;

interface DateInputBoxProviderProps {
  value?: string;
  isNeedEndDate?: boolean;
  isNeedPrevDatePrevents?: boolean;
  preventBeforeDate?: Date;
  preventAfterDate?: Date;
  hideCalendarFooterToggle?: boolean;
  onChange?: (value: string) => void;
  onClose?: (beginDate: Date | null, endDate: Date | null) => void;
}

const DATE_FORMAT = 'yyyy.MM.dd';

export const DateInputBoxProvider = ({
  value: initialValue = '',
  isNeedEndDate: initialNeedEndDate = false,
  isNeedPrevDatePrevents = false,
  hideCalendarFooterToggle = false,
  preventBeforeDate,
  preventAfterDate,
  onChange,
  onClose,
  children,
}: React.PropsWithChildren<DateInputBoxProviderProps>) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [beginDate, setBeginDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [inputValue, setInputValue] = useState<string>(initialValue);
  const [isNeedEndDate, setNeedEndDate] = useState<boolean>(initialNeedEndDate);

  const syncInputBoxFromCalendar = (targetBeginDate: Date | null, targetEndDate: Date | null) => {
    const beginDateText = targetBeginDate ? format(targetBeginDate, DATE_FORMAT) : null;
    const endDateText = targetEndDate ? format(targetEndDate, DATE_FORMAT) : null;

    const nextInputValue = [beginDateText, endDateText].filter(Boolean).join(' - ');
    setInputValue(nextInputValue);
    onChange?.(nextInputValue);
  };

  const syncCalendarFromInputBox = (value: string) => {
    const [beginDateText, endDateText] = value
      .replace(/\s/g, '')
      .split('-')
      .filter((text) => isMatch(text.trim(), DATE_FORMAT));

    if (beginDateText) {
      const nextBeginDate = parse(beginDateText, DATE_FORMAT, new Date());
      setBeginDate(nextBeginDate);
    }

    if (endDateText) {
      const nextEndDate = parse(endDateText, DATE_FORMAT, new Date());
      setEndDate(nextEndDate);
      setNeedEndDate(Boolean(endDateText));
    }
  };

  const updateShowCalendarPopup = (show: boolean) => {
    if (!show) {
      onClose?.(beginDate, endDate);
    }

    popupState.setIsShowCalendarPopup(show);
  };

  const popupState = useCalendarPopupState({ updateShowCalendarPopup });

  const value = useMemo(
    () => ({
      currentDate,
      beginDate,
      endDate,
      value: inputValue,
      isNeedEndDate,
      isNeedPrevDatePrevents,
      preventBeforeDate,
      preventAfterDate,
      hideCalendarFooterToggle,
      handleChange: onChange,
      prevMonth: () => {
        const prevMonthDate = sub(currentDate, { months: 1 });
        setCurrentDate(prevMonthDate);
      },
      nextMonth: () => {
        const nextMonthDate = add(currentDate, { months: 1 });
        setCurrentDate(nextMonthDate);
      },
      syncInputBoxFromCalendar,
      syncCalendarFromInputBox,
      updateTextValue: setInputValue,
      updateBeginDate: setBeginDate,
      updateEndDate: setEndDate,
      updateNeedEndDate: setNeedEndDate,
      updateShowCalendarPopup,
      popupState,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDate, beginDate, endDate, inputValue, isNeedEndDate, popupState, preventBeforeDate, preventAfterDate],
  );

  useEffect(() => {
    setInputValue(initialValue);
    syncCalendarFromInputBox(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (isNeedEndDate) {
      return;
    }

    if (beginDate === null) {
      return;
    }

    setEndDate(null);
    syncInputBoxFromCalendar(beginDate, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedEndDate, beginDate]);

  useEffect(() => {
    if (!isNeedEndDate) {
      return;
    }

    if (beginDate === null) {
      return;
    }

    if (endDate !== null) {
      return;
    }

    setEndDate(beginDate);
    syncInputBoxFromCalendar(beginDate, beginDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedEndDate, beginDate, endDate]);

  return <DateInputBoxContextProvider value={value}>{children}</DateInputBoxContextProvider>;
};

DateInputBoxProvider.displayName = 'DateInputBoxProvider';

export const useDateInputBoxContext = () => {
  const context = useContext(DateInputBoxContext);

  if (!context) {
    throw new Error(`${DateInputBoxProvider.displayName}와 함께 사용해야 합니다.`);
  }

  return context;
};
