import { BaseIconProps } from '../../types/iconography.types';
import { getColor } from '../../../../core/colors';

export const MediumCloseIcon = ({ className, color = 'blue500' }: BaseIconProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.85355 3.14645C3.65829 2.95118 3.34171 2.95118 3.14645 3.14645C2.95118 3.34171 2.95118 3.65829 3.14645 3.85355L8.29319 9.0003L3.14645 14.147C2.95118 14.3423 2.95118 14.6589 3.14645 14.8541C3.34171 15.0494 3.65829 15.0494 3.85355 14.8541L9.0003 9.7074L14.147 14.8541C14.3423 15.0494 14.6589 15.0494 14.8541 14.8541C15.0494 14.6589 15.0494 14.3423 14.8541 14.147L9.7074 9.0003L14.8541 3.85358C15.0494 3.65831 15.0494 3.34173 14.8541 3.14647C14.6589 2.95121 14.3423 2.95121 14.147 3.14647L9.0003 8.29319L3.85355 3.14645Z"
        fill={getColor(color)}
      />
    </svg>
  );
};
