import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

/**
 ```tsx
 <TrashIcon
 color="gray600"
 />
 ```
 */

export const DragIcon = ({ className, color = 'gray500' }: BaseIconProps) => {
  const fill = getColor(color);

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="11" width="2" height="2" rx="1" fill={fill} />
      <rect x="5" y="7" width="2" height="2" rx="1" fill={fill} />
      <rect x="5" y="3" width="2" height="2" rx="1" fill={fill} />
      <rect x="9" y="11" width="2" height="2" rx="1" fill={fill} />
      <rect x="9" y="7" width="2" height="2" rx="1" fill={fill} />
      <rect x="9" y="3" width="2" height="2" rx="1" fill={fill} />
    </svg>
  );
};
