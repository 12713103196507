import { getExpiryTimeFromJwt } from './getExpiryTimeFromJwt';

export const isExpiredToken = (jsonwebtoken?: string) => {
  if (!jsonwebtoken) {
    return true;
  }

  const ExpiryTimeFromJwt = getExpiryTimeFromJwt(jsonwebtoken);

  if (!ExpiryTimeFromJwt) {
    return true;
  }

  const nowDate = new Date();

  return ExpiryTimeFromJwt <= nowDate;
};
