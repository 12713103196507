import { InputBox, getColor } from '@29cm/design-systems';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ChangeEventHandler, HTMLInputTypeAttribute, forwardRef } from 'react';
import type { FieldError } from 'react-hook-form';

interface Props {
  className?: string;
  label: string;
  name: string;
  title?: string;
  inputType?: HTMLInputTypeAttribute;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  readonly?: boolean;
  value?: string;
  pattern?: string;
  autocomplete?: string;
  errors?: FieldError | undefined;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const FormItem = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      label,
      name,
      title,
      inputType = 'text',
      required = true,
      minLength,
      maxLength,
      readonly = false,
      value,
      pattern,
      autocomplete,
      errors,
      onChange,
      ...restProps
    },
    ref,
  ) => {
    return (
      <Container className={className}>
        <Label htmlFor={name}>{label}</Label>
        <StyledInputBox
          type={inputType}
          ref={ref}
          name={name}
          title={title}
          id={name}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          readOnly={readonly}
          value={value}
          pattern={pattern}
          autoComplete={autocomplete}
          onChange={onChange}
          {...restProps}
        />
        {errors && <ErrorMessage>{errors.message}</ErrorMessage>}
      </Container>
    );
  },
);

const Container = styled.div`
  margin-top: 15px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 3px;
`;

const StyledInputBox = styled(InputBox)<{ readOnly: boolean }>`
  ${({ readOnly }) =>
    readOnly &&
    css`
      color: ${getColor('text_gray01')};
      background-color: ${getColor('line_gray01')};
    `}
`;

const ErrorMessage = styled.p`
  margin-top: 3px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.legacy.red};
`;
