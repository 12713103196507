import { ErrorModel } from '../api';

interface ErrorResponse {
  result: 'SUCCESS' | 'FAIL';
  message: string;
  errorCode: string;
}

export const parseStatusCode = (err: unknown) => {
  const error = err as ErrorModel<ErrorResponse | undefined>;
  return error.status;
};

export const parseErrorMessage = (err: unknown, fallbackMessage?: string) => {
  const error = err as ErrorModel<ErrorResponse>;
  const message = error?.data?.message ?? error?.message;

  if (message) {
    return message;
  }

  return fallbackMessage;
};
