import { css, SerializedStyles } from '@emotion/react';
import { AxisDirection } from '../types';

function horizontalGap(space: number, selector: string) {
  return css`
    & > ${selector} ~ ${selector} {
      margin-left: ${space}px;
    }
  `;
}

function verticalGap(space: number, selector: string) {
  return css`
    & > ${selector} ~ ${selector} {
      margin-top: ${space}px;
    }
  `;
}

export interface GapOptions {
  direction: AxisDirection;
  space?: number;
  selector?: string;
}

export function gap(options: GapOptions): SerializedStyles;
export function gap(direction: AxisDirection, space?: number, selector?: string): SerializedStyles;
export function gap(directionOrGapOptions: GapOptions | AxisDirection, space = 24, selector = '*:not(style)') {
  if (typeof directionOrGapOptions === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { direction, space = 24, selector = '*:not(style)' } = directionOrGapOptions;

    if (direction === 'vertical') {
      return verticalGap(space, selector);
    } else {
      return horizontalGap(space, selector);
    }
  }

  if (directionOrGapOptions === 'vertical') {
    return verticalGap(space, selector);
  } else {
    return horizontalGap(space, selector);
  }
}
