import { PropsWithChildren, useRef } from 'react';
import styled from '@emotion/styled';
import { useOutsideClick } from '../../../hooks';
import { DropdownMenuSize } from '../DropdownMenu.types';
import { DropdownMenuContextType, DropdownMenuProvider } from '../providers/DropdownMenuProvider';

interface Props {
  className?: string;
  size?: DropdownMenuSize;
  isToggled: boolean;
  onToggle: (nextIsToggled: boolean) => void;
}

export const Root = ({ className, size = 'md', isToggled, onToggle, children }: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClose = () => onToggle(false);

  useOutsideClick(ref, handleClose);

  const context: DropdownMenuContextType = {
    size,
    isToggled,
    onToggle,
  };

  return (
    <DropdownMenuProvider value={context}>
      <Container ref={ref} className={className}>
        {children}
      </Container>
    </DropdownMenuProvider>
  );
};

const Container = styled.div`
  position: relative;
`;
