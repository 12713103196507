import { CreatePopupParams } from '../components/Popup/Popup.types';
import { popup } from '../containers/PopupContainer';

/**
 * ## Usage
 * const popup = usePopup();
 *
 * const show = () => {
 *   popup.show({ content: '~' }); // return popup id
 * }
 */
export const usePopup = () => {
  const show = (params: CreatePopupParams) => {
    const id = params.id ?? Math.random().toString(36).substr(2, 10);

    popup.show({
      ...params,
      id,
    });

    return id;
  };

  const hide = (id: string) => {
    popup.hide(id);
  };

  return {
    show,
    hide,
  };
};
