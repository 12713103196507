import { Flex } from '@29cm/admin-emotion-utils';
import styled from '@emotion/styled';
import { Button } from '../../button';
import { Toggle } from '../../toggle';
import { useDateInputBoxContext } from '../providers';

interface Props {
  className?: string;
}

export const CalendarFooter = ({ className }: Props) => {
  const { isNeedEndDate, hideCalendarFooterToggle, updateNeedEndDate, updateShowCalendarPopup } =
    useDateInputBoxContext();

  const handleToggleNeedEndDate = () => updateNeedEndDate(!isNeedEndDate);

  const handleConfirmClick = () => {
    updateShowCalendarPopup(false);
  };

  return (
    <Container className={className} align="center" justify={hideCalendarFooterToggle ? 'flex-end' : 'space-between'}>
      {hideCalendarFooterToggle ? null : (
        <Toggle size="sm" active={isNeedEndDate} onToggle={handleToggleNeedEndDate}>
          종료일 있음
        </Toggle>
      )}
      <Button color="primaryBlue" size="md" onClick={handleConfirmClick}>
        확인
      </Button>
    </Container>
  );
};

const Container = styled(Flex)`
  height: 36px;
`;
