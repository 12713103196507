import { dialog } from '../containers';
import { CreateDialogParams, DialogSetCautionType } from '../Dialog.types';

/**
 * ## Usage
 * const dialog = useDialog();
 *
 * const show = () => {
 *   dialog.show({ content: '~' }); // return dialog id
 * }
 */
export const useDialog = () => {
  const show = (params: CreateDialogParams) => {
    const id = params.id ?? Math.random().toString(36).substr(2, 10);

    dialog.show({
      ...params,
      id,
    });

    return id;
  };

  const hide = (id: string) => {
    dialog.hide(id);
  };

  const setCaution: DialogSetCautionType = (id, content) => {
    dialog.caution(id, content);
  };

  return {
    show,
    hide,
    setCaution,
  };
};
