import { Button, InputBox, Typography } from '@29cm/admin-design-system';
import { Flex, size, Spacing, Stack } from '@29cm/admin-emotion-utils';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { BlackLogo, Form } from '~apps/@shared/components';
import { AuthLayout } from '~apps/@shared/layouts';
import { UnionRegistrationGuide } from '~apps/login/components/sections/union-registration-guide/UnionRegistrationGuide';
import {
  useFirstVisitUnionRegistrationGuideTooltip,
  useLoginAction,
  useLoginFormWithId,
  useUnionRegistration,
  useUnionRegistrationDialog,
} from '~apps/login/LoginPage.hooks';

const DynamicUnionRegistrationGuide = dynamic(
  () =>
    import('~apps/login/components/sections/union-registration-guide/UnionRegistrationGuide').then(
      (module) => module.UnionRegistrationGuide,
    ),
  {
    ssr: false,
  },
) as typeof UnionRegistrationGuide;

export const LoginPage = () => {
  useUnionRegistrationDialog();
  const { handleLoginSubmit, isLoading } = useLoginAction();
  const { errors, loginIdFieldProps, passwordFieldProps } = useLoginFormWithId();
  const { handleUnionRegistrationClick } = useUnionRegistration();
  const { showUnionRegistrationGuideTooltip } = useFirstVisitUnionRegistrationGuideTooltip();

  return (
    <AuthLayout title="로그인" subTitle={<StyledLogo />}>
      <Spacing size={54} />
      <Form onSubmit={handleLoginSubmit}>
        <InputBoxGroup gap={6}>
          <InputBox
            label="아이디"
            hint={errors.loginId?.message}
            {...loginIdFieldProps}
            hasError={Boolean(errors.loginId)}
          />

          <InputBox
            label="비밀번호"
            type="password"
            hint={errors.password?.message}
            hasError={Boolean(errors.password)}
            {...passwordFieldProps}
          />
        </InputBoxGroup>

        <Spacing size={10} />
        <LoginButton size="lg" type="submit" isLoading={isLoading}>
          로그인
        </LoginButton>
      </Form>
      <Spacing size={14} />
      <LinkGroup justify="space-between">
        <Link href={'/otp/find'}>
          <Typography variant="body/12-medium" color="gray600">
            OTP 초기화 하기
          </Typography>
        </Link>

        <Link href={'/join/find-password'}>
          <Typography variant="body/12-medium" color="gray600">
            비밀번호 찾기
          </Typography>
        </Link>
      </LinkGroup>

      <Spacing size={90} />

      <DynamicUnionRegistrationGuide showUnionRegistrationGuideTooltip={showUnionRegistrationGuideTooltip} />

      <UnionRegistrationButton size="lg" color="secondaryBlue" onClick={handleUnionRegistrationClick}>
        통합가입
      </UnionRegistrationButton>
    </AuthLayout>
  );
};

const StyledLogo = styled(BlackLogo)`
  ${size({
    width: 220,
    height: 60,
  })}
`;

const LoginButton = styled(Button)`
  justify-content: center;
  ${size.width100}
`;

const InputBoxGroup = styled(Stack)`
  ${size.width100}
`;

const UnionRegistrationButton = styled(Button)`
  justify-content: center;
  ${size.width100}
`;

const LinkGroup = styled(Flex)`
  ${size.width100}
`;
