interface ScreenSize {
  minWidth?: number;
  maxWidth?: number;
}

export function buildMediaQuery(screenSize: ScreenSize, not?: boolean): string {
  const { minWidth, maxWidth } = screenSize;
  let query = '@media ';

  if (minWidth) {
    query += `(min-width: ${minWidth}px)`;
  }

  if (minWidth && maxWidth) {
    query += ' and ';
  }

  if (maxWidth) {
    query += `(max-width: ${maxWidth}px)`;
  }

  if (not) {
    query = `not all and ${query}`;
  }

  return query;
}

export const SIZES = {
  pc: {
    minWidth: 541,
  },
  mobile: {
    maxWidth: 540,
  },
  max1919: {
    maxWidth: 1919,
  },
  min1920: {
    minWidth: 1920,
  },
  max1439: {
    maxWidth: 1439,
  },
  min1440: {
    minWidth: 1440,
  },
  max1299: {
    maxWidth: 1299,
  },
  min1300: {
    minWidth: 1300,
  },
} as const;

export const breakPoints = {
  pc: buildMediaQuery(SIZES.pc),
  mobile: buildMediaQuery(SIZES.mobile),
  max1919: buildMediaQuery(SIZES.max1919),
  min1920: buildMediaQuery(SIZES.min1920),
  max1439: buildMediaQuery(SIZES.max1439),
  min1440: buildMediaQuery(SIZES.min1440),
  max1299: buildMediaQuery(SIZES.max1299),
  min1300: buildMediaQuery(SIZES.min1300),
} as const;
