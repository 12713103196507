import { APIService } from '@29cm/admin-core/api';
import {
  GetVerificationResponse,
  JoinCommonResponse,
  PostVerificationParams,
  SignUpParams,
} from '~apps/@shared/apis/join/JoinService.types';

export const JOIN_API_URL = '/inhouse-admin/v4/users/integration';

class JoinService extends APIService {
  constructor() {
    super();
    this.useAuth();
  }

  public async join(params: SignUpParams) {
    const res = await this.post<JoinCommonResponse>(`${JOIN_API_URL}/sign-up`, params);

    return res;
  }

  public async getVerification(code: string) {
    const res = await this.get<GetVerificationResponse>(`${JOIN_API_URL}/verification`, { code });

    return res;
  }

  public async postVerification(params: PostVerificationParams) {
    const res = await this.post<JoinCommonResponse>(`${JOIN_API_URL}/verification`, params);

    return res;
  }
}

export const joinApi: JoinService = JoinService.shared();
