import styled from '@emotion/styled';
import React from 'react';
import { getTypographyStyle } from '../styles/variants';
import { colors, getColor } from '../../../core/colors';
import { KeyOfTypographyVariantType } from '../types/variant.types';

export interface Props {
  className?: string;
  as?: React.ElementType;
  color?: keyof typeof colors;
  variant: KeyOfTypographyVariantType;
}

/**
  ```tsx
  <Typography
    as="div"
    color="gray800"
    variant="heading/22-semiBold"
  >
    Text
  </Typography>
  ```
 */
export const Typography = ({ className, as, color, variant, children }: React.PropsWithChildren<Props>) => {
  return (
    <Text as={as} className={className} color={color} variant={variant}>
      {children}
    </Text>
  );
};

const Text = styled.span<Pick<Props, 'color' | 'variant'>>`
  color: ${({ color }) => (color ? getColor(color) : colors.gray700)};

  ${({ variant }) => getTypographyStyle(variant)};
`;
