export enum Events {
  SHOW = 'show',
  HIDE = 'hide',
}

export type Handler<T = unknown> = (event: T) => void;

interface Emitter {
  on<T = unknown>(event: Events, handler: Handler<T>): void;
  emit<T = unknown>(event: Events, args?: T): void;
  off(): void;
}

export const emitter = ((): Emitter => {
  const events = new Map<Events, Handler<unknown>[]>();

  return {
    on<T = unknown>(event: Events, callback: Handler<T>) {
      if (!events.has(event)) {
        events.set(event, []);
      }
      events.get(event)?.push(callback as Handler<unknown>);
    },
    emit<T = unknown>(event: Events, args: T) {
      if (!events.has(event)) {
        return;
      }
      events.get(event)?.forEach((callback: Handler<T>) => callback(args));
    },
    off() {
      events.clear();
    },
  };
})();
