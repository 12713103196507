import { KeyOfTypographyVariantType } from '../../foundations';
import { DialogSize as Size } from './Dialog.types';

export const TYPOGRAPHY_VARIANT_BY_SIZE: Record<Size, KeyOfTypographyVariantType> = {
  [Size.SMALL]: 'body/16-semiBold',
  [Size.MEDIUM]: 'heading/22-semiBold',
  [Size.LARGE]: 'heading/22-semiBold',
  [Size.XLARGE]: 'heading/22-semiBold',
};

export const CONTAINER_WIDTH_BY_SIZE: Record<Size, string> = {
  [Size.SMALL]: '420px',
  [Size.MEDIUM]: '550px',
  [Size.LARGE]: '800px',
  [Size.XLARGE]: '1150px',
};
