import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { NextIcon, PrevIcon } from '../Icons';

interface Props {
  className?: string;
  /**
   * 현재 페이지
   * @default 1
   */
  currentPage?: number;
  /**
   * 페이지 당 글 개수
   * @default 50
   */
  perPage?: number;
  /**
   * 총 개수
   * @default 0
   */
  totalCount?: number;
  /**
   * 한 번에 표시될 최대 페이지 수
   * @default 10
   */
  displayCount?: number;
  onPageChange: (page: number) => void;
}

export const Pagination = ({
  className,
  currentPage = 1,
  perPage = 50,
  displayCount = 10,
  totalCount = 0,
  onPageChange,
}: Props) => {
  const totalPage = Math.ceil(totalCount / perPage);

  const page = Math.min(currentPage, totalPage);

  const pages = useMemo(() => {
    const firstPage = Math.max(page - Math.floor(displayCount / 2), 1);
    const lastPage = Math.min(displayCount, totalPage);
    const lastIndex = lastPage - 1;

    const needCollapse = displayCount >= 5; // ... 표시 유무

    return Array.from({ length: lastPage }).map((_, idx) => {
      const pageNo = firstPage + idx;

      // 첫번째 페이지
      if (idx === 0) {
        return {
          pageNo: needCollapse ? 1 : pageNo,
          shouldRenderCollapse: false,
        };
      }

      // 마지막 페이지
      if (idx === lastIndex) {
        return {
          pageNo: needCollapse ? Math.max(pageNo, totalPage) : pageNo,
          shouldRenderCollapse: false,
        };
      }

      // ... 표시
      if (idx === 1) {
        const collapsable = firstPage !== 1 && pageNo !== firstPage;

        return {
          pageNo,
          shouldRenderCollapse: needCollapse && collapsable,
        };
      }

      // ... 표시
      if (idx === lastIndex - 1) {
        const collapsable = lastPage !== totalPage && pageNo !== totalPage;

        return {
          pageNo,
          shouldRenderCollapse: needCollapse && collapsable,
        };
      }

      return {
        pageNo,
        shouldRenderCollapse: false,
      };
    });
  }, [page, displayCount, totalPage]);

  if (totalCount === 0) {
    return null;
  }

  const isFirstPage = page === 1;
  const isLastPage = page === totalPage;

  const handlePrevClick = () => onPageChange(page - 1);
  const handleNextClick = () => onPageChange(page + 1);
  const handlePageClick = (pageIndex: number) => onPageChange(pageIndex);

  return (
    <Container className={className}>
      {isFirstPage ? null : (
        <ArrowButton type="button" onClick={handlePrevClick}>
          <StyledPrevIcon aria-disabled={isFirstPage} />
          <HiddenText>이전 페이지</HiddenText>
        </ArrowButton>
      )}

      <PageList>
        {pages.map(({ pageNo: currentNo, shouldRenderCollapse }, idx) => {
          if (currentNo > totalPage) {
            return null;
          }

          if (shouldRenderCollapse) {
            // eslint-disable-next-line react/no-array-index-key
            return <PageItem key={`${currentNo}-${idx}`}>...</PageItem>;
          }

          const isCurrent = currentNo === page;

          return (
            <PageItem key={currentNo} aria-current={isCurrent}>
              <StyledButton type="button" active={isCurrent} onClick={() => handlePageClick(currentNo)}>
                {currentNo}
              </StyledButton>
            </PageItem>
          );
        })}
      </PageList>

      {isLastPage ? null : (
        <ArrowButton type="button" onClick={handleNextClick} aria-disabled={isLastPage}>
          <StyledNextIcon />
          <HiddenText>다음 페이지</HiddenText>
        </ArrowButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 0 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageList = styled.ul`
  display: flex;
  align-items: center;
  line-height: 1;
`;

const PageItem = styled.li`
  padding: 0 9px;
  height: 18px;
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.legacy.primary};
`;

const ArrowButton = styled.button`
  line-height: 0;
`;

const StyledButton = styled.button<{ active?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.legacy.text_gray01};

  ${({ theme, active }) =>
    active &&
    css`
      color: ${theme.colors.legacy.primary};
    `}
`;

const StyledPrevIcon = styled(PrevIcon)`
  margin: 0 9px;
`;

const StyledNextIcon = styled(NextIcon)`
  margin: 0 9px;
`;

const HiddenText = styled.span`
  position: absolute;
  display: block;
  text-indent: -9999px;
`;
