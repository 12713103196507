import { Flex } from '@29cm/admin-emotion-utils';
import styled from '@emotion/styled';
import { ComponentProps, forwardRef, memo } from 'react';
import ReactSelect, { ActionMeta, GroupBase } from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';
import { ZIndex } from '../../constants';
import { colors } from '../../core/colors';
import { Typography } from '../../foundations/typography';
import { isClient } from '../../utils';
import { MultiSelectComponents } from './MultiSelect.components';
import { multiSelectStyles } from './MultiSelect.styles';
import { MultiSelectOptionType, MultiSelectSize, SelectValueType } from './MultiSelect.types';

export interface MultiSelectProps
  extends Pick<
    ComponentProps<ReactSelect>,
    'isDisabled' | 'placeholder' | 'isSearchable' | 'onKeyDown' | 'isLoading' | 'loadingMessage'
  > {
  className?: string;
  size?: MultiSelectSize;
  hasError?: boolean;
  options: MultiSelectOptionType[];
  value?: SelectValueType;
  onChange?: (value: SelectValueType, actionMeta: ActionMeta<MultiSelectOptionType>) => void;
  title?: string;
  hint?: string;
  menuPortalZIndex?: number;
  borderColor?: keyof typeof colors;
}

export const MultiSelect = memo(
  forwardRef<
    Select<MultiSelectOptionType<string, string>, boolean, GroupBase<MultiSelectOptionType<string, string>>>,
    MultiSelectProps
  >(
    (
      {
        className,
        size = 'md',
        title,
        hint,
        hasError = false,
        menuPortalZIndex = ZIndex.Select,
        isSearchable = false,
        borderColor,
        ...rest
      },
      ref,
    ) => {
      return (
        <Container className={className} direction="column">
          {title ? (
            <Typography variant="body/14-medium" color="gray700">
              {title}
            </Typography>
          ) : null}
          <ReactSelect
            ref={ref}
            isMulti
            styles={multiSelectStyles(size, hasError, menuPortalZIndex, borderColor)}
            components={MultiSelectComponents()}
            isSearchable={isSearchable}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            menuPortalTarget={isClient() ? document.querySelector('body') : null}
            {...rest}
          />
          {hint ? (
            <Typography variant="body/14-regular" color={hasError ? 'red500' : 'gray500'}>
              {hint}
            </Typography>
          ) : null}
        </Container>
      );
    },
  ),
);

const Container = styled(Flex)`
  gap: 6px;
`;
