import styled from '@emotion/styled';

interface Props {
  className?: string;
}

export const PlusIcon = ({ className }: Props) => {
  return (
    <BarWrapper className={className}>
      <HorizontalBar />
      <VerticalBar />
    </BarWrapper>
  );
};

const BarWrapper = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
`;

const HorizontalBar = styled.i`
  display: inline-block;
  width: 14px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.legacy.white};
  border-radius: 2px;
`;

const VerticalBar = styled(HorizontalBar)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 14px;
  transform: translate(-50%, -50%);
`;
