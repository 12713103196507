import { Flex, padding } from '@29cm/admin-emotion-utils';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { ValueOfColor, getColor } from '../..';
import { BaseIconProps as IconBaseProps } from '../../foundations/iconography/types/iconography.types';
import { LoadingIndicator } from '../loading-indicator/LoadingIndicator';
import { IconColors } from './Button.constants';
import { ButtonColor, ButtonSize } from './Button.types';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  as?: React.ElementType;
  color?: ButtonColor;
  size?: ButtonSize;
  icon?: React.ReactNode;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  isLoading?: boolean;
}

/**
 ```tsx
 <Button
    type="button"
    size="lg"
    color="primaryRed"
 />
 ```
 */
export const Button = React.forwardRef(
  (
    {
      className,
      as,
      color = 'primaryBlue',
      size = 'lg',
      startIcon,
      endIcon,
      children,
      isLoading,
      ...restProps
    }: React.PropsWithChildren<ButtonProps>,
    forwardRef: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const isPrimaryButton = useMemo(() => color.includes('primary'), [color]);

    const startIconElement = useMemo(() => {
      return startIcon ? <StartIcon>{startIcon}</StartIcon> : null;
    }, [startIcon]);

    const endIconElement = useMemo(() => {
      return endIcon ? <EndIcon>{endIcon}</EndIcon> : null;
    }, [endIcon]);

    const getColorProp = (element: typeof startIcon | typeof endIcon) => {
      return React.Children.map(element, (child) => {
        if (!React.isValidElement(child)) {
          return;
        }
        const props = child.props as IconBaseProps;
        return props.color;
      });
    };

    const getIconColor = (element: typeof startIcon | typeof endIcon) => {
      const iconColorProp = getColorProp(element);
      const hasIconColorProp = iconColorProp && iconColorProp?.length > 0;
      return hasIconColorProp ? getColor(iconColorProp[0]) : IconColors[color];
    };

    const startIconColor = getIconColor(startIcon);
    const endIconColor = getIconColor(endIcon);

    const buttonChildren = useMemo(() => {
      if (isLoading) {
        return (
          <Flex align="center">
            <StyledLoadingIndicator size={18} color="gray800" />
            <span>진행중</span>
          </Flex>
        );
      }

      return (
        <>
          {startIconElement}
          {children}
          {endIconElement}
        </>
      );
    }, [children, endIconElement, isLoading, startIconElement]);

    if (isPrimaryButton) {
      return (
        <PrimaryButton
          ref={forwardRef}
          as={as}
          type="button"
          className={className}
          color={color}
          size={size}
          hasIcon={Boolean(startIcon) || Boolean(endIcon)}
          startIconColor={startIconColor}
          endIconColor={endIconColor}
          disabled={isLoading}
          {...restProps}
        >
          {buttonChildren}
        </PrimaryButton>
      );
    }

    return (
      <SecondaryButton
        ref={forwardRef}
        as={as}
        type="button"
        className={className}
        color={color}
        size={size}
        hasIcon={Boolean(startIcon) || Boolean(endIcon)}
        startIconColor={startIconColor}
        endIconColor={endIconColor}
        disabled={isLoading}
        {...restProps}
      >
        {buttonChildren}
      </SecondaryButton>
    );
  },
);

const StyledButton = styled.button<{
  size?: ButtonSize;
  hasIcon?: boolean;
  startIconColor: ValueOfColor;
  endIconColor: ValueOfColor;
}>`
  display: flex;
  align-items: center;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  border-radius: 4px;

  ${({ startIconColor, endIconColor }) => {
    return css`
      ${StartIcon} {
        svg path {
          stroke: ${startIconColor};
        }
      }

      ${EndIcon} {
        svg path {
          stroke: ${endIconColor};
        }
      }
    `;
  }}
  ${({ size, hasIcon }) => {
    switch (size) {
      case 'lg': {
        return css`
          min-height: 44px;
          padding: ${hasIcon ? '11.5px 20px 11.5px 18px' : '11.5px 20px'};

          ${StartIcon} {
            margin-right: 8px;

            svg {
              width: 16px;
              height: 16px;
            }
          }

          ${EndIcon} {
            margin-left: 8px;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        `;
      }
      case 'md': {
        return css`
          min-height: 36px;
          padding: 7px 18px;
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;

          ${StartIcon} {
            margin-right: 6px;

            svg {
              width: 14px;
              height: 14px;
            }
          }

          ${EndIcon} {
            margin-left: 6px;

            svg {
              width: 14px;
              height: 14px;
            }
          }
        `;
      }
      case 'sm': {
        return css`
          height: 24px;
          padding: 2px 7px;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;

          ${StartIcon} {
            margin-right: 6px;

            svg {
              width: 14px;
              height: 14px;
            }
          }

          ${EndIcon} {
            margin-left: 6px;

            svg {
              width: 14px;
              height: 14px;
            }
          }
        `;
      }
    }
  }}
`;

const PrimaryButton = styled(StyledButton)<{
  color: ButtonColor;
}>`
  ${({ theme, color }) => {
    switch (color) {
      case 'primaryRed': {
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.red500};
        `;
      }
      case 'primaryBlue': {
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.blue500};

          &:hover {
            background: ${theme.colors.blue700};
          }

          &:disabled {
            cursor: not-allowed;
            background: ${theme.colors.blue300};
          }
        `;
      }
    }
  }}
`;

const SecondaryButton = styled(StyledButton)<{ color: ButtonColor; size: ButtonSize }>`
  ${({ theme, color, size }) => {
    switch (color) {
      case 'secondaryRed': {
        return css`
          color: ${theme.colors.red500};
          background: ${theme.colors.red100};
        `;
      }
      case 'secondaryBlue': {
        return css`
          color: ${theme.colors.blue500};
          background: ${theme.colors.blue100};

          &:hover {
            background: ${theme.colors.blue200};
          }

          &:disabled {
            cursor: not-allowed;
            color: ${theme.colors.blue300};

            ${StartIcon} {
              svg path {
                stroke: ${theme.colors.blue300};
              }
            }
            ${EndIcon} {
              svg path {
                stroke: ${theme.colors.blue300};
              }
            }
          }

          ${size === 'sm' &&
          css`
            background: ${theme.colors.white};
            border: 1px solid ${theme.colors.blue200};

            &:hover {
              background: ${theme.colors.blue100};
            }

            &:disabled {
              cursor: not-allowed;
              color: ${theme.colors.blue200};

              ${StartIcon} {
                svg path {
                  stroke: ${theme.colors.blue200};
                }
              }
              ${EndIcon} {
                svg path {
                  stroke: ${theme.colors.blue200};
                }
              }
            }
          `}
        `;
      }
      case 'secondaryGray': {
        return css`
          color: ${theme.colors.gray700};
          background: ${theme.colors.gray300};

          &:hover {
            background: ${theme.colors.gray400};
          }

          &:disabled {
            cursor: not-allowed;
            color: ${theme.colors.gray500};

            ${StartIcon} {
              svg path {
                stroke: ${theme.colors.gray500};
              }
            }
            ${EndIcon} {
              svg path {
                stroke: ${theme.colors.gray500};
              }
            }
          }

          ${size === 'sm' &&
          css`
            background: ${theme.colors.white};
            border: 1px solid ${theme.colors.gray400};

            &:hover {
              background: ${theme.colors.gray200};
            }

            &:disabled {
              cursor: not-allowed;
              color: ${theme.colors.gray200};

              ${StartIcon} {
                svg path {
                  stroke: ${theme.colors.gray400};
                }
              }
              ${EndIcon} {
                svg path {
                  stroke: ${theme.colors.gray400};
                }
              }
            }
          `}
        `;
      }
    }
  }}
`;

const StartIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EndIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  ${padding({
    top: 14.5,
    left: 12,
  })}
`;
