export const colors = {
  transparent: 'transparent',
  primary: '#111111',
  white: '#ffffff',
  black: '#111111',
  red: '#ff0000',
  blue: '#3c63f6',
  text_gray01: '#999999',
  line_gray01: '#e7e7e7',
  line_gray02: '#dcdcdc',
  bg_gray01: '#f4f5f6',
  bg_blue01: '#3C63F608',
} as const;

export const getColor = (key: string) => {
  return (colors as Record<string, string>)[key];
};
