import { EventEmitterDialogProps, CreateDialogParams } from '../../components/Dialog/Dialog.types';

export const alert = ({
  id,
  responsiveFullSize = false,
  ...restProps
}: CreateDialogParams): EventEmitterDialogProps => {
  return {
    ...restProps,
    id: id ?? Math.random().toString(36).substr(2, 10),
    responsiveFullSize,
    open: false,
  };
};
