import { CSSObjectWithLabel, StylesConfig } from 'react-select';
import { colors, getColor } from '../../core/colors';
import { theme } from '../../core/theme';
import { MenuAlign, OptionType, Size } from './Select';

export const dropdownStyle = (
  menuAlign: MenuAlign,
  hasError?: boolean,
  size?: Size,
  menuPortalZIndex?: number,
  menuWidth?: CSSObjectWithLabel['width'],
  borderColor?: keyof typeof colors,
) => {
  const sizeOptionHeight = size === 'md' ? 46 : 36;
  const valueFontSize = size === 'sm' ? 14 : 15;
  const indicatorSeparatorMarginY = size === 'sm' ? 9 : 14;

  const focusedBorderColor = (isMenuOpen: boolean) => {
    return isMenuOpen ? theme.colors.blue500 : borderColor ? getColor(borderColor) : theme.colors.gray400;
  };

  const selectStyles: StylesConfig<OptionType> = {
    clearIndicator: (baseStyles) => ({
      ...baseStyles,
      transform: `scale(${12 / 16})`,
      cursor: 'pointer',
    }),
    indicatorSeparator: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: indicatorSeparatorMarginY,
      marginBottom: indicatorSeparatorMarginY,
      backgroundColor: theme.colors.gray500,
      display: state.hasValue ? 'block' : 'none',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: hasError ? theme.colors.red500 : focusedBorderColor(state.menuIsOpen),
      paddingRight: 6,
      paddingLeft: 6,
      minHeight: sizeOptionHeight,
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? theme.colors.gray100 : undefined,
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      borderRadius: 4,
      boxShadow: '4px 20px 38px rgba(82, 86, 108, 0.12)',
      width: menuWidth,
      [menuAlign]: 0,
    }),
    menuPortal: (baseStyles) => ({ ...baseStyles, zIndex: menuPortalZIndex }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      'paddingLeft': 12,
      ':active': {
        ...baseStyles[':active'],
        backgroundColor: theme.colors.gray100,
      },
      'backgroundColor': state.isDisabled ? undefined : state.isFocused ? theme.colors.gray100 : undefined,
      'color': state.isDisabled ? '#ccc' : theme.colors.gray700,
      'height': sizeOptionHeight,
      'minHeight': 36,
      'display': 'flex',
      'alignItems': 'center',
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      'paddingRight': 4,
      'paddingLeft': 4,
      '::-webkit-scrollbar': {
        width: 3,
      },
      '::-webkit-scrollbar-thumb': {
        background: '#a9a9a9',
        borderRadius: 3,
      },
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: theme.colors.gray700,
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      fontSize: valueFontSize,
    }),
  };
  return selectStyles;
};
