import { CheckboxProps, Table, TableColumnsType } from 'antd';

interface Props<T extends object> {
  className?: string;
  dataSource: readonly T[];
  columns: TableColumnsType<T>;
  selectionType?: 'checkbox' | 'radio';
  onChange?: (selectedRowKeys: React.Key[], selectedRows: T[]) => void;
  getCheckboxProps?: (record: T) => Partial<Omit<CheckboxProps, 'checked' | 'defaultChecked'>>;
}

export const BasicTable = <T extends object>({
  className,
  selectionType,
  dataSource,
  columns,
  onChange,
  getCheckboxProps,
}: Props<T>) => {
  const rowSelection = selectionType
    ? {
        type: selectionType,
        onChange,
        getCheckboxProps,
      }
    : undefined;

  return <Table className={className} dataSource={dataSource} columns={columns} rowSelection={rowSelection} bordered />;
};
