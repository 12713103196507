import {
  ChevronDown,
  ChevronDownBold,
  ChevronLeft,
  ChevronLeftBold,
  ChevronRight,
  ChevronRightBold,
  ChevronUp,
  ChevronUpBold,
} from './chevron';
import { BaseIconProps } from './types/iconography.types';

interface Props extends BaseIconProps {
  direction: 'up' | 'down' | 'left' | 'right';
  bold?: boolean;
}

/**
 ```tsx
  <ChevronIcon
    direction="up"
    color="blue500"
  />
 ```
 */
export const ChevronIcon = (props: Props) => {
  if (props.bold) {
    return createBoldElement(props);
  }

  return createBasicElement(props);
};

const createBasicElement = ({ className, direction, color }: Props) => {
  switch (direction) {
    case 'up':
      return <ChevronUp className={className} color={color} />;
    case 'down':
      return <ChevronDown className={className} color={color} />;
    case 'left':
      return <ChevronLeft className={className} color={color} />;
    case 'right':
      return <ChevronRight className={className} color={color} />;
  }
};

const createBoldElement = ({ className, direction, color }: Props) => {
  switch (direction) {
    case 'up':
      return <ChevronUpBold className={className} color={color} />;
    case 'down':
      return <ChevronDownBold className={className} color={color} />;
    case 'left':
      return <ChevronLeftBold className={className} color={color} />;
    case 'right':
      return <ChevronRightBold className={className} color={color} />;
  }
};
