import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FlexBox = styled.div<{
  flex: string;
  flexFlow: string;
  space: boolean;
}>`
  display: flex;
  flex: ${({ flex }) => flex || 0};
  flex-flow: ${({ flexFlow }) => flexFlow || 'row'};

  ${({ space }) =>
    space &&
    css`
      padding-right: 10px;
    `}
`;
