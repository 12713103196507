import styled from '@emotion/styled';
import { useCallback } from 'react';
import { ClearIndicatorProps, components, DropdownIndicatorProps, MultiValueProps, OptionProps } from 'react-select';
import { ChevronIcon, CloseIcon, RemoveIcon } from '../../foundations';
import { Badge } from '../badge';
import { badgeColor } from '../badge/Badge.constants';
import { CheckBox } from '../check-box';
import { MultiSelectOptionType } from './MultiSelect.types';

export const MultiSelectComponents = () => {
  const handleCheckBoxChange = useCallback(
    (props: OptionProps<MultiSelectOptionType>) => () => {
      props.setValue(props.getValue(), 'select-option');
    },
    [],
  );

  const selectComponent = {
    ClearIndicator: (props: ClearIndicatorProps<MultiSelectOptionType>) => {
      return (
        <components.ClearIndicator {...props}>
          <CloseIcon size="medium" color="gray500" />
        </components.ClearIndicator>
      );
    },

    DropdownIndicator: ({ selectProps }: DropdownIndicatorProps<MultiSelectOptionType>) => {
      const { menuIsOpen } = selectProps;
      return <ChevronIcon direction={menuIsOpen ? 'up' : 'down'} />;
    },
    Option: (props: OptionProps<MultiSelectOptionType>) => {
      return (
        <components.Option {...props} isSelected={props.isSelected}>
          <CheckBox size="sm" selected={props.isSelected} onChange={handleCheckBoxChange(props)} />
          {props.children}
        </components.Option>
      );
    },
    MultiValue: (props: MultiValueProps<MultiSelectOptionType>) => {
      return (
        <Badge size="s" color="gray">
          {props.children}

          <MultiValueRemoveContainer onClick={props.removeProps.onClick}>
            <NonClickableRemoveIcon size="sm" color={badgeColor.gray} />
          </MultiValueRemoveContainer>
        </Badge>
      );
    },
  };
  return selectComponent;
};

const MultiValueRemoveContainer = styled.div`
  display: inline;
  margin-left: 6px;
  cursor: pointer;
`;

const NonClickableRemoveIcon = styled(RemoveIcon)`
  pointer-events: none;
`;
