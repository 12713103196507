import { EventEmitterPopupProps } from '../../components/Popup/Popup.types';
import { PopupAction } from '../../hooks';
import { emitter, Events } from './emitter';

interface Dispatcher {
  dispatch: (value: PopupAction) => void;
}

export const dialogDispatcher = ({ dispatch }: Dispatcher) => {
  emitter.on(Events.SHOW, (popup: EventEmitterPopupProps) => {
    dispatch({ type: 'INIT', popup });
    setTimeout(() => dispatch({ type: 'SHOW', id: popup.id }), 200);
  });

  emitter.on(Events.HIDE, (id: string) => {
    dispatch({ type: 'HIDE', id });
    setTimeout(() => dispatch({ type: 'REMOVE', id }), 200);
  });
};
