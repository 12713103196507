import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

interface Props {
  className?: string;
}

export const ProgressBar = ({ className }: Props) => {
  return (
    <Container className={className}>
      <Block>
        <Bar />
      </Block>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 25px;
`;

const Block = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const play = keyframes`
  100% {
    background-position: -1000px;
  }
`;

const Bar = styled.div`
  font-size: 16px;
  font-weight: 500;

  &::before {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    margin: 0 auto 30px;
    background: url('//img.29cm.co.kr/next29cm/loading.png') 0 0;
    background-size: 1000px 25px;
    animation: ${play} 1.5s steps(40) infinite;
  }
`;
