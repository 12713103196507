import { Typography } from '@29cm/admin-design-system';
import { padding, position, size, Spacing, Stack } from '@29cm/admin-emotion-utils';
import styled from '@emotion/styled';
import Image from 'next/image';
import { FormStepCommonLayout } from '~apps/auth/login/components/dialogs/union-registration/form-step/form-step-common-layout/FormStepCommonLayout';
import { UNION_REGISTRATION_IMAGE_URL } from '~apps/auth/login/components/dialogs/union-registration/form-step/UnionRegistrationFormStep1.constants';
import { useVisibleTopRightTooltip } from '~apps/auth/login/components/dialogs/union-registration/form-step/UnionRegistrationFormStep1.hooks';

export const UnionRegistrationFormStep1 = () => {
  const { showTooltip } = useVisibleTopRightTooltip();

  return (
    <FormStepCommonLayout>
      <Image src={UNION_REGISTRATION_IMAGE_URL} alt="통합가입" width={300} height={208} />
      {showTooltip ? (
        <TopRightTooltip>
          <Typography variant="body/12-regular" color="white">
            이미 계정 인증을 완료하신 경우, 바로 로그인 해주세요.
          </Typography>
        </TopRightTooltip>
      ) : null}
      <Spacing size={46} />
      <Stack.Vertical gap={14}>
        <Typography variant="heading/26-bold" color="gray800">
          새로운 메뉴 이용을 위해 통합가입을 진행해 주세요.
        </Typography>
        <StyledTypography variant="body/15-regular" color="black">
          새로운 정산 기능으로 금액과 수량을 더욱 간편하게 확인하고,{'\n'}
          개선된 리뷰 기능으로 상품에 대한 고객 반응을 더욱 쉽게 확인하실 수 있습니다.{'\n'}
          기존 계정으로 간편하게 인증하고, 새로운 메뉴를 이용해 보세요.
        </StyledTypography>
      </Stack.Vertical>
    </FormStepCommonLayout>
  );
};

const StyledTypography = styled(Typography)`
  text-align: center;
  word-break: break-all;
  white-space: pre-wrap;
`;

const TopRightTooltip = styled.div`
  ${position('absolute', {
    top: 60,
    right: -14,
  })};
  ${padding({
    x: 14,
    y: 12,
  })};
  ${size({
    width: 284,
    height: 42,
  })};
  display: flex;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray800};

  &::after {
    content: '';
    ${position('absolute', {
      top: -6,
      right: '16%',
    })};
    transform: translateX(-50%);
    border-top: none;
    border-bottom: 6px solid ${({ theme }) => theme.colors.gray800};
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
`;
