import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { breakPoints, colors } from '../../core';
import { Button } from '../Button';
import { OverlaidPortal } from '../Portal';

type Theme = keyof typeof colors;

interface Props {
  className?: string;
  overlayCss?: SerializedStyles;
  open: boolean;
  /** 팝업 콘텐츠 백그라운드 컬러 */
  backgroundColor?: string;
  /** 오버레이 백그라운드 컬러 (기본값: 투명) */
  overlayColor?: string;
  /** 오버레이 클릭 시 닫힘 여부 */
  overlayCloseable?: boolean;
  /** 기본 콘텐츠 텍스트 정렬 (기본값: center) */
  textAlign?: 'left' | 'center' | 'right';
  /** 확인 버튼 텍스트 */
  successText?: React.ReactNode;
  /** 확인 버튼 배경색상 */
  successTheme?: Theme;
  /** 확인, 취소 버튼 숨김 유무 */
  hideButton?: boolean;
  /** 확인 버튼 눌렀을 때 액션 */
  onSuccess?: () => Promise<void> | void | null;
}

/**
 * 사용법
 * ```
 * import { popup } from '@29cm/design-systems';
 * ...
 * popup.show({
 *   ...props,
 * })
 * ```
 */
export const Popup = ({
  className,
  overlayCss,
  open,
  backgroundColor = colors.white,
  overlayColor = 'rgba(0,0,0,.4)',
  overlayCloseable = false,
  children,
  textAlign = 'left',
  successText = '닫기',
  successTheme = 'blue',
  hideButton,
  onSuccess,
}: React.PropsWithChildren<Props>) => {
  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <OverlaidPortal
      overlayCss={overlayCss}
      overlayColor={overlayColor}
      opened={open}
      onClose={onSuccess}
      closeable={overlayCloseable}
      alwaysVisibled
    >
      <Container className={className} backgroundColor={backgroundColor} open={open} onClick={stopPropagation}>
        <Content textAlign={textAlign}>{children}</Content>

        {!hideButton && (
          <ButtonContainer>
            <Button type="fill" onClick={onSuccess} theme={successTheme}>
              {successText}
            </Button>
          </ButtonContainer>
        )}
      </Container>
    </OverlaidPortal>
  );
};

const Container = styled.div<{
  open: boolean;
  backgroundColor?: string;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px;
  min-width: 300px;
  text-align: center;
  border: 1px solid ${colors.line_gray01};
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : colors.white)};
  transform: translate(-50%, -75%) scale(0.975);
  transition: all 250ms ease-out;
  opacity: 0;

  ${({ open }) =>
    open &&
    css`
      transform: translate(-50%, -50%) scale(1);
      transition: all 100ms ease-in;
      opacity: 1;
    `}

  ${breakPoints.mobile} {
    overflow: auto;
  }
`;

const Content = styled.div<{ textAlign: 'left' | 'center' | 'right' }>`
  font-size: 18px;
  color: ${colors.black};
  word-break: keep-all;
  white-space: pre-line;
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
  line-height: 1.4em;
`;

const ButtonContainer = styled.div`
  margin: 20px 0 0;
  display: flex;
  justify-content: flex-end;
`;
