import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import { Theme } from '../..';
import { Typography } from '../../foundations/typography';

interface Props extends TextareaAutosizeProps {
  className?: string;
  autoSize?: boolean;
  title?: string;
  hint?: string;
  hasError?: boolean;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ className, autoSize = true, title, hint, hasError, maxLength, value, ...restProps }, ref) => {
    const [overvalued, setOvervalued] = useState(false);
    const [valueCount, setValueCount] = useState('');

    useEffect(() => {
      if (maxLength && typeof value === 'string') {
        setOvervalued(maxLength < value.length);
        setValueCount(`${value.length}/${maxLength}`);
      }
    }, [hasError, maxLength, value]);

    return (
      <Container className={className} hasError={hasError || overvalued}>
        {title ? (
          <Title as="h3" variant="body/14-medium" color="gray700">
            {title}
          </Title>
        ) : null}

        <TextareaWrapper disabled={restProps.disabled}>
          {autoSize ? (
            <StyledAutoSizeTextarea ref={ref} minRows={3} maxRows={10} value={value} {...restProps} />
          ) : (
            <StyledTextarea ref={ref} value={value} {...restProps} />
          )}

          {maxLength ? (
            <TextCount variant="body/14-regular" color={overvalued ? 'red500' : 'gray500'}>
              {valueCount}
            </TextCount>
          ) : null}
        </TextareaWrapper>

        {hint ? (
          <Hint as="p" variant="body/14-regular" color={hasError ? 'red500' : 'gray500'}>
            {hint}
          </Hint>
        ) : null}

        {overvalued && !hint ? (
          <Hint as="p" variant="body/14-regular" color="red500">
            최대 {maxLength}자까지 입력할 수 있습니다.
          </Hint>
        ) : null}
      </Container>
    );
  },
);

const Container = styled.div<{ hasError?: boolean }>`
  font-family: Pretendard;

  ${({ theme, hasError }) =>
    hasError &&
    css`
      ${TextareaWrapper} {
        border-color: ${theme.colors.red500};
      }
    `}
`;

const TextareaWrapper = styled.div<{ disabled?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.gray400};
  border-radius: 4px;
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray100 : theme.colors.white)};
  display: flex;
  box-sizing: border-box;
  overflow: hidden;

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.blue500};
  }
`;

const textareaCss = (theme: Theme) => css`
  margin: 0;
  padding: 10.5px 13.5px;
  width: 100%;
  font-family: Pretendard;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: ${theme.colors.gray700};
  border: 0;
  appearance: none;
  outline: none;
  word-break: break-all;
  text-size-adjust: none;
  resize: none;

  &::placeholder {
    font-weight: 400;
    color: ${theme.colors.gray500};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledAutoSizeTextarea = styled(TextareaAutosize)`
  ${({ theme }) => textareaCss(theme)}
`;

const StyledTextarea = styled.textarea`
  ${({ theme }) => textareaCss(theme)}
`;

const Title = styled(Typography)`
  margin-top: 0;
  margin-bottom: 6px;
`;

const Hint = styled(Typography)`
  margin-top: 6px;
  margin-bottom: 0;
`;

const TextCount = styled(Typography)`
  padding: 10.5px 13.5px 10.5px 0;
`;
