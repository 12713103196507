import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getTypographyStyle } from '../../foundations/typography/styles';
import { CloseIcon } from '../../foundations/iconography/close/CloseIcon';
import { Badge } from '../badge/Badge';
import { badgeColor } from '../badge/Badge.constants';
import { useTagInput } from './TagInput.hooks';
import { TagInputSizeType, TagType } from './TagInput.types';
import { Typography } from '../../foundations/typography';

export interface TagInputProps {
  size: TagInputSizeType;
  tags: TagType[];
  title?: string;
  hint?: string;
  hasError?: boolean;
  className?: string;
  placeholder?: string;
  onTagsChange: (tags: TagType[]) => void;
}

export const TagInput = ({
  size,
  tags,
  onTagsChange,
  title,
  hasError,
  hint,
  className,
  placeholder,
}: TagInputProps) => {
  const hasTag = tags.length !== 0;

  const handleTagAdd = (tag: TagType) => {
    onTagsChange(tags.concat(tag));
  };

  const { text, handleTextChange, handleTextKeyDown } = useTagInput({
    onTagAdd: handleTagAdd,
  });

  const handleTagRemoveFunctor = (removingItemIndex: number) => () => {
    const nextTags = tags.filter((_, i) => i !== removingItemIndex);
    onTagsChange(nextTags);
  };

  return (
    <Root className={className} hasError={hasError}>
      {title ? (
        <Title variant="body/14-medium" color="gray700">
          {title}
        </Title>
      ) : null}

      <Container size={size}>
        {tags.map((tag, index) => (
          <StyledBadge
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            color="gray"
            size="s"
            labelCSS={BadgeLabelCSS}
            endIcon={<StyledCloseIcon size="small" color={badgeColor.gray} />}
            onIconClick={handleTagRemoveFunctor(index)}
          >
            {tag}
          </StyledBadge>
        ))}

        <InputWrapper hasTag={hasTag}>
          <Input value={text} onChange={handleTextChange} placeholder={placeholder} onKeyPress={handleTextKeyDown} />
        </InputWrapper>
      </Container>

      {hint ? (
        <Hint variant="body/14-regular" color="gray500">
          {hint}
        </Hint>
      ) : null}
    </Root>
  );
};

const Root = styled.div<{ hasError?: boolean }>`
  ${({ theme, hasError }) =>
    hasError &&
    css`
      ${Container} {
        border-color: ${theme.colors.red500};
      }

      ${Hint} {
        color: ${theme.colors.red500};
      }
    `}
`;

const Title = styled(Typography)`
  margin-top: 0;
  margin-bottom: 6px;
  display: inline-block;
`;

const Container = styled.div<{ size: TagInputSizeType }>`
  display: flex;
  flex-wrap: wrap;

  padding: ${({ size }) => (size === 'regular' ? '11px 15px 9px 15px' : '6px 15px 4px 15px')};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.gray400};
  border-radius: 4px;
`;

const StyledBadge = styled(Badge)`
  margin-bottom: 2px;
  margin-right: 2px;
  overflow: hidden;
`;

const BadgeLabelCSS = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCloseIcon = styled(CloseIcon)``;

const Hint = styled(Typography)`
  margin-top: 6px;
  margin-bottom: 0;
  display: inline-block;
`;

const InputWrapper = styled.div<{ hasTag: boolean; hasError?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex: 1 1 60px;

  width: auto;
  min-width: 60px;
  margin-left: ${({ hasTag }) => (hasTag ? '2px' : undefined)};
`;

const Input = styled.input`
  width: 100%;
  height: 24px;
  border: none;
  outline: none;

  ${getTypographyStyle('body/15-regular')}
`;
