import { Buffer } from 'buffer';

/**
 * Angular ruler base64 util migration.
 */
export const base64 = {
  encode(input: string): string {
    return Buffer.from(input).toString('base64');
  },
  decode(input: string): string {
    return Buffer.from(input, 'base64').toString();
  },
};
