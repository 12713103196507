import { BaseIconProps } from '../../types/iconography.types';
import { getColor } from '../../../../core/colors';

export const SmallCloseIcon = ({ className, color = 'blue500' }: BaseIconProps) => {
  return (
    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.853553 0.146447C0.658291 -0.0488155 0.341709 -0.0488155 0.146447 0.146447C-0.0488155 0.341709 -0.0488155 0.658291 0.146447 0.853553L2.29242 2.99952L0.146447 5.14549C-0.0488155 5.34076 -0.0488155 5.65734 0.146447 5.8526C0.341709 6.04786 0.658291 6.04786 0.853553 5.8526L2.99952 3.70663L5.14548 5.85259C5.34074 6.04785 5.65733 6.04785 5.85259 5.85259C6.04785 5.65733 6.04785 5.34074 5.85259 5.14548L3.70663 2.99952L5.85259 0.853565C6.04785 0.658303 6.04785 0.341721 5.85259 0.146459C5.65733 -0.0488033 5.34074 -0.0488033 5.14548 0.146459L2.99952 2.29242L0.853553 0.146447Z"
        fill={getColor(color)}
      />
    </svg>
  );
};
