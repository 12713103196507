import styled from '@emotion/styled';
import { getColor } from '../../core/colors';

const ColorFrame = styled.div<{ color: string }>`
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 50px;
  background: ${({ color }) => getColor(color)};
  margin-bottom: 4px;
`;

const ColorLabel = styled.p<{ labelColor: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ labelColor }) => getColor(labelColor || 'white')};
`;

export const ColorBox = ({ color, labelColor }: { color: string; labelColor: string }) => {
  return (
    <ColorFrame color={color}>
      <ColorLabel labelColor={labelColor}>{color}</ColorLabel>
    </ColorFrame>
  );
};
