import { StylesConfig } from 'react-select';
import { colors, getColor } from '../../core/colors';
import { theme } from '../../core/theme';
import { MultiSelectOptionType, MultiSelectSize } from './MultiSelect.types';

export const multiSelectStyles = (
  size: MultiSelectSize,
  hasError?: boolean,
  menuPortalZIndex?: number,
  borderColor?: keyof typeof colors,
) => {
  const sizeOptionHeight = size === 'md' ? 46 : 36;
  const controlYPadding = size === 'md' ? 11 : 6;
  const optionYPadding = size === 'md' ? 10 : 9;

  const focusedBorderColor = (isMenuOpen: boolean) => {
    return isMenuOpen ? theme.colors.blue500 : borderColor ? getColor(borderColor) : theme.colors.gray400;
  };

  const multiSelectStylesConfig: StylesConfig<MultiSelectOptionType> = {
    clearIndicator: (baseStyles) => ({
      ...baseStyles,
      transform: `scale(${12 / 16})`,
      cursor: 'pointer',
      padding: 0,
    }),
    indicatorSeparator: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: theme.colors.gray500,
      height: 16,
      margin: 'auto 8px',
      display: state.hasValue ? 'block' : 'none',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      padding: `${controlYPadding}px 15px`,
      minHeight: sizeOptionHeight,
      borderColor: hasError ? theme.colors.red500 : focusedBorderColor(state.menuIsOpen),
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? theme.colors.gray100 : undefined,
      alignItems: 'baseline',
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      padding: 0,
      gap: '2px',
    }),
    multiValueRemove: (baseStyles) => ({
      ...baseStyles,
      ':hover': {
        backgroundColor: theme.colors.gray300,
      },
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      borderRadius: 4,
      boxShadow: '4px 20px 38px rgba(82, 86, 108, 0.12)',
      zIndex: 6,
    }),
    menuPortal: (baseStyles) => ({ ...baseStyles, zIndex: menuPortalZIndex }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      ':active': {
        ...baseStyles[':active'],
        backgroundColor: theme.colors.gray100,
      },
      'padding': `${optionYPadding}px 16px`,
      'backgroundColor': state.isFocused ? theme.colors.gray100 : undefined,
      'color': theme.colors.gray700,
      'fontSize': '14px',
      'height': sizeOptionHeight,
      'minHeight': 36,
      'display': 'flex',
      'alignItems': 'center',
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      'paddingRight': 4,
      'paddingLeft': 4,
      '::-webkit-scrollbar': {
        width: 3,
      },
      '::-webkit-scrollbar-thumb': {
        background: '#a9a9a9',
        borderRadius: 3,
      },
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      margin: 0,
      fontSize: '14px',
      lineHeight: '22px',
      color: theme.colors.gray500,
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };
  return multiSelectStylesConfig;
};
