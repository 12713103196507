import { useContext } from 'react';

import { CookieContext } from './providers';

export const useCookieService = () => {
  const { cookieService } = useContext(CookieContext);

  if (cookieService === undefined) {
    throw new Error('CookieService에 접근할 수 없습니다. <CookieProvider>와 함께 쓰여야 합니다.');
  }

  return cookieService;
};
