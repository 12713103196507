import { useMemo } from 'react';
import { DefaultRecordType } from './Table.types';
import { formatIfNumber } from './Table.utils';
import { useTableContext } from './providers';

export const useTableHeader = <DataRecord extends DefaultRecordType>(data: readonly DataRecord[]) => {
  const { selection, updateSelectedAllRows } = useTableContext();

  const itemIdList = useMemo(
    () => data.filter((item) => item.key && !selection?.disabledRowKeys?.includes(item.key)).map((item) => item.key),
    [data, selection],
  );

  const selectedAll = useMemo(
    () =>
      data.reduce((map, item) => {
        if (item.key && !selection?.disabledRowKeys?.includes(item.key)) {
          map.set(item.key, item);
        }
        return map;
      }, new Map<React.Key, DataRecord>()),
    [data, selection],
  );

  const checked = itemIdList.length
    ? itemIdList.every((id) => (id ? selection?.selectedRowKeys.includes(formatIfNumber(id)) : false))
    : false;

  const handleChange = () => {
    if (!checked) {
      updateSelectedAllRows(selectedAll);

      const copied = new Map(selectedAll);
      selection?.onChange?.(Array.from(copied.keys()));
      return;
    }

    selection?.onChange?.([]);

    updateSelectedAllRows(new Map());
  };

  return {
    checked,
    selection,
    handleChange,
  };
};
