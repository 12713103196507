import type { NextApiRequest, NextPageContext } from 'next';
import { parseCookies } from 'nookies';

export type NextContextType =
  | Pick<NextPageContext, 'req'>
  | {
      req: NextApiRequest;
    };

export const getRefreshToken = (environment: 'dev' | 'qa' | undefined, ctx?: NextContextType) => {
  const cookies = parseCookies(ctx);
  const cookieSuffix = environment ? `_${environment}` : '';

  return cookies[`_frtn${cookieSuffix}`];
};
