import { refetchToken } from '@29cm/admin-utils';
import type { NextPageContext } from 'next';

export default class AuthTokenService {
  protected async getAccessToken(ctx?: NextPageContext) {
    const tokens = await refetchToken(ctx);

    if (!tokens) {
      return null;
    }

    return tokens.accessToken;
  }
}
