import { APIService } from '@29cm/admin-core/api';
import { PostVerificationParams } from '~apps/@shared/apis/join/JoinService.types';
import {
  GetVerificationResponse,
  ReRegisterParams,
  ResgiterParams,
  Result,
  SignInParams,
  SignInResponse,
  TwoFactorsSignInResponse,
  VerifyKeyResponse,
} from '~apps/@shared/apis/otp/OtpService.types';
import { CommonResponse } from '~apps/@shared/apis/user/UserService.types';
import { GetVerificationParams } from '~apps/join/apis/FindPasswordService.types';

const OTP_API_URL = '/inhouse-admin/v4/two-factors';
const OTP_API_URL_V2 = '/inhouse-admin/v5/two-factors';

class OtpService extends APIService {
  constructor() {
    super();
    this.useAuth();
  }

  public async register(params: ResgiterParams) {
    const res = await this.post<SignInResponse>(`${OTP_API_URL}/totp`, params);

    return res;
  }

  public async verifyKey(key: string) {
    const res = await this.post<VerifyKeyResponse>(`${OTP_API_URL}/totp:check`, { key });

    return res;
  }

  public async login(params: SignInParams) {
    const res = await this.post<TwoFactorsSignInResponse<Result>>(`${OTP_API_URL}/sign-in`, params, {
      withCredentials: true,
    });

    return res;
  }

  public async reset(params: PostVerificationParams) {
    const res = await this.post<VerifyKeyResponse>(`${OTP_API_URL_V2}/totp:reset`, params);

    return res;
  }

  public async getVerification({ code }: GetVerificationParams) {
    const res = await this.get<GetVerificationResponse>(`${OTP_API_URL_V2}/totp:reset-verify`, { code });

    return res;
  }

  public async reRegister(params: ReRegisterParams) {
    const res = await this.post<SignInResponse>(`${OTP_API_URL_V2}/totp:re-register`, params);

    return res;
  }

  /**
   * 유저의 마지막 로그인 시간
   */
  public async postUserPreviousLoginAccessTime() {
    const { data } = await this.post<CommonResponse<null>>('/inhouse-admin/v4/users/previous-login-access-time');

    return data;
  }
}

export const otpApi: OtpService = OtpService.shared();
