import { PropsWithChildren, useEffect, useState } from 'react';
import ReactDom from 'react-dom';

export const Portal = ({ children }: PropsWithChildren) => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return ReactDom.createPortal(children, document.body);
};
