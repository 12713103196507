import styled from '@emotion/styled';
import { MinusIcon } from '../Icons';

interface Props {
  className?: string;
  disabled?: React.ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  onClick?: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
}

export const MinusButton = ({ className, disabled, onClick }: Props) => {
  return (
    <Button className={className} type="button" onClick={onClick} disabled={disabled}>
      <MinusIcon />
    </Button>
  );
};

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.colors.legacy.blue};
  border-radius: 4px;

  &:disabled {
    background: ${({ theme }) => theme.colors.legacy.line_gray01};
    cursor: not-allowed;
  }
`;
