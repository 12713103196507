import { position } from '@29cm/admin-emotion-utils';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CheckBox } from '../check-box';
import { CellElement, Columns, DefaultRecordType, FlattenColumns, TableSize } from './Table.types';
import { TableCell } from './TableCell';
import { useTableHeader } from './TableHeader.hooks';

interface Props<DataRecord extends DefaultRecordType> {
  columns: Columns<DataRecord>;
  flattenColumns: FlattenColumns<DataRecord>;
  data: readonly DataRecord[];
  size: TableSize;
}

export const TableHeader = <DataRecord extends DefaultRecordType>({
  flattenColumns,
  data,
  size,
}: Props<DataRecord>) => {
  const { checked, selection, handleChange } = useTableHeader(data);

  return (
    <TableHead>
      <tr>
        {selection ? (
          <CheckBoxCell size={size}>
            <CheckBox selected={checked} onChange={handleChange} />
          </CheckBoxCell>
        ) : null}
        {flattenColumns.map((cell) => {
          return (
            <TableCell key={cell.key} column={cell} component={CellElement.TH} size={size} hasError={cell.hasError} />
          );
        })}
      </tr>
    </TableHead>
  );
};

const TableHead = styled.thead`
  ${position('sticky', {
    top: 0,
  })};
  z-index: 3;

  th:first-of-type {
    border-start-start-radius: 4px;
  }

  th:last-of-type {
    border-start-end-radius: 4px;
  }
`;

const CheckBoxCell = styled.th<{ size: TableSize }>`
  position: sticky;
  left: 0;
  color: ${({ theme }) => theme.colors.gray600};
  background: ${({ theme }) => theme.colors.gray100};
  border-right: ${({ theme }) => `1px solid ${theme.colors.gray300}`};

  ${({ size }) => {
    switch (size) {
      case 'sm': {
        return css`
          padding: 9px 12px;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
        `;
      }
      case 'md': {
        return css`
          padding: 12px 14px;
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
        `;
      }
    }
  }}
`;
