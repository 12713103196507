import { colors } from '../../../core';

interface Props {
  className?: string;
  fill?: string;
  strokeWidth?: number;
}

export const HelpIcon = ({ className, fill = colors.text_gray01 }: Props) => {
  return (
    <svg className={className} width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.82764 7.17529H2.20898C2.21322 6.79443 2.24284 6.46647 2.29785 6.19141C2.3571 5.91211 2.45654 5.66032 2.59619 5.43604C2.74007 5.21175 2.9305 4.98958 3.16748 4.76953C3.36637 4.5918 3.53988 4.42253 3.68799 4.26172C3.8361 4.10091 3.95247 3.93587 4.03711 3.7666C4.12174 3.5931 4.16406 3.40055 4.16406 3.18896C4.16406 2.94352 4.12598 2.7404 4.0498 2.57959C3.97786 2.41455 3.86784 2.28971 3.71973 2.20508C3.57585 2.12044 3.39388 2.07812 3.17383 2.07812C2.99186 2.07812 2.82259 2.11833 2.66602 2.19873C2.50944 2.2749 2.38037 2.39339 2.27881 2.5542C2.18148 2.71501 2.1307 2.92659 2.12646 3.18896H0.285645C0.29834 2.60921 0.431641 2.13102 0.685547 1.75439C0.943685 1.37354 1.28857 1.09212 1.72021 0.910156C2.15186 0.723958 2.63639 0.630859 3.17383 0.630859C3.76628 0.630859 4.27409 0.72819 4.69727 0.922852C5.12044 1.11328 5.44417 1.39469 5.66846 1.76709C5.89274 2.13525 6.00488 2.58382 6.00488 3.11279C6.00488 3.48096 5.93294 3.80892 5.78906 4.09668C5.64518 4.38021 5.45687 4.64469 5.22412 4.89014C4.99137 5.13558 4.73535 5.38949 4.45605 5.65186C4.21484 5.86768 4.0498 6.09408 3.96094 6.33105C3.8763 6.56803 3.83187 6.84945 3.82764 7.17529ZM2.01855 9.13672C2.01855 8.86589 2.11165 8.6416 2.29785 8.46387C2.48405 8.2819 2.73372 8.19092 3.04688 8.19092C3.35579 8.19092 3.60335 8.2819 3.78955 8.46387C3.97998 8.6416 4.0752 8.86589 4.0752 9.13672C4.0752 9.39909 3.97998 9.62126 3.78955 9.80322C3.60335 9.98519 3.35579 10.0762 3.04688 10.0762C2.73372 10.0762 2.48405 9.98519 2.29785 9.80322C2.11165 9.62126 2.01855 9.39909 2.01855 9.13672Z"
        fill={fill}
      />
    </svg>
  );
};
