import { GetServerSideProps } from 'next';
import { LoginPage } from '~apps/login/LoginPage';

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  return {
    props: {},
  };
};

export default LoginPage;
