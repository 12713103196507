import { useMemo } from 'react';
import { DefaultRecordType } from './Table.types';
import { formatIfNumber } from './Table.utils';
import { useTableContext } from './providers';

export const useTableBodyRow = <DataRecord extends DefaultRecordType>(key: React.Key, data: DataRecord) => {
  const { selection, addSelectedRow, removeSelectedRow } = useTableContext();
  const checked = selection?.selectedRowKeys.includes(formatIfNumber(key)) ?? false;

  const disabled = useMemo(() => selection?.disabledRowKeys?.includes(key) ?? false, [key, selection]);

  const handleChange = () => {
    if (checked) {
      removeSelectedRow(key);
    } else {
      addSelectedRow(key);
    }
  };

  return {
    checked,
    disabled,
    selection,
    handleChange,
  };
};
