import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import { DefaultRecordType, TableRowSelection } from '../Table.types';
import { formatIfNumber } from '../Table.utils';

interface TableContextProps {
  emptyText: string | undefined;
  selection?: TableRowSelection;
  addSelectedRow: (value: React.Key) => void;
  removeSelectedRow: (value: React.Key) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateSelectedAllRows: React.Dispatch<React.SetStateAction<any>>;
}

const TableContext = createContext<TableContextProps>({
  emptyText: undefined,
  selection: undefined,
  addSelectedRow: () => {},
  removeSelectedRow: () => {},
  updateSelectedAllRows: () => {},
});

const TableContextProvider = TableContext.Provider;

interface TableProviderProps {
  children: ReactNode;
  rowSelection?: TableRowSelection;
  emptyText?: string;
}

export const TableProvider = ({ rowSelection, emptyText = '검색 결과가 없습니다.', children }: TableProviderProps) => {
  const addSelectedRow = useCallback(
    (value: React.Key) => {
      if (rowSelection) {
        const newSelectedRowKeys = [...rowSelection.selectedRowKeys, value];
        rowSelection?.onChange?.(newSelectedRowKeys);
      }
    },
    [rowSelection],
  );

  const removeSelectedRow = useCallback(
    (value: React.Key) => {
      if (rowSelection) {
        const newSelectedRowKeys = rowSelection?.selectedRowKeys.filter(
          (selectedRowKey) => selectedRowKey !== formatIfNumber(value),
        );
        rowSelection?.onChange?.(newSelectedRowKeys);
      }
    },
    [rowSelection],
  );

  const updateSelectedAllRows = useCallback(
    (value: React.Key[]) => {
      rowSelection?.onChange?.(value);
    },
    [rowSelection],
  );

  const value = useMemo(
    () => ({
      emptyText,
      selection: rowSelection,
      addSelectedRow,
      removeSelectedRow,
      updateSelectedAllRows,
    }),
    [emptyText, rowSelection, addSelectedRow, removeSelectedRow, updateSelectedAllRows],
  );

  return <TableContextProvider value={value}>{children}</TableContextProvider>;
};

TableProvider.displayName = 'TableProvider';

export const useTableContext = <DataRecord extends DefaultRecordType>() => {
  const context = useContext(TableContext);

  if (!context) {
    throw new Error(`${TableProvider.displayName}와 함께 사용해야 합니다.`);
  }

  return context;
};
