import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  ExistingInformationInitialState,
  ExistingInformationState,
} from '~apps/login/stores/existing-information/existingInformation.types';

const initialState: ExistingInformationInitialState = {
  id: {
    value: '',
  },
  email: {
    value: '',
  },
};

export const useExistingInformationStore = create(
  immer<ExistingInformationState>((set) => ({
    ...initialState,
    dispatchId: (key, value) => {
      set((state) => {
        state.id[key] = value;
      });
    },
    dispatchEmail: (key, value) => {
      set((state) => {
        state.email[key] = value;
      });
    },
    dispatchInitialize: () => set(initialState),
  })),
);
