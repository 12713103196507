import create from 'zustand';
import {
  UnionRegistrationInitialState,
  UnionRegistrationState,
} from '~apps/login/stores/union-registration/unionRegistration.types';

const initialState: UnionRegistrationInitialState = {
  currentStep: 1,
};

export const useUnionRegistrationStore = create<UnionRegistrationState>((set) => ({
  ...initialState,
  dispatchStep: (newStep) => set({ currentStep: newStep }),
}));
