import { css } from '@emotion/react';
import { Theme } from '..';

/**
 * ref. https://fkhadra.github.io/react-toastify/how-to-style/
 */

export const toastStyle = (theme: Theme) => css`
  /** Used to define container behavior: width, position: fixed etc... **/
  .Toastify__toast-container {
    min-width: 420px;
  }

  /** Used to define the position of the ToastContainer **/
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-right {
    top: 86px;
  }

  .Toastify__toast-container--top-center {
    left: calc(50% + 100px);

    &.collapsed {
      left: 50%;
    }
  }
  .Toastify__toast-container--top-right {
  }
  .Toastify__toast-container--bottom-left {
  }
  .Toastify__toast-container--bottom-center {
    left: calc(50% + 100px);
    width: calc(100% - 290px);

    &.collapsed {
      left: 50%;
      width: calc(100% - 90px);
    }
  }
  .Toastify__toast-container--bottom-right {
  }

  /** Classes for the displayed toast **/
  .Toastify__toast {
    min-height: 74px;
    background: ${theme.colors.white};
    border-radius: 8px;
    box-shadow: 4px 20px 38px rgba(82, 86, 108, 0.12);
    padding: 24px 30px;
  }

  .Toastify__toast--rtl {
  }
  .Toastify__toast-body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${theme.colors.gray700};
    padding: 0;
  }

  /** Used to position the icon **/
  .Toastify__toast-icon {
    margin-inline-end: 12px;
  }

  /** handle the notificaiton color and the text color based on the theme **/
  .Toastify__toast-theme--dark {
  }
  .Toastify__toast-theme--light {
  }
  .Toastify__toast-theme--colored.Toastify__toast--default {
  }
  .Toastify__toast-theme--colored.Toastify__toast--info {
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
  }
  .Toastify__toast-theme--colored.Toastify__toast--warning {
  }
  .Toastify__toast-theme--colored.Toastify__toast--error {
  }

  .Toastify__progress-bar {
  }
  .Toastify__progress-bar--rtl {
  }
  .Toastify__progress-bar-theme--light {
  }
  .Toastify__progress-bar-theme--dark {
  }
  .Toastify__progress-bar--info {
  }
  .Toastify__progress-bar--success {
  }
  .Toastify__progress-bar--warning {
  }
  .Toastify__progress-bar--error {
  }
  /** colored notifications share the same progress bar color **/
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  }

  /** Classes for the close button. Better use your own closeButton **/
  .Toastify__close-button {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
  }
  .Toastify__close-button--default {
  }
  .Toastify__close-button > svg {
  }
  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
  }
`;
