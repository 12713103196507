import { Stack, size } from '@29cm/admin-emotion-utils';
import styled from '@emotion/styled';

type Props = {
  className?: string;
  totalStep: number;
  currentStep: number;
};

export const StepIndicator = ({ className, totalStep, currentStep }: Props) => {
  return (
    <Stack.Horizontal className={className} as="ol" gap={6}>
      {Array.from({ length: totalStep }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index} isFilled={index <= currentStep - 1} />
      ))}
    </Stack.Horizontal>
  );
};

const Box = styled.li<{ isFilled: boolean }>`
  ${size({
    width: 30,
    height: 4,
  })};
  background-color: ${({ theme, isFilled }) => theme.colors[isFilled ? 'blue500' : 'gray500']};
`;
