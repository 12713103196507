import env from '@29cm/admin-env';
import { isServer } from '@29cm/admin-utils';
import * as Sentry from '@sentry/nextjs';
import type { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import axios from 'axios';
import qs from 'qs';
import APIError from './APIError';
import AuthTokenService from './AuthTokenService';

export type Headers = Record<string, string>;

export interface APIResponse<T> {
  status: number;
  data: T;
  headers: Headers;
}

interface SystemError {
  code: string;
  message: string;
}

type APIRequestConfig = AxiosRequestConfig & {
  withCredentials?: boolean;
  logging?: boolean;
  ignoreLoggingStatueCodes?: number[];
};

const trimSlash = (str = '') => `${str}`.replace(/^\/+|\/+$/g, '');

const trimLeftSlash = (str = '') => `${str}`.replace(/^\/+/g, '').replace(/\/+$/g, '/');

export class APIService extends AuthTokenService {
  baseUrl: string;

  headers: Headers = {
    'Content-Type': 'application/json',
  };

  withCredentials = false;

  static $instance: APIService;

  public static shared<T extends APIService>(): T {
    if (this.$instance) {
      return this.$instance as T;
    }

    this.$instance = new this();
    return this.$instance as T;
  }

  constructor(baseUrl?: string) {
    super();
    this.baseUrl = trimSlash(baseUrl);
  }

  public setBaseUrl(url: string) {
    this.baseUrl = trimSlash(url);
  }

  public setWithCredentials(withCredentials: boolean) {
    this.withCredentials = withCredentials;
  }

  public useApiHub() {
    this.setBaseUrl(env.api.apihub);
  }

  public useItem() {
    this.setBaseUrl(env.api.item);
  }

  public useBrand() {
    this.setBaseUrl(env.api.brand);
  }

  public useBooking() {
    this.setBaseUrl(env.api.booking);
  }

  public useAuth() {
    this.setBaseUrl(env.api.auth);
  }

  public useContent() {
    this.setBaseUrl(env.api.content);
  }

  public useReview() {
    this.setBaseUrl(env.api.review);
  }

  public useSettlement() {
    this.setBaseUrl(env.api.settlement);
  }

  public useLogistics() {
    this.setBaseUrl(env.api.logistics);
  }

  public useClaim() {
    this.setBaseUrl(env.api.claim);
  }

  public usePromotion() {
    this.setBaseUrl(env.api.promotion);
  }

  public usePayment() {
    this.setBaseUrl(env.api.payment);
  }

  public useActivation() {
    this.setBaseUrl(env.api.activation);
  }

  public useMileage() {
    this.setBaseUrl(env.api.mileage);
  }

  public usePartner() {
    this.setBaseUrl(env.api.partner);
  }

  public useCommerce() {
    this.setBaseUrl(env.api.commerce);
  }

  public useTagAdmin() {
    this.setBaseUrl(env.api.tagAdmin)
  }

  public useUser() {
    this.setBaseUrl(env.api.user)
  }

  private showAlert(message: string) {
    if (isServer()) {
      return;
    }

    alert(message);
  }

  async request<R = unknown, P = unknown>(method: Method, path: string, data?: P, config?: APIRequestConfig) {
    const headers: Headers = {
      ...this.headers,
      ...((config?.headers as Headers | undefined) ?? {}),
    };

    const {
      logging = true,
      ignoreLoggingStatueCodes,
      withCredentials = this.withCredentials,
      ...restConfig
    } = config ?? {};

    let accessToken = null;

    try {
      accessToken = await this.getAccessToken();
    } catch (error) {
      const err = error as SystemError;

      if (err.message === 'EXPIRED_TOKEN') {
        this.showAlert('토큰이 만료되었습니다.\n로그인 페이지로 이동합니다.');
        window.location.replace('/login');
      } else {
        this.showAlert(err.message);
      }
    }

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const options: AxiosRequestConfig = {
      withCredentials,
      ...(restConfig ?? {}),
      method,
      headers,
      url: `${this.baseUrl}/${trimLeftSlash(path)}`,
      paramsSerializer: (params) =>
        qs.stringify(params, {
          arrayFormat: 'repeat',
        }),
    };

    if (['POST', 'PUT', 'DELETE', 'PATCH'].includes(method)) {
      options.data = data;
    } else {
      options.params = data;
    }

    return axios(options)
      .then((res: AxiosResponse<R>) => {
        return {
          status: res.status,
          data: res.data,
          headers: res.headers,
        } as APIResponse<R>;
      })
      .catch((error: AxiosError<R>) => {
        if (error.response) {
          const { status } = error.response;
          const requestUrl = error.config.url ?? '';
          const figerprint = `${method.toUpperCase()} ${requestUrl} [${status}]`;
          const sentryAllow = logging && !ignoreLoggingStatueCodes?.includes(status);
          const apiError = new APIError<R>({
            status,
            message: `${figerprint} (sentry-allow: ${String(sentryAllow)})`,
            code: error.code,
            data: error.response.data,
          });

          if (sentryAllow) {
            Sentry.captureException(apiError, {
              tags: {
                type: 'api',
              },
              extra: {
                error,
                status: apiError.status,
                code: apiError.code,
                response: apiError.data,
              },
              fingerprint: [figerprint],
            });
          }

          throw apiError;
        }

        Sentry.captureException(error);
        throw error;
      });
  }

  get<R, P = unknown>(path: string, data?: P, config?: APIRequestConfig) {
    return this.request<R, P>('GET', path, data, config);
  }

  post<R, P = unknown>(path: string, data?: P, config?: APIRequestConfig) {
    return this.request<R, P>('POST', path, data, config);
  }

  upload<R>(path: string, formData: FormData, config?: APIRequestConfig) {
    return this.request<R>('POST', path, formData, {
      ...(config ?? {}),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  put<R, P = unknown>(path: string, data?: P, config?: APIRequestConfig) {
    return this.request<R, P>('PUT', path, data, config);
  }

  delete<R>(path: string, config?: APIRequestConfig) {
    return this.request<R>('DELETE', path, config);
  }

  patch<R, P = unknown>(path: string, data?: P, config?: APIRequestConfig) {
    return this.request<R, P>('PATCH', path, data, config);
  }
}

export default APIService;
