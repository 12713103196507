import { useCallback, useState } from 'react';
import debounce from 'lodash-es/debounce';
import { useReceiveMessage } from '~apps/@shared/hooks/useReceiveMessage';

type ReceiveMessageIframe = {
  minHeight: number;
  enabled?: boolean;
  scrollTop?: number;
};

export const useReceiveMessageIframe = () => {
  const [minHeight, setMinHeight] = useState<number>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetMinHeight = useCallback(debounce(setMinHeight, 300), []);

  useReceiveMessage<ReceiveMessageIframe>(({ data: { type, payload } }) => {
    if (type === 'resizeIframe') {
      debouncedSetMinHeight(payload.minHeight);
    }
    if (type === 'lockedBodyScroll') {
      document.body.style.overflow = payload.enabled ? 'hidden' : 'auto';
    }

    if (type === 'scrollTop') {
      window.scrollTo({ top: payload.scrollTop });
    }
  });

  return {
    minHeight,
  };
};
