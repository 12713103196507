import { useReducer } from 'react';
import { EventEmitterPopupProps } from '../components/Popup/Popup.types';

export type PopupAction =
  | { type: 'INIT'; popup: EventEmitterPopupProps }
  | { type: 'SHOW' | 'HIDE' | 'REMOVE'; id: string };

interface State {
  popups: EventEmitterPopupProps[];
}

const initialState: State = {
  popups: [],
};

const popupReducer = (state: State, action: PopupAction): State => {
  switch (action.type) {
    case 'INIT': {
      const alreadyPopup = state.popups.some((popup) => popup.id === action.popup.id);

      if (alreadyPopup) {
        return state;
      }

      return {
        ...state,
        popups: [...state.popups, action.popup],
      };
    }
    case 'SHOW': {
      return {
        ...state,
        popups: state.popups.map((popup) => {
          if (popup.id !== action.id) {
            return popup;
          }
          return {
            ...popup,
            open: true,
          };
        }),
      };
    }
    case 'HIDE': {
      return {
        ...state,
        popups: state.popups.map((popup) => {
          if (popup.id !== action.id) {
            return popup;
          }
          return {
            ...popup,
            open: false,
          };
        }),
      };
    }
    case 'REMOVE': {
      return {
        ...state,
        popups: [...state.popups.filter((popup) => popup.id !== action.id)],
      };
    }
    default:
      throw new Error();
  }
};

export const usePopupEventEmitter = () => {
  const [state, dispatch] = useReducer(popupReducer, initialState);
  return { ...state, dispatch };
};
