import config from 'next/config';

import { env as development } from './env.development';
import { env as production } from './env.production';
import { env as qa } from './env.qa';

// eslint-disable-next-line
const { publicRuntimeConfig: runtimeEnv = {} } = config() ?? {};

type AppEnvironment = 'development' | 'qa' | 'production';

interface RuntimeEnviroment {
  APP_ENV: AppEnvironment;
  TAGS: string;
}

export const namespace =
  (runtimeEnv as RuntimeEnviroment).APP_ENV ??
  process.env.APP_ENV ??
  process.env.NODE_ENV ??
  process.env.STORYBOOK_ENV ??
  'development';

export const tag =
  (runtimeEnv as RuntimeEnviroment).TAGS ?? process.env.NEXT_PUBLIC_TAG ?? process.env.TAGS ?? process.env.revision;

const environments = {
  development,
  qa,
  production,
};

const baseEnvironment = environments[namespace] ?? environments.development;

export type Environment = typeof baseEnvironment;

export const env = baseEnvironment;

export default baseEnvironment;
