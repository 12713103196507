import { useCallback, useRef } from 'react';

export const useScrollObserver = (
  cb: ({
    scrollHeight,
    clientHeight,
    scrollTop,
  }: {
    scrollHeight: number;
    clientHeight: number;
    scrollTop: number;
  }) => void,
) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const onScroll = () => {
    if (!ref.current) {
      return;
    }

    const { scrollHeight, clientHeight, scrollTop } = ref.current;
    cb({ scrollHeight, clientHeight, scrollTop });
  };

  return useCallback((node: HTMLDivElement | null) => {
    if (node) {
      ref.current = node;
      ref.current.addEventListener('scroll', onScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
