import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

export const ErrorIcon = ({ className, color = 'red500' }: BaseIconProps) => {
  const fill = getColor(color);

  return (
    <svg
      width="22"
      height="22"
      className={className}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill={fill} />
      <path
        d="M6.28033 5.21967C5.98744 4.92678 5.51256 4.92678 5.21967 5.21967C4.92678 5.51256 4.92678 5.98744 5.21967 6.28033L9.99264 11.0533L5.21967 15.8263C4.92678 16.1192 4.92678 16.594 5.21967 16.8869C5.51256 17.1798 5.98744 17.1798 6.28033 16.8869L11.0533 12.114L15.8263 16.8869C16.1192 17.1798 16.594 17.1798 16.8869 16.8869C17.1798 16.594 17.1798 16.1192 16.8869 15.8263L12.114 11.0533L16.8869 6.28033C17.1798 5.98744 17.1798 5.51256 16.8869 5.21967C16.594 4.92678 16.1192 4.92678 15.8263 5.21967L11.0533 9.99264L6.28033 5.21967Z"
        fill="white"
      />
    </svg>
  );
};
