import { differenceInCalendarDays } from 'date-fns';
import isMatch from 'date-fns/isMatch';
import parse from 'date-fns/parse';
import React, { useEffect, useMemo, useState } from 'react';
import { useDateInputBoxContext } from './providers';
import { getDiffDaysFromNow, isAfterDate, isBeforeDate } from './utils';

interface Params {
  hasError?: boolean;
}

export const DATE_FORMAT = 'yyyy.MM.dd';

export enum HasDateError {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
  PREV_DATE = 'PREV_DATE',
  PREVENT_BEFORE_DATE = 'PREVENT_BEFORE_DATE',
  PREVENT_AFTER_DATE = 'PREVENT_AFTER_DATE',
}

export const useDateInputBoxHandler = ({ hasError = false }: Params) => {
  const { handleChange: onChange } = useDateInputBoxContext();
  const {
    value,
    isNeedPrevDatePrevents,
    preventBeforeDate,
    preventAfterDate,
    syncCalendarFromInputBox,
    updateBeginDate,
    updateEndDate,
    updateTextValue,
    updateNeedEndDate,
    updateShowCalendarPopup,
  } = useDateInputBoxContext();

  const [dateError, setError] = useState<HasDateError>(HasDateError.NONE);

  useEffect(() => {
    if (hasError) {
      setError(HasDateError.DEFAULT);
    } else {
      setError(HasDateError.NONE);
    }
  }, [hasError]);

  useEffect(() => {
    if (value === '') {
      setError(HasDateError.NONE);
      updateBeginDate(null);
      updateEndDate(null);
      return;
    }

    const rangeDateList = value.split(' - ');

    const isSomeInvalidDateFormat = rangeDateList.some((text) => isMatch(text.trim(), DATE_FORMAT) === false);
    if (isSomeInvalidDateFormat) {
      setError(HasDateError.DEFAULT);
      return;
    }

    const [beginDate, endDate] = rangeDateList;

    const hasPreventBeforeDateError =
      preventBeforeDate && isBeforeDate(parse(beginDate, DATE_FORMAT, new Date()), preventBeforeDate);

    if (hasPreventBeforeDateError) {
      setError(HasDateError.PREVENT_BEFORE_DATE);
      return;
    }

    const hasPreventAfterDateError =
      preventAfterDate && isAfterDate(parse(endDate ?? beginDate, DATE_FORMAT, new Date()), preventAfterDate);

    if (hasPreventAfterDateError) {
      setError(HasDateError.PREVENT_AFTER_DATE);
      return;
    }

    const diff = getDiffDaysFromNow({ date: beginDate, type: DATE_FORMAT });
    if (isNeedPrevDatePrevents && diff > 0) {
      setError(HasDateError.PREV_DATE);
      return;
    }

    if (hasError) {
      setError(HasDateError.DEFAULT);
      return;
    }

    setError(HasDateError.NONE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;

    if (key === 'Backspace') {
      return;
    }

    if (key === '-') {
      return;
    }

    /**
     * 아래 케이스에 대응
     * - 20221010
     * - 2022.10.10
     */
    const rangeDateList = value.split('-');

    const rangeDateListSeparator = rangeDateList.length === 1 ? ' -' : ' - ';
    const rangeDateText = rangeDateList
      .map((text) => {
        const dateText = text.trim();

        if (/^\d{4}$/.test(dateText)) {
          return dateText + '.';
        }

        if (/^\d{4}.\d{2}$/.test(dateText)) {
          return dateText + '.';
        }

        return dateText;
      })
      .join(rangeDateListSeparator);

    updateTextValue(rangeDateText);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value;

    if (targetValue.length === 0) {
      onChange?.(targetValue);
    }

    if (targetValue.lastIndexOf(' ') === targetValue.length - 1) {
      updateTextValue(targetValue);
      return;
    }

    const nextValue = targetValue
      .replace(/[^\d|.|-]/g, '')
      .replace(/\.+/g, '.')
      .replace(/-+/g, '-');

    if (nextValue.lastIndexOf('-') === nextValue.length - 1) {
      updateTextValue(nextValue.replace(/\s?-/, ' -'));
      return;
    }

    const rangeDateList = nextValue.split('-');

    if (rangeDateList.length > 2) {
      return;
    }

    updateNeedEndDate(rangeDateList.length >= 2);

    const rangeDateListSeparator = rangeDateList.length === 1 ? ' -' : ' - ';

    const rangeDateText = rangeDateList
      .map((text) => {
        const dateText = text.trim();

        if (/\.\d{2}$/.test(dateText) === false) {
          return dateText.slice(0, DATE_FORMAT.length);
        }

        return dateText;
      })
      .join(rangeDateListSeparator);

    updateTextValue(rangeDateText);
    syncCalendarFromInputBox(rangeDateText);

    e.target.value = rangeDateText;

    onChange?.(rangeDateText);
  };

  const handleClick = () => {
    updateShowCalendarPopup(true);
  };

  return {
    hasError: dateError,
    value,
    handleClick,
    handleKeyDown,
    handleChange,
  };
};

export const useDateInputBoxRangeValue = (value: string) => {
  return useMemo(() => {
    const [beginDateText, endDateText] = value.split('-');

    const beginDate = beginDateText ? parse(beginDateText, DATE_FORMAT, new Date()) : null;
    const endDate = endDateText ? parse(endDateText, DATE_FORMAT, new Date()) : null;

    return {
      beginDate,
      endDate,
    };
  }, [value]);
};
