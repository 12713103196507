import styled from '@emotion/styled';
import { RemoveIcon } from '../../../foundations/iconography';
import { toast, ToastContainer as BaseToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  className?: string;
}

export const ToastContainer = ({ className }: Props) => {
  return (
    <Container className={className} position={toast.POSITION.TOP_RIGHT} closeButton={<RemoveIcon />} hideProgressBar />
  );
};

const Container = styled(BaseToastContainer)``;
