import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

interface Props extends BaseIconProps {
  strokeWidth?: number;
}

/**
 ```tsx
  <ArrowIcon 
    color="white" 
  />
 ```
 */
export const ArrowIcon = ({ className, color = 'gray700', strokeWidth = 1.5 }: Props) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 2L9 15" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14 11L9 16L4 11"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
