import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Theme } from '../../../core/theme';
import { Typography } from '../../../foundations/typography';
import { CalendarDate } from '../models';
import { useDateInputBoxContext } from '../providers';
import { useDateBoxHandler, useSelectedDateBox } from './CalendarDateBox.hooks';
import { position, size } from '@29cm/admin-emotion-utils';
import { ZIndex } from '../../../constants';

interface Props {
  calendarDate: CalendarDate;
}

export const CalendarDateBox = ({ calendarDate }: Props) => {
  const { endDate } = useDateInputBoxContext();
  const { handleClick } = useDateBoxHandler(calendarDate);

  const selectedProps = useSelectedDateBox(calendarDate);
  const hasEndDate = Boolean(endDate);

  return (
    <Container>
      <StyledButton onClick={handleClick}>
        <Background hasEndDate={hasEndDate} {...selectedProps} />
        <DayDate variant="body/13-regular" color={'gray700'} {...selectedProps}>
          {calendarDate.dayDate}
        </DayDate>
      </StyledButton>
    </Container>
  );
};

export const DisabledCalendarDateBox = ({ calendarDate }: Props) => {
  const { endDate } = useDateInputBoxContext();

  const selectedProps = useSelectedDateBox(calendarDate);
  const hasEndDate = Boolean(endDate);

  return (
    <Container>
      <StyledDisabledButton>
        <Background hasEndDate={hasEndDate} {...selectedProps} />
        <DayDate variant="body/13-regular" color={'gray500'} {...selectedProps}>
          {calendarDate.dayDate}
        </DayDate>
      </StyledDisabledButton>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  width: 14.28571%;
`;

const StyledButton = styled.button`
  position: relative;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  margin: 4px;
  border: 0;
  outline: none;
`;

const StyledDisabledButton = styled(StyledButton)`
  cursor: default;
  user-select: none;
`;

const Background = styled.div<{
  isBegin: boolean;
  isBetween: boolean;
  isEnd: boolean;
  isToday: boolean;
  hasEndDate: boolean;
}>`
  ${position('absolute', {
    top: 0,
    bottom: 0,
  })}
  display: none;
  content: '';
  z-index: ${ZIndex.Float};
  background: ${({ theme }) => theme.colors.blue100};

  ${({ isBegin, isBetween, isEnd, isToday, theme }) => {
    if (isBegin && isEnd) {
      return css`
        right: 0;
        left: 0;
        display: block;
        border-radius: 29px;
      `;
    }

    if (isBegin) {
      return css`
        right: -4px;
        left: 0;
        display: block;
        border-top-left-radius: 29px;
        border-bottom-left-radius: 29px;
      `;
    }

    if (isEnd) {
      return css`
        right: 0;
        left: -4px;
        display: block;
        border-top-right-radius: 29px;
        border-bottom-right-radius: 29px;
      `;
    }

    if (isBetween) {
      return css`
        right: -4px;
        left: -4px;
        display: block;
      `;
    }

    if (isToday) {
      return css`
        right: 0;
        left: 0;
        display: block;
        background: ${theme.colors.gray100};
        border-radius: 29px;
      `;
    }
  }}

  ${({ isBegin, hasEndDate }) =>
    isBegin &&
    !hasEndDate &&
    css`
      right: 0;
      border-top-right-radius: 29px;
      border-bottom-right-radius: 29px;
    `}
`;

const selectedTextCss = (theme: Theme) => css`
  font-weight: 600;
  color: ${theme.colors.blue500};
`;

const DayDate = styled(Typography)<{
  isBegin?: boolean;
  isBetween?: boolean;
  isEnd?: boolean;
}>`
  ${position.absolute(0, 0, 0, 0)};
  ${size.full}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  ${({ theme, isBegin }) =>
    isBegin &&
    css`
      ${selectedTextCss(theme)}
    `};

  ${({ theme, isBetween }) =>
    isBetween &&
    css`
      ${selectedTextCss(theme)}
    `};

  ${({ theme, isEnd }) =>
    isEnd &&
    css`
      ${selectedTextCss(theme)}
    `};
`;
