import { css, SerializedStyles } from '@emotion/react';
import { KeyOfTypographyVariantType, TypographyVariantType } from '../types/variant.types';

const typographyVariants: Record<TypographyVariantType, SerializedStyles> = {
  [TypographyVariantType['heading/26-bold']]: css`
    font-size: 26px;
    font-weight: 700;
    line-height: 31px;
  `,
  [TypographyVariantType['heading/24-medium']]: css`
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  `,
  [TypographyVariantType['heading/22-semiBold']]: css`
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
  `,
  [TypographyVariantType['body/18-semiBold']]: css`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  `,
  [TypographyVariantType['body/16-semiBold']]: css`
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  `,
  [TypographyVariantType['body/15-semiBold']]: css`
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
  `,
  [TypographyVariantType['body/15-regular']]: css`
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
  `,
  [TypographyVariantType['body/14-semiBold']]: css`
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  `,
  [TypographyVariantType['body/14-bold']]: css`
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  `,
  [TypographyVariantType['body/14-medium']]: css`
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  `,
  [TypographyVariantType['body/14-regular']]: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  `,
  [TypographyVariantType['body/14-regular-table']]: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
  [TypographyVariantType['body/13-semiBold']]: css`
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
  `,
  [TypographyVariantType['body/13-regular']]: css`
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
  `,
  [TypographyVariantType['body/12-medium']]: css`
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  `,
  [TypographyVariantType['body/12-regular']]: css`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  `,
};

export const getTypographyStyle = (variant: KeyOfTypographyVariantType): SerializedStyles => {
  return typographyVariants[TypographyVariantType[variant]];
};
