import { getColor } from '../..';
import { BaseIconProps } from '../../foundations/iconography/types/iconography.types';

export const CheckIcon = ({ className, color = 'white' }: BaseIconProps) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 6.5L7.40351 12L16 2" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
