import { AudienceType, createPagePropsWithAuth, withCookies } from '@29cm/admin-utils';
import { NextApp } from '@inhouse/core';
import 'antd/dist/antd.less';
import { NextPage } from 'next';
import { AppContext, AppProps } from 'next/app';
import { ReactElement, ReactNode } from 'react';
import { env } from '~env';

interface PageProps {
  $cookie: string;
  dehydratedState: unknown;
}

interface Props extends AppProps<PageProps> {
  pageProps: PageProps;
}

type NextPageWithLayout = NextPage<PageProps> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type PropsWithLayout = Props & {
  Component: NextPageWithLayout;
};

const WORKSPACE = 'index';
const WORKSPACE_NAME = '@inhouse/auth';

const App = ({ Component, pageProps }: PropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  return getLayout(
    <NextApp
      workspace={WORKSPACE}
      environment={env}
      dehydratedState={pageProps.dehydratedState}
      serverCookies={pageProps.$cookie}
    >
      <Component {...pageProps} />
    </NextApp>,
  );
};

const AUTH_WHITE_LIST = ['/login', '/otp', '/join', '/reset-password'];

App.getInitialProps = withCookies(async (appContext: AppContext) => {
  const headers = appContext.ctx.req?.headers;
  const asPath = appContext.ctx.asPath;

  // 헬스체크는 쿠키 검사가 필요없습니다
  if (appContext.ctx.pathname === '/lifecycle/health') {
    return {
      pageProps: {},
    };
  }

  if (asPath && AUTH_WHITE_LIST.some((path) => asPath.startsWith(path))) {
    return {
      pageProps: {},
    };
  }

  return createPagePropsWithAuth({
    context: appContext,
    cookie: headers?.cookie ?? '',
    audience: AudienceType.INHOUSE,
  });
});

export default App;
