import styled from '@emotion/styled';
import { useTableContext } from './providers';

interface Props {
  colSpan: number;
}

export const TableBodyEmpty = ({ colSpan }: Props) => {
  const { emptyText } = useTableContext();

  return (
    <tr>
      <TdCell colSpan={colSpan}>{emptyText}</TdCell>
    </tr>
  );
};

const TdCell = styled.td<{ lastSticky?: boolean }>`
  box-sizing: content-box;
  height: 100px;
  padding-top: 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.gray700};
  text-align: center !important;
  background: ${({ theme }) => theme.colors.white};
`;
