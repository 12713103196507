import { Flex } from '@29cm/admin-emotion-utils';
import { PropsWithChildren } from 'react';

export const FormStepCommonLayout = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <Flex direction="column" align="center">
      {children}
    </Flex>
  );
};
