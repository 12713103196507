import { Button, Typography, ChevronLeft } from '@29cm/admin-design-system';
import { Flex, Spacing, position, margin, flex } from '@29cm/admin-emotion-utils';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import { TOTAL_STEP_COUNT } from '~apps/login/components/dialogs/union-registration/bottom-container/BottomContainer.constants';
import {
  useCurrentStep,
  useShowBackButton,
  useClickButton,
} from '~apps/login/components/dialogs/union-registration/bottom-container/BottomContainer.hooks';
import { StepIndicator } from '~apps/login/components/dialogs/union-registration/bottom-container/step-indicator/StepIndicator';

type Props = {
  rightButton: {
    content: ComponentProps<typeof Button>['children'];
    onClick: ComponentProps<typeof Button>['onClick'];
  };
};

export const BottomContainer = ({ rightButton }: Props) => {
  const { showBackButton } = useShowBackButton();
  const { currentStep } = useCurrentStep();
  const { handlePrevButtonClick } = useClickButton();

  return (
    <Container justify="space-between" align="center">
      {showBackButton ? (
        <button type="button" onClick={handlePrevButtonClick}>
          <PreviousTypography variant="body/14-regular" color="gray700">
            <ChevronLeft color="gray700" /> 이전
          </PreviousTypography>
        </button>
      ) : (
        <Spacing size={0} />
      )}

      <StyledStepIndicator totalStep={TOTAL_STEP_COUNT} currentStep={currentStep} />

      <Button size="md" onClick={rightButton.onClick}>
        {rightButton.content}
      </Button>
    </Container>
  );
};

const Container = styled(Flex)`
  position: relative;
  ${margin({
    y: 30,
  })}
`;

const StyledStepIndicator = styled(StepIndicator)`
  ${position('absolute', {
    left: '50%',
    top: '50%',
  })}
  transform: translate3d(-50%, -50%, 0);
`;

const PreviousTypography = styled(Typography)`
  ${flex({ align: 'center' })};
  gap: 4px;
`;
