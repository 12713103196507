import { DialogAction } from '../../hooks';
import { EventEmitterDialogProps } from '../../components/Dialog/Dialog.types';
import { emitter, Events } from './emitter';

interface Dispatcher {
  dispatch: (value: DialogAction) => void;
}

export const dialogDispatcher = ({ dispatch }: Dispatcher) => {
  emitter.on(Events.SHOW, (dialog: EventEmitterDialogProps) => {
    dispatch({ type: 'INIT', dialog });
    setTimeout(() => dispatch({ type: 'SHOW', id: dialog.id }), 200);
  });

  emitter.on(Events.HIDE, (id: string) => {
    dispatch({ type: 'HIDE', id });
    setTimeout(() => dispatch({ type: 'REMOVE', id }), 200);
  });
};
