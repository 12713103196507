import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';

interface Props {
  children: React.ReactNode;
}

export const Portal: React.FC<Props> = ({ children }) => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return ReactDom.createPortal(children, document.body);
};
