import { SetStateAction, useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { isServer } from '../ssr';

interface StorageData<T> {
  value: T;
  expired?: number;
}

/**
 * useLocalStorage
 *
 * const [value, setValue] = useLocalStorage(STORAGE_KEY, initialValue);
 * const handleTestClick = () => setValue('~~');
 *
 * @param key string
 * @param initialValue T
 * @returns T
 */
export const useLocalStorage = <T>(
  key: string,
  initialValue?: T,
): [T | undefined, (value: SetStateAction<T | undefined>, expired?: Date | number) => void] => {
  const readValue = () => {
    if (isServer()) {
      return initialValue;
    }
    try {
      const item = LocalStorage.shared().getItem(key);
      if (!item) {
        return initialValue;
      }

      const parsedItem = JSON.parse(item) as StorageData<T>;
      if (parsedItem.expired && parsedItem.expired <= Date.now()) {
        LocalStorage.shared().removeItem(key);
        return undefined;
      }
      return parsedItem.value;
    } catch (error) {
      console.error({
        text: `Error reading localStorage key “${key}”`,
      });
      return initialValue;
    }
  };

  const [storedValue, setStoredValue] = useState<T | undefined>(readValue);

  const setValue = useCallback((value: SetStateAction<T | undefined>, expired?: Date | number) => {
    if (isServer()) {
      console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
    }
    setStoredValue((prevValue) => {
      try {
        const nextValue = value instanceof Function ? value(storedValue) : value;
        if (isEqual(prevValue, nextValue)) {
          return prevValue;
        }
        if (nextValue) {
          LocalStorage.shared().setItem(
            key,
            JSON.stringify({
              value: nextValue,
              expired: getExpired(expired),
            }),
          );
          return nextValue;
        }
        LocalStorage.shared().removeItem(key);
        return undefined;
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”`);
        return prevValue;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshValue = (prevValue?: T) => {
    const nextValue = readValue();
    if (isEqual(prevValue, nextValue)) {
      return prevValue;
    }
    return nextValue;
  };

  useEffect(() => {
    setStoredValue(refreshValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(refreshValue);
    };
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storedValue, setValue];
};

const getExpired = (expired?: Date | number) => {
  if (!expired) {
    return undefined;
  }
  if (expired instanceof Date) {
    return expired.getTime();
  }
  return Date.now() + expired * 1000;
};

class MemoryStorage {
  storage = new Map<string, string>();

  getItem(key: string) {
    if (this.storage.has(key)) {
      return this.storage.get(key) ?? null;
    }
    return null;
  }

  setItem(key: string, value: string) {
    this.storage.set(key, value);
  }

  removeItem(key: string) {
    this.storage.delete(key);
  }

  get length() {
    return this.storage.size;
  }
}

class LocalStorage {
  static $instance: Storage | MemoryStorage;

  static shared(): Storage | MemoryStorage {
    if (LocalStorage.$instance) {
      return LocalStorage.$instance;
    }
    const localStorage = new LocalStorage();
    LocalStorage.$instance = localStorage.getLocalStorage();
    return LocalStorage.$instance;
  }

  private getLocalStorage = (): Storage | MemoryStorage => {
    try {
      if (isServer()) {
        return new MemoryStorage();
      }
      if (!('localStorage' in window)) {
        return new MemoryStorage();
      }
      const key = '29cm:supported-storage';
      window.localStorage.setItem(key, 'true');
      window.localStorage.removeItem(key);
      return window.localStorage;
    } catch (e) {
      return new MemoryStorage();
    }
  };
}
