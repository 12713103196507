import { useReducer } from 'react';
import { EventEmitterDialogProps } from '../components/Dialog/Dialog.types';

export const DIALOG_APPLY_ID = 'DIALOG_APPLY';

export type DialogAction =
  | { type: 'INIT'; dialog: EventEmitterDialogProps }
  | { type: 'SHOW' | 'HIDE' | 'REMOVE'; id: string };

interface State {
  dialogs: EventEmitterDialogProps[];
  index: number;
}

const initialState: State = {
  dialogs: [],
  index: 0,
};

const dialogReducer = (state: State, action: DialogAction): State => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        dialogs: [...state.dialogs, action.dialog],
        index: state.index + 1,
      };
    case 'SHOW': {
      return {
        ...state,
        dialogs: state.dialogs.map((dialog) => {
          if (dialog.id !== action.id) {
            return dialog;
          }
          return {
            ...dialog,
            open: true,
          };
        }),
      };
    }
    case 'HIDE': {
      if (state.index === 2) {
        return {
          ...state,
          dialogs: state.dialogs.map((dialog) => {
            if (dialog.id !== DIALOG_APPLY_ID) {
              return dialog;
            }
            return {
              ...dialog,
              open: false,
            };
          }),
        };
      }
      return {
        ...state,
        dialogs: state.dialogs.map((dialog) => {
          if (dialog.id !== action.id) {
            return dialog;
          }
          return {
            ...dialog,
            open: false,
          };
        }),
      };
    }
    case 'REMOVE': {
      if (state.index === 2) {
        return {
          ...state,
          dialogs: [...state.dialogs.filter((item) => item.id !== DIALOG_APPLY_ID)],
          index: state.index - 1,
        };
      }
      return {
        ...state,
        dialogs: [...state.dialogs.filter((dialog) => dialog.id !== action.id)],
        index: state.index === 0 ? 0 : state.index - 1,
      };
    }
    default:
      throw new Error();
  }
};

export const useDialogEventEmitter = () => {
  const [state, dispatch] = useReducer(dialogReducer, initialState);
  return { ...state, dispatch };
};
