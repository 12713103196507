import styled from '@emotion/styled';

interface Props {
  className?: string;
}

export const MinusIcon = ({ className }: Props) => {
  return <Bar className={className} />;
};

const Bar = styled.i`
  display: inline-block;
  width: 14px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.legacy.white};
  border-radius: 2px;
`;
