import debounce from 'lodash-es/debounce';
import throttle from 'lodash-es/throttle';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { Columns, DefaultRecordType, FlattenColumns } from './Table.types';
import { useTableContext } from './providers';

export const useColumns = <DataRecord extends DefaultRecordType>(
  columns: Columns<DataRecord>,
  isHorizontalScroll: boolean,
  tableWidth?: number,
): FlattenColumns<DataRecord> => {
  const tableRefWidth = useMemo(() => tableWidth, [tableWidth]) as number;
  const { selection } = useTableContext();

  return (
    columns.reduce((list, column, currentIndex) => {
      const { sticky } = column;

      if (!sticky) {
        return [...list, { ...column }];
      }

      let stickyOffset = selection ? 40 : 0;
      let lastSticky = false;
      const width = parseNumber(column.width, tableRefWidth) ?? '*';

      if (currentIndex !== 0) {
        const prevOffset = list[currentIndex - 1].stickyOffset ?? 0;
        const prevWidth = parseNumber(list[currentIndex - 1].width, tableRefWidth);

        const nextColumn = columns[currentIndex + 1];

        lastSticky = !(nextColumn && nextColumn.sticky);

        if (prevWidth) {
          stickyOffset = prevOffset === 0 ? prevWidth : prevOffset + prevWidth;
        }
      }

      return [...list, { ...column, width, stickyOffset, lastSticky }];
    }, [] as FlattenColumns<DataRecord>) ?? []
  );
};

export const useTableResize = (
  tableWrapperRef: RefObject<HTMLElement>,
  tableRef: RefObject<HTMLElement>,
  defaultWidth?: number,
) => {
  const [tableWidth, setTableWidth] = useState<number>(defaultWidth ?? 1000);
  const [showBackground, setShowBackground] = useState<boolean>(false);
  const [isHorizontalScroll, setIsHorizontalScroll] = useState<boolean>(false);

  const handleScroll = () => {
    if (tableWrapperRef.current) {
      const scrollWidth = tableWrapperRef.current.scrollWidth;
      const clientWidth = tableWrapperRef.current.clientWidth;
      const scrollLeft = tableWrapperRef.current.scrollLeft;

      if (scrollWidth === clientWidth) {
        setIsHorizontalScroll(false);
        setShowBackground(false);
        return;
      }

      if (scrollWidth - clientWidth === scrollLeft) {
        setShowBackground(false);
        return;
      }

      setIsHorizontalScroll(true);
      setShowBackground(true);
    }
  };

  const debouncedScroll = debounce(handleScroll, 200);

  useEffect(() => {
    if (tableWrapperRef.current && tableRef.current) {
      setIsHorizontalScroll(tableWrapperRef.current.clientWidth !== tableRef.current.clientWidth);
      setShowBackground(tableWrapperRef.current.clientWidth !== tableRef.current.clientWidth);
    }
  }, [tableWrapperRef, tableRef]);

  useEffect(() => {
    const handleResize = throttle(() => {
      if (tableRef.current) {
        setTableWidth(tableRef.current.clientWidth);
      }

      if (tableWrapperRef.current && tableRef.current) {
        setIsHorizontalScroll(tableWrapperRef.current.clientWidth !== tableRef.current.clientWidth);
        setShowBackground(tableWrapperRef.current.clientWidth !== tableRef.current.clientWidth);
      }
    }, 300);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [tableWrapperRef, tableRef, tableWidth]);

  return { tableWidth, showBackground, isHorizontalScroll, debouncedScroll };
};

export const useInitScrollLeftPosition = <DataRecord extends DefaultRecordType>(
  initScrollLeftPosition: number,
  tableWrapperRef: RefObject<HTMLElement>,
  data: readonly DataRecord[],
) => {
  useEffect(() => {
    if (!tableWrapperRef.current) {
      return;
    }

    const scrollLeft = tableWrapperRef.current.scrollLeft;

    if (scrollLeft !== initScrollLeftPosition) {
      tableWrapperRef.current.scrollLeft = initScrollLeftPosition;
    }
  }, [initScrollLeftPosition, tableWrapperRef, data]);
};

const parseNumber = (number: number | string | undefined, tableRefWidth: number) => {
  if (!number || number === '' || number === '*') {
    return;
  }

  if (typeof number === 'number') {
    return number;
  }

  if (!/[0-9]/g.test(number)) {
    return 0;
  }

  const replaceNumber = +number.replace(/[^0-9.]/g, '');

  if (number.includes('%')) {
    return +(tableRefWidth * (replaceNumber / 100)).toFixed(2);
  }

  return replaceNumber;
};
