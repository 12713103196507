import React, { useRef } from 'react';

import { CookieService } from '../services';
import { CookieContext } from './CookieContext';

export interface CookieServiceProviderProps {
  cookie: string;
  children?: React.ReactNode;
}

export const CookieProvider = React.memo<CookieServiceProviderProps>(({ cookie, children }) => {
  const cookieService = useRef(new CookieService(cookie)).current;

  return (
    <CookieContext.Provider
      value={{
        cookieService,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
});

CookieProvider.displayName = 'CookieProvider';
