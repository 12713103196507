import { env } from '@29cm/admin-env';

export const getHostPrefix = () => {
  if (env.development) {
    return 'dev-';
  }

  if (env.qa) {
    return 'qa-';
  }

  return '';
};
