import { Typography } from '@29cm/admin-design-system';
import { Spacing, Stack } from '@29cm/admin-emotion-utils';
import styled from '@emotion/styled';
import { EmailSentImage } from '~apps/@shared/components/Image';
import { FormStepCommonLayout } from '~apps/login/components/dialogs/union-registration/form-step/form-step-common-layout/FormStepCommonLayout';
import { useEmail } from '~apps/login/components/dialogs/union-registration/form-step/UnionRegistrationFormStep3.hooks';

export const UnionRegistrationFormStep3 = () => {
  const { email } = useEmail();

  return (
    <FormStepCommonLayout>
      <EmailSentImage width={184} height={180} />
      <Spacing size={52} />
      <Stack.Vertical gap={14}>
        <Typography variant="heading/26-bold" color="gray800">
          통합가입을 위한 인증 이메일이 발송되었습니다.
        </Typography>

        <StyledTypography variant="body/15-regular" color="black">
          <strong>{email}</strong>로 인증 링크가 발송되었습니다. {'\n'}클릭하여 비밀번호 설정 후 인증을 완료해 주세요.
        </StyledTypography>
      </Stack.Vertical>
      <Spacing size={20} />
    </FormStepCommonLayout>
  );
};

const StyledTypography = styled(Typography)`
  text-align: center;
  word-break: break-all;
  white-space: pre-wrap;
`;
