import { ComponentProps } from 'react';
import { Dialog } from './Dialog';

type Props = ComponentProps<typeof Dialog>;

export interface CreateDialogParams extends Omit<Props, 'open' | 'children'> {
  id?: string;
  content: React.ReactNode;
}

export interface EventEmitterDialogProps extends Omit<CreateDialogParams, 'id'> {
  id: string;
  open: boolean;
}

export const enum DialogSize {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  XLARGE = 'xlg',
}

export type SizeValue = 'sm' | 'md' | 'lg' | 'xlg';

export type DialogSetCautionType = (id: string, content: string) => void;
