import styled from '@emotion/styled';
import { NextIcon } from '../Icons';
import { colors } from '../../core';
import { Link } from '../Link';
import { css } from '@emotion/react';

interface Props {
  className?: string;
  title: string;
  titleColor?: string;
  content: string | number;
  contentColor?: string;
  href?: string;
  onClick?: () => void;
}

export const ItemInfoBox = ({ className, title, titleColor, content, contentColor, href, onClick }: Props) => {
  if (href) {
    return (
      <StyledLink className={className} href={href} onClick={onClick}>
        <div>
          <Title color={titleColor}>{title}</Title>
          <Content color={contentColor}>{content}</Content>
        </div>
        <div>
          <NextIcon />
        </div>
      </StyledLink>
    );
  }

  const cursor = onClick ? 'pointer' : 'default';
  const role = onClick ? 'button' : 'none';

  return (
    <Container className={className} cursor={cursor} onClick={onClick} role={role}>
      <div>
        <Title color={titleColor}>{title}</Title>
        <Content color={contentColor}>{content}</Content>
      </div>
      {onClick ? (
        <div>
          <NextIcon />
        </div>
      ) : null}
    </Container>
  );
};

const boxCss = css`
  padding: 20px;
  width: 242px;
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${colors.line_gray01};
  border-radius: 4px;
  background-color: ${colors.white};
  line-height: 1;
`;

const Container = styled.div<{ cursor: string }>`
  ${boxCss}
  cursor: ${({ cursor }) => cursor ?? 'default'};
`;

const StyledLink = styled(Link)`
  ${boxCss}
`;

const Title = styled.h2<{ color?: string }>`
  margin-bottom: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${({ color }) => (color ? color : colors.primary)};
`;

const Content = styled.p<{ color?: string }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({ color }) => (color ? color : colors.primary)};
`;
