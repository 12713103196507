import { Spacing } from '@29cm/admin-emotion-utils';
import styled from '@emotion/styled';
import {
  ClearIndicatorProps,
  components,
  ControlProps,
  DropdownIndicatorProps,
  IndicatorSeparatorProps,
  OptionProps,
} from 'react-select';
import { Typography } from '../..';
import { ChevronIcon, CloseIcon } from '../../foundations/iconography';
import { OptionType } from './Select';

type SelectIndicatorParams = {
  hint?: string;
  hasError: boolean;
  title?: string;
  subTitle?: string;
  isClearable: boolean;
};

export const SelectIndicator = ({ hint, hasError, title, subTitle, isClearable }: SelectIndicatorParams) => {
  const selectComponent = {
    ClearIndicator: (props: ClearIndicatorProps<OptionType>) => {
      return (
        <components.ClearIndicator {...props}>
          <CloseIcon size="medium" color="gray500" />
        </components.ClearIndicator>
      );
    },
    IndicatorSeparator: (props: IndicatorSeparatorProps<OptionType>) => {
      return isClearable ? <components.IndicatorSeparator {...props} /> : null;
    },
    DropdownIndicator: ({ children, ...props }: DropdownIndicatorProps<OptionType>) => {
      const { menuIsOpen } = props.selectProps;
      return (
        <components.DropdownIndicator {...props}>
          <ChevronIcon direction={menuIsOpen ? 'up' : 'down'} />
        </components.DropdownIndicator>
      );
    },
    Control: (props: ControlProps<OptionType>) => {
      return (
        <div>
          {title ? (
            <>
              <Typography variant="body/14-medium">
                <Title>{title}</Title>
                {subTitle ? <SubTitle>&nbsp;{subTitle}</SubTitle> : null}
              </Typography>
              <Spacing size={6} />
            </>
          ) : null}
          <components.Control {...props} />
          {hint && (
            <>
              <Spacing size={6} />
              <Typography variant="body/14-regular" color={hasError ? 'red500' : 'gray500'}>
                {hint}
              </Typography>
            </>
          )}
        </div>
      );
    },
    Option: (props: OptionProps<OptionType>) => {
      return (
        <components.Option {...props} isSelected={props.isSelected}>
          {props.children}
        </components.Option>
      );
    },
  };
  return selectComponent;
};

const Title = styled.span`
  color: ${({ theme }) => theme.colors.gray700};
`;
const SubTitle = styled.span`
  color: ${({ theme }) => theme.colors.gray500};
`;
