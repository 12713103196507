import { useEffect } from 'react';
import { useSideMenuStore } from '@inhouse/core';

export const useControlSideMenuCollapse = () => {
  const dispatchCollapse = useSideMenuStore((state) => state.dispatchCollapse);

  useEffect(() => {
    dispatchCollapse(true);

    return () => {
      dispatchCollapse(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
