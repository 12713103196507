import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

export const EditIcon = ({ className, color = 'gray600' }: BaseIconProps) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.89471 3.8804L10.1194 6.10509M2.33301 11.6668L5.03106 11.1232C5.17429 11.0943 5.3058 11.0238 5.40909 10.9205L11.4489 4.87729C11.7385 4.58756 11.7383 4.11791 11.4485 3.82841L10.169 2.5504C9.87934 2.26102 9.40992 2.26122 9.12046 2.55084L3.07999 8.59463C2.9769 8.69777 2.90651 8.82902 2.87763 8.97195L2.33301 11.6668Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line x1="7.5" y1="11.5557" x2="11.9444" y2="11.5557" stroke={stroke} strokeLinecap="round" />
    </svg>
  );
};
