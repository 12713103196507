import styled from '@emotion/styled';
import { ButtonHTMLAttributes } from 'react';
import { TopIcon } from '../Icons';

interface Props {
  className?: string;
  disabled?: ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
}

export const TopButton = ({ className, disabled, onClick }: Props) => {
  return (
    <Button className={className} type="button" onClick={onClick} disabled={disabled}>
      <TopIcon />
    </Button>
  );
};

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.colors.legacy.blue};
  border-radius: 4px;

  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.legacy.line_gray01};
  }
`;
