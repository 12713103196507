import { APIService } from '@29cm/admin-core/api';
import { LoginParams, LoginResponse } from '~apps/@shared/apis/login/LoginService.types';

class LoginService extends APIService {
  constructor() {
    super();
    this.useAuth();
  }

  public async login({ loginId, password }: LoginParams) {
    const res = await this.post<LoginResponse>('/inhouse-admin/v4/users/sign-in', { loginId, password });

    return res;
  }
}

export const loginApi: LoginService = LoginService.shared();
