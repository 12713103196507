import { ChangeEvent, useEffect, useRef } from 'react';
import { AUTH_FORM_REGEXP } from '~apps/@shared/constants/authForm';
import { VERIFICATION_FORM_ERROR_MESSAGE } from '~apps/login/components/dialogs/union-registration/form-step/UnionRegistrationFormStep2.constants';
import { useExistingInformationStore } from '~apps/login/stores/existing-information/existingInformation.store';
import { InputCommonErrorCode } from '~apps/login/stores/existing-information/existingInformation.types';

export const useId = () => {
  const { id, dispatchId } = useExistingInformationStore((state) => ({
    id: state.id,
    dispatchId: state.dispatchId,
  }));

  const handleIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    dispatchId('value', newValue);

    if (AUTH_FORM_REGEXP.ID.test(newValue)) {
      dispatchId('error', undefined);
    } else {
      dispatchId('error', InputCommonErrorCode.REGEX);
    }
  };

  return {
    id: id.value,
    idErrorMessage: id.error && VERIFICATION_FORM_ERROR_MESSAGE[id.error].id,
    handleIdChange,
  };
};

export const useEmail = () => {
  const { email, dispatchEmail } = useExistingInformationStore((state) => ({
    email: state.email,
    dispatchEmail: state.dispatchEmail,
  }));

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    dispatchEmail('value', e.currentTarget.value);

    if (AUTH_FORM_REGEXP.EMAIL.test(newValue)) {
      dispatchEmail('error', undefined);
    } else {
      dispatchEmail('error', InputCommonErrorCode.REGEX);
    }
  };

  return {
    email: email.value,
    emailErrorMessage: email.error && VERIFICATION_FORM_ERROR_MESSAGE[email.error].email,
    handleEmailChange,
  };
};

export const useNudgeIdInput = () => {
  const idInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    idInputRef.current?.focus();
  }, [idInputRef]);

  return {
    idInputRef,
  };
};
