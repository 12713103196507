import { PropsWithChildren, useEffect } from 'react';
import styled from '@emotion/styled';
import { DropdownMenuSize } from '../DropdownMenu.types';
import { useDropdownMenuContext } from '../providers/DropdownMenuProvider';
import { useIsMounted } from '../../../hooks/useIsMounted';

type Props = {
  className?: string;
  onClose?: () => void;
};

export const Menu = ({ className, children, onClose }: PropsWithChildren<Props>) => {
  const { size, isToggled: open } = useDropdownMenuContext();

  const isMounted = useIsMounted();

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (!open) {
      onClose?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Container className={className} size={size}>
      {open ? children : null}
    </Container>
  );
};

const Container = styled.div<{ size: DropdownMenuSize }>`
  position: absolute;
  left: 0;
  top: ${({ size }) => (size === 'md' ? '52px' : '42px')};
  z-index: 10;

  width: 100%;
  min-width: 240px;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 4px 20px 38px rgba(82, 86, 108, 0.12);
  border-radius: 4px;
`;
