import { AuthenticationProvider, CookieProvider, EnvironmentProvider } from '@29cm/admin-contexts';
import { DialogContainer, globalStyle, theme, ThemeProvider, ToastContainer } from '@29cm/admin-design-system';
import { Environment } from '@29cm/admin-env';
import { DialogContainer as LegacyDialogContainer, PopupContainer } from '@29cm/design-systems';
import { FeatureFlagProvider } from '@29cm/feature-flag';
import { createQueryClient, Hydrate, QueryClientProvider, ReactQueryDevtools } from '@29cm/react-query';
import { Global } from '@emotion/react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import React, { useRef } from 'react';
import { useInitializeGoogleTagManager } from '../hooks';
import { useSideMenuStore } from '../stores/sideMenu.stores';
import { DebugReleaseInfo } from './debug';
import { NextAppChildren } from './NextAppChildren';

const LazyReactQueryDevtools = dynamic(() => import('@29cm/react-query').then((module) => module.ReactQueryDevtools), {
  ssr: false,
}) as typeof ReactQueryDevtools;

interface Props {
  environment: Environment;
  workspace: string;
  serverCookies: string;
  dehydratedState: unknown;
}

const isDevelopmentMode = process.env.NODE_ENV === 'development';

export const NextApp = ({
  environment,
  workspace,
  serverCookies,
  dehydratedState,
  children,
}: React.PropsWithChildren<Props>) => {
  useInitializeGoogleTagManager();

  const queryClientRef = useRef(createQueryClient());

  const unleashConfigRef = useRef({
    url: environment.sdk.unleash.proxy,
    clientKey: environment.sdk.unleash.clientSecret,
    appName: `front-admin-commerce-${workspace}`,
    environment: environment.name,
    refreshInterval: 60,
  });

  const collapsed = useSideMenuStore((state) => state.collapsed);

  return (
    <EnvironmentProvider environment={environment}>
      <QueryClientProvider client={queryClientRef.current}>
        <ThemeProvider theme={theme}>
          <Global styles={() => globalStyle(theme)} />
          <Hydrate state={dehydratedState}>
            <CookieProvider cookie={serverCookies}>
              <AuthenticationProvider>
                <FeatureFlagProvider config={unleashConfigRef.current}>
                  <ToastContainer className={classnames({ collapsed })} />
                  <DialogContainer />
                  <LegacyDialogContainer />
                  <PopupContainer />
                  <NextAppChildren>{children}</NextAppChildren>
                  <DebugReleaseInfo />
                </FeatureFlagProvider>
              </AuthenticationProvider>
            </CookieProvider>
          </Hydrate>
        </ThemeProvider>
        {isDevelopmentMode && <LazyReactQueryDevtools />}
      </QueryClientProvider>
    </EnvironmentProvider>
  );
};
