import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

export const PlusIcon = ({ className, color = 'gray500' }: BaseIconProps) => {
  const fill = getColor(color);

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.888889" y="7.11111" width="14.2222" height="1.77778" rx="0.888889" fill={fill} />
      <rect
        x="8.88889"
        y="0.888885"
        width="14.2222"
        height="1.77778"
        rx="0.888889"
        transform="rotate(90 8.88889 0.888885)"
        fill={fill}
      />
    </svg>
  );
};
