import React, { useCallback, useMemo, useRef } from 'react';
import { AuthService } from '../services';
import { useCookieService } from '../../cookies';
import { useEnvironments } from '../../environments';
import { AuthenticationContext } from './AuthenticationContext';

export interface AuthServiceProviderProps {
  children?: React.ReactNode;
}

export const AuthenticationProvider = React.memo<AuthServiceProviderProps>(({ children }) => {
  const cookie = useCookieService();
  const env = useEnvironments();
  const authService = useRef(new AuthService(cookie, env)).current;

  const currentUser = authService.getCurrentUser();

  const userInfo = useMemo(() => {
    return currentUser;
  }, [currentUser]);

  const handleLogout = useCallback(() => {
    authService.logout();
  }, [authService]);

  return (
    <AuthenticationContext.Provider
      value={{
        authService,
        isLoggedIn: currentUser.logged,
        loading: false,
        userInfo,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
});

AuthenticationProvider.displayName = 'AuthenticationProvider';
