import baseEnvironment from '@29cm/admin-env';

interface SentryProps {
  dsn: string;
}

export const createSentryConfig = ({ dsn }: SentryProps) => {
  if (!dsn) {
    return {};
  }

  return {
    normalizeDepth: 10,
    tracesSampleRate: 0.05,
    debug: baseEnvironment.development,
    dsn,
    enabled: true,
    environment: baseEnvironment.name,
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Safari extensions
      /^safari-extension:\/\//i,
      // Other plugins
      /hotjar\.com/,
    ],
    ignoreErrors: [
      /document.querySelector\('ui-root'\).innerHTML/,
      /\(sentry-allow: false\)/,
      'Non-Error exception captured',
      'Non-Error promise rejection captured',

      // BEGIN - 브라우저 네트워크 요청 취소 에러 ignore 처리
      // ref. https://github.com/getsentry/sentry/issues/12676
      'Network request failed',
      'Failed to fetch',
      'Network Error',
      'withrealtime/messaging',
      'Request aborted',
      '네트워크 연결이 유실되었습니다.',
      'The network connection was lost.',
      'Load failed',
      // END - 브라우저 네트워크 요청 취소 에러 ignore 처리
    ],
  };
};
