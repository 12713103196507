import type { Environment } from '@29cm/admin-env';
import React, { useMemo } from 'react';
import { EnvironmentContext } from './EnvironmentContext';

export interface EnvironmentProps<T extends Environment> {
  environment: T;
  children: React.ReactNode;
}

export function EnvironmentProvider<T extends Environment>({ environment, children }: EnvironmentProps<T>) {
  const value = useMemo(() => {
    return {
      environment,
    };
  }, [environment]);

  return <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>;
}

EnvironmentProvider.displayName = 'EnvironmentProvider';
