import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

interface Props extends BaseIconProps {
  strokeWidth?: number;
}

export const ExcelIcon = ({ className, color = 'blue500', strokeWidth = 1.4 }: Props) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.7 4.55816C1.7 3.42818 2.52083 2.46557 3.63662 2.28704L11.2946 1.06177C12.0845 0.935388 12.8 1.54553 12.8 2.34544V15.6546C12.8 16.4545 12.0845 17.0646 11.2946 16.9382L3.63662 15.713C2.52083 15.5344 1.7 14.5718 1.7 13.4418V4.55816Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      <path
        d="M12.5 2H15.3C15.6866 2 16 2.3134 16 2.7V15.3C16 15.6866 15.6866 16 15.3 16H12.5"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.21429 6H4.5L6.64286 9L4.5 12H6.21429L7.5 10.2L8.78571 12H10.5L8.35714 9L10.5 6H8.78571L7.5 7.8L6.21429 6Z"
        fill={stroke}
      />
    </svg>
  );
};
