import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

interface Props {
  className?: string;
}

export const ButtonGroup = ({ className, children }: PropsWithChildren<Props>) => {
  return <Container className={className}>{children}</Container>;
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 4px;
  padding: 16px;

  &::after {
    position: absolute;
    left: 0;
    top: 0;

    width: calc(100% - 32px);
    margin: 0 16px;

    border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    content: '';
  }
`;
