import { BaseIconProps, getColor } from '@29cm/admin-design-system';

export const NewIcon = ({ className, color = 'red500' }: BaseIconProps) => {
  const stroke = getColor(color);

  return (
    <svg className={className} width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.905 0.16H6.721V5.139L2.457 0.16H0.78V9H2.977V4.151L7.215 9H8.905V0.16Z" fill={stroke} />
    </svg>
  );
};
