import { getColor } from '../../../core/colors';
import { BaseIconProps } from '../types/iconography.types';

interface Props extends BaseIconProps {
  strokeWidth?: number;
}

export const ChevronUpBold = ({ className, color = 'gray500', strokeWidth = 2 }: Props) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.6667 10.6667L8 4L1.33333 10.6667" stroke={stroke} strokeWidth={strokeWidth} />
    </svg>
  );
};
