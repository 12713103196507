export const env = {
  'development': false,
  'qa': false,
  'production': true,
  'name': 'production',
  'image': {
    host: 'https://img.29cm.co.kr',
  },
  'asset': {
    host: 'https://asset.29cm.co.kr',
  },
  '29cm': {
    product: 'https://product.29cm.co.kr',
    shop: 'https://shop.29cm.co.kr',
  },
  'workspace': {
    inhouse: {
      index: 'https://inhouse-connect.29cm.co.kr',
      auth: 'https://inhouse-auth.29cm.co.kr',
      brand: 'https://inhouse-brand.29cm.co.kr',
      booking: 'https://inhouse-booking.29cm.co.kr',
      content: 'https://inhouse-content.29cm.co.kr',
      review: 'https://inhouse-review.29cm.co.kr',
      search: 'https://inhouse-search.29cm.co.kr',
      item: 'https://inhouse-item.29cm.co.kr',
      order: 'https://inhouse-order.29cm.co.kr',
      settlement: 'https://inhouse-settlement.29cm.co.kr',
      regulation: 'https://inhouse-regulation.29cm.co.kr',
      analytics: 'https://inhouse-analytics.29cm.co.kr',
      display: 'https://inhouse-display.29cm.co.kr',
    },
    partner: {
      index: 'https://partner-connect.29cm.co.kr',
      auth: 'https://partner-auth.29cm.co.kr',
      brand: 'https://partner-brand.29cm.co.kr',
      booking: 'https://partner-booking.29cm.co.kr',
      content: 'https://partner-content.29cm.co.kr',
      review: 'https://partner-review.29cm.co.kr',
      search: 'https://partner-search.29cm.co.kr',
      item: 'https://partner-item.29cm.co.kr',
      order: 'https://partner-order.29cm.co.kr',
      settlement: 'https://partner-settlement.29cm.co.kr',
      regulation: 'https://partner-regulation.29cm.co.kr',
      analytics: 'https://partner-analytics.29cm.co.kr',
    },
  },
  'api': {
    apihub: 'https://apihub.29cm.co.kr',
    item: 'https://item-admin.29cm.co.kr',
    brand: 'https://brand-api.29cm.co.kr',
    booking: 'https://booking-item-api.29cm.co.kr',
    auth: 'https://authentication-api.29cm.co.kr',
    content: 'https://content-api.29cm.co.kr',
    review: 'https://review-api.29cm.co.kr',
    settlement: 'https://settlement-api.29cm.co.kr',
    logistics: 'https://logistics-admin-api.29cm.co.kr',
    claim: 'https://claim-admin-api.29cm.co.kr',
    itemBatch: 'https://qa-item-batch-api.29cm.co.kr',
    promotion: 'https://promotion-admin-api.29cm.co.kr',
    activation: 'https://activation-api.29cm.co.kr',
    mileage: 'https://mileage-admin-api.29cm.co.kr',
    partner: 'https://partner-api.29cm.co.kr',
    commerce: 'https://commerce-admin-api.29cm.co.kr',
    payment: 'https://payment-api.29cm.co.kr',
    searchAdmin: 'https://search-admin-api.29cm.co.kr/',
    search: 'https://search-api.29cm.co.kr',
    tagAdmin: 'https://tag-admin-api.29cm.co.kr',
    user: 'https://user-api.29cm.co.kr',
  },
  'elasticsearch': {
    endpoint: 'https://web-log.29cm.co.kr',
  },
  'sdk': {
    unleash: {
      proxy: 'https://flag.29cm.co.kr/proxy',
      clientSecret: '3efc121a-0d46-4c9a-bdd1-a155c5488474',
    },
    kakao: {
      clientId: 'jrfG6IJNBEXLPQJdrUqD39kfe7k6vInZ', //29CM partner center developers
      apiKey: '97953ac3bf12765ea54b6fc39a05a8a9', //29CM partner center developers
      channelPublicId: '_zWbTG', //29CM partner center developers
    },
  },
  'admin': {
    mother: 'https://mother.29cm.co.kr',
    partner: 'https://partner.29cm.co.kr',
  },
};
