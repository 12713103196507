import React, { useCallback, useEffect } from 'react';
import { Dialog } from '../components';
import { DIALOG_APPLY_ID, useDialogEventEmitter } from '../hooks';
import { CreateDialogParams } from '../components/Dialog/Dialog.types';
import { alert, dialogDispatcher, emitter, Events } from '../utils/dialog';
import { InfoText } from '../components/InfoText';

export const DialogContainer = () => {
  const { dialogs, dispatch } = useDialogEventEmitter();

  useEffect(() => {
    dialogDispatcher({ dispatch });
    return () => {
      emitter.off();
    };
  }, [dispatch]);

  const handleSuccess = useCallback(async (id: string, cb?: () => Promise<void> | void | null) => {
    await cb?.();
    emitter.emit(Events.HIDE, id);
  }, []);

  const handleCancel = useCallback(async (id: string, cb?: () => Promise<void> | void | null) => {
    await cb?.();
    emitter.emit(Events.HIDE, id);
  }, []);

  const handleApply = useCallback(
    (id: string, cb?: () => Promise<unknown> | void | null, applyInfoTitle?: string, applyInfoDescription?: string) => {
      dialog.show({
        id: DIALOG_APPLY_ID,
        content: <InfoText title={applyInfoTitle} description={applyInfoDescription} />,
        onSuccess: async () => {
          await cb?.();
          emitter.emit(Events.HIDE, id);
        },
      });
    },
    [],
  );

  const handleEscapeEvent = useCallback((id: string) => {
    emitter.emit(Events.HIDE, id);
  }, []);

  return (
    <>
      {dialogs.map(
        ({
          id,
          open,
          content,
          onSuccess,
          onCancel,
          onApply,
          onEscape,
          applyInfoTitle,
          applyInfoDescription,
          ...restProps
        }) => {
          const handleDialogSuccess = () => handleSuccess(id, onSuccess);
          const handleDialogCancel = () => handleCancel(id, onCancel);
          const handleDialogApply = () => handleApply(id, onApply, applyInfoTitle, applyInfoDescription);
          const handleDialogEscape = () => handleEscapeEvent(id);

          return (
            <Dialog
              key={id}
              open={open}
              onSuccess={onSuccess && handleDialogSuccess}
              onCancel={handleDialogCancel}
              onApply={onApply && handleDialogApply}
              onEscape={handleDialogEscape}
              {...restProps}
            >
              {content}
            </Dialog>
          );
        },
      )}
    </>
  );
};

export const dialog = {
  show(params: CreateDialogParams) {
    emitter.emit(Events.SHOW, alert(params));
  },
  hide(id: string) {
    emitter.emit(Events.HIDE, id);
  },
};
