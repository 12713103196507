import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { ChevronIcon } from '../../../foundations/iconography';
import { Typography } from '../../../foundations/typography';
import { DropdownMenuSize } from '../DropdownMenu.types';
import { useDropdownMenuContext } from '../providers/DropdownMenuProvider';

interface Props {
  className?: string;
}

export const Trigger = ({ className, children }: PropsWithChildren<Props>) => {
  const { size, isToggled: open, onToggle: handleToggle } = useDropdownMenuContext();
  const TYPOGRAPHY_VARIANT = size === 'md' ? 'body/15-regular' : 'body/14-regular';

  const handleClick = () => {
    handleToggle(!open);
  };

  return (
    <Container className={className} size={size} active={open} onClick={handleClick}>
      <Placeholder>
        <Typography variant={TYPOGRAPHY_VARIANT} color="gray700">
          {children}
        </Typography>
      </Placeholder>
      <ChevronIcon direction={open ? 'up' : 'down'} color="gray500" />
    </Container>
  );
};

const Container = styled.div<{ size: DropdownMenuSize; active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: ${({ size }) => (size === 'md' ? '46px' : '36px')};

  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, active }) => (active ? theme.colors.blue500 : theme.colors.gray400)};
  border-radius: 4px;

  padding-right: 16px;
  padding-left: 16px;

  cursor: pointer;
`;

const Placeholder = styled.div`
  user-select: none;
`;
