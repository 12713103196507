import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface Props {
  className?: string;
  active: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const ToggleButton = ({
  className,
  disabled = false,
  active = false,
  onClick,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <Button className={className} disabled={disabled} active={active} onClick={onClick}>
      {children}
    </Button>
  );
};

const Button = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px;
  min-height: 36px;
  border: 1px solid ${({ theme }) => theme.colors.gray400};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.offWhite};
  color: ${({ theme }) => theme.colors.gray700};

  /* Body/14-Medium */
  font: normal 500 14px/22px 'Pretendard';

  ${({ theme, active }) =>
    active &&
    css`
      border: 1px solid ${theme.colors.blue500};
      color: ${theme.colors.blue500};
    `}

  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.offWhite};
    color: ${({ theme }) => theme.colors.gray400};
    border-color: ${({ theme }) => theme.colors.gray400};
  }
`;
