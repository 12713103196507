import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { ChangeEvent, PropsWithChildren, useId } from 'react';
import { colors, getColor } from '../../core';
import { CheckIcon } from '../Icons';

type Theme = keyof typeof colors;

interface Props {
  className?: string;
  inputCss?: SerializedStyles;
  labelCss?: SerializedStyles;
  name?: string;
  theme?: Theme;
  checked: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export const Checkbox = ({
  className,
  inputCss,
  labelCss,
  name,
  theme = 'blue',
  checked,
  disabled,
  children,
  onChange,
}: PropsWithChildren<Props>) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }

    onChange?.(e.target.checked);
  };

  const radioId = useId();

  return (
    <Container className={className}>
      <Input
        type="checkbox"
        css={inputCss}
        id={radioId}
        name={name}
        themes={theme}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <Label css={labelCss} htmlFor={radioId} title={name}>
        <Box>{checked ? <StyledCheckIcon /> : null}</Box>
        {children}
      </Label>
    </Container>
  );
};

const Box = styled.div`
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  text-align: center;
  background: ${colors.white};
  border: 1px solid ${colors.line_gray01};
  border-radius: 2px;
  transition: background-color 0.2s;
`;

const Label = styled.label`
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: inline-flex;
  min-height: 20px;
  padding-left: 29px;
  font-size: 14px;
  color: ${colors.line_gray01};
  vertical-align: top;
`;

const Input = styled.input<{ themes: Theme }>`
  position: absolute;
  z-index: -1;
  top: 2px;
  left: 2px;
  overflow: hidden;
  width: 1px;
  height: 1px;
  appearance: none;
  visibility: hidden;
  background: transparent;
  border: 0;

  &:checked + ${Label} {
    font-weight: 600;

    ${Box} {
      background: ${({ themes }) => getColor(themes) ?? colors.blue};
      border-color: ${({ themes }) => getColor(themes) ?? colors.blue};
    }
  }

  &:disabled + ${Label} {
    pointer-events: none;
    cursor: not-allowed;
    color: ${colors.bg_gray01};

    ${Box} {
      background: ${colors.bg_gray01};
    }
  }
`;

const Container = styled.span`
  position: relative;
  overflow: hidden;
  display: inline-block;
  min-width: 18px;
  min-height: 18px;
  line-height: 20px;
  vertical-align: top;

  ${Label} {
    padding: 0 0 0 26px;
    font-size: 14px;
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
