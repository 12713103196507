import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MouseEventHandler, PropsWithChildren } from 'react';
import { colors, getColor } from '../../core';

type Theme = keyof typeof colors;

interface Props {
  className?: string;
  active: boolean;
  disabled?: boolean;
  theme?: Theme;
  onToggle?: (active: boolean) => void;
}

const ANIMATION_MS = 260;

export const Toggle = ({
  className,
  active,
  theme = 'blue',
  disabled,
  onToggle,
  children,
}: PropsWithChildren<Props>) => {
  const handleToggle: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (disabled) {
      return;
    }

    const nextValue = !active;
    onToggle?.(nextValue);
  };

  return (
    <ToggleButton className={className} type="button" onClick={handleToggle} disabled={disabled}>
      <CircleWrapper active={active} themes={theme} disabled={disabled}>
        <Circle active={active} disabled={disabled} />
      </CircleWrapper>

      {children ? <ChildrenWrapper>{children}</ChildrenWrapper> : null}
    </ToggleButton>
  );
};

const ToggleButton = styled.button`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ disabled }) => disabled && 'cursor: not-allowed;'};
`;

const CircleWrapper = styled.div<{ active: boolean; disabled?: boolean; themes: Theme }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 20px;
  background-color: ${({ active, themes }) => (active ? getColor(themes) : 'none')};
  border: 1px solid ${({ active, themes }) => (active ? getColor(themes) : colors.line_gray01)};
  border-radius: 16px;
  transition: all ${ANIMATION_MS}ms ease-in-out;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${colors.white};
      border-color: ${colors.line_gray01};
    `};
`;

const ChildrenWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const Circle = styled.div<{ active: boolean; disabled?: boolean }>`
  position: absolute;
  left: 3px;
  width: 14px;
  height: 14px;
  background-color: ${colors.line_gray01};
  border-radius: 100%;
  transition: all ${ANIMATION_MS}ms ease-in-out;

  ${({ active }) =>
    active &&
    css`
      transform: translate(14px, 0);
      background-color: ${colors.white};
      transition: all ${ANIMATION_MS}ms ease-in-out;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${colors.line_gray01};
    `};
`;
