import { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import { TagType } from './TagInput.types';

interface UseTagInputParamType {
  onTagAdd: (tag: TagType) => void;
}

export const useTagInput = ({ onTagAdd }: UseTagInputParamType) => {
  const [text, setText] = useState<string>('');

  const handleTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setText(e.currentTarget.value);
  };

  const handleTextKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (text === '') {
      return;
    }

    if (e.key === 'Enter') {
      onTagAdd(text);
      setText('');
    }
  };

  return {
    text,
    handleTextChange,
    handleTextKeyDown,
  };
};
