import config from 'next/config';
import { tag } from '@29cm/admin-env';
import { env as development } from './env.development';
import { env as qa } from './env.qa';
import { env as production } from './env.production';

interface NextConfig {
  publicRuntimeConfig: {
    APP_ENV: 'development' | 'qa' | 'production';
  };
}

// eslint-disable-next-line
const { publicRuntimeConfig: runtimeEnv = { APP_ENV: 'production' } }: NextConfig = config() ?? {};

const appEnv = runtimeEnv.APP_ENV ?? process.env.APP_ENV ?? 'production';

const environments = {
  development,
  qa,
  production,
};

const baseEnvironment = environments[appEnv] ?? environments.development;

export const env = {
  ...baseEnvironment,
  app: {
    tag,
    sentryDsn: 'https://81c6c45d5d464b809fb4b4b7275ed819@s3ntry.29cm.co.kr/31',
  },
};

export type EnvironmentType = typeof env;

export default env;
