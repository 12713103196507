import { useEffect, useState } from 'react';

export const useVisibleTopRightTooltip = () => {
  // TODO: 디자인팀 육종선님 이 노출 날짜 알려주실 예정 기본값만 true로 바꾸면 됌
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShowTooltip(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  return {
    showTooltip,
  };
};
