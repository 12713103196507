import { ThemeProvider as BaseThemeProvider } from '@emotion/react';
import React, { ComponentProps } from 'react';
import { theme as defaultTheme, Theme } from '../../core/theme';

interface Props extends Omit<ComponentProps<typeof BaseThemeProvider>, 'theme'> {
  theme?: Theme;
}

export const ThemeProvider = ({ theme = defaultTheme, ...props }: Props) => {
  return <BaseThemeProvider theme={theme} {...props} />;
};
