import { VerificationFailMessageErrorCode } from '~apps/auth/@shared/apis/join/JoinService.types';
import { ShowToastParams } from '@29cm/admin-design-system';

// 서버에서 정의된 errorCode이외의 값을 넘겨주면 타입이 꺠져 런타임 에러가 나는걸 방지하고자 key type을 string으로 완화합니다.
export const VERIFICATION_FORM_ERROR_MESSAGE: Record<string, Pick<ShowToastParams, 'message' | 'subMessage'>> = {
  [VerificationFailMessageErrorCode.DUPLICATED_ID]: {
    message: '이미 인증된 계정입니다.',
    subMessage: '로그인 화면으로 이동해서 진행해주세요.',
  },
  [VerificationFailMessageErrorCode.NOT_ALLOWED_INTERNAL_REGISTRATION]: {
    message: '관리자 어드민으로 접속하였습니다.',
    subMessage: '파트너 어드민에서 인증을 진행해주세요.',
  },
  [VerificationFailMessageErrorCode.NOT_EXISTS_EMAIL]: {
    message: '입력한 정보가 올바르지 않습니다.',
    subMessage: '아이디와 이메일 정보를 다시 확인해주세요.',
  },
  [VerificationFailMessageErrorCode.ADMIN_APPROVAL_REQUIRED]: {
    message: '관리자 승인이 필요한 계정입니다.',
  },
};
