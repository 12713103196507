import { colors } from '../../../core';

interface Props {
  className?: string;
  fill?: string;
  stroke?: string;
}

export const EditIcon = ({ className, fill = colors.primary, stroke = colors.white }: Props) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.7502 5.25C10.2587 4.74147 10.513 4.48721 10.8188 4.4308C10.9387 4.40868 11.0617 4.40868 11.1816 4.4308C11.4874 4.48721 11.7417 4.74147 12.2502 5.25V5.25C12.7587 5.75853 13.013 6.01279 13.0694 6.31861C13.0915 6.43853 13.0915 6.56147 13.0694 6.68139C13.013 6.98721 12.7587 7.24147 12.2502 7.75L8.36812 11.6321C8.14757 11.8526 8.0373 11.9629 7.91434 12.0531C7.73307 12.1862 7.53066 12.2877 7.31563 12.3535C7.16976 12.3981 7.01544 12.4205 6.70678 12.4654V12.4654C6.1386 12.548 5.85451 12.5894 5.63985 12.5236C5.32235 12.4264 5.07384 12.1778 4.97659 11.8604C4.91083 11.6457 4.95216 11.3616 5.0348 10.7934V10.7934C5.0797 10.4848 5.10215 10.3304 5.14674 10.1846C5.21247 9.96954 5.314 9.76713 5.44705 9.58586C5.5373 9.4629 5.64757 9.35262 5.86812 9.13207L9.7502 5.25Z"
        stroke={stroke}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.79187 9.20833L8.45853 10.875L6.79187 9.20833Z" fill={stroke} />
      <path d="M6.79187 9.20833L8.45853 10.875" stroke={stroke} strokeLinecap="square" />
    </svg>
  );
};
