import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

interface Props {
  className?: string;
}

export const List = ({ className, children }: PropsWithChildren<Props>) => {
  return <Container className={className}>{children}</Container>;
};

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 4px;

  &::after {
    position: absolute;
    left: 0;
    bottom: 0;

    width: calc(100% - 32px);
    margin: 0 16px;

    border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    content: '';
  }

  &:last-child::after {
    display: none;
  }
`;
