import { KeyOfColor } from '../..';
import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

interface Props extends BaseIconProps {
  borderColor?: KeyOfColor;
}

export const QuestionIcon = ({ className, borderColor = 'gray400', color = 'gray600' }: Props) => {
  const styledBorderColor = getColor(borderColor);
  const styledColor = getColor(color);

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7.5" stroke={styledBorderColor} />
      <path
        d="M7.13151 9.60324V9.66802H8.37922V9.60324C8.39756 8.72335 8.68503 8.33468 9.41897 7.94062C10.1957 7.51957 10.6667 6.94197 10.6667 6.07287C10.6667 4.84211 9.60246 4 8.01224 4C6.55659 4 5.38227 4.75034 5.33334 6.12686H6.65444C6.70949 5.32794 7.35169 4.97166 8.01224 4.97166C8.74619 4.97166 9.33334 5.39811 9.33334 6.07287C9.33334 6.63968 8.94191 7.03914 8.42814 7.31444C7.62081 7.75709 7.14986 8.18893 7.13151 9.60324ZM6.93579 11.2335C6.92967 11.6545 7.32723 11.9946 7.80429 12C8.27524 11.9946 8.66668 11.6545 8.67279 11.2335C8.66668 10.8124 8.27524 10.4669 7.80429 10.4669C7.32723 10.4669 6.92967 10.8124 6.93579 11.2335Z"
        fill={styledColor}
      />
    </svg>
  );
};
