import { ReactNode, forwardRef, useId } from 'react';
import ReactSelect, { ActionMeta, GroupBase, InputActionMeta, MultiValue, SingleValue } from 'react-select';
import { theme } from '../../core';
import { isClient } from '../../utils';
import { dropdownStyle } from './SearchSelect.styles';
import { SelectIndicator } from './SearchSelectIndicator';
import { BaseSelect } from './base-select';

export type SearchSelectOptionType<Label = string, Value = string> = {
  label: Label;
  value: Value;
};
export type SearchSelectOptionValueType<Label = string, Value = string> =
  | MultiValue<SearchSelectOptionType<Label, Value>>
  | SingleValue<SearchSelectOptionType<Label, Value>>;
export type SearchSelectSize = 'md' | 'sm';

export interface SearchSelectProps {
  id?: string;
  className?: string;
  size?: SearchSelectSize;
  inputValue: string;
  value?: SearchSelectOptionValueType;
  onInputChange: (newValue: string, actionMeta: InputActionMeta) => void;
  onChange: (newValue: SearchSelectOptionValueType, actionMeta: ActionMeta<SearchSelectOptionType>) => void;
  options: SearchSelectOptionType[];
  placeholder: ReactNode;
  isClearable?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  hint?: string;
}

export const SearchSelect = forwardRef<
  BaseSelect<SearchSelectOptionType<string, string>, boolean, GroupBase<SearchSelectOptionType<string, string>>>,
  SearchSelectProps
>(
  (
    {
      className,
      id,
      size = 'md',
      inputValue,
      value,
      onInputChange,
      onChange,
      options,
      placeholder,
      isClearable = true,
      isDisabled = false,
      hasError = false,
      hint,
    },
    ref,
  ) => {
    const uniqueId = useId();
    const componentId = id ?? uniqueId;

    return (
      <ReactSelect
        ref={ref}
        className={className}
        id={componentId}
        inputId={`input-${componentId}`}
        instanceId={`instance-${componentId}`}
        inputValue={inputValue}
        value={value}
        onInputChange={onInputChange}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        menuPortalTarget={isClient() ? document.querySelector('body') : null}
        styles={dropdownStyle(size, hasError)}
        theme={(selectTheme) => ({
          ...selectTheme,
          colors: {
            ...selectTheme.colors,
            neutral50: theme.colors.gray500,
          },
        })}
        components={SelectIndicator({ isClearable, hasError, hint })}
        isClearable={isClearable}
        isDisabled={isDisabled}
      />
    );
  },
);
