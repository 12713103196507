import { useEffect } from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

const GTM_ID = 'GTM-PC9NSCB';

export const useInitializeGoogleTagManager = (options?: TagManagerArgs) => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: GTM_ID,
      ...options,
    });
  }, [options]);
};
