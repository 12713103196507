interface Props {
  className?: string;
  stroke?: string;
  strokeWidth?: number;
}

export const CheckIcon = ({ className, stroke = 'white', strokeWidth = 2 }: Props) => {
  return (
    <svg
      className={className}
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.875L4.33333 10L11 1"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
