import { KeyOfColor } from '.';

export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  offWhite: '#F8F8FC',
  red100: '#FFF3F0',
  red500: '#FF4800',
  blue100: '#F3F5FF',
  blue200: '#DDE4FF',
  blue300: '#ADBEFF',
  blue500: '#375FFF',
  blue700: '#0A31CC',
  gray100: '#F4F4F9',
  gray200: '#EFEFF4',
  gray300: '#ECECF1',
  gray400: '#D8D8DD',
  gray500: '#A4A4AF',
  gray600: '#757582',
  gray700: '#393948',
  gray800: '#181C32',
  gray900: '#00051E',
  green100: '#E8FFEA',
  green500: '#12BA23',
  yellow100: '#FFF9E3',
  yellow500: '#FFA800',
  burgundy100: '#FFE9E9',
  burgundy500: '#891A01',
} as const;

export const getColor = (key: KeyOfColor) => {
  return colors[key];
};
