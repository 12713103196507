import { Typography } from '@29cm/admin-design-system';
import { CSSPixelValue, Flex, Stack, padding } from '@29cm/admin-emotion-utils';
import styled from '@emotion/styled';
import { CommonHeadlessLayout } from '@inhouse/core';
import { PropsWithChildren, ReactNode, forwardRef } from 'react';
import { useControlSideMenuCollapse } from '~apps/@shared/layouts/AuthLayout.hooks';

interface Props {
  className?: string;
  title?: string;
  subTitle?: ReactNode;
  horizontalPadding?: CSSPixelValue;
}

export const AuthLayout = forwardRef<HTMLDivElement | null, PropsWithChildren<Props>>(
  ({ className, title, subTitle, children, horizontalPadding = 80 }, ref) => {
    const createSubTitleElement = (node: ReactNode) => {
      if (!node) {
        return null;
      }

      if (typeof node === 'string') {
        return (
          <Typography variant="heading/22-semiBold" color="gray800">
            {node}
          </Typography>
        );
      }

      return node;
    };

    useControlSideMenuCollapse();

    return (
      <>
        <CommonHeadlessLayout title={title} />
        <Container as="section" ref={ref} className={className}>
          <Card horizontalPadding={horizontalPadding} gap={16}>
            <Flex justify="center">{createSubTitleElement(subTitle)}</Flex>
            <Flex direction="column" align="center">
              {children}
            </Flex>
          </Card>
        </Container>
      </>
    );
  },
);

const Container = styled(Flex.Center)`
  height: 100vh;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.gray100};
`;

const Card = styled(Stack)<{ horizontalPadding: CSSPixelValue }>`
  width: min(70%, 480px);
  background-color: ${({ theme }) => theme.colors.white};

  ${({ horizontalPadding }) =>
    padding({
      x: horizontalPadding,
      y: 40,
    })}
`;
