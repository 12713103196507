import { useState } from 'react';
import { useScrollObserver } from './useScrollObserver';

export const useScrollIsBottom = () => {
  const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);

  const setScrollRef = useScrollObserver(({ scrollHeight, clientHeight, scrollTop }) => {
    const checkScrollBottom = scrollHeight === clientHeight + scrollTop;
    setIsScrollBottom(checkScrollBottom);
  });
  return {
    isScrollBottom,
    setScrollRef,
  };
};
