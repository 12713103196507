import styled from '@emotion/styled';
import { forwardRef } from 'react';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import { colors } from '../../core';

interface Props extends TextareaAutosizeProps {
  className?: string;
}

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(({ className, ...restProps }, ref) => {
  return <StyledTextarea ref={ref} className={className} minRows={3} maxRows={10} {...restProps} />;
});

const StyledTextarea = styled(TextareaAutosize)`
  width: 100%;
  margin: 0;
  padding: 12px 18px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-size-adjust: none;
  word-break: break-all;
  appearance: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.line_gray01};
  border-radius: 4px;
  outline: none;

  &::placeholder {
    color: ${colors.text_gray01};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.bg_gray01};
  }
`;
