import axios from 'axios';
import { NextPageContext } from 'next';
import type { AppContext, AppInitialProps } from 'next/app';
import { parseCookies } from 'nookies';

import { getEnvName } from './getEnvName';

type GetInitialPropsFunction<T extends AppInitialProps> = (appContext: AppContext) => Promise<T>;

// NOTE: Server Side 에서만 실행되는 코드입니다.
export const withCookies = <T extends AppInitialProps>(cb: GetInitialPropsFunction<T>) => {
  return async (appContext: AppContext) => {
    // 헬스체크는 쿠키를 만들 필요없습니다.
    if (appContext.ctx.pathname === '/lifecycle/health') {
      return cb(appContext);
    }

    const headers = appContext.ctx.req?.headers ?? {};

    const nextPageContextForNookies: Pick<NextPageContext, 'req'> = appContext.ctx;
    const cookies = parseCookies(nextPageContextForNookies);
    const envName = getEnvName();
    const cookieSuffix = envName ? `_${envName}` : '';
    const accessToken = cookies[`_fatn${cookieSuffix}`];

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    if (!appContext.ctx.req) {
      return cb(appContext);
    }

    Object.assign(appContext.ctx.req.headers, headers);

    if (headers.Authorization) {
      Object.assign(axios.defaults.headers, {
        common: {
          Authorization: headers.Authorization,
        },
      });

      return cb(appContext);
    }

    return cb(appContext);
  };
};
