/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export function useQueryParams<T extends { [key: string]: string } = { [key: string]: string }>(): Partial<T> {
  const router = useRouter();

  //  router.query, QS.parse에서 사용하는 타입인 ParsedUrlQuery가
  //    유효한 제네릭을 받지 않아 any로 처리합니다
  return useMemo(() => {
    return router.query as any;
  }, [router]);
}
