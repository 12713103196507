import { getColor } from '../../../core/colors';
import { BaseIconProps } from '../types/iconography.types';

export const AlignLeft = ({ className, color = 'gray700' }: BaseIconProps) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.33 12H2" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13 9.33334H2" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.33 6.66667H2" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13 4H2" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
