import styled from '@emotion/styled';
import { Select } from 'antd';
import { DropdownIcon } from '../Icons';

const { Option } = Select;

export interface SelectBoxItem {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface SelectBoxProps<T extends SelectBoxItem = SelectBoxItem> {
  className?: string;
  name?: string;
  items: T[] | readonly T[];
  selectedItem?: T;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  bordered?: boolean;
  onChange: (item: T) => void | Promise<void>;
}

export const SelectBox = ({
  className,
  items,
  selectedItem,
  defaultValue,
  placeholder,
  bordered,
  disabled,
  onChange,
}: SelectBoxProps) => {
  const handleChange = (value: unknown) => {
    const targetItem = items.find((item) => item.value === value);

    if (targetItem) {
      onChange(targetItem);
    }
  };

  return (
    <Select
      className={className}
      bordered={bordered}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={handleChange}
      value={selectedItem?.value}
      disabled={disabled}
      suffixIcon={<StyledDropdownIcon />}
    >
      {items.map((item) => (
        <Option key={item.value} value={item.value} disabled={item.disabled}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};

const StyledDropdownIcon = styled(DropdownIcon)`
  margin-right: 7px;
`;
