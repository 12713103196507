import { useReducer } from 'react';
import { EventEmitterDialogProps } from '../Dialog.types';

export type DialogAction =
  | { type: 'INIT'; dialog: EventEmitterDialogProps }
  | { type: 'SHOW' | 'HIDE' | 'REMOVE'; id: string }
  | { type: 'CAUTION'; args: { id: string; content: string } };

interface State {
  dialogs: EventEmitterDialogProps[];
  index: number;
}

const initialState: State = {
  dialogs: [],
  index: 0,
};

const dialogReducer = (state: State, action: DialogAction): State => {
  switch (action.type) {
    case 'INIT': {
      return {
        ...state,
        dialogs: [...state.dialogs, action.dialog],
        index: state.index + 1,
      };
    }
    case 'SHOW': {
      return {
        ...state,
        dialogs: state.dialogs.map((dialog) => {
          if (dialog.id !== action.id) {
            return dialog;
          }
          return {
            ...dialog,
            open: true,
          };
        }),
      };
    }
    case 'HIDE': {
      return {
        ...state,
        dialogs: state.dialogs.map((dialog) => {
          if (dialog.id !== action.id) {
            return dialog;
          }
          return {
            ...dialog,
            open: false,
          };
        }),
      };
    }
    case 'REMOVE': {
      return {
        ...state,
        dialogs: [...state.dialogs.filter((dialog) => dialog.id !== action.id)],
        index: state.index === 0 ? 0 : state.index - 1,
      };
    }
    case 'CAUTION': {
      const index = state.dialogs.findIndex((dialog) => dialog.id === action.args.id);

      if (index === -1) {
        return {
          ...state,
        };
      }

      const copied = [...state.dialogs];
      const copiedItem = copied[index];

      copiedItem.cautionText = action.args.content;
      copied[index] = copiedItem;

      return {
        ...state,
        dialogs: copied,
      };
    }
    default:
      throw new Error();
  }
};

export const useDialogEventEmitter = () => {
  const [state, dispatch] = useReducer(dialogReducer, initialState);

  return { ...state, dispatch };
};
