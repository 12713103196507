import styled from '@emotion/styled';
import { useMergeRefs } from '@floating-ui/react';
import { forwardRef } from 'react';
import { useTooltipContext } from '../providers/TooltipProvider';

export const TooltipTrigger = forwardRef<HTMLElement, React.HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const context = useTooltipContext();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const childrenRef = (children as any).ref;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    return (
      <Container
        ref={ref}
        // The user can style the trigger based on the state
        data-state={context.open ? 'open' : 'closed'}
        {...context.getReferenceProps(props)}
      >
        {children}
      </Container>
    );
  },
);

const Container = styled.div`
  cursor: pointer;
`;
