import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect } from 'react';
import { breakPoints, colors } from '../../core';
import { Button } from '../Button';
import { OverlaidPortal } from '../Portal';

interface Props {
  className?: string;
  overlayCss?: SerializedStyles;
  open: boolean;
  /** 모바일에서 풀사이즈로 표시할 지 유무 */
  responsiveFullSize?: boolean;
  /** 다이얼로그 콘텐츠 백그라운드 컬러 */
  backgroundColor?: string;
  /** 다이얼로그 콘텐츠 borderRadius*/
  borderRadius?: string;
  /** 오버레이 백그라운드 컬러 (기본값: 투명) */
  overlayColor?: string;
  /** 오버레이 클릭 시 닫힘 여부 */
  overlayCloseable?: boolean;
  /** 기본 콘텐츠 텍스트 정렬 (기본값: center) */
  textAlign?: 'left' | 'center' | 'right';
  /** 확인 버튼 텍스트 */
  successText?: React.ReactNode;
  /** 확인 버튼 배경색상 */
  successCss?: SerializedStyles;
  /** 취소 버튼 텍스트 */
  cancelText?: React.ReactNode;
  /** 취소 버튼 배경색상 */
  cancelCss?: SerializedStyles;
  /** 확인, 취소 버튼 숨김 유무 */
  hideButton?: boolean;
  /** 다이얼로그 그림자 유무 */
  hasShadow?: boolean;
  /** 커스텀 닫기 버튼 */
  closeButton?: React.ReactElement;
  /** 확인 버튼 눌렀을 때 액션 */
  onSuccess?: () => Promise<void> | void | null;
  /** 취소, X 버튼 눌렀을 때 액션 */
  onCancel?: () => Promise<void> | void | null;
  /** 적용 버튼 텍스트 */
  applyText?: React.ReactNode;
  /** 적용 눌렀을 때 액션 */
  onApply?: () => Promise<unknown> | void | null;
  /** 적용 눌렀을 때 추가 다이얼로그 내용 */
  applyInfoTitle?: string;
  /** 적용 눌렀을 때 추가 다이얼로그 내용 */
  applyInfoDescription?: string;
  onEscape?: () => void;
}

/**
 * 사용법
 * ```
 * import { dialog } from '@29cm/uio';
 * ...
 * dialog.show({
 *   ...props,
 * })
 * ```
 */
export const Dialog = ({
  className,
  overlayCss,
  open,
  responsiveFullSize = false,
  backgroundColor = colors.white,
  borderRadius,
  overlayColor = 'transparent',
  overlayCloseable = false,
  children,
  textAlign = 'left',
  hideButton,
  closeButton,
  successText = '확인',
  successCss,
  cancelText = '취소',
  cancelCss,
  applyText = '적용',
  hasShadow = true,
  onSuccess,
  onCancel,
  onApply,
  onEscape,
}: React.PropsWithChildren<Props>) => {
  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  const escapeEventHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        onEscape?.();
      }
    },
    [onEscape],
  );

  useEffect(() => {
    document.body.addEventListener('keydown', escapeEventHandler);

    return () => {
      document.body.removeEventListener('keydown', escapeEventHandler);
    };
  }, [escapeEventHandler, onEscape]);

  return (
    <OverlaidPortal
      overlayCss={overlayCss}
      overlayColor={overlayColor}
      opened={open}
      onClose={onCancel}
      closeable={overlayCloseable}
      alwaysVisibled
    >
      <Container
        className={className}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        open={open}
        responsiveFullSize={responsiveFullSize}
        onClick={stopPropagation}
        hasShadow={hasShadow}
      >
        {closeButton &&
          React.cloneElement(closeButton, {
            type: 'button',
            onClick: onCancel,
          })}

        <Content textAlign={textAlign}>{children}</Content>

        {!hideButton && (
          <ButtonContainer>
            {onCancel && (
              <StyledButton type={onSuccess || onApply ? 'line' : 'fill'} onClick={onCancel} css={cancelCss}>
                {onSuccess || onApply ? cancelText : successText}
              </StyledButton>
            )}
            {onSuccess && (
              <StyledButton type="fill" onClick={onSuccess} css={successCss}>
                {successText}
              </StyledButton>
            )}

            {onApply && (
              <StyledButton type="fill" onClick={onApply}>
                {applyText}
              </StyledButton>
            )}
          </ButtonContainer>
        )}
      </Container>
    </OverlaidPortal>
  );
};

const Container = styled.div<{
  open: boolean;
  responsiveFullSize: boolean;
  hasShadow: boolean;
  backgroundColor?: string;
  borderRadius?: string;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px;
  min-width: 300px;
  text-align: center;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : colors.white)};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : 0)};
  ${({ hasShadow }) =>
    hasShadow &&
    css`
      box-shadow: rgb(0 0 0 / 50%) 20px 20px 80px 0px;
    `}
  transform: translate(-50%, -75%) scale(0.975);
  transition: all 250ms ease-out;
  opacity: 0;

  ${({ open }) =>
    open &&
    css`
      transform: translate(-50%, -50%) scale(1);
      transition: all 100ms ease-in;
      opacity: 1;
    `}

  ${({ open, responsiveFullSize }) =>
    responsiveFullSize &&
    css`
      ${breakPoints.mobile} {
        padding: 50px 12px 12px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        ${open && 'transform: translate(0, 0);'};
      }
    `}

  ${breakPoints.mobile} {
    overflow: auto;
  }
`;

const Content = styled.div<{ textAlign: 'left' | 'center' | 'right' }>`
  font-size: 18px;
  color: ${colors.black};
  word-break: keep-all;
  white-space: pre-wrap;
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
  line-height: 1.4em;
`;

const ButtonContainer = styled.div`
  margin: 20px -4px 0;
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  margin: 0 4px;
`;
