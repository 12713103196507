interface ScreenSize {
  minWidth?: number;
  maxWidth?: number;
}

export function buildMediaQuery(screenSize: ScreenSize, not?: boolean): string {
  const { minWidth, maxWidth } = screenSize;
  let query = '@media ';

  if (minWidth) {
    query += `(min-width: ${minWidth}px)`;
  }

  if (minWidth && maxWidth) {
    query += ' and ';
  }

  if (maxWidth) {
    query += `(max-width: ${maxWidth}px)`;
  }

  if (not) {
    query = `not all and ${query}`;
  }

  return query;
}

export const SIZES = {
  pc: {
    minWidth: 541,
  },
  mobile: {
    maxWidth: 540,
  },
} as const;

export const breakPoints = {
  pc: buildMediaQuery(SIZES.pc),
  mobile: buildMediaQuery(SIZES.mobile),
} as const;
