import {
  flip,
  autoUpdate,
  offset,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useMemo, useState } from 'react';
import { CalendarProviderProps } from '../providers';

export const useCalendarPopupState = ({
  updateShowCalendarPopup,
}: Pick<CalendarProviderProps, 'updateShowCalendarPopup'>) => {
  const [isShowCalendarPopup, setIsShowCalendarPopup] = useState<boolean>(false);

  const floatingData = useFloating({
    placement: 'bottom-start',
    open: isShowCalendarPopup,
    onOpenChange: updateShowCalendarPopup,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: 5,
        crossAxis: -14, // left padding of InputBox
      }),
      flip(),
    ],
  });

  const context = floatingData.context;

  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'menu' });

  const interactions = useInteractions([focus, dismiss, role]);

  const popupState = useMemo(
    () => ({
      isShowCalendarPopup,
      setIsShowCalendarPopup,
      ...interactions,
      ...floatingData,
    }),
    [floatingData, interactions, isShowCalendarPopup],
  );

  return popupState;
};
