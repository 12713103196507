export const AUTH_FORM_REGEXP = {
  ID: /^[A-Za-z0-9_]{3,16}$/,
  // eslint-disable-next-line no-useless-escape
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[a-zA-Z\d@$!%*#?&]{8,20}$/,
};

export const AUTH_FORM_ERROR_MESSAGE = {
  EMPTY_OTP: '인증코드 6자리를 입력해주세요.',
  ID: '영문 혹은 영문과 숫자 조합 3~16 글자',
  EMPTY_ID: '아이디를 입력해주세요.',
  EMAIL: '이메일 형식이 올바르지 않습니다.',
  EMPTY_EMAIL: '이메일을 입력해주세요.',
  PASSWORD: '8~20자 이내의 영문 대소문자, 특수문자, 숫자의 조합',
  EMPTY_PASSWORD: '비밀번호를 입력해주세요.',
  CONFIRM_PASSWORD: '비밀번호가 일치하지 않습니다.',
  EMPTY_CONFIRM_PASSWORD: '비밀번호를 확인해주세요.',
  SAME_ID_PASSWORD: '아이디와 비밀번호는 같을 수 없습니다.',
};

export const AUTH_FORM_SUCCESS_MESSAGE = {
  SET_PASSWORD: '비밀번호 설정이 완료되었습니다.',
  REGISTER_OTP: '인증코드 등록이 완료되었습니다.',
};

export const AUTH_FORM_NOTICE = {
  JOIN: '기존에 사용하던 계정 아이디와 이메일 주소를 입력해 주세요.',
};
