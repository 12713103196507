import { BaseIconProps } from '../../types/iconography.types';
import { getColor } from '../../../../core/colors';

export const LargeCloseIcon = ({ className, color = 'blue500' }: BaseIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.22553 17.6839C4.92482 17.9846 4.92482 18.4721 5.22553 18.7728C5.52623 19.0735 6.01377 19.0735 6.31447 18.7728L11.9992 13.0881L17.6839 18.7728C17.9846 19.0735 18.4721 19.0735 18.7728 18.7728C19.0735 18.4721 19.0735 17.9846 18.7728 17.6839L13.0881 11.9992L18.7728 6.31447C19.0735 6.01377 19.0735 5.52623 18.7728 5.22553C18.4721 4.92482 17.9846 4.92482 17.6839 5.22553L11.9992 10.9102L6.31447 5.22553C6.01377 4.92483 5.52623 4.92483 5.22553 5.22553C4.92482 5.52624 4.92482 6.01377 5.22553 6.31448L10.9102 11.9992L5.22553 17.6839Z"
        fill={getColor(color)}
      />
    </svg>
  );
};
