import { showToast, useDialog } from '@29cm/admin-design-system';
import { noop } from '@29cm/admin-utils';
import { useCallback, useMemo } from 'react';
import { Result } from '~apps/@shared/apis/join/JoinService.types';
import { VERIFICATION_FORM_ERROR_MESSAGE } from '~apps/@shared/constants/verificationForm';
import { useErrorHandler } from '~apps/@shared/hooks';
import { usePostVerificationMutation } from '~apps/@shared/mutations/usePostVerificationMutation';
import { UNION_REGISTRATION_DIALOG_ID } from '~apps/login/constants/unionRegistrationDialogId';
import { useExistingInformationStore } from '~apps/login/stores/existing-information/existingInformation.store';
import { useUnionRegistrationStore } from '~apps/login/stores/union-registration/unionRegistration.store';
import { InputCommonErrorCode } from '~apps/login/stores/existing-information/existingInformation.types';

export const useCurrentStep = () => {
  const currentStep = useUnionRegistrationStore((state) => state.currentStep);

  return {
    currentStep,
  };
};

export const useBottomContainerRightButton = () => {
  const dialog = useDialog();
  const { currentStep, dispatchStep } = useUnionRegistrationStore((state) => ({
    currentStep: state.currentStep,
    dispatchStep: state.dispatchStep,
  }));

  const { id, email } = useExistingInformationStore((state) => ({
    id: state.id,
    email: state.email,
  }));

  const { validate } = useCheckClientValidation();

  const dispatchNextStep = useCallback(() => {
    dispatchStep(currentStep + 1);
  }, [currentStep, dispatchStep]);

  const { requestRegistration } = useRequestRegistration();

  const content = useMemo(() => {
    switch (currentStep) {
      case 1:
        return '다음';
      case 2:
        return '인증 요청';
      case 3:
        return '확인';
      default:
        return '';
    }
  }, [currentStep]);

  const onClick = useMemo(() => {
    switch (currentStep) {
      case 1:
        return dispatchNextStep;
      case 2:
        return async () => {
          if (!validate()) {
            return;
          }

          const success = await requestRegistration({
            loginId: id.value,
            email: email.value,
          });

          if (success) {
            dispatchNextStep();
          }
        };
      case 3:
        return () => {
          dialog.hide(UNION_REGISTRATION_DIALOG_ID);
        };
      default:
        return noop;
    }
  }, [currentStep, dispatchNextStep, validate, requestRegistration, id.value, email.value, dialog]);

  return {
    content,
    onClick,
  };
};

const useCheckClientValidation = () => {
  const { id, email, dispatchId, dispatchEmail } = useExistingInformationStore((state) => ({
    id: state.id,
    email: state.email,
    dispatchId: state.dispatchId,
    dispatchEmail: state.dispatchEmail,
  }));

  const validate = () => {
    if (!id.value) {
      dispatchId('error', InputCommonErrorCode.EMPTY);
    }
    if (!email.value) {
      dispatchEmail('error', InputCommonErrorCode.EMPTY);
    }

    if (!id.value || !email.value) {
      return false;
    }

    if (id.error || email.error) {
      return false;
    }

    return true;
  };

  return {
    validate,
  };
};

const useRequestRegistration = () => {
  const { mutateAsync, isLoading } = usePostVerificationMutation();
  const errorHandler = useErrorHandler();

  const requestRegistration = async (params: Parameters<typeof mutateAsync>[0]) => {
    try {
      const {
        data: { result, errorCode },
      } = await mutateAsync(params);

      if (result === Result.SUCCESS) {
        return true;
      }

      if (result === Result.FAIL) {
        showToast({
          ...VERIFICATION_FORM_ERROR_MESSAGE[errorCode],
          options: {
            position: 'top-center',
            type: 'error',
            duration: 1500,
          },
        });

        return false;
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  return {
    requestRegistrationIsLoading: isLoading,
    requestRegistration,
  };
};
