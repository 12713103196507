import { BaseIconProps } from '../types/iconography.types';
import { SmallCloseIcon } from './variants/SmallCloseIcon';
import { MediumCloseIcon } from './variants/MediumCloseIcon';
import { LargeCloseIcon } from './variants/LargeCloseIcon';

type CloseIconSize = 'small' | 'medium' | 'large';

interface Props extends BaseIconProps {
  size: CloseIconSize;
}

export const CloseIcon = ({ size, ...otherProps }: Props) => {
  switch (size) {
    case 'small': {
      return <SmallCloseIcon {...otherProps} />;
    }
    case 'medium': {
      return <MediumCloseIcon {...otherProps} />;
    }
    case 'large': {
      return <LargeCloseIcon {...otherProps} />;
    }

    default:
      return null;
  }
};
