import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Typography } from '../../../../foundations';

interface Props {
  className?: string;
}

export const Label = ({ className, children }: PropsWithChildren<Props>) => {
  return (
    <StyledTypography className={className} variant="body/14-regular" color="gray700">
      {children}
    </StyledTypography>
  );
};

const StyledTypography = styled(Typography)`
  text-align: left;
  margin-left: 2px;
`;
