import styled from '@emotion/styled';

export const InfoText = ({ title = '타이틀을 입력하세요', description = '설명을 입력하세요' }) => {
  return (
    <Container>
      <h3>{title}</h3>
      <p>{description}</p>
    </Container>
  );
};

const Container = styled.div`
  h3 {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }
`;
