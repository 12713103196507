import styled from '@emotion/styled';
import { Typography } from '../../../foundations/typography';
import { CalendarDate } from '../models';
import { createMonthlyDate, getDiffDaysFromNow, isAfterDate, isBeforeDate } from '../utils';
import { CalendarDateBox, DisabledCalendarDateBox } from './CalendarDateBox';
import { Flex, flex, padding, margin } from '@29cm/admin-emotion-utils';
import { parse } from 'date-fns/esm';

enum DAYS {
  SUNDAY = 0,
  SATURDAY = 6,
}

const WEEK_LIST: string[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

interface Props {
  className?: string;
  date: Date;
  isNeedPrevDatePrevents: boolean;
  preventBeforeDate?: Date;
  preventAfterDate?: Date;
}

export const CalendarContent = ({
  className,
  date,
  isNeedPrevDatePrevents,
  preventBeforeDate,
  preventAfterDate,
}: Props) => {
  const isPreventBeforeDate = (calendarDate: CalendarDate) => {
    if (!preventBeforeDate) {
      return;
    }
    return isBeforeDate(parse(calendarDate.fullDate, 'yyyy-MM-dd', new Date()), preventBeforeDate);
  };

  const isPreventAfterDate = (calendarDate: CalendarDate) => {
    if (!preventAfterDate) {
      return;
    }

    return isAfterDate(parse(calendarDate.fullDate, 'yyyy-MM-dd', new Date()), preventAfterDate);
  };

  const isPrevTodayPrevents = (calendarDate: CalendarDate) => {
    const diff = getDiffDaysFromNow({ date: calendarDate.fullDate, type: 'yyyy-MM-dd' });
    return isNeedPrevDatePrevents && diff > 0;
  };

  const isDateBoxDisabled = (calendarDate: CalendarDate) =>
    isPrevTodayPrevents(calendarDate) || isPreventAfterDate(calendarDate) || isPreventBeforeDate(calendarDate);

  return (
    <Container className={className}>
      {WEEK_LIST.map((title: string, i: number) => {
        const isHoliday = i % 7 === DAYS.SUNDAY || i % 7 === DAYS.SATURDAY;

        return (
          <Day as="div" key={title} variant="body/12-medium" color="gray500" isHoliday={isHoliday}>
            {title}
          </Day>
        );
      })}

      {createMonthlyDate(date).map((calendarDate) => {
        const key = [calendarDate.year, calendarDate.month, calendarDate.dayDate].join('-');
        return isDateBoxDisabled(calendarDate) ? (
          <DisabledCalendarDateBox key={key} calendarDate={calendarDate}></DisabledCalendarDateBox>
        ) : (
          <CalendarDateBox key={key} calendarDate={calendarDate} />
        );
      })}
    </Container>
  );
};

const Container = styled(Flex)`
  ${margin({
    x: -4,
  })};
  flex-wrap: wrap;
`;

const Day = styled(Typography)<{ isHoliday?: boolean }>`
  ${padding({
    x: 4,
  })};
  ${flex.center()};
  margin-bottom: 19px;
  min-width: 30px;
  width: 14.28571%;
  height: 20px;
  box-sizing: border-box;
`;
