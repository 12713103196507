import { Environment } from '@29cm/admin-env';
import { useContext } from 'react';
import { EnvironmentContext } from './providers';

export const useEnvironments = <T extends Environment>(): T => {
  const context = useContext(EnvironmentContext);

  if (!context) {
    throw new Error(`${EnvironmentContext.displayName ?? 'Context Provider'}와 함께 사용해야 합니다.`);
  }

  return context.environment as T;
};
