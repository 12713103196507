import { BaseIconProps } from './types/iconography.types';
import { getColor } from '../../core/colors/colors';

export const UploadIcon = ({ className, color = 'gray700' }: BaseIconProps) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2248_20461)">
        <path d="M7 9.33337L7 2.33337" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M3.88886 4.66675L6.99997 1.55564L10.1111 4.66675"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.4444 9.33337V11.4445C12.4444 11.9968 11.9967 12.4445 11.4444 12.4445H2.55554C2.00326 12.4445 1.55554 11.9968 1.55554 11.4445V9.33337"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2248_20461">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
