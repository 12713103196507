import { useState } from 'react';

export const useTooltip = () => {
  const [isShow, setIsShow] = useState(false);

  const handleTooltipEnter = () => {
    setIsShow(true);
  };

  const handleTooltipLeave = () => {
    setIsShow(false);
  };

  const handleTooltipClose = () => {
    setIsShow(false);
  };

  return {
    isShow,
    handleTooltipEnter,
    handleTooltipLeave,
    handleTooltipClose,
  };
};
