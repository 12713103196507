import { flex } from '@29cm/admin-emotion-utils';
import { SwitchCase } from '@29cm/admin-utils';
import styled from '@emotion/styled';
import { BottomContainer } from '~apps/login/components/dialogs/union-registration/bottom-container/BottomContainer';

import {
  UnionRegistrationFormStep1,
  UnionRegistrationFormStep2,
  UnionRegistrationFormStep3,
} from '~apps/login/components/dialogs/union-registration/form-step';
import {
  useBottomContainerRightButton,
  useCurrentStep,
} from '~apps/login/components/dialogs/union-registration/UnionRegistration.hooks';

export const UnionRegistration = () => {
  const { currentStep } = useCurrentStep();
  const { content, onClick } = useBottomContainerRightButton();

  return (
    <Container>
      <SwitchCase
        value={`${currentStep}`}
        caseBy={{
          1: <UnionRegistrationFormStep1 />,
          2: <UnionRegistrationFormStep2 />,
          3: <UnionRegistrationFormStep3 />,
        }}
      />
      <BottomContainer
        rightButton={{
          content,
          onClick,
        }}
      />
    </Container>
  );
};

const Container = styled.main`
  ${flex({ direction: 'column' })};
  margin-top: 40px;
`;
