import { getColor } from '../../core/colors/colors';
import { BaseIconProps } from './types/iconography.types';

export const CautionIcon = ({ className, color = 'red500' }: BaseIconProps) => {
  const fill = getColor(color);

  return (
    <svg
      className={className}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2373 11.663L8.17953 2.45523C7.91368 1.97124 7.21831 1.97124 6.95246 2.45523L1.89473 11.663C1.63848 12.1295 1.97601 12.7 2.50827 12.7H12.6237C13.156 12.7 13.4935 12.1295 13.2373 11.663ZM9.31895 1.82935C8.55938 0.44653 6.57261 0.446531 5.81304 1.82936L0.755306 11.0371C0.0231676 12.37 0.987541 14 2.50827 14H12.6237C14.1445 14 15.1088 12.37 14.3767 11.0371L9.31895 1.82935ZM7.49999 4.3C7.88659 4.3 8.19999 4.6134 8.19999 5V7.75608C8.19999 8.14267 7.88659 8.45608 7.49999 8.45608C7.11339 8.45608 6.79999 8.14267 6.79999 7.75608V5C6.79999 4.6134 7.11339 4.3 7.49999 4.3ZM8.2 9.99999C8.2 9.61339 7.8866 9.29999 7.5 9.29999C7.1134 9.29999 6.8 9.61339 6.8 9.99999V10.5104C6.8 10.897 7.1134 11.2104 7.5 11.2104C7.8866 11.2104 8.2 10.897 8.2 10.5104V9.99999Z"
        fill={fill}
      />
    </svg>
  );
};
