import { InputBox, Typography } from '@29cm/admin-design-system';
import { padding, Spacing, Stack } from '@29cm/admin-emotion-utils';
import styled from '@emotion/styled';
import { FormStepCommonLayout } from '~apps/login/components/dialogs/union-registration/form-step/form-step-common-layout/FormStepCommonLayout';
import {
  useEmail,
  useId,
  useNudgeIdInput,
} from '~apps/login/components/dialogs/union-registration/form-step/UnionRegistrationFormStep2.hooks';

export const UnionRegistrationFormStep2 = () => {
  const { id, idErrorMessage, handleIdChange } = useId();
  const { email, emailErrorMessage, handleEmailChange } = useEmail();
  const { idInputRef } = useNudgeIdInput();

  return (
    <FormStepCommonLayout>
      <Stack.Vertical align="center" gap={30}>
        <InputGroup gap={12}>
          <StyledInput
            ref={idInputRef}
            label="아이디"
            placeholder="아이디"
            value={id}
            hint={idErrorMessage}
            hasError={Boolean(idErrorMessage)}
            onChange={handleIdChange}
          />
          <StyledInput
            label="이메일"
            placeholder="이메일 주소를 입력해 주세요."
            value={email}
            hint={emailErrorMessage}
            hasError={Boolean(emailErrorMessage)}
            onChange={handleEmailChange}
          />
        </InputGroup>
        <Stack.Vertical gap={14} align="center">
          <Typography variant="heading/26-bold" color="gray800">
            기존에 사용하시던 아이디와 이메일을 입력해 주세요.
          </Typography>
          <Typography variant="body/15-regular" color="black">
            입력하신 이메일로 인증 링크가 발송됩니다.
          </Typography>
        </Stack.Vertical>
      </Stack.Vertical>
      <Spacing size={20} />
    </FormStepCommonLayout>
  );
};

const StyledInput = styled(InputBox)`
  width: 320px;
`;

const InputGroup = styled(Stack)`
  ${padding({
    x: 60,
    y: 40,
  })};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray200};
`;
