import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Placement } from '@floating-ui/react-dom';
import { Typography } from '../../foundations/typography';
import { TooltipContent, TooltipProvider, TooltipTrigger } from './headless';
import { TooltipOptions } from './headless/Tooltip.types';

export interface TooltipProps extends Pick<TooltipOptions, 'applySafePolygon'> {
  className?: string;
  tooltipText: React.ReactNode;
  autoWidth?: boolean;
  placement?: 'top' | 'bottom';
}

export const Tooltip = ({
  className,
  tooltipText,
  autoWidth,
  children,
  applySafePolygon,
  placement,
}: React.PropsWithChildren<TooltipProps>) => {
  return (
    <Container className={className}>
      <TooltipProvider applySafePolygon={applySafePolygon} placement={placement}>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent>
          <StyledTypography as="p" color="white" variant="body/12-regular" autoWidth={autoWidth} placement={placement}>
            {tooltipText}
          </StyledTypography>
        </TooltipContent>
      </TooltipProvider>
    </Container>
  );
};

const Container = styled.div``;

const StyledTypography = styled(Typography)<{ autoWidth?: boolean; placement?: Placement }>`
  width: ${({ autoWidth }) => (autoWidth ? 'auto' : '240px')};
  position: relative;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, -14px);
  background-color: ${({ theme }) => theme.colors.gray800};
  white-space: pre-line;
  padding: 12px 14px;
  border-radius: 4px;

  ${({ placement }) => {
    switch (placement) {
      case 'bottom':
        return css`
          transform: translate(-50%, 14px);
        `;
      default:
        return false;
    }
  }}

  &::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-bottom: none;
    border-top: 6px solid ${({ theme }) => theme.colors.gray800};
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    ${({ placement }) => {
      switch (placement) {
        case 'bottom':
          return css`
            top: -6px;
            left: 50%;
            margin-left: -12px;
            transform: rotate(180deg) translateX(-50%);
          `;
        default:
          return false;
      }
    }}
  }
`;
