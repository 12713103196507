import { CSSProperties } from 'react';

export enum CellElement {
  TH = 'th',
  TD = 'td',
}

export type TableSize = 'md' | 'sm';
export type AlignType = 'left' | 'center' | 'right';

export interface Column<DataRecord extends DefaultRecordType> {
  /**
   * @warn string 으로 해야함. number의 경우 버그가 굉장히 많음.
   */
  key: React.Key;
  title: string | React.ReactNode;
  dataIndex?: keyof DataRecord;
  width?: number | string;
  className?: string;
  hasError?: boolean;
  sticky?: boolean;
  align?: AlignType;
  hasImage?: boolean;
}

export interface FlattenColumn<DataRecord extends DefaultRecordType> extends Column<DataRecord> {
  stickyOffset?: number;
  lastSticky?: boolean;
}

export type Columns<DataRecord extends DefaultRecordType> = Column<DataRecord>[];
export type FlattenColumns<DataRecord extends DefaultRecordType> = FlattenColumn<DataRecord>[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface DefaultRecordType extends Record<string, any> {
  key?: string;
  backgroundColor?: CSSProperties['backgroundColor'];
}

export interface TableRowSelection {
  selectedRowKeys: React.Key[];
  disabledRowKeys?: React.Key[];
  onChange: (selectedKeys: React.Key[]) => void;
}
