export const env = {
  'development': true,
  'qa': false,
  'production': false,
  'name': 'development',
  'image': {
    host: 'https://img.29cm.co.kr',
  },
  'asset': {
    host: 'https://asset.29cm.co.kr',
  },
  '29cm': {
    product: 'https://dev-product.29cm.co.kr',
    shop: 'https://dev-shop.29cm.co.kr',
  },
  'workspace': {
    inhouse: {
      index: 'https://dev-inhouse-connect.29cm.co.kr',
      auth: 'https://dev-inhouse-auth.29cm.co.kr',
      brand: 'https://dev-inhouse-brand.29cm.co.kr',
      booking: 'https://dev-inhouse-booking.29cm.co.kr',
      content: 'https://dev-inhouse-content.29cm.co.kr',
      review: 'https://dev-inhouse-review.29cm.co.kr',
      search: 'https://dev-inhouse-search.29cm.co.kr',
      item: 'https://dev-inhouse-item.29cm.co.kr',
      order: 'https://dev-inhouse-order.29cm.co.kr',
      settlement: 'https://dev-inhouse-settlement.29cm.co.kr',
      regulation: 'https://dev-inhouse-regulation.29cm.co.kr',
      analytics: 'https://dev-inhouse-analytics.29cm.co.kr',
      display: 'https://dev-inhouse-display.29cm.co.kr',
    },
    partner: {
      index: 'https://dev-partner-connect.29cm.co.kr',
      auth: 'https://dev-partner-auth.29cm.co.kr',
      brand: 'https://dev-partner-brand.29cm.co.kr',
      booking: 'https://dev-partner-booking.29cm.co.kr',
      content: 'https://dev-partner-content.29cm.co.kr',
      review: 'https://dev-partner-review.29cm.co.kr',
      search: 'https://dev-partner-search.29cm.co.kr',
      item: 'https://dev-partner-item.29cm.co.kr',
      order: 'https://dev-partner-order.29cm.co.kr',
      settlement: 'https://dev-partner-settlement.29cm.co.kr',
      regulation: 'https://dev-partner-regulation.29cm.co.kr',
      analytics: 'https://dev-partner-analytics.29cm.co.kr',
    },
  },
  'api': {
    apihub: 'https://apihub.29cm.co.kr/dev',
    item: 'https://dev-item-admin.29cm.co.kr',
    brand: 'https://dev-brand-api.29cm.co.kr',
    booking: 'https://dev-booking-item-api.29cm.co.kr',
    auth: 'https://dev-authentication-api.29cm.co.kr',
    content: 'https://dev-content-api.29cm.co.kr',
    review: 'https://dev-review-api.29cm.co.kr',
    settlement: 'https://dev-settlement-api.29cm.co.kr',
    logistics: 'https://dev-logistics-admin-api.29cm.co.kr',
    claim: 'https://dev-claim-admin-api.29cm.co.kr',
    itemBatch: 'https://dev-item-batch-api.29cm.co.kr',
    promotion: 'https://dev-promotion-admin-api.29cm.co.kr',
    activation: 'https://dev-activation-api.29cm.co.kr',
    mileage: 'https://dev-mileage-admin-api.29cm.co.kr',
    partner: 'https://dev-partner-api.29cm.co.kr',
    commerce: 'https://dev-commerce-admin-api.29cm.co.kr',
    payment: 'https://dev-payment-api.29cm.co.kr',
    searchAdmin: 'https://dev-search-admin-api.29cm.co.kr/',
    search: 'https://dev-search-api.29cm.co.kr',
    tagAdmin: 'https://dev-tag-admin-api.29cm.co.kr',
    user: 'https://dev-user-api.29cm.co.kr',
  },
  'elasticsearch': {
    endpoint: 'https://dev-web-log.29cm.co.kr',
  },
  'sdk': {
    unleash: {
      proxy: 'https://dev-flag.29cm.co.kr/proxy',
      clientSecret: '0f5e8ef7-f1ea-4bec-af65-abb5e1925bea',
    },
    kakao: {
      clientId: 'jrfG6IJNBEXLPQJdrUqD39kfe7k6vInZ', //29CM partner center developers
      apiKey: '97953ac3bf12765ea54b6fc39a05a8a9', //29CM partner center developers
      channelPublicId: '_zWbTG', //29CM partner center developers
    },
  },
  'admin': {
    mother: 'https://mother-dev.29cm.co.kr',
    partner: 'https://partner-dev.29cm.co.kr',
  },
};
