import React from 'react';

import type { User } from '../models';
import type { AuthService } from '../services';

export interface AuthenticationContextType {
  authService?: AuthService;
  isLoggedIn: boolean;
  loading: boolean;
  userInfo: User;
  logout: () => void;
}

export const AuthenticationContext = React.createContext<AuthenticationContextType>({
  isLoggedIn: false,
  loading: false,
  userInfo: {
    loginId: '',
    adminId: 0,
    partnerId: null,
    email: '',
    accessToken: '',
    refreshToken: '',
    logged: false,
    expiredAt: null,
  },
  logout: () => {},
});
