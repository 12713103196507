import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useRef } from 'react';
import { useColumns, useInitScrollLeftPosition, useTableResize } from './Table.hooks';
import { AlignType, Columns, DefaultRecordType, TableSize } from './Table.types';
import { TableBody } from './TableBody';
import { TableColgroup } from './TableColgroup';
import { TableHeader } from './TableHeader';

interface Props<DataRecord extends DefaultRecordType> {
  columns: Columns<DataRecord>;
  data: readonly DataRecord[];
  defaultWidth?: number;
  align: AlignType;
  size: TableSize;
  verticalScroll: boolean;
  initScrollLeftPosition: number;
  className?: string;
}

export const TableContents = <DataRecord extends DefaultRecordType>({
  columns,
  data,
  defaultWidth,
  align,
  size,
  verticalScroll,
  initScrollLeftPosition,
  className,
}: Props<DataRecord>) => {
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const { tableWidth, showBackground, isHorizontalScroll, debouncedScroll } = useTableResize(
    tableWrapperRef,
    tableRef,
    defaultWidth,
  );
  const flattenColumns = useColumns<DataRecord>(columns, isHorizontalScroll, tableWidth);

  useInitScrollLeftPosition(initScrollLeftPosition, tableWrapperRef, data);

  return (
    <Container className={className} showBackground={showBackground}>
      <TableWrapper ref={tableWrapperRef} onScroll={debouncedScroll} verticalScroll={verticalScroll}>
        <StyledTable ref={tableRef} defaultWidth={defaultWidth} align={align}>
          <TableColgroup colWidths={flattenColumns.map(({ width }) => width)} flattenColumns={flattenColumns} />
          <TableHeader columns={columns} flattenColumns={flattenColumns} data={data} size={size} />
          <TableBody flattenColumns={flattenColumns} data={data} size={size} />
        </StyledTable>
      </TableWrapper>
    </Container>
  );
};

const Container = styled.div<{ showBackground?: boolean }>`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: ${({ showBackground }) => (showBackground ? 5 : -1)};
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    opacity: ${({ showBackground }) => (showBackground ? 1 : 0)};
    background: linear-gradient(270deg, #fff 0%, rgb(255 255 255 / 0%) 100%);
    transition: opacity 0.2s linear;
  }
`;

const TableWrapper = styled.div<{ verticalScroll: boolean }>`
  overflow-x: auto;
  width: 100%;

  ${({ verticalScroll }) =>
    verticalScroll &&
    css`
      overflow: auto;
      max-height: 70vh;
    `}
`;

const StyledTable = styled.table<{ defaultWidth?: number; align: AlignType }>`
  table-layout: fixed;
  border-collapse: separate;
  width: 100%;
  min-width: ${({ defaultWidth }) => defaultWidth && `${defaultWidth}px`};
  background-color: ${({ theme }) => theme.colors.white};

  th,
  td {
    text-align: ${({ align }) => align};
  }

  tr th:last-of-type,
  tr td:last-of-type {
    border-right: 0;
  }
`;
