import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CellElement, DefaultRecordType, FlattenColumn, TableSize } from './Table.types';

interface Props<DataRecord extends DefaultRecordType> {
  component?: CellElement;
  column: FlattenColumn<DataRecord>;
  data?: DataRecord;
  size: TableSize;
  hasError?: boolean;
  isFirstRow?: boolean;
}

export const TableCell = <DataRecord extends DefaultRecordType>({
  component = CellElement.TD,
  column,
  data,
  size,
  hasError = false,
  isFirstRow = false,
}: Props<DataRecord>) => {
  const { title, className, stickyOffset, sticky, align, lastSticky, hasImage = false } = column;

  const alignStyle: React.CSSProperties = {};
  const stickyStyle: React.CSSProperties = {};

  if (sticky) {
    stickyStyle.position = 'sticky';
    stickyStyle.left = stickyOffset;
    stickyStyle.zIndex = 2;
  }

  if (align) {
    alignStyle.textAlign = align;
  }

  switch (component) {
    case CellElement.TH:
      return (
        <ThCell
          className={className}
          lastSticky={lastSticky}
          size={size}
          hasError={hasError}
          style={{ ...alignStyle, ...stickyStyle }}
        >
          {/* @ts-ignore */}
          {data ? data : title}
        </ThCell>
      );
    case CellElement.TD:
      return (
        <TdCell
          className={className}
          lastSticky={lastSticky}
          size={size}
          hasImage={hasImage}
          isFirstRow={isFirstRow}
          style={{ ...alignStyle, ...stickyStyle }}
        >
          {/* @ts-ignore */}
          {data ? data : null}
        </TdCell>
      );
  }
};

const addPixel = (value: number) => `${value}px`;

const ThCell = styled.th<{ size: TableSize; hasError: boolean; lastSticky?: boolean }>`
  height: 46px;
  color: ${({ hasError, theme }) => (hasError ? theme.colors.red500 : theme.colors.gray600)};
  background: ${({ theme }) => theme.colors.gray100};
  border-right: ${({ lastSticky, theme }) =>
    lastSticky ? `1.5px solid ${theme.colors.gray400}` : `1px solid ${theme.colors.gray300}`};

  ${({ size }) => {
    switch (size) {
      case 'sm': {
        return css`
          padding: 9px 12px;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
        `;
      }
      case 'md': {
        return css`
          padding: 12px;
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
        `;
      }
    }
  }}
`;

const TdCell = styled.td<{ size: TableSize; lastSticky?: boolean; hasImage: boolean; isFirstRow: boolean }>`
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.gray700};
  word-wrap: break-word;
  background: ${({ theme }) => theme.colors.white};
  border-right: ${({ lastSticky, theme }) =>
    lastSticky ? `1.5px solid ${theme.colors.gray400}` : `1px solid ${theme.colors.gray300}`};

  ${({ size, hasImage, isFirstRow }) => {
    interface PaddingType {
      top: number;
      right: number;
      bottom: number;
      left: number;
    }

    const FIRST_ROW_PADDING_TOP = 10;
    const SIZE_SM_HEIGHT = 46;
    const SIZE_MD_HEIGHT = 100;

    const getPadding = (defaultPadding: PaddingType, imagePadding: PaddingType): string => {
      const rawPadding = hasImage ? imagePadding : defaultPadding;

      const { top, right, bottom, left } = {
        ...rawPadding,
        top: isFirstRow ? rawPadding.top + FIRST_ROW_PADDING_TOP : rawPadding.top,
      };

      return [top, right, bottom, left].map(addPixel).join(' ');
    };

    const getHeight = (defaultHeight: number) => {
      return isFirstRow ? `${defaultHeight + FIRST_ROW_PADDING_TOP}px` : `${defaultHeight}px`;
    };

    switch (size) {
      case 'sm': {
        const defaultPadding: PaddingType = {
          top: 10,
          right: 12,
          bottom: 10,
          left: 12,
        };

        const imagePadding: PaddingType = {
          top: 8,
          right: 12,
          bottom: 8,
          left: 12,
        };

        return css`
          height: ${getHeight(SIZE_SM_HEIGHT)};
          padding: ${getPadding(defaultPadding, imagePadding)};
          font-size: 12px;
        `;
      }
      case 'md': {
        const defaultPadding: PaddingType = {
          top: 22,
          right: 12,
          bottom: 22,
          left: 12,
        };

        const imagePadding: PaddingType = {
          top: 10,
          right: 10,
          bottom: 10,
          left: 10,
        };

        return css`
          height: ${getHeight(SIZE_MD_HEIGHT)};
          padding: ${getPadding(defaultPadding, imagePadding)};
          font-size: 14px;
        `;
      }
    }
  }}
`;
