import type { NextPageContext } from 'next';

import { getEnvName } from './getEnvName';
import { getPayloadFromToken } from './getPayloadFromToken';
import { getRefreshToken, NextContextType } from './getRefreshToken';
import { isExpiredToken } from './isExpiredToken';
import { AudienceType } from '../constants/cookie';

export const isLoggedIn = (audience: AudienceType, ctx?: NextContextType) => {
  const envName = getEnvName();
  const refreshToken = getRefreshToken(envName, ctx);

  if (!refreshToken) {
    return false;
  }

  const payload = getPayloadFromToken(refreshToken);
  const isExpiredRefreshToken = isExpiredToken(refreshToken);

  if (isExpiredRefreshToken) {
    return false;
  }

  if (!payload) {
    return false;
  }

  if (payload.aud !== audience) {
    return false;
  }

  return true;
};
