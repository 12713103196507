import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect } from 'react';
import { Portal } from './Portal';

export interface OverlayProps {
  className?: string;
  overlayCss?: SerializedStyles;
  children: React.ReactNode;
  opened: boolean;
  alwaysVisibled?: boolean;
  lazy?: boolean;
  overlayColor?: string;
  closeable?: boolean;
  onClose?: () => void;
  zIndex?: number;
}

export const OverlaidPortal = React.memo<OverlayProps>(
  ({
    className,
    overlayCss,
    lazy = false,
    overlayColor = 'hsla(0, 0%, 100%, .98)',
    opened,
    alwaysVisibled = false,
    closeable = true,
    zIndex = 3000,
    children,
    onClose,
  }) => {
    useEffect(() => {
      if (lazy && !opened) {
        return undefined;
      }

      document.body.style.overflow = opened ? 'hidden' : '';

      return () => {
        document.body.style.overflow = '';
      };
    }, [lazy, opened]);

    if (lazy && !opened) {
      return null;
    }

    return (
      <Portal>
        <Overlay
          className={className}
          css={overlayCss}
          zIndex={zIndex}
          overlayColor={overlayColor}
          visible={alwaysVisibled || opened}
          onClick={closeable ? onClose : undefined}
        >
          {children}
        </Overlay>
      </Portal>
    );
  },
);

export const Overlay = styled.div<{
  zIndex?: number;
  visible: boolean;
  overlayColor: string;
}>`
  position: fixed;
  z-index: ${(props) => props.zIndex};
  inset: 0;
  overflow: hidden;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? '1' : '0')};
  background-color: ${(props) => props.overlayColor};
`;
