import { size as _size, padding } from '@29cm/admin-emotion-utils';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getTypographyStyle } from '../../foundations';
import { useTabs } from './Tabs.hooks';
import { Colors, Sizes, Tab } from './Tabs.types';

interface Props {
  className?: string;
  color?: Colors;
  size?: Sizes;
  items: Tab[];
  defaultActiveTab?: Tab;
  onChange?: (key: string) => void;
}

export const Tabs = ({
  className,
  color = 'blue',
  size = 's',
  items,
  defaultActiveTab = items[0],
  onChange,
}: Props) => {
  const { activeTab, changeActiveTab } = useTabs(defaultActiveTab);

  const handleClick = (item: Tab) => {
    changeActiveTab(item);
    onChange?.(item.key);
  };

  return (
    <Container className={className} color={color} size={size}>
      {items.map((item) => (
        <ListItem key={item.key} color={color} active={item.key === activeTab.key}>
          <Button disabled={item.key === activeTab.key} size={size} onClick={() => handleClick(item)}>
            {item.label}
          </Button>
          {item.hasError ? <ErrorDot /> : null}
        </ListItem>
      ))}
    </Container>
  );
};

const Container = styled.ul<{ color: Colors; size: Sizes }>`
  display: flex;
  width: fit-content;
  padding: 3px;
  border-radius: 4px;

  ${({ theme, color }) => {
    switch (color) {
      case 'blue': {
        return css`
          background-color: ${theme.colors.blue100};
        `;
      }
      case 'gray': {
        return css`
          background-color: ${theme.colors.gray300};
        `;
      }
    }
  }}

  ${({ size }) => {
    switch (size) {
      case 's': {
        return css`
          ${padding({ x: 3, y: 3 })};
        `;
      }

      case 'm': {
        return css`
          ${padding({ x: 4, y: 4 })};
        `;
      }
    }
  }};
`;

const ListItem = styled.li<{ color: Colors; active?: boolean }>`
  border-radius: 3px;
  cursor: pointer;
  position: relative;

  ${({ theme, color, active }) => {
    switch (color) {
      case 'blue': {
        return css`
          color: ${active ? theme.colors.blue500 : theme.colors.blue300};
          background: ${active ? theme.colors.white : 'inherit'};
        `;
      }

      case 'gray': {
        return css`
          color: ${active ? theme.colors.gray700 : theme.colors.gray600};
          background: ${active ? theme.colors.white : 'inherit'};
        `;
      }
    }
  }};
`;

const ErrorDot = styled.div`
  position: absolute;
  top: 4px;
  right: 10.5px;
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.red500};
  border-radius: 100%;
`;

const Button = styled.button<{ size: Sizes }>`
  ${getTypographyStyle('body/14-semiBold')};
  color: inherit;

  ${({ size }) => {
    switch (size) {
      case 's': {
        return css`
          ${padding({ x: 16, y: 4 })};
        `;
      }

      case 'm': {
        return css`
          ${padding({ x: 0, y: 12 })};
          ${_size({ width: 160 })};
        `;
      }
    }
  }};
`;
