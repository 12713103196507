import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

/**
 ```tsx
  <LinkIcon 
    color="gray700" 
  />
 ```
 */
export const LinkIcon = ({ className, color = 'gray700' }: BaseIconProps) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.77273 5H11.5455C11.9335 5 12.3176 5.0776 12.6761 5.22836C13.0346 5.37913 13.3603 5.6001 13.6346 5.87868C13.909 6.15726 14.1266 6.48797 14.2751 6.85195C14.4236 7.21593 14.5 7.60603 14.5 8C14.5 8.39397 14.4236 8.78407 14.2751 9.14805C14.1266 9.51203 13.909 9.84274 13.6346 10.1213C13.3603 10.3999 13.0346 10.6209 12.6761 10.7716C12.3176 10.9224 11.9335 11 11.5455 11H9.77273M6.22727 11H4.45455C4.06655 11 3.68235 10.9224 3.32389 10.7716C2.96543 10.6209 2.63972 10.3999 2.36537 10.1213C1.81128 9.55871 1.5 8.79565 1.5 8C1.5 7.20435 1.81128 6.44129 2.36537 5.87868C2.91945 5.31607 3.67095 5 4.45455 5H6.22727"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.11108 8H10.8889" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
