import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { colors } from '../../core';

export interface StickySubMenuItem {
  value: string;
  label: string;
}

interface Props {
  className?: string;
  items: StickySubMenuItem[];
  selectedItem?: StickySubMenuItem;
  rightComponent?: ReactNode;
  onClick?: (item: StickySubMenuItem) => void;
}

export const StickySubMenu = ({ className, items, selectedItem, rightComponent, onClick }: Props) => {
  return (
    <Container className={className}>
      <MenuList>
        {items.map((item: StickySubMenuItem) => {
          const handleClick = () => onClick?.(item);

          return (
            <Menu key={item.value}>
              <MenuButton type="button" active={item.value === selectedItem?.value} onClick={handleClick}>
                {item.label}
              </MenuButton>
            </Menu>
          );
        })}
      </MenuList>

      {rightComponent ? <Right>{rightComponent}</Right> : null}
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  z-index: 4;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.primary};
  border-bottom: 1px solid ${colors.line_gray01};
`;

const MenuList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -20px;
`;

const Menu = styled.li`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 10px;
`;

const MenuButton = styled.button<{ active?: boolean }>`
  padding: 15px 10px;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  color: ${({ active }) => (active ? colors.primary : colors.text_gray01)};
`;

const Right = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
`;
