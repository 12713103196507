interface Props {
  className?: string;
  stroke?: string;
}

export const DropdownIcon = ({ className, stroke = '#111111' }: Props) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 7L9 3L12 7" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 11L9 15L6 11" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
