import { getColor } from '../../core/colors';
import { BaseIconProps } from './types/iconography.types';

export const ImageIcon = ({ className, color = 'gray700' }: BaseIconProps) => {
  const stroke = getColor(color);

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2778 2.5H3.72222C3.04721 2.5 2.5 3.04721 2.5 3.72222V12.2778C2.5 12.9528 3.04721 13.5 3.72222 13.5H12.2778C12.9528 13.5 13.5 12.9528 13.5 12.2778V3.72222C13.5 3.04721 12.9528 2.5 12.2778 2.5Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5001 10.2L10.2001 6.90002L3.6001 13.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.9501 5.52498C6.9501 6.31198 6.3121 6.94998 5.5251 6.94998C4.73809 6.94998 4.1001 6.31198 4.1001 5.52498C4.1001 4.73797 4.73809 4.09998 5.5251 4.09998C6.3121 4.09998 6.9501 4.73797 6.9501 5.52498Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
