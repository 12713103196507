import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { useMemo } from 'react';
import { CalendarDate } from '../models';
import { useDateInputBoxContext } from '../providers';

export const useSelectedDateBox = (calendarDate: CalendarDate) => {
  const { beginDate, endDate } = useDateInputBoxContext();

  return useMemo(() => {
    const isBegin = beginDate ? differenceInCalendarDays(beginDate, calendarDate.date) === 0 : false;
    const isEnd = endDate ? differenceInCalendarDays(endDate, calendarDate.date) === 0 : false;
    const isToday = differenceInCalendarDays(new Date(), calendarDate.date) === 0;
    const isBetween = isBetweenPeriod({
      calendarDate,
      beginDate,
      endDate,
    });

    return {
      isBegin,
      isBetween,
      isEnd,
      isToday,
    };
  }, [calendarDate, beginDate, endDate]);
};

export const useDateBoxHandler = (calendarDate: CalendarDate) => {
  const { isNeedEndDate, beginDate, endDate, updateBeginDate, updateEndDate, syncInputBoxFromCalendar } =
    useDateInputBoxContext();

  const handleClick = () => {
    if (
      beginDate &&
      endDate &&
      differenceInCalendarDays(beginDate, calendarDate.date) === 0 &&
      differenceInCalendarDays(endDate, calendarDate.date) === 0
    ) {
      updateBeginDate(null);
      updateEndDate(null);
      syncInputBoxFromCalendar(null, null);
      return;
    }

    if (beginDate && endDate && differenceInCalendarDays(beginDate, calendarDate.date) === 0) {
      updateBeginDate(beginDate);
      updateEndDate(beginDate);
      syncInputBoxFromCalendar(beginDate, beginDate);
      return;
    }

    if (beginDate && differenceInCalendarDays(beginDate, calendarDate.date) === 0) {
      updateBeginDate(null);
      syncInputBoxFromCalendar(null, endDate);
      return;
    }

    if (!isNeedEndDate) {
      updateBeginDate(calendarDate.date);
      syncInputBoxFromCalendar(calendarDate.date, endDate);
      return;
    }

    if (!beginDate) {
      updateBeginDate(calendarDate.date);
      updateEndDate(calendarDate.date);
      syncInputBoxFromCalendar(calendarDate.date, calendarDate.date);
      return;
    }

    if (differenceInCalendarDays(beginDate, calendarDate.date) > 0) {
      updateBeginDate(calendarDate.date);
      updateEndDate(calendarDate.date);
      syncInputBoxFromCalendar(calendarDate.date, calendarDate.date);
      return;
    }

    if (endDate && differenceInCalendarDays(endDate, calendarDate.date) === 0) {
      updateEndDate(endDate);
      syncInputBoxFromCalendar(endDate, endDate);
      return;
    }

    if (
      beginDate &&
      endDate &&
      differenceInCalendarDays(beginDate, calendarDate.date) <= 0 &&
      differenceInCalendarDays(endDate, calendarDate.date) >= 0
    ) {
      updateBeginDate(calendarDate.date);
      updateEndDate(calendarDate.date);
      syncInputBoxFromCalendar(calendarDate.date, calendarDate.date);
      return;
    }

    if (
      beginDate &&
      endDate &&
      differenceInCalendarDays(beginDate, endDate) !== 0 &&
      differenceInCalendarDays(endDate, calendarDate.date) < 0
    ) {
      updateBeginDate(calendarDate.date);
      updateEndDate(calendarDate.date);
      syncInputBoxFromCalendar(calendarDate.date, calendarDate.date);
      return;
    }

    if (beginDate && differenceInCalendarDays(beginDate, calendarDate.date) <= 0) {
      updateEndDate(calendarDate.date);
      syncInputBoxFromCalendar(beginDate, calendarDate.date);
      return;
    }
  };

  return {
    handleClick,
  };
};

interface BetweenPeriodParams {
  calendarDate: CalendarDate;
  beginDate: Date | null;
  endDate: Date | null;
}

const isBetweenPeriod = ({ calendarDate, beginDate, endDate }: BetweenPeriodParams) => {
  if (!beginDate) {
    return false;
  }

  if (!endDate) {
    return false;
  }

  return (
    differenceInCalendarDays(beginDate, calendarDate.date) < 0 &&
    differenceInCalendarDays(endDate, calendarDate.date) > 0
  );
};
