import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { useMergeRefs } from '@floating-ui/react';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { breakPoints, colors } from '../../core';
import { RemoveIcon, Typography } from '../../foundations';
import { Button } from '../button';
import { OverlaidPortal } from '../portal';
import { CONTAINER_WIDTH_BY_SIZE, TYPOGRAPHY_VARIANT_BY_SIZE } from './Dialog.constants';
import { SizeValue } from './Dialog.types';
import { useIsOverflow, useScrollIsBottom } from './hooks/';
import { useDialogHandler } from './hooks/useDialogHandler';

interface Props {
  className?: string;
  overlayCss?: SerializedStyles;
  subContainerCss?: SerializedStyles;
  size?: SizeValue;
  /** 제목 */
  title?: string | React.ReactNode;
  /** 제목 스타일 */
  titleCss?: SerializedStyles;
  /** 서브 제목 */
  subtitle?: string;
  /** 버튼과 같은 선상 경고 문구 */
  cautionText?: string;
  /** 버튼과 같은 선상 안내 문구 */
  infoText?: string;
  open: boolean;
  /** 다이얼로그 콘텐츠 백그라운드 컬러 */
  backgroundColor?: string;
  /** 다이얼로그 콘텐츠 borderRadius*/
  borderRadius?: string;
  /** 오버레이 백그라운드 컬러 (기본값: 0.4) */
  overlayColor?: string;
  /** 오버레이 클릭 시 닫힘 여부 */
  overlayCloseable?: boolean;
  /** 확인 버튼 텍스트 */
  successText?: React.ReactNode;
  /** 확인 버튼 배경색상 */
  successCss?: SerializedStyles;
  /** 취소 버튼 텍스트 */
  cancelText?: React.ReactNode;
  /** 취소 버튼 배경색상 */
  cancelCss?: SerializedStyles;
  /** 적용 버튼 텍스트 */
  subText?: React.ReactNode;
  /** 적용 버튼 배경색상 */
  subCss?: SerializedStyles;
  /** 확인, 취소 버튼 숨김 유무 */
  hideButton?: boolean;
  /** 닫기 버튼 유무*/
  hideCloseButton?: boolean;
  /** 확인 버튼 눌렀을 때 액션 */
  onSuccess?: () => Promise<void> | void | null;
  /** 취소, X 버튼 눌렀을 때 액션 */
  onCancel?: () => Promise<void> | void | null;
  /** 적용 버튼 눌렀을 때 액션 */
  onSubSuccess?: () => Promise<void> | void | null;
  /** onSuccess를 실행시킬때 Dialog를 닫을것인지 여부 (기본값: true) */
  alwaysCloseOnSuccess?: boolean;
  /** onSubSuccess를 실행시킬때 Dialog를 닫을것인지 여부 (기본값: false) */
  alwaysCloseOnSubSuccess?: boolean;
}

/**
 * 사용법
 * ```
 * import { dialog } from '@29cm/admin-design-system';
 * ...
 * dialog.show({
 *   ...props,
 * })
 * ```
 */
export const Dialog = ({
  className,
  overlayCss,
  subContainerCss,
  size = 'md',
  title,
  titleCss,
  subtitle,
  cautionText,
  infoText,
  open,
  backgroundColor = colors.white,
  borderRadius = '8px',
  overlayColor = 'rgba(0,0,0,.4)',
  overlayCloseable = false,
  children,
  hideButton,
  successText = '확인',
  successCss,
  cancelText = '취소',
  cancelCss,
  subCss,
  subText = '기존 설정 초기화',
  hideCloseButton,
  onSuccess,
  onSubSuccess,
  onCancel,
}: React.PropsWithChildren<Props>) => {
  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  const { isOverflow, setResizeRef } = useIsOverflow();
  const { isScrollBottom, setScrollRef } = useScrollIsBottom();
  const { isLoading, handleSuccess } = useDialogHandler(onSuccess);
  const isOverflowTopBorderNeeds = isOverflow && !isScrollBottom;

  const mergedRefs = useMergeRefs([setResizeRef, setScrollRef]);

  const refModal = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      refModal.current?.focus();
    }
  }, [open]);

  const titleVariant = TYPOGRAPHY_VARIANT_BY_SIZE[size];

  return (
    <OverlaidPortal
      overlayCss={overlayCss}
      overlayColor={overlayColor}
      opened={open}
      onClose={onCancel}
      closeable={overlayCloseable}
      alwaysVisibled
    >
      <Container
        className={className}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        open={open}
        onClick={stopPropagation}
        size={size}
        ref={refModal}
        tabIndex={-1}
      >
        <SubContainer css={subContainerCss}>
          {!hideCloseButton && (
            <CloseButton onClick={onCancel}>
              <RemoveIcon />
            </CloseButton>
          )}

          {title &&
            (React.isValidElement(title) ? (
              title
            ) : (
              <Title variant={titleVariant} color="gray700" size={size} css={titleCss}>
                {title}
              </Title>
            ))}
          {subtitle && (
            <Subtitle variant="body/13-regular" color="gray600">
              {subtitle}
            </Subtitle>
          )}
          <ContentContainer ref={mergedRefs}>
            <section>{children}</section>
          </ContentContainer>
        </SubContainer>

        {!hideButton && (
          <BottomContainer
            justify={cautionText ? 'space-between' : 'flex-end'}
            isOverflowTopBorderNeeds={isOverflowTopBorderNeeds}
          >
            {cautionText && (
              <Typography variant="body/13-regular" color="red500">
                {cautionText}
              </Typography>
            )}

            {infoText && (
              <StyledTypography variant="body/13-regular" color="gray500">
                {infoText}
              </StyledTypography>
            )}

            <ButtonContainer>
              {onCancel && (
                <StyledButton color="secondaryGray" onClick={onCancel} css={cancelCss} size="md">
                  {cancelText}
                </StyledButton>
              )}
              {onSubSuccess && (
                <StyledButton onClick={onSubSuccess} css={subCss} size="md">
                  {subText}
                </StyledButton>
              )}
              {onSuccess && (
                <StyledButton onClick={handleSuccess} css={successCss} isLoading={isLoading} size="md">
                  {successText}
                </StyledButton>
              )}
            </ButtonContainer>
          </BottomContainer>
        )}
      </Container>
    </OverlaidPortal>
  );
};

const Container = styled.div<{
  open: boolean;
  backgroundColor: string;
  borderRadius: string;
  size: SizeValue;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%) scale(0.975);
  display: flex;
  flex-direction: column;
  width: ${({ size }) => CONTAINER_WIDTH_BY_SIZE[size]};
  min-height: 150px;
  max-height: calc(100% - 120px);
  opacity: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  transition: all 250ms ease-out;

  ${({ open }) =>
    open &&
    css`
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      transition: all 100ms ease-in;
    `}
  ${breakPoints.mobile} {
    overflow: auto;
  }
`;

const SubContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px 30px 0;
`;

const ContentContainer = styled.div`
  flex: 1;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Title = styled(Typography)<{ size: SizeValue }>`
  display: block;
  margin-bottom: ${({ size }) => size !== 'sm' && '30px'};
`;
const Subtitle = styled(Typography)`
  display: block;
  margin-top: 6px;
  white-space: pre-line;
`;

const BottomContainer = styled.div<{ justify: CSSProperties['justifyContent']; isOverflowTopBorderNeeds: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
  padding: 30px;
  border-top: ${({ isOverflowTopBorderNeeds, theme }) =>
    isOverflowTopBorderNeeds && ` 1px solid ${theme.colors.gray400}`};
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin: 0 4px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
`;

const StyledTypography = styled(Typography)`
  margin-right: 16px;
`;
