import { colors } from '../../../core';

interface Props {
  className?: string;
  lineColor?: string;
  backgroundColor?: string;
}

export const CloseIcon = ({ className, backgroundColor = colors.black, lineColor = colors.white }: Props) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill={backgroundColor} />
      <rect x="5.88931" y="5.18198" width="10" height="1" transform="rotate(45 5.88931 5.18198)" fill={lineColor} />
      <rect x="12.9604" y="5.8891" width="10" height="1" transform="rotate(135 12.9604 5.8891)" fill={lineColor} />
    </svg>
  );
};
