import { env } from '@29cm/admin-env';
import { NextPageContext } from 'next';
import type { AppContext } from 'next/app';
import NextAppBase from 'next/app';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import urlJoin from 'url-join';
import { AudienceType } from '../constants/cookie';
import { getAccessToken } from './getAccessToken';
import { getEnvName } from './getEnvName';
import { getRefreshToken } from './getRefreshToken';
import { isExpiredToken } from './isExpiredToken';
import { isLoggedIn } from './isLoggedIn';
import { isServer } from './isServer';
import { refetchToken } from './refetchToken';

interface Params {
  context: AppContext;
  cookie: string;
  audience: AudienceType;
}

interface PageProps {
  $cookie: string;
  dehydratedState: unknown;
}

interface SystemError {
  code: string;
  message: string;
}

const EXPIRED_TOKEN_MESSAGE = '로그인이 만료되었습니다.\n로그인 페이지로 이동합니다.';
const REDIRECT_QUERY_KEY = 'redirect_uri';

export const createPagePropsWithAuth = async ({ context, cookie, audience }: Params) => {
  const appProps = await NextAppBase.getInitialProps(context);
  const prevPageProps = (appProps.pageProps as PageProps) ?? {};
  const nextPageProps = {
    ...appProps,
    pageProps: {
      ...prevPageProps,
      $cookie: cookie,
    },
  };

  const nextPageContextForNookies: Pick<NextPageContext, 'req' | 'res'> = context.ctx;
  const host = context.ctx.req?.headers.host;
  const envName = getEnvName();
  const cookies = parseCookies(nextPageContextForNookies);
  const cookieSuffix = envName ? `_${envName}` : '';
  const domain = `.29cm.co.kr`;
  const accessTokenCookieName = `_fatn${cookieSuffix}`;
  const refreshTokenCookieName = `_frtn${cookieSuffix}`;
  const cookieOptions = {
    domain,
    path: '/',
  };

  const previousAudience = cookies[`_fws${cookieSuffix}`];
  const shouldSetAudienceCookie = previousAudience !== audience;

  const currentPath = host ? `https://${host}${context.ctx.asPath ?? ''}` : undefined;

  const workspaceType = audience === AudienceType.PARTNER ? 'partner' : 'inhouse';
  const authBaseUrl = env.workspace[workspaceType].auth;
  const loginUrl = urlJoin(authBaseUrl, 'login', `?${REDIRECT_QUERY_KEY}=${encodeURIComponent(currentPath ?? '')}`);

  // `?${REDIRECT_QUERY_KEY}=${encodeURIComponent(context.ctx.)}`
  if (shouldSetAudienceCookie) {
    setCookie(nextPageContextForNookies, `_fws${cookieSuffix}`, audience, cookieOptions);
  }

  const isLogged = isLoggedIn(audience, context.ctx);

  const redirectPageTo = (path: string) => {
    if (context.ctx.req && context.ctx.res) {
      context.ctx.res.statusCode = 302;
      context.ctx.res.setHeader('Location', path);
    }

    return nextPageProps;
  };

  const accessToken = getAccessToken(envName, context.ctx);
  const refreshToken = getRefreshToken(envName, context.ctx);
  const hasAccessToken = Boolean(accessToken);
  const hasRefreshToken = Boolean(refreshToken);

  const showAlert = (message: string) => {
    if (isServer()) {
      return;
    }

    alert(message);
  };

  if (hasAccessToken && !hasRefreshToken) {
    destroyCookie(nextPageContextForNookies, accessTokenCookieName, cookieOptions);
    destroyCookie(nextPageContextForNookies, refreshTokenCookieName, cookieOptions);

    showAlert(EXPIRED_TOKEN_MESSAGE);
  }

  if (isExpiredToken(accessToken) && hasRefreshToken) {
    try {
      await refetchToken(nextPageContextForNookies);
    } catch (error) {
      const err = error as SystemError;

      if (err.message === 'EXPIRED_TOKEN') {
        showAlert(EXPIRED_TOKEN_MESSAGE);
        return redirectPageTo(loginUrl);
      }

      showAlert(err.message);
    }
  }

  if (isLogged) {
    return nextPageProps;
  }

  return redirectPageTo(loginUrl);
};
